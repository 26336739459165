import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const RouteGuard = ({ component: Component, ...rest }: any) => {
  function hasJWT() {
    let flag = false;

    //check user has JWT token
    localStorage.getItem("accessToken") ? (flag = true) : (flag = false);

    return flag;
  }

  return hasJWT() ? <Outlet /> : <Navigate to="/" />;
};

export default RouteGuard;
