import { Outlet } from "react-router-dom";
import * as Yup from "yup";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LakeSuperior from "../../../assets/images/lake-superior.jpg";

// Styled Components
const BGCol = styled(Col)`
  background-image: url("${LakeSuperior}");
  background-size: cover;
  background-position: center;
  z-index: -1;
`;

export default function LoginRegister() {
  return (
    <Container fluid className="h-100">
      <Row className="h-100">
        <Col sm={8} lg={6} className="py-5 bg-light shadow">
          <Row className="justify-content-center h-100">
            <Col
              sm={11}
              md={11}
              xl={10}
              xxl={8}
              className="d-flex flex-column justify-content-between"
            >
              <Outlet />
            </Col>
          </Row>
        </Col>

        <BGCol sm={4} lg={6} className="d-none d-sm-block" />
      </Row>
    </Container>
  );
}
