import axios from "axios";

export const getSalt = async (username: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Auth/salt?UserName=${username}`
    );

    return response.data.salt;
  } catch (error) {
    console.error(error);
  }
};
