import { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { Calendar, DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/esm/InputGroup";
import { Formik } from "formik";
import {
  DiffResult,
  getDifferentValues,
} from "../../helpers/getDifferentValues";
import { postDeadline } from "../../api/postDeadline";
import { deleteDeadline } from "../../api/deleteDeadline";
import { getFormattedDateYYYYMMDD } from "../../helpers/getFormattedDateYYYYMMDD";
import { apiSuccess } from "../../helpers/apiSuccess";
import { Organization } from "../../models/organization";
import { saveSettings } from "../../api/saveSettings";
import Alert from "react-bootstrap/esm/Alert";

export default function EditCatchReportDeadlines({
  organizationID,
  deadlines,
  hasGracePeriod,
  gracePeriodDays,
}: any) {
  const [value, setValue] = useState<any[]>(
    deadlines.map((dl: string) => new Date(dl))
  );
  const [alert, setAlert] = useState({
    show: false,
    variant: "",
    message: "",
  });

  useEffect(() => {
    setValue(deadlines.map((dl: string) => new Date(dl)));
  }, [deadlines]);

  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const clickDay = (dates: any) => {
    // Reset error
    setAlert({
      show: false,
      variant: "",
      message: "",
    });

    // Dates array passed in from the calendar.
    const newDates = dates.map((d: DateObject) => {
      const temp = d.toDate();
      return getFormattedDateYYYYMMDD(String(temp));
    });

    // Original / Previous dates
    const orgDates = value?.map((d: any) => {
      const temp = new Date(d);
      return formatDate(temp);
    });

    // Get the difference from the two arrays
    const diffValues: DiffResult<string>[] = getDifferentValues(
      [...newDates],
      orgDates
    );

    // Add or delete the value from the server.
    diffValues.forEach(async ({ val, added, removed }) => {
      let response;

      if (added) {
        // Add a date to the deadline
        response = await postDeadline(val);
        if (apiSuccess(response.status)) {
          setValue(dates);
        } else {
          setAlert({
            show: true,
            variant: "danger",
            message: `Error: ${response.data.message}`,
          });
          setValue([...value]);
        }
      } else if (removed) {
        // Remove a date from the deadline
        response = await deleteDeadline(val);
        if (apiSuccess(response.status)) {
          setValue(dates);
        } else {
          setAlert({
            show: true,
            variant: "danger",
            message: `Error: ${response.data.message}`,
          });
          setValue([...value]);
        }
      }
    });
  };

  return (
    <div className="form-card">
      <Formik
        enableReinitialize
        initialValues={{
          hasGracePeriod: hasGracePeriod,
          gracePeriodDays: gracePeriodDays ?? 0,
        }}
        onSubmit={async (values) => {
          const data: Organization = {
            id: organizationID,
            ...values,
          };

          const response = await saveSettings(data);
          if (apiSuccess(response.status)) {
            setAlert({
              show: true,
              variant: "success",
              message: "Grace Period updated successfully",
            });
          } else {
            setAlert({
              show: true,
              variant: "danger",
              message: "Whoops, something went wrong",
            });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form noValidate onSubmit={handleSubmit} autoComplete="off">
            <Row className="flex-wrap">
              <Col xs={12} xl>
                <Calendar
                  className="mb-3"
                  multiple
                  value={value}
                  onChange={clickDay}
                  plugins={[<DatePanel />]}
                />
              </Col>
              {organizationID !== 1 && (
                <Col className="d-flex flex-column justify-content-end">
                  <Row>
                    <Col
                      className="d-flex flex-column justify-content-end"
                      xs={12}
                      sm={6}
                      xl={12}
                      xxl={6}
                    >
                      <Form.Check
                        type="switch"
                        name="hasGracePeriod"
                        id="gracePeriodSwitch"
                        className="mb-3"
                        label="Enable Grace Period"
                        checked={values.hasGracePeriod}
                        onChange={handleChange}
                      />

                      {values.hasGracePeriod && (
                        <>
                          <Form.Label>Grace Period</Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              type="number"
                              placeholder="Grace Period Days"
                              aria-label="Grace Period Days"
                              aria-describedby="gracePeriodDays"
                              name="gracePeriodDays"
                              min={0}
                              value={values.gracePeriodDays}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.gracePeriodDays &&
                                !!errors.gracePeriodDays
                              }
                            />
                            <InputGroup.Text id="gracePeriod">
                              Days
                            </InputGroup.Text>
                          </InputGroup>
                        </>
                      )}
                    </Col>
                    {/* Don't show for CORA */}
                    <Col
                      xs={12}
                      sm={6}
                      xl={12}
                      xxl={6}
                      className="d-flex flex-column justify-content-end"
                    >
                      <Button
                        type="submit"
                        variant="secondary"
                        className="w-100 mb-3 form-btn-sm"
                      >
                        Save Grace Period
                      </Button>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
            {alert.show && (
              <Alert variant={alert.variant}>{alert.message}</Alert>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
