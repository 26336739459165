import axios from "axios";

export interface Organization {
  ID: number;
  Code: string;
  Name: string;
  title?: string;
  subtitle?: string;
  supportEmail?: string;
  logo?: string;
}

export const getOrganization = async (id: any, code: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/User/organization?ID=${id}&Code=${code}`
    );
    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
