import { useEffect, useImperativeHandle, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReviewDailyTrip from "./reviewDailyTrip";
import { DailyTrip } from "../../models/dailyTrip";
import { getTrip } from "../../api/getTrip";
import { lookupMeshSizes } from "../../api/lookupMeshSizes";
import { MeshSize } from "../../models/meshSize";

export default function ReadonlyReviewModal({ refId }: any) {
  useImperativeHandle(refId, () => ({
    handleShow,
    loadDailyTrip,
  }));

  const [dailyTrip, setDailyTrip] = useState<DailyTrip>();
  const [meshList, setMeshList] = useState<MeshSize[]>([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const getMeshSizes = await lookupMeshSizes();
      if (getMeshSizes) setMeshList(getMeshSizes);
    };
    fetch();
  }, []);

  // Open the dialog and load daily trip
  const handleShow = () => setShow(true);

  // Click close button
  const handleClose = () => setShow(false);

  // load Daily Trip
  const loadDailyTrip = async (tripID: any) => {
    const trip = await getTrip(tripID);
    const newDT = new DailyTrip(trip);
    trip.fishingDate = trip.fishingDate?.split("T")[0];
    setDailyTrip(newDT);
    handleShow();
  };

  const handleFormData = (data: any) => {};

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        dialogClassName="daily-trip-modal"
        keyboard={false}
      >
        <Modal.Header
          closeButton
          className="bg-primary text-light align-items-start px-3 px-lg-5"
        >
          <Modal.Title className="w-100 d-flex justify-content-between align-items-end flex-wrap h6">
            <div className="h1 mb-0">Review</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3 px-lg-5 border-none pb-3 pt-0 bg-background">
          <h2 className="display-2 mt-3">
            {dailyTrip &&
              new Date(
                dailyTrip.fishingDate.replace(/-/g, "/")
              ).toLocaleDateString()}
          </h2>
          {dailyTrip?.fisherName && (
            <p className="mb-1">
              <span className="fw-bold">Fisher:</span> {dailyTrip.fisherName}
            </p>
          )}
          {dailyTrip?.licenseNumber && (
            <p className="mb-1">
              <span className="fw-bold">License Number:</span>{" "}
              {dailyTrip.licenseNumber}
            </p>
          )}
          <hr />

          <ReviewDailyTrip
            initialValues={dailyTrip}
            formData={handleFormData}
            meshList={meshList}
            isReadonly={true}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between px-3 px-lg-5 border-none pb-3 pt-0 bg-background">
          <Button variant="outline-primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
