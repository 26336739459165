import axios from "axios";
import { convertObjectToUrlParams } from "../helpers/convertObjToUrlParams";

export interface CrewSearch {
  name?: string;
  licenseNumber: string;
  licenseType?: string;
}

export const getCrewSearchAll = async (search: CrewSearch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Crew/search-all${convertObjectToUrlParams(
        search
      )}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
