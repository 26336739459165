import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  ColumnDef,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getSortedRowModel,
} from "@tanstack/react-table";

import { lookupSpeciesList } from "../../api/lookupSpeciesList";
import Table from "react-bootstrap/esm/Table";
import { fuzzyFilter } from "../../components/tables/fuzzyFilter";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import { fuzzySort } from "../../components/tables/fuzzySort";

export default function AdminSpeciesList() {
  const [data, setData] = React.useState([]);

  //On Load
  useEffect(() => {
    lookupSpeciesList().then((res) => {
      if (res) setData(res);
    });
  }, []);

  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorFn: (row) => row.name,
        id: "speciesName",
        header: "Species Name",
        cell: (info) => {
          return <strong>{info.getValue()}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => (row.active ? "Active" : "Inactive"),
        id: "active",
        header: "Active",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.code,
        id: "code",
        header: "Code",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.abbreviation,
        id: "abbreviation",
        header: "Abbreviation",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.dressedRate,
        id: "dressedRate",
        header: "Dressed Rate",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.filletRate,
        id: "filletRate",
        header: "Fillet Rate",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.sortOrder,
        id: "sortOrder",
        header: "Sort Order",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div className="py-5">
      <Container fluid>
        <h1 className="text-primary mb-5 display-4 fw-bold">Species</h1>

        <div className="form-card mb-3">
          <Table striped hover responsive>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{ verticalAlign: "top" }}
                      >
                        {header.isPlaceholder ? null : (
                          <>
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? "cursor-pointer d-flex align-items-center"
                                  : "",
                                onClick:
                                  header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: <FaSortUp className="text-primary ms-1" />,
                                desc: (
                                  <FaSortDown className="text-primary ms-1" />
                                ),
                              }[header.column.getIsSorted() as string] ?? (
                                <FaSort className="text-gray ms-1" />
                              )}
                            </div>
                          </>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id} className="align-middle">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>
    </div>
  );
}
