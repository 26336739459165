import axios from "axios";
import { createHashPassword } from "../helpers/createHashPassword";

export interface ResetPassword {
  id: number;
  code: string;
  new: string;
}

export const resetPassword = async (payload: ResetPassword) => {
  const hashPassword = await createHashPassword(payload.new);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/Auth/reset-password`,
      { id: payload.id, code: payload.code, newHash: hashPassword }
    );

    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
