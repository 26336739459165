import axios from "axios";
import { DailyTrip } from "../models/dailyTrip";

export const postTrip = async (trip: DailyTrip) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/Trip/trip`,
      trip
    );
    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
