import { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/esm/Container";
import SelectOrganization from "../../components/formControls/selectOrganization";
import {
  getOrganizationID,
  getTribalOrganization,
} from "../../helpers/authHelper";
import FormToast, { ToastData } from "../../components/formToast";
import { useNavigate, useParams } from "react-router-dom";
import { Helper } from "../../models/helper";
import { getHelper } from "../../api/getHelper";
import { updateHelper } from "../../api/updateHelper";
import { apiSuccess } from "../../helpers/apiSuccess";
import InputGroup from "react-bootstrap/esm/InputGroup";
import { getFormattedDateYYYYMMDD } from "../../helpers/getFormattedDateYYYYMMDD";

export default function EditHelper() {
  const navigate = useNavigate();

  let { helperID } = useParams();
  if (helperID === undefined) {
    helperID = "0";
    navigate(-1);
  }

  const [helper, setHelper] = useState<Helper>({
    firstName: "",
    lastName: "",
    middleName: "",
    licenseNumber: "",
    organizationID: getOrganizationID() === 1 ? 2 : getOrganizationID(),
    activeDate: "",
    expireDate: "",
  });

  const [toastData, setToastData] = useState<ToastData>({
    show: false,
    title: "",
    body: "",
    bg: "",
  });

  useEffect(() => {
    const getInitialValues = async () => {
      const response = await getHelper(helperID);
      if (response) {
        setHelper({
          firstName: response.firstName ?? undefined,
          middleName: response.middleName ?? undefined,
          lastName: response.lastName ?? undefined,
          licenseNumber: response.licenseNumber.replace(/\D/g, "") ?? undefined,
          organizationID: response.organizationID ?? 0,
          organizationCode: response.organizationCode,
          activeDate: response.activeDate ?? undefined,
          expireDate: response.expireDate ?? undefined,
        });
      }
    };
    getInitialValues();
  }, []);

  const helperSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    middleName: Yup.string(),
    lastName: Yup.string().required("Last Name is required"),
    licenseNumber: Yup.string().required("License Number is required"),
    activeDate: Yup.date(),
    expireDate: Yup.date(),
  });

  return (
    <>
      <div className="py-5">
        <Container>
          <h1 className="text-primary mb-5">Edit Helper</h1>

          <Row>
            <Col lg={3}>
              <h2 className="form-header">Helper Details</h2>
            </Col>
            <Col>
              <div className="form-card">
                <FormToast toastData={toastData} setData={setToastData} />
                <Formik
                  initialValues={helper}
                  enableReinitialize
                  validationSchema={helperSchema}
                  onSubmit={async (values) => {
                    const formattedLicense = `${helper.organizationCode}-${values.licenseNumber}H`;
                    const response = await updateHelper({
                      helperID: helperID,
                      ...values,
                      licenseNumber: formattedLicense,
                    });

                    if (apiSuccess(response.status)) {
                      setToastData({
                        show: true,
                        bg: "Success",
                        title: "Success",
                        body: "Saved Successfully",
                      });

                      setTimeout(() => navigate(-1), 2000);
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Form
                      noValidate
                      onSubmit={handleSubmit}
                      className="mb-4"
                      autoComplete="off"
                    >
                      <Row>
                        <Form.Group
                          as={Col}
                          lg
                          className="mb-3"
                          controlId="formHelperFirstName"
                        >
                          <Form.Label>
                            First Name
                            <span className="text-danger text-small ms-2">
                              (Required)
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.firstName && !!errors.firstName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.firstName}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          lg
                          className="mb-3"
                          controlId="formHelperMiddleName"
                        >
                          <Form.Label>Middle Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="middleName"
                            value={values.middleName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.middleName && !!errors.middleName
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.middleName}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          lg
                          className="mb-3"
                          controlId="formHelperLastName"
                        >
                          <Form.Label>
                            Last Name
                            <span className="text-danger text-small ms-2">
                              (Required)
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.lastName && !!errors.lastName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.lastName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group
                          as={Col}
                          className="mb-3"
                          controlId="formTribe"
                        >
                          <SelectOrganization
                            name="organizationID"
                            value={values.organizationID}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            enabled={!getTribalOrganization()}
                            isInvalid={
                              touched.organizationID && !!errors.organizationID
                            }
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          lg
                          className="mb-3"
                          controlId="formHelperLicense"
                        >
                          <Form.Label>License Number*</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              {helper.organizationCode}-
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              name="licenseNumber"
                              placeholder="1234"
                              value={values.licenseNumber.replace(/\D/g, "")}
                              onChange={(e) => {
                                if (
                                  (e.target.value === "" ||
                                    e.target.value.match(/^\d+$/)) &&
                                  e.target.value.length < 6
                                ) {
                                  handleChange(e);
                                }
                              }}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.licenseNumber && !!errors.licenseNumber
                              }
                            />
                            <InputGroup.Text>H</InputGroup.Text>
                          </InputGroup>
                          <Form.Control.Feedback type="invalid">
                            {errors.licenseNumber}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group
                          as={Col}
                          lg
                          className="mb-3"
                          controlId="formHelperActiveDate"
                        >
                          <Form.Label>Active Date</Form.Label>
                          <Form.Control
                            type="date"
                            name="activeDate"
                            value={getFormattedDateYYYYMMDD(
                              values.activeDate ?? ""
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.activeDate && !!errors.activeDate
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.activeDate}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          lg
                          className="mb-3"
                          controlId="formHelperFirstName"
                        >
                          <Form.Label>Expire Date</Form.Label>
                          <Form.Control
                            type="date"
                            name="expireDate"
                            value={getFormattedDateYYYYMMDD(
                              values.expireDate ?? ""
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.expireDate && !!errors.expireDate
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.expireDate}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Col lg>
                          <Button
                            className="w-100"
                            variant="success"
                            type="submit"
                          >
                            Save & Go Back
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
