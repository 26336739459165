import axios from "axios";
import { Vessel } from "../models/vessel";

export const putVessel = async (vessel: Vessel) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/Vessel/vessel`,
      vessel
    );
    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
