import axios from "axios";

import { Helper } from "../models/helper";

export const updateHelper = async (helper: Helper) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/Helper/helper`,
      helper
    );

    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
