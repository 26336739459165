import Form from "react-bootstrap/Form";

export default function SelectLicense({
  name,
  value,
  onChange,
  onBlur,
  isInvalid,
  readOnly,
}: any) {
  return (
    <Form.Select
      aria-label="State Select"
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      isInvalid={isInvalid}
      //className={readOnly ? "readOnly" : ""}
      disabled={readOnly}
    >
      <option value="C">Commercial (BIA)</option>
      <option value="S">Subsistence</option>
    </Form.Select>
  );
}
