import { Formik } from "formik";
import { useEffect, useImperativeHandle, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/esm/Card";
import Form from "react-bootstrap/esm/Form";
import Spinner from "react-bootstrap/esm/Spinner";
import { FaCalendar, FaCheckCircle, FaFileDownload } from "react-icons/fa";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { getCatchReportExportPDF } from "../../api/getCatchReportExportPDF";
import { listTripsRange } from "../../api/listTripsRange";
import { postCatchReportSubmit } from "../../api/postCatchReportSubmit";
import { apiSuccess } from "../../helpers/apiSuccess";
import { getFormattedDateMMDDYYYY } from "../../helpers/getFormattedDateMMDDYYYY";
import { CatchReport } from "../../models/catchReport";
import { CatchReportPeriod } from "../../models/catchReportPeriod";
import { DailyTrip } from "../../models/dailyTrip";
import { Helper } from "../../models/helper";

import EditCatchReportCrew from "../formControls/editCatchReportCrew";
import FormToast, { ToastData } from "../formToast";
import TripsSummaryTable from "../tables/tripsSummaryTable";
import ToggleButtonGroup from "react-bootstrap/esm/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/esm/ToggleButton";
import MobileDailyTrips from "../mobileDailyTrips";
import { getFormattedDateYYYYMMDD } from "../../helpers/getFormattedDateYYYYMMDD";
import { listTripsUserRange } from "../../api/listTripsUserRange";

export default function SubmitCatchReportModal({
  refId,
  userID,
  isAdmin = false,
}: any) {
  useImperativeHandle(refId, () => ({
    handleShow,
  }));

  const incompleteTripsMessage =
    "You have incomplete trips in this reporting period. Please complete or delete them to submit a catch report.";
  const [catchReport, setCatchReport] = useState<CatchReport>();
  const [submittedCatchReportID, setSubmittedCatchReportID] = useState(0);
  const [noFish, setNoFish] = useState(false);
  const [show, setShow] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [catchReportPeriod, setCatchReportPeriod] =
    useState<CatchReportPeriod>();
  const [helpers, setHelpers] = useState<Helper[]>([]);
  const [dailyTrips, setDailyTrips] = useState<DailyTrip[]>([]);
  // const [isCheckedAssisted, setIsCheckedAssisted] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [isCheckedConfirm, setIsCheckedConfirm] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [hasIncompleteTrips, setHasIncompleteTrips] = useState(false);
  const [dailyTripView, setDailyTripView] = useState(1);

  const [toastData, setToastData] = useState<ToastData>({
    show: false,
    title: "",
    body: "",
    bg: "",
  });

  // Reset values when modal closes
  useEffect(() => {
    if (!show) {
      setDailyTrips([]);
      setIsCheckedConfirm(false);
      setNoFish(false);
      setHasIncompleteTrips(false);
    }
  }, [show]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleShow = (period: CatchReportPeriod) => {
    if (period.tripCount === 0) {
      setNoFish(true);
    }

    setCatchReportPeriod(period);
    fetchDailyTrips(period);
    setShow(true);
  };
  const handleClose = () => {
    if (submitted) {
      window.location.reload();
    } else {
      setShow(false);
    }
  };

  const handleToggleDailyTripView = (val: number) => setDailyTripView(val);

  const downloadPDF = async (catchReportID: number) => {
    setIsDownloading(true);
    await getCatchReportExportPDF(catchReportID);
    setIsDownloading(false);
  };

  const fetchDailyTrips = async (period: CatchReportPeriod) => {
    let response;

    //if userID was passed in, use that instead
    if (userID) {
      response = await listTripsUserRange({
        userID: userID,
        beginDate: period.beginDate,
        endDate: period.endDate,
      });
    } else {
      response = await listTripsRange({
        beginDate: period.beginDate,
        endDate: period.endDate,
      });
    }

    const tripIDs = response.map((dt: DailyTrip) => dt.id);
    const newCatchReport = new CatchReport({
      ...period,
      noFishReport: response.length === 0,
      tripIDs: tripIDs,
    });

    if (userID) {
      newCatchReport.userID = userID;
    }

    if (response) {
      setDailyTrips(response);
      setHasIncompleteTrips(
        response.some((trip: DailyTrip) => trip.complete === false)
      );
      setCatchReport(newCatchReport);
    }
  };

  const handleSetCrewMembers = (data: Helper[]) => {
    if (catchReport) {
      setCatchReport({ ...catchReport, helpers: data });
    }
  };

  const submitCatchReport = async (data: any) => {
    if (!hasIncompleteTrips) {
      const reportData = { ...catchReport, ...data };

      let msg;

      if (catchReportPeriod) {
        const currentDate = new Date();
        const dueDate = new Date(
          getFormattedDateYYYYMMDD(catchReportPeriod?.endDate)
        );

        if (currentDate >= dueDate) {
          msg =
            "Submit catch report? You will no longer be able to recall this report after midnight.";
        } else {
          msg = `Submit catch report? You will no longer be able to recall this report after ${getFormattedDateMMDDYYYY(
            catchReportPeriod.endDate
          )}.`;
        }
      }

      setCatchReport(reportData);

      if (isCheckedConfirm && catchReportPeriod && window.confirm(msg)) {
        const response = await postCatchReportSubmit(reportData);
        if (apiSuccess(response.status)) {
          setSubmittedCatchReportID(response.data.id);
          setSubmitted(true);
        } else {
          setSubmitError("Oops, something went wrong.");
        }
      }
    } else {
      alert(incompleteTripsMessage);
    }
  };

  const validationSchema = Yup.object().shape({
    assistName: Yup.string().when("isCheckedAssisted", {
      is: true,
      then: Yup.string().required("Assist Name is required"),
      otherwise: Yup.string(),
    }),
    assistRelationship: Yup.string().when("isCheckedAssisted", {
      is: true,
      then: Yup.string().required("Assist Relationship is required"),
      otherwise: Yup.string(),
    }),
    isCheckedAssisted: Yup.boolean(),
  });

  // Current state

  return (
    <>
      <FormToast toastData={toastData} setData={setToastData} />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        dialogClassName="submit-catch-report-modal"
        keyboard={false}
      >
        <Modal.Header
          closeButton
          className="bg-primary text-light align-items-start px-3 px-lg-5"
        >
          <Modal.Title className="w-100 d-flex justify-content-between align-items-end flex-wrap h6">
            <div className="mb-lg-2">
              <div className="h1 mb-0">Submit Catch Report</div>
              <p className="fw-normal mb-0">
                <span className="me-4">
                  <FaCalendar className="mt-n1 me-2" />
                  {catchReportPeriod && (
                    <>
                      {getFormattedDateMMDDYYYY(catchReportPeriod.beginDate)} -{" "}
                      {getFormattedDateMMDDYYYY(catchReportPeriod.endDate)}
                    </>
                  )}
                </span>
              </p>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="px-3 px-lg-5 border-none pb-3 pt-5 bg-background">
          <>
            {submitted ? (
              <div className="text-center">
                <h2 className="mt-5 mb-3">
                  Catch Report Submitted Successfully!
                </h2>
                <Row className="justify-content-center">
                  <Col lg={3}>
                    <FaCheckCircle className="text-success mb-5 w-50 w-lg-100 h-100" />
                  </Col>
                </Row>
                <p>You may download a copy for your records.</p>
                <Row className="flex-column align-items-center justify-content-center">
                  <Col lg={6}>
                    <Link to="reports/print">
                      <Button
                        variant="outline-primary"
                        className="w-100 mb-3"
                        onClick={() => downloadPDF(submittedCatchReportID)}
                      >
                        {isDownloading && (
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                            className="ms-2"
                          />
                        )}
                        <FaFileDownload className="me-2" />
                        Download Report & Close
                      </Button>
                    </Link>
                  </Col>
                  <Col lg={6}>
                    <Button
                      variant="primary"
                      className="w-100 mb-5"
                      onClick={() => window.location.reload()}
                    >
                      Close
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <div>
                {/* No Trips */}

                {noFish ? (
                  <>
                    <h1 className="text-center mb-5">
                      Submit a NO FISHING catch report
                    </h1>
                  </>
                ) : (
                  <>
                    {/* Multiple Trips */}
                    {hasIncompleteTrips && (
                      <Alert variant="danger">{incompleteTripsMessage}</Alert>
                    )}
                    <h3 className="fw-normal mb-4">Summary of trips</h3>
                    <ToggleButtonGroup
                      className="w-100 mb-2 d-flex d-lg-none"
                      size="sm"
                      type="radio"
                      name="mobileTripViewSubmitReport"
                      defaultValue={1}
                      onChange={handleToggleDailyTripView}
                    >
                      <ToggleButton
                        id="mobileTripViewSubmitReport1"
                        variant="outline-primary"
                        value={1}
                      >
                        List
                      </ToggleButton>
                      <ToggleButton
                        id="mobileTripViewSubmitReport3"
                        variant="outline-primary"
                        value={2}
                      >
                        Table
                      </ToggleButton>
                    </ToggleButtonGroup>

                    {/* Mobile List */}
                    <div
                      className={`${
                        isMobile && dailyTripView === 1 ? "d-block" : "d-none"
                      } mb-5`}
                    >
                      <MobileDailyTrips dailyTrips={dailyTrips} goBack />
                    </div>

                    <div
                      className={
                        !isMobile || (isMobile && dailyTripView === 2)
                          ? "d-block"
                          : "d-none"
                      }
                    >
                      <div className="form-card">
                        <TripsSummaryTable dailyTrips={dailyTrips} />
                      </div>
                    </div>

                    <h3 className="fw-normal">Your Crew</h3>
                    <EditCatchReportCrew
                      handleSetCrewMembers={handleSetCrewMembers}
                      crew={helpers}
                    />
                  </>
                )}

                <Formik
                  enableReinitialize
                  initialValues={{
                    assistName: "",
                    assistRelationship: "",
                    isCheckedAssisted: isAdmin,
                  }}
                  onSubmit={async (values) => {
                    submitCatchReport(values);
                  }}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit} autoComplete="off">
                      <Form.Check
                        className="mb-2"
                        type="checkbox"
                        id="assistedCatchReport"
                        label="Someone assisted me with this report"
                        name="isCheckedAssisted"
                        checked={values.isCheckedAssisted}
                        onChange={handleChange}
                      />

                      {values.isCheckedAssisted && (
                        <Row className="mb-2">
                          <Form.Group as={Col} lg={8} className="mb-3">
                            <Form.Label>
                              Name of person who helped complete this report{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="assistName"
                              value={values.assistName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.assistName && !!errors.assistName
                              }
                            />
                          </Form.Group>
                          <Form.Group as={Col} lg={4} className="mb-3">
                            <Form.Label>
                              Relationship{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="assistRelationship"
                              value={values.assistRelationship}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.assistRelationship &&
                                !!errors.assistRelationship
                              }
                            />
                          </Form.Group>
                        </Row>
                      )}
                      <Form.Check
                        className="mb-3"
                        type="checkbox"
                        id="confirm"
                        label="I confirm this data is as accurate as possible"
                        checked={isCheckedConfirm}
                        onChange={(e) => setIsCheckedConfirm(e.target.checked)}
                      />

                      <hr className="my-4" />
                      <Row className="d-flex flex-column-reverse flex-md-row">
                        <Col className="mb-3" xs={12} md={6}>
                          <Button
                            variant="outline-primary"
                            className="w-100"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col className="mb-3" xs={12} md={6}>
                          <Button
                            disabled={!isCheckedConfirm || hasIncompleteTrips}
                            variant="success"
                            className="w-100"
                            type="submit"
                          >
                            Submit Catch Report
                          </Button>
                        </Col>
                      </Row>
                      {hasIncompleteTrips && (
                        <Alert className="mt-4" variant="danger">
                          {incompleteTripsMessage}
                        </Alert>
                      )}
                    </Form>
                  )}
                </Formik>
                {submitError.length > 0 && (
                  <Alert className="mt-4" variant="danger">
                    {submitError}
                  </Alert>
                )}
              </div>
            )}
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}
