import React from "react";
import Container from "react-bootstrap/esm/Container";
import UsersTable from "../../components/tables/usersTable";
import { ColumnDef } from "@tanstack/react-table";
import { fuzzySort } from "../../components/tables/fuzzySort";
import {
  Admin,
  listOrganizationAdmins,
} from "../../api/listOrganizationAdmins";

export default function AdminAllAdmins() {
  const columns = React.useMemo<ColumnDef<Admin, any>[]>(
    () => [
      {
        accessorFn: (row) => row.displayName,
        id: "displayName",
        header: "Name",
        cell: (info) => {
          return <strong>{info.getValue()}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.userName,
        id: "userName",
        header: "Username",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.email,
        id: "email",
        header: "Email",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.phone,
        id: "phone",
        header: "Phone",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.status,
        id: "status",
        header: "Status",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
    ],
    []
  );

  return (
    <div className="py-5">
      <Container fluid>
        <h1 className="text-primary mb-5">Administrators</h1>
        <UsersTable
          columns={columns}
          lookupList={listOrganizationAdmins}
          role={"admin"}
        />
      </Container>
    </div>
  );
}
