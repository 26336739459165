export function convertObjectToUrlParams(obj: any): string {
  const params = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      // Skip empty strings
      if (value === "") {
        continue;
      }

      const titleCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
      const encodedValue = encodeURIComponent(value);
      params.push(`${titleCaseKey}=${encodedValue}`);
    }
  }

  return params.length > 0 ? `?${params.join("&")}` : "";
}
