import { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import passwordReg from "../../helpers/passwordReg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { changePassword } from "../../api/changePassword";
import FormToast, { ToastData } from "../../components/formToast";
import { apiSuccess } from "../../helpers/apiSuccess";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function ChangeOnetimePassword() {
  const [showCurrPass, setShowCurrPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfPass, setShowConfPass] = useState(false);

  const [toastData, setToastData] = useState<ToastData>({
    show: false,
    title: "",
    body: "",
    bg: "",
  });

  const schema = Yup.object().shape({
    currentPassword: Yup.string().required("Old password required"),
    password: Yup.string()
      .required("Password required")
      .min(10, "Minimum ten characters, at least one letter and one number")
      .test(
        "isValidPass",
        "At least one letter and one number",
        (value: any, context) => {
          return passwordReg(value, context);
        }
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  return (
    <Container className="h-100 py-5 d-flex flex-column">
      <FormToast toastData={toastData} setData={setToastData} />
      <Row className="justify-content-center flex-grow-1">
        <Col md={10} lg={8}>
          <div className="form-card py-5 px-3 px-sm-4 px-md-5">
            <div className="text-center">
              <h1>Change Password</h1>
              <p className="lead">Your account requires a password change</p>
            </div>

            <Formik
              initialValues={{
                currentPassword: "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                const submit = async () => {
                  const response = await changePassword({
                    current: values.currentPassword,
                    new: values.password,
                  });

                  if (apiSuccess(response.status)) {
                    setToastData({
                      show: true,
                      bg: "Success",
                      title: "Success",
                      body: "Saved Successfully",
                    });

                    setTimeout(() => {
                      localStorage.removeItem("requirePasswordChange");
                      window.location.href = "/";
                    }, 1500);
                  }
                };
                submit();
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form noValidate onSubmit={handleSubmit} autoComplete="off">
                  <Form.Group
                    className="mb-4"
                    controlId="validationCurrentFormikPassword"
                  >
                    <Form.Label>Current Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={showCurrPass ? "text" : "password"}
                        name="currentPassword"
                        value={values.currentPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.currentPassword && !!errors.currentPassword
                        }
                      />
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            {showCurrPass ? "Hide password" : "Show password"}
                          </Tooltip>
                        }
                      >
                        <InputGroup.Text
                          onClick={() => setShowCurrPass(!showCurrPass)}
                        >
                          <>{showCurrPass ? <FaEyeSlash /> : <FaEye />}</>
                        </InputGroup.Text>
                      </OverlayTrigger>
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.currentPassword}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-4"
                    controlId="validationFormikPassword"
                  >
                    <Form.Label>New Password*</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={showNewPass ? "text" : "password"}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.password && !!errors.password}
                      />
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            {showNewPass ? "Hide password" : "Show password"}
                          </Tooltip>
                        }
                      >
                        <InputGroup.Text
                          onClick={() => setShowNewPass(!showNewPass)}
                        >
                          <>{showNewPass ? <FaEyeSlash /> : <FaEye />}</>
                        </InputGroup.Text>
                      </OverlayTrigger>
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-4"
                    controlId="validationFormikConfirmPassword"
                  >
                    <Form.Label>Confirm Password*</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={showConfPass ? "text" : "password"}
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.confirmPassword && !!errors.confirmPassword
                        }
                      />
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            {showConfPass ? "Hide password" : "Show password"}
                          </Tooltip>
                        }
                      >
                        <InputGroup.Text
                          onClick={() => setShowConfPass(!showConfPass)}
                        >
                          <>{showConfPass ? <FaEyeSlash /> : <FaEye />}</>
                        </InputGroup.Text>
                      </OverlayTrigger>
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmPassword}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button
                    variant="success"
                    type="submit"
                    size="lg"
                    className="w-100"
                  >
                    Save Password
                  </Button>
                </Form>
              )}
            </Formik>
            <p className="mt-3">
              *Password minimum length 10 characters with at least one letter
              and one number
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
