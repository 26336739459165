import axios from "axios";

export const getRegistrationInfo = async (id: any, code: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/User/registration-info?ID=${id}&Code=${code}`
    );
    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
