import { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CORALogo from "../../components/coraLogo";

import ForgotPassword from "./components/forgotPassword";
import ResetPassword from "./components/resetPassword";
import ForgotUsername from "./components/forgotUsername";
import { Link, useSearchParams } from "react-router-dom";

export default function AccountRecovery() {
  const [searchParams] = useSearchParams();
  const [activeComponent, setActiveComponent] = useState("ForgotPassword");
  let id = searchParams.get("i");
  let code = searchParams.get("c");

  useEffect(() => {
    if (id || code)
      setActiveComponent("ResetPassword");
  }, [id, code]);

  return (
    <Container className="h-100 py-5 d-flex flex-column">
      <Row className="justify-content-center flex-grow-1">
        <Col md={10} lg={8}>
          <div className="form-card py-5 px-3 px-sm-4 px-md-5">
            <CORALogo />
            {activeComponent === "ForgotPassword" && (
              <ForgotPassword setActiveComponent={setActiveComponent} />
            )}
            {activeComponent === "ForgotUsername" && (
              <ForgotUsername setActiveComponent={setActiveComponent} />
            )}
            {activeComponent === "ResetPassword" && (
              <ResetPassword setActiveComponent={setActiveComponent} urlId={id} urlCode={code} />
            )}
          </div>
        </Col>
      </Row>
      <p className="text-center mb-1">
        <Link to="/">Login to your account</Link>
      </p>
      <p className="text-center mb-0">Don't have an account? Get access</p>
    </Container>
  );
}
