import React, { useEffect, useImperativeHandle } from "react";
import Table from "react-bootstrap/esm/Table";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";
import { RankingInfo } from "@tanstack/match-sorter-utils";
import { fuzzyFilter } from "./fuzzyFilter";
import { fuzzySort } from "./fuzzySort";
import { ColumnDef } from "@tanstack/react-table";
import { DailyTrip } from "../../models/dailyTrip";

import {
  FaSort,
  FaSortUp,
  FaSortDown,
  FaGreaterThan,
  FaLessThan,
  FaCheckCircle,
  FaExclamationCircle,
} from "react-icons/fa";

import {
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  flexRender,
} from "@tanstack/react-table";
import { listTrips } from "../../api/listTrips";
import { Catch } from "../../models/catch";
import Tooltip from "react-bootstrap/esm/Tooltip";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }

  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

export default function TripsSummaryTable({ dailyTrips }: any) {
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const columns = React.useMemo<ColumnDef<DailyTrip, any>[]>(
    () => [
      {
        accessorFn: (row) => row.complete,
        id: "complete",
        header: "Complete",
        cell: (info) => {
          return (
            <div className="d-flex justify-content-center ">
              {info.getValue() ? (
                <FaCheckCircle className="text-success fs-5" />
              ) : (
                <OverlayTrigger overlay={<Tooltip>Trip not completed</Tooltip>}>
                  <span className="d-inline-block">
                    <FaExclamationCircle className="text-warning fs-5" />
                  </span>
                </OverlayTrigger>
              )}
            </div>
          );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
        enableColumnFilter: false,
        enableHiding: false,
      },
      {
        accessorFn: (row) => row.fishingDate,
        id: "fishingDate",
        header: "Date",
        cell: (info) => {
          return (
            <strong>
              {new Date(info.getValue()).toLocaleDateString("en-US")}
            </strong>
          );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
        enableColumnFilter: false,
        enableHiding: false,
      },
      {
        accessorFn: (row) => row.harvest,
        id: "whitefish",
        header: "Whitefish",
        cell: (info) => {
          return (
            info.getValue().reduce((total: number, item: Catch) => {
              if (item.speciesName === "Whitefish") {
                return total + (item.landedWeight || 0);
              }
              return total;
            }, 0) + " lbs."
          );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.harvest,
        id: "lakeTrout",
        header: "Lake Trout",
        cell: (info) => {
          return (
            info.getValue().reduce((total: number, item: Catch) => {
              if (item.speciesName === "Lake Trout") {
                return total + (item.landedWeight || 0);
              }
              return total;
            }, 0) + " lbs."
          );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.harvest,
        id: "otherSpecies",
        header: "Other Species",
        cell: (info) => {
          return (
            info.getValue().reduce((total: number, item: Catch) => {
              if (
                item.speciesName !== "Whitefish" &&
                item.speciesName !== "Lake Trout"
              ) {
                return total + (item.landedWeight || 0);
              }
              return total;
            }, 0) + " lbs."
          );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.lakeName,
        id: "lakeName",
        header: "Lake",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.grids,
        id: "gridNumber",
        header: "Grid #",
        cell: (info) => {
          const last = info.getValue().length - 1;
          return info
            .getValue()
            .map(
              (g: any, i: number) => `${g.gridNumber}${i !== last ? ", " : ""}`
            );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.effort,
        id: "gear",
        header: "Gear",
        cell: (info) => {
          const unique = info
            .getValue()
            .map((item: any) => item.gearName)
            .filter(
              (value: any, index: any, self: any) =>
                self.indexOf(value) === index
            );

          const last = unique.length - 1;
          return unique.map(
            (g: any, i: number) => `${g}${i !== last ? ", " : ""}`
          );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
    ],
    []
  );

  // Set data and filter states
  const [data, setData] = React.useState([]);

  // Get and update data on load and org change
  useEffect(() => {
    setData(dailyTrips);
  }, [dailyTrips]);

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    onColumnVisibilityChange: setColumnVisibility,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  });

  return (
    <div>
      {/* Table */}
      <Table striped hover responsive className="trips-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ verticalAlign: "top" }}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer d-flex align-items-center"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <FaSortUp className="text-primary ms-1" />,
                            desc: <FaSortDown className="text-primary ms-1" />,
                          }[header.column.getIsSorted() as string] ?? (
                            <FaSort className="text-gray ms-1" />
                          )}
                        </div>
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        {table.getRowModel().rows.length > 0 ? (
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className="align-middle">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={100}>
                <h2 className="text-gray w-100 text-center">No Daily Trips</h2>
              </td>
            </tr>
          </tbody>
        )}
      </Table>

      {/* Table footer */}
      <div className="d-flex flex-row justify-content-end align-items-center mt-3">
        <span className="text-nowrap me-3">
          {`${
            table.getState().pagination.pageIndex *
            table.getState().pagination.pageSize
          }-${
            table.getState().pagination.pageIndex *
              table.getState().pagination.pageSize +
            table.getState().pagination.pageSize
          } of ${table.getPrePaginationRowModel().rows.length}`}
        </span>

        <Form.Select
          aria-label="Table rows per page"
          size="sm"
          style={{ maxWidth: "10rem" }}
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Rows per page {pageSize}
            </option>
          ))}
        </Form.Select>

        <Button
          variant="light-gray"
          size="sm"
          className="ms-2 text-primary"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <FaLessThan />
        </Button>
        <Button
          variant="light-gray"
          size="sm"
          className="ms-2"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <FaGreaterThan className="text-primary" />
        </Button>
      </div>
    </div>
  );
}
