import { useEffect, useImperativeHandle, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import FishCaught from "./fishCaught";
import GridEffort from "./effort";
import ReviewDailyTrip from "./reviewDailyTrip";
import {
  FaArrowLeft,
  FaArrowRight,
  FaCalendar,
  FaCalendarAlt,
} from "react-icons/fa";
import { DailyTrip } from "../../models/dailyTrip";
import FormToast, { ToastData } from "../formToast";
import { postTrip } from "../../api/postTrip";
import { getTrip } from "../../api/getTrip";
import { apiSuccess } from "../../helpers/apiSuccess";
import { putTrip } from "../../api/putTrip";
import TripDate from "./tripDate";
import FishingLocation from "./fishingLocation";
import { lookupSpecies } from "../../api/lookupSpecies";
import SelectVessel from "./selectVessel";
import { Vessel } from "../../models/vessel";
import { getUserVessels } from "../../api/getUserVessels";
import SelectGear from "./gear";
import { getUserID } from "../../helpers/authHelper";
import { getVessels } from "../../api/getVessels";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Offcanvas from "react-bootstrap/esm/Offcanvas";
import { getBuyersRecent } from "../../api/getBuyersRecent";
import { Buyer } from "../../models/buyer";

export default function DailyTripModal({
  loadDataFromComponent,
  refId,
  handleRefreshDailyTrips,
  userID,
  fisherID,
  catchReportID,
  gearList,
  meshList,
  speciesList,
  conditionsList,
  lakesList,
}: any) {
  useImperativeHandle(refId, () => ({
    handleShow,
    newDailyTrip,
    loadDailyTrip,
  }));

  const [vesselsList, setVesselsList] = useState<Vessel[]>([]);
  const [recentBuyersList, setRecentBuyersList] = useState<Buyer[]>([]);
  const [activeSpecies, setActiveSpecies] = useState(speciesList);
  const [saveClose, setSaveClose] = useState(false);
  const [dailyTrip, setDailyTrip] = useState<DailyTrip>();
  const [show, setShow] = useState(false);
  const [showMobileClose, setShowMobileClose] = useState(false);
  const [toastData, setToastData] = useState<ToastData>({
    show: false,
    title: "",
    body: "",
    bg: "",
  });
  const modalRef = useRef<any>(null);

  useEffect(() => {
    if (show) {
      handleUserVessels();
      handleUserRecentBuyers();
    }
  }, [show]);

  // Update species list when date changes
  useEffect(() => {
    if (dailyTrip?.fishingDate !== undefined) {
      const fetch = async () => {
        const getSpecies = await lookupSpecies(dailyTrip.fishingDate);
        if (getSpecies) {
          const activeSpeciesList = getSpecies.filter((s: any) => s.active);
          setActiveSpecies(activeSpeciesList);
        }
      };
      fetch();
    }
  }, [dailyTrip?.fishingDate]);

  const handleUserVessels = async () => {
    if (fisherID) {
      getUserVessels(fisherID).then((res) => {
        if (res) setVesselsList(res.data);
      });
    } else if (parseInt(getUserID()) === parseInt(userID)) {
      getVessels().then((res) => {
        if (res) setVesselsList(res.data);
      });
    } else {
      getUserVessels(userID).then((res) => {
        if (res) setVesselsList(res.data);
      });
    }
  };

  const handleUserRecentBuyers = async () => {
    if (fisherID) {
      getBuyersRecent(fisherID).then((res) => {
        if (res) setRecentBuyersList(res);
      });
    } else {
      getBuyersRecent(userID).then((res) => {
        if (res) setRecentBuyersList(res);
      });
    }
  };

  const handleFormData = (data: any) => {
    const index = steps.findIndex((x) => x.key === activeStep.key);

    setSteps((prevStep) =>
      prevStep.map((x) => {
        if (x.key === activeStep.key) {
          x.validated = data.validated;
          setDailyTrip({ ...dailyTrip, ...data.values });
        }
        return x;
      })
    );
    setActiveStep(steps[index]);
    // console.log(data.values);
  };

  // Click close button
  const handleClose = () => {
    if (activeStep.key !== "tripDate") {
      // If Mobile, give option to save and close
      if (window.innerWidth < 992) {
        setShowMobileClose(true);
      } else {
        if (window.confirm("Are you sure you want to close without saving?")) {
          setShow(false);
        }
      }
    } else {
      setShow(false);
    }
  };

  // Open the dialog and load daily trip
  const handleShow = () => setShow(true);

  // new Daily Trip
  const newDailyTrip = async (tripType: any, date: string) => {
    const newDT = new DailyTrip();
    newDT.tripTypeID = tripType.id;
    if (userID) newDT.userID = userID;
    if (catchReportID) newDT.catchReportID = catchReportID;
    if (date) newDT.fishingDate = date;
    // console.log("New Daily Trip: ", newDT);

    setSteps(stepsArr);
    setDailyTrip(newDT);
    setActiveStep(steps[0]);
    handleShow();
  };

  // load Daily Trip
  const loadDailyTrip = async (tripID: any) => {
    const trip = await getTrip(tripID);
    const newDT = new DailyTrip(trip);
    trip.fishingDate = trip.fishingDate?.split("T")[0];
    if (catchReportID) newDT.catchReportID = catchReportID;

    // console.log(newDT);
    setSteps(stepsArr);
    setDailyTrip(newDT);
    setActiveStep(steps[0]);
    handleShow();
  };

  const stepsArr = [
    {
      key: "tripDate",
      label: "Trip Date",
      validated: true,
      ref: useRef<HTMLFormElement>(null),
    },
    {
      key: "fishingLocation",
      label: "Fishing Location",
      validated: false,
      ref: useRef<HTMLFormElement>(null),
    },
    {
      key: "selectGear",
      label: "Gear",
      validated: false,
      ref: useRef<HTMLFormElement>(null),
    },
    {
      key: "effort",
      label: "Effort",
      validated: false,
      ref: useRef<HTMLFormElement>(null),
    },
    {
      key: "fishCaught",
      label: "Harvest",
      validated: false,
      ref: useRef<HTMLFormElement>(null),
    },
    {
      key: "vessel",
      label: "Vessel",
      validated: false,
      ref: useRef<HTMLFormElement>(null),
    },
    {
      key: "reviewDailyTrip",
      label: "Review",
      validated: false,
      ref: useRef<HTMLFormElement>(null),
    },
  ];

  // Dialog Steps
  const [steps, setSteps] = useState(stepsArr);

  // Current dialog step/page
  const [activeStep, setActiveStep] = useState(steps[0]);

  // Click next button
  const handleNext = async () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);
    // Submit form for validation

    try {
      await activeStep.ref.current?.Submit();

      if (activeStep.validated) {
        // If last step
        if (steps[steps.length - 1].key === activeStep.key && dailyTrip) {
          let response;
          if (dailyTrip.id > 0) {
            response = await putTrip({ ...dailyTrip, complete: true });
          } else {
            response = await postTrip({ ...dailyTrip, complete: true });
          }

          if (apiSuccess(response.status)) {
            setToastData({
              show: true,
              bg: "Success",
              title: "Success",
              body: "Saved Successfully",
            });

            setShow(false);
            handleRefreshDailyTrips();
          }

          return;
        }

        setActiveStep(steps[index + 1]);
        modalRef.current?.scrollIntoView({ block: "nearest" });
      }
    } catch {}
  };

  const saveTrip = async () => {
    if (dailyTrip) {
      // If its a new trip vs existing
      if (dailyTrip.id > 0) {
        await putTrip(dailyTrip);
      } else {
        await postTrip(dailyTrip);
      }

      handleRefreshDailyTrips();
    }
  };

  //Detect when save and close was clicked
  useEffect(() => {
    if (saveClose) {
      saveTrip();
      setSaveClose(false);
      setShowMobileClose(false);
      setShow(false);
    }
  }, [dailyTrip, saveClose]);

  const handleSaveAndClose = async () => {
    // Submit form for validation
    await activeStep.ref.current?.Submit();
    if (activeStep.validated) {
      setSaveClose(true);
    } else {
      if (
        window.confirm(
          "You have validation errors, and the current page won't be saved. Continue?"
        )
      ) {
        saveTrip();
        setSaveClose(false);
        setShow(false);
        // handleClose();
      }
    }
  };

  // Click back button
  const handleBack = () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);

    // If on the first page, don't do anything
    if (index === 0) return;

    steps[index - 1].validated = false;
    setSteps(steps);
    setActiveStep(steps[index - 1]);
    modalRef.current?.scrollIntoView({ block: "nearest" });
  };

  const gotoStep = (step: string) => {
    const index = steps.findIndex((x) => x.key === step);

    steps[index].validated = false;
    setSteps(steps);
    setActiveStep(steps[index]);
  };

  // Calculate the progress bar
  const getCompletion = () => {
    const currentStep = steps.findIndex((el) => el.key === activeStep.key);
    return ((currentStep + 1) / steps.length) * 100;
  };

  return (
    <>
      <FormToast toastData={toastData} setData={setToastData} />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        dialogClassName="daily-trip-modal"
        keyboard={false}
      >
        <Modal.Header
          ref={modalRef}
          closeButton
          className="bg-primary text-light align-items-start px-3 px-lg-5"
        >
          <Modal.Title className="w-100 d-flex justify-content-between align-items-end flex-wrap h6">
            <div className="mb-lg-2">
              <div className="h1 mb-0">{activeStep.label}</div>
              <p className="fw-normal mb-0 d-none d-lg-block">
                <span className="me-4">
                  <FaCalendar className="mt-n1 me-2" />
                  {dailyTrip &&
                    new Date(
                      dailyTrip.fishingDate.replace(/-/g, "/")
                    ).toLocaleDateString()}
                </span>
              </p>
            </div>
            <div className="pb-2 d-none d-lg-block">
              {activeStep.key !== "tripDate" &&
                activeStep.key !== "reviewDailyTrip" && (
                  <Button
                    variant="outline-light"
                    className="me-2"
                    onClick={handleSaveAndClose}
                  >
                    Save & Close
                  </Button>
                )}

              {activeStep.key !== "tripDate" && (
                <Button
                  variant="outline-light"
                  className="me-2"
                  onClick={handleBack}
                >
                  <FaArrowLeft className="me-2" /> Back
                </Button>
              )}
              <Button
                variant="outline-light"
                className="me-n4"
                onClick={handleNext}
              >
                {activeStep.key === "reviewDailyTrip" ? (
                  "Save & Close"
                ) : (
                  <>
                    Continue <FaArrowRight className="ms-2" />
                  </>
                )}
              </Button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3 px-lg-5 border-none pb-3 pt-0 bg-background">
          <>
            <div className="d-flex mb-5 py-3 shadow px-3 mx-n3 mx-lg-n5">
              <span className="d-block d-lg-none mt-n1 me-3">
                {dailyTrip &&
                  new Date(
                    dailyTrip.fishingDate.replace(/-/g, "/")
                  ).toLocaleDateString()}
              </span>
              <ProgressBar
                className="w-100"
                now={getCompletion()}
                variant="success"
              />
            </div>

            {activeStep.key === "tripDate" && (
              <TripDate
                initialValues={dailyTrip}
                refId={activeStep.ref}
                formData={handleFormData}
              />
            )}
            {activeStep.key === "fishingLocation" && (
              <FishingLocation
                initialValues={dailyTrip}
                refId={activeStep.ref}
                formData={handleFormData}
                lakesList={lakesList}
              />
            )}
            {activeStep.key === "selectGear" && (
              <SelectGear
                initialValues={dailyTrip}
                refId={activeStep.ref}
                formData={handleFormData}
                gearList={gearList}
                meshList={meshList}
              />
            )}
            {activeStep.key === "effort" && (
              <GridEffort
                initialValues={dailyTrip}
                refId={activeStep.ref}
                formData={handleFormData}
                gearList={gearList}
                meshList={meshList}
              />
            )}
            {activeStep.key === "fishCaught" && (
              <FishCaught
                initialValues={dailyTrip}
                refId={activeStep.ref}
                formData={handleFormData}
                speciesList={activeSpecies}
                conditionsList={conditionsList}
              />
            )}
            {activeStep.key === "vessel" && (
              <SelectVessel
                initialValues={dailyTrip}
                refId={activeStep.ref}
                formData={handleFormData}
                vesselsList={vesselsList}
                handleUserVessels={handleUserVessels}
                setVesselsList={setVesselsList}
                userID={userID}
                fisherID={fisherID}
              />
            )}
            {activeStep.key === "reviewDailyTrip" && (
              <ReviewDailyTrip
                initialValues={dailyTrip}
                refId={activeStep.ref}
                formData={handleFormData}
                gotoStep={gotoStep}
                meshList={meshList}
                recentBuyersList={recentBuyersList}
              />
            )}
          </>
        </Modal.Body>
        <Modal.Footer className="dailyTripFooter py-2 px-3 px-lg-5 border-none z-1000 bottom-0 bg-background">
          <Row className="w-100 mx-0 g-2">
            <Col className="ps-0 mt-0">
              {activeStep.key !== "tripDate" ? (
                <Button
                  className="w-lg-auto w-xs-100 "
                  variant="outline-primary"
                  onClick={handleBack}
                >
                  <FaArrowLeft className="me-2" /> Back
                </Button>
              ) : (
                <span></span>
              )}
            </Col>
            <Col className="pe-0 mt-0">
              <Button
                className="w-lg-auto w-xs-100 float-end"
                variant={
                  activeStep.key === "reviewDailyTrip" ? "success" : "primary"
                }
                onClick={handleNext}
              >
                {activeStep.key === "reviewDailyTrip" ? (
                  "Save & Close"
                ) : (
                  <>
                    Continue <FaArrowRight className="ms-2" />
                  </>
                )}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        className="mobileDailyTripCloseMenu"
        show={showMobileClose}
        placement="bottom"
        onHide={() => setShowMobileClose(false)}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Do you want to save changes?</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            variant="primary"
            className="w-100 mb-3"
            onClick={() => {
              handleSaveAndClose();
              setShowMobileClose(false);
            }}
          >
            Save & Close
          </Button>
          <Button
            variant="outline-primary"
            className="w-100 mb-3"
            onClick={() => {
              setShow(false);
              setShowMobileClose(false);
            }}
          >
            Don't Save
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
