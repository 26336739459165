import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

// Onboarding Steps
import FisherOnboardingStepOne from "./fisherOnboardingStepOne";
import FisherOnboardingStepTwo from "./fisherOnboardingStepTwo";
import FisherOnboardingStepThree from "./fisherOnboardingStepThree";

const Onboard = styled(Modal)`
  backdrop-filter: blur(0.5rem);
`;

export default function FisherOnboarding() {
  const [onboardingStep, setOnboardingStep] = useState(1);
  const handleClose = () => setOnboardingStep(0);
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState<any>(null);

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);
    return () => window.removeEventListener("transitionend", handler);
  }, []);

  return (
    <>
      {onboardingStep !== 0 && (
        <Onboard
          show={onboardingStep === 0 ? false : true}
          onHide={handleClose}
          backdrop="static"
          animation={false}
          keyboard={false}
          size="xl"
          dialogClassName="fisher-onboard-dialog"
        >
          <Modal.Header closeButton className="bg-background">
            {onboardingStep > 1 && (
              <Button
                variant="light"
                size="sm"
                onClick={() => setOnboardingStep(onboardingStep - 1)}
              >
                Back
              </Button>
            )}
            <Modal.Title className="text-center w-100">
              First Time Setup
            </Modal.Title>
          </Modal.Header>
          {onboardingStep === 1 && (
            <FisherOnboardingStepOne setOnboardingStep={setOnboardingStep} />
          )}
          {onboardingStep === 2 && (
            <FisherOnboardingStepTwo setOnboardingStep={setOnboardingStep} />
          )}
          {onboardingStep === 3 && (
            <FisherOnboardingStepThree
              setOnboardingStep={setOnboardingStep}
              promptInstall={promptInstall}
              supportsPWA={supportsPWA}
            />
          )}
        </Onboard>
      )}
    </>
  );
}
