import axios from "axios";
import { Helper } from "../models/helper";

export const createHelper = async (payload: Helper) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/Helper/helper`,
      payload
    );
    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
