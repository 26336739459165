import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { lookupRoles } from "../../api/lookupRoles";

export default function SelectRole({
  name,
  value,
  onChange,
  onBlur,
  isInvalid,
  enabled,
}: any) {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchTribes = async () => {
      const response = await lookupRoles();
      if (response) setRoles(response);
    };
    fetchTribes();
  }, []);

  return (
    <Form.Group className="mb-3" controlId="formSelectRole">
      <Form.Label>Role</Form.Label>
      <Form.Select
        aria-label="Select Role"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        isInvalid={isInvalid}
        disabled={!enabled}
      >
        {Array.isArray(roles) &&
          roles.map((role: { roleID: number; displayName: string }) => (
            <option key={role.roleID} value={role.roleID}>
              {role.displayName}
            </option>
          ))}
      </Form.Select>
    </Form.Group>
  );
}
