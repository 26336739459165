import { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Button from "react-bootstrap/esm/Button";
import Row from "react-bootstrap/esm/Row";
import Spinner from "react-bootstrap/Spinner";

import { getCatchReportExportHTML } from "../../api/getCatchReportExportHTML";
import { useLocation, useParams } from "react-router-dom";
import { getCatchReportExportPDF } from "../../api/getCatchReportExportPDF";
import { FaPrint, FaFileDownload } from "react-icons/fa";

//import * as DOMPurify from "dompurify";

export default function PrintCatchReport() {
  const { catchReportID } = useParams();
  const [reportHTML, setReportHTML] = useState<any>();
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      if (catchReportID) {
        const resPreviewCatchReportHTML = await getCatchReportExportHTML(
          catchReportID
        );

        if (resPreviewCatchReportHTML) {
          //const safeHTML = DOMPurify.sanitize(resPreviewCatchReportHTML);
          setReportHTML(resPreviewCatchReportHTML);
        }
      }
    };
    fetch();
  }, [catchReportID]);

  const downloadPDF = async () => {
    setIsDownloading(true);
    await getCatchReportExportPDF(catchReportID);
    setIsDownloading(false);
  };

  return (
    <Container className="print-container">
      <div className="my-3 d-flex justify-content-end d-print-none">
        <Button
          variant="primary"
          className="me-2"
          onClick={() => window.print()}
        >
          <FaPrint className="me-2" />
          Print
        </Button>
        <Button variant="primary" onClick={downloadPDF}>
          <FaFileDownload className="me-2" />
          Download PDF{" "}
          {isDownloading && (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              className="ms-2"
            />
          )}
        </Button>
      </div>

      <div
        dangerouslySetInnerHTML={{
          __html: reportHTML,
        }}
      ></div>
    </Container>
  );
}
