import axios from "axios";

export interface ForgotUsername {
  email?: string;
  phone?: string;
}

export const forgotUsername = async (payload: ForgotUsername) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/Auth/forgot-username`,
      payload
    );

    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
