import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

export interface ToastData {
  show: boolean;
  title: string;
  body: string;
  bg: string;
}

export default function FormToast({ toastData, setData }: any) {
  //   const [show, setShow] = useState(false);

  return (
    <ToastContainer position={"bottom-start"}>
      <Toast
        onClose={() => setData({ ...toastData, show: false })}
        show={toastData.show}
        delay={5000}
        autohide
        className="ms-4 mb-4"
        bg={toastData.bg.toLowerCase()}
      >
        <Toast.Header>
          <img src="" className="rounded me-2" alt="" />
          <strong className="me-auto">{toastData.title}</strong>
        </Toast.Header>
        <Toast.Body className="text-white">{toastData.body}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}
