import axios from "axios";

export const onboardingIssue = async (summary: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/User/onboarding-issue`,
      { summary: summary }
    );
    return response.data;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
