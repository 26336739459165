import axios from "axios";

export const putLicenseDeactivate = async (fisherID: number) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/License/deactivate?FisherID=${fisherID}&Type=COMMERCIAL`
    );
    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
