import { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { GiBoatFishing, GiFishingBoat } from "react-icons/gi";
import { getUserID } from "../../helpers/authHelper";
import InputGroup from "react-bootstrap/InputGroup";
import { getFormattedDateYYYYMMDD } from "../../helpers/getFormattedDateYYYYMMDD";
import { License } from "../../models/license";
import { getLicenses } from "../../api/getLicenses";
import { getUserLicenses } from "../../api/getUserLicenses";

export default function ReadonlyLicenseDetails({ userID }: any) {
  const [licenses, setLicenses] = useState<License[]>([]);

  // On Load
  useEffect(() => {
    fetchLicenses();
  }, [userID]);

  const fetchLicenses = async () => {
    if (userID === getUserID() || userID === undefined) {
      const response = await getLicenses();
      if (response) setLicenses(response);
    } else {
      const response = await getUserLicenses(userID);
      if (response) setLicenses(response);
    }
  };

  return (
    <>
      {licenses.length > 0 && (
        <div className="form-card">
          <Row className="d-none d-lg-flex mb-4">
            <Col>
              <Form.Label>License Type</Form.Label>
            </Col>

            <Col>
              <Form.Label>License Number</Form.Label>
            </Col>

            <Col>
              <Form.Label>Active / Expire Date</Form.Label>
            </Col>
          </Row>

          {Array.isArray(licenses) &&
            licenses.map((license: License, i) => (
              <Form
                key={i}
                className="border-bottom border-primary"
                autoComplete="off"
              >
                <Row>
                  <Col lg={4} className="d-flex align-items-center">
                    {/* {license.licenseTypeCode === "C" && !license.isEditing && (
                      <GiFishingBoat className="color-secondary fs-3 text-primary me-2" />
                    )}
                    {license.licenseTypeCode === "S" && !license.isEditing && (
                      <GiBoatFishing className="color-secondary fs-3 text-primary me-2" />
                    )} */}

                    <Form.Group controlId={`formLicenseType-${i}`}>
                      <Form.Control
                        plaintext
                        readOnly
                        type="text"
                        name="licenseType"
                        value={
                          license.licenseTypeCode === "C"
                            ? "Commercial (BIA)"
                            : "Subsistence"
                        }
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <Form.Control
                      plaintext
                      readOnly
                      type="text"
                      name="licenseNumber"
                      value={license.licenseNumber}
                    />
                  </Col>
                  <Col lg={4}>
                    <InputGroup className="flex-nowrap">
                      <Form.Control
                        plaintext
                        readOnly
                        type="date"
                        name="activeDate"
                        value={getFormattedDateYYYYMMDD(
                          license.activeDate ?? ""
                        )}
                      />

                      <Form.Control
                        plaintext
                        readOnly
                        type="date"
                        name="expireDate"
                        value={getFormattedDateYYYYMMDD(
                          license.expireDate ?? ""
                        )}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Form>
            ))}
        </div>
      )}
    </>
  );
}
