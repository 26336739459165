import axios from "axios";

export interface ChangeRequest {
  userID: number;
  catchReportID: number;
  beginDate: string;
  endDate: string;
  issueSummary: string;
}

export const catchReportChangeRequest = async (
  changeRequest: ChangeRequest
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/CatchReport/change-request`,
      changeRequest
    );
    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
