import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Alert from "react-bootstrap/Alert";
import { FaPlus, FaMinus, FaPlusCircle } from "react-icons/fa";
import {
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { GiFishingHook } from "react-icons/gi";
import { DailyTrip } from "../../models/dailyTrip";
import { TripGear } from "../../models/tripGear";
import { Gear } from "../../models/gear";
import GearAttributes from "../formControls/gearAttributes";
import { MeshSize } from "../../models/meshSize";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import Card from "react-bootstrap/Card";
import { TripEffort } from "../../models/tripEffort";

export default function SelectGear({
  initialValues,
  refId,
  formData,
  gearList,
  meshList,
}: any) {
  // Submit form from parent component
  useImperativeHandle(refId, () => ({
    Submit: async () => {
      // Empty Errors Array
      const formErrors: SetStateAction<string[]> = [];

      // Check if we have at least one gear
      if (selectedGear.length > 0) {
        //Submit all forms
        for (const key in gearRefs.current) {
          if (gearRefs.current[key] !== null)
            await gearRefs.current[key].Submit();
        }

        // Check if all gears have required field filled
        if (!selectedGear.every((e) => e.validated)) {
          formErrors.push("Please fill all required fields");
        }

        if (formErrors.length === 0) {
          formData({
            values: {
              ...values,
              gear: selectedGear,
              effort: effort,
            },
            validated: true,
          });
        }
        setErrors(formErrors);
      } else {
        formErrors.push("At least one gear is required");
        setErrors(formErrors);
      }
    },
  }));

  const [values, setValues] = useState<DailyTrip>({ ...initialValues });
  const [buttonsList, listAnimation] =
    useAutoAnimate<any>(/* optional config */);
  const [gears, setGears] = useState<Gear[]>(gearList);
  const [selectedGear, setSelectedGear] = useState<TripGear[]>(values.gear);
  const [effort, setEffort] = useState<TripEffort[]>(values.effort);
  const [showFormList, setShowFormList] = useState(false);
  const [listHeight, setListHeight] = useState(0);
  const [btnDistance, setBtnDistance] = useState<any>({ "--buttonOffset": 0 });
  const listRef = useRef<any>(null);
  const [gearID, setGearID] = useState(0);
  const moreBtnRef = useRef<any>(null);
  const gearRefs = useRef<any>({});
  const [meshSizes, setMeshSizes] = useState<MeshSize[]>(meshList);
  const [showMore, setShowMore] = useState(false);
  const [showHelp, setShowHelp] = useState(
    localStorage.getItem("dtGearHelp") === "true"
  );
  const [errors, setErrors] = useState<string[]>([]);

  // Set the distance for the list arrow to be below/center the "More" button
  useLayoutEffect(() => {
    // Change height of modal body so the list doesn't overflow
    setListHeight(listRef?.current?.offsetHeight ?? 0);
  }, [showFormList, selectedGear]);

  // Get array of buttons for the top
  const getVisibleFormButtons = () => {
    // Create array of gear ID's from selected gear

    // const selectedGearIDs = selectedGear.map((e) => e.gearID);
    // const visible = gears.filter((g: Gear) => selectedGearIDs.includes(g.id));

    const visible: Gear[] = [];
    const gearCount = showMore ? gears.length : 5;

    // If there's less than four, fill with non selected items
    let i = 0;
    while (visible.length < gearCount && i < gears.length + 1) {
      if (!visible.includes(gears[i])) {
        visible.push(gears[i]);
      }
      i++;
    }
    return visible;
  };

  // Get array of buttons for the "Show More Gear" list
  const getFormButtonsList = () => {
    const moreButtons = gears.filter((g) => {
      return !getVisibleFormButtons().some((v: Gear) => v.id === g.id);
    });
    return moreButtons;
  };

  const selectGear = (gear: Gear) => {
    // Create a new id that's negative
    const newID = Math.min(...selectedGear.map((e) => e.id), gearID) - 1;

    // Create new gear with selected gear
    const newGear: TripGear = {
      id: newID,
      gearName: gear.name,
      gearID: gear.id,
      gearKey: gear.typeCode,
    };

    setValues({ ...values, complete: false });
    setGearID(newID);
    setSelectedGear([newGear]);
    addGearEffort(newGear);
  };

  const updateGear = (gearData: TripGear) => {
    selectedGear[0] = { ...selectedGear[0], ...gearData };
    setSelectedGear(selectedGear);

    formData({
      values: { ...values, gear: selectedGear },
      validated: false,
    });
  };

  const addGearEffort = (gear: TripGear) => {
    // Create a new id that's negative
    const newID =
      Math.min(...values.effort.map((e) => e.id), values.newEffortID) - 1;
    const newEffort: TripEffort = {
      id: newID,
      tripGearID: gear.id,
      gearID: gear.gearID,
      gearKey: gear.gearKey,
      gearName: gear.gearName,
      grids: values.grids,
    };

    setEffort([newEffort]);
  };

  return (
    <>
      <h2 className="mb-3 fw-normal">
        Select the gear used for this trip
        {/* {!showHelp && (
          <span
            onClick={() => {
              localStorage.setItem("dtGearHelp", "true");
              setShowHelp(true);
            }}
            className="link fs-6 ms-3"
          >
            Help
          </span>
        )} */}
      </h2>
      <div className="mb-3">
        <ol>
          <li>Select the gear you used, and enter any gear details.</li>
          <li>
            Click <strong>Continue</strong> to move on to the next step.
          </li>
        </ol>
      </div>

      {/* Help Info */}
      {showHelp && (
        <Alert
          variant="info"
          onClose={() => {
            localStorage.setItem("dtGearHelp", "false");
            setShowHelp(false);
          }}
          className="mb-5"
        >
          <p>
            If you have more than one gear, you will need to enter all required
            information for that gear in a new daily trip.
          </p>
          <p>
            *Not all gear types listed are approved for use at all times of the
            year. Consult your fishing regulations for details.
          </p>
        </Alert>
      )}

      {/* Visible Gear Buttons */}

      <Row
        // ref={buttonsList}
        className="g-2"
      >
        {getVisibleFormButtons().map((g: Gear) => (
          <Col lg={2} sm={4} xs={4} key={`buttons-${g.id}`}>
            <div
              onClick={() => {
                selectGear(g);
              }}
              className={`form-button ${
                selectedGear.some((x) => x.gearID === g.id) ? "selected" : ""
              }`}
            >
              <GiFishingHook />
              <strong>{g.name}</strong>
            </div>
          </Col>
        ))}
        <Col lg={2} xs={4}>
          <div
            onClick={() => setShowMore(!showMore)}
            className="form-button"
            ref={moreBtnRef}
          >
            {showMore ? <FaMinus /> : <FaPlus />}
            <strong>Show {showMore ? "Less" : "More"} Gear</strong>
          </div>
        </Col>
      </Row>

      {/* More Gear List */}
      <div
        ref={refId}
        className="position-relative mb-4"
        style={{ minHeight: `${listHeight}px` }}
      >
        {/* Form Button List */}
        {showFormList && (
          <div ref={listRef} className="form-button-list" style={btnDistance}>
            <div className="popover-arrow"></div>
            <Row className="g-3">
              {getFormButtonsList().map((g: Gear) => (
                <Col md={3} key={`more-${g.id}`}>
                  <div
                    onClick={() => {
                      selectGear(g);
                    }}
                    className="form-button-small"
                  >
                    <strong>{g.name}</strong>
                  </div>
                </Col>
              ))}
            </Row>
            <p className="lead text-end mb-0 pt-4">
              <span
                className="cursor-pointer text-primary"
                onClick={() => setShowFormList(false)}
              >
                CLOSE DROPDOWN
              </span>
            </p>
          </div>
        )}
      </div>

      {selectedGear.length === 0 ? (
        <h1 className="text-gray text-center my-5">No Gear Selected</h1>
      ) : (
        <Card className="mb-4 shadow border-gray">
          <Card.Header className="d-flex justify-space-between">
            <span className="fw-bold">{selectedGear[0].gearName}</span>
          </Card.Header>

          <Card.Body className="gear-card">
            <GearAttributes
              gear={gears.find((g) => g.id === selectedGear[0].gearID)}
              effort={selectedGear[0]}
              meshSizes={meshSizes}
              updateEffort={updateGear}
              refID={(ref: any) => (gearRefs.current[selectedGear[0].id] = ref)}
            />
          </Card.Body>
        </Card>
      )}

      {errors.length > 0 && (
        <Alert variant="danger" className="mt-4 mb-0">
          <Alert.Heading>Error</Alert.Heading>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </Alert>
      )}
    </>
  );
}
