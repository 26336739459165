import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import OrHR from "../../components/orHR";
import { onetimePassword } from "../../helpers/onetimePassword";
import EditAdminTribe, {
  TribeValues,
} from "../../components/formControls/editAdminTribe";
import EditContact, {
  ContactValues,
} from "../../components/formControls/editContact";
import EditPersonalInformation, {
  PersonalInformationValues,
} from "../../components/formControls/editPersonalInformation";
import { usernameReg } from "../../helpers/usernameReg";
import passwordReg from "../../helpers/passwordReg";
import { User } from "../../api/createUser";
import { createTribalAdmin } from "../../api/createTribalAdmin";
import { createCoraAdmin } from "../../api/createCoraAdmin";
import { getOrganizationID, getRoles } from "../../helpers/authHelper";
import { removeEmptyValues } from "../../helpers/removeEmptyValues";
import FormToast, { ToastData } from "../../components/formToast";
import { useNavigate } from "react-router-dom";
import { apiSuccess } from "../../helpers/apiSuccess";
import { Agency, createAgency } from "../../api/createAgency";

export default function RegisterAgency() {
  interface FormData {
    values: any;
    validated: boolean;
    errors: any;
  }

  interface RegisterAgency {
    username: string;
    oneTimePassword: string;
  }

  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [toastData, setToastData] = useState<ToastData>({
    show: false,
    title: "",
    body: "",
    bg: "",
  });

  // Set initial values
  const personalInformationValues: PersonalInformationValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    address1: "",
    city: "",
    stateCode: "",
    zip: "",
  };

  // const tribeValues: TribeValues = {
  //   organizationID: getOrganizationID(),
  // };

  const contactValues: ContactValues = {
    email: "",
    phone: "",
  };

  const registerAgencyValues: RegisterAgency = {
    username: "",
    oneTimePassword: "",
  };

  // Set all forms in single object
  const [formData, setFormData] = React.useState({
    editPersonalInformation: {
      values: personalInformationValues,
      validated: false,
    },
    // editTribe: { values: tribeValues, validated: false, errors: {} },
    editContact: { values: contactValues, validated: false, errors: {} },
    editRegisterAgency: {
      values: registerAgencyValues,
      validated: false,
      errors: {},
    },
  });

  // Create Refs for each form
  const editPersonalInformationRef = React.useRef<HTMLFormElement>(null);
  // const editTribeRef = React.useRef<HTMLFormElement>(null);
  const editContactRef = React.useRef<HTMLFormElement>(null);

  // Handle form data when updated
  const handleChangeEditPersonalInformation = (data: FormData) => {
    formData.editPersonalInformation = { ...data };
    setFormData({ ...formData });
  };

  // const handleChangeEditTribe = (data: FormData) => {
  //   formData.editTribe = { ...data };
  //   setFormData({ ...formData });
  // };

  const handleChangeEditContact = (data: FormData) => {
    formData.editContact = { ...data };
    setFormData({ ...formData });
  };

  const handleChangeEditRegisterAgency = (data: FormData) => {
    formData.editRegisterAgency = { ...data };
    setFormData({ ...formData });
  };

  // Register with username and password
  async function registerAdminUsernamePassword() {
    try {
      await editPersonalInformationRef.current?.Submit();
      await editContactRef.current?.Submit();
      await formik.submitForm();

      if (
        formData.editPersonalInformation.validated &&
        formData.editContact.validated &&
        formData.editRegisterAgency.validated
      ) {
        const data: Agency = {
          ...formData.editPersonalInformation.values,
          ...formData.editContact.values,
          //organizationID: null,
          ...formData.editRegisterAgency.values,
          createVia: "PASSWORD",
        };
        removeEmptyValues(data);
        registerAgency(data);
      } else {
        // Form Validation Errors
        setToastData({
          show: true,
          bg: "Danger",
          title: "Error",
          body: "There were validation errors",
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Register Admin with Email Link
  async function submitEmailRegistration() {
    await editPersonalInformationRef.current?.Submit();
    await editContactRef.current?.Submit();

    if (
      formData.editPersonalInformation.validated &&
      formData.editContact.validated
    ) {
      const data: Agency = {
        ...formData.editPersonalInformation.values,
        // ...formData.editTribe.values,
        ...formData.editContact.values,
        ...formData.editRegisterAgency.values,
        createVia: "EMAIL",
      };

      removeEmptyValues(data);
      registerAgency(data);
    } else {
      // Form Validation Errors
      setToastData({
        show: true,
        bg: "Danger",
        title: "Error",
        body: "There were validation errors",
      });
    }
  }

  // Register Admin with Phone Link
  async function submitPhoneRegistration() {
    await editPersonalInformationRef.current?.Submit();
    await editContactRef.current?.Submit();

    if (
      formData.editPersonalInformation.validated &&
      formData.editContact.validated
    ) {
      const data: Agency = {
        ...formData.editPersonalInformation.values,
        // ...formData.editTribe.values,
        ...formData.editContact.values,
        ...formData.editRegisterAgency.values,
        createVia: "PHONE",
      };

      removeEmptyValues(data);
      alert(JSON.stringify(data));
      registerAgency(data);
    } else {
      // Form Validation Errors
      setToastData({
        show: true,
        bg: "Danger",
        title: "Error",
        body: "There were validation errors",
      });
    }
  }

  // API Call to register Admin
  const registerAgency = async (data: Agency) => {
    const response = await createAgency(data);

    if (apiSuccess(response.status)) {
      setToastData({
        show: true,
        bg: "Success",
        title: "Success",
        body: response.data.message,
      });

      // Reset Form
      setDisabled(true);
      setTimeout(() => navigate("/agency"), 3000);
    }
  };

  // Register Admin Form
  const formik = useFormik({
    initialValues: {
      username: "",
      oneTimePassword: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string()
        .min(6, "Username must be 6 characters or longer")
        .matches(usernameReg, "Only letters and numbers allowed")
        .required("Username is required"),
      oneTimePassword: Yup.string()
        .required("Password required")
        .min(10, "Minimum ten characters, at least one letter and one number")
        .test(
          "isValidPass",
          "At least one letter and one number",
          (value: any, context) => {
            return passwordReg(value, context);
          }
        ),
    }),
    onSubmit: () => {
      handleChangeEditRegisterAgency({
        values: formik.values,
        validated:
          formik.dirty && Object.keys(formik.errors).length === 0
            ? true
            : false,
        errors: formik.errors,
      });
    },
  });

  // Update values on change
  React.useEffect(() => {
    handleChangeEditRegisterAgency({
      values: formik.values,
      validated:
        formik.dirty && Object.keys(formik.errors).length === 0 ? true : false,
      errors: formik.errors,
    });
  }, [formik.values, formik.errors]);

  // Generate Password
  const generatePassword = () => {
    formik.setFieldValue("oneTimePassword", onetimePassword());
  };

  return (
    <div className="py-5">
      <Container>
        <FormToast toastData={toastData} setData={setToastData} />

        <h1 className="text-primary mb-5">Register New Agency</h1>

        {/* Personal Information */}
        <Row>
          <Col lg={3}>
            <h2 className="form-header">Personal Information</h2>
          </Col>
          <Col>
            <EditPersonalInformation
              formData={handleChangeEditPersonalInformation}
              refId={editPersonalInformationRef}
              initialValues={personalInformationValues}
            />
          </Col>
        </Row>

        {/* Contact Details */}
        <Row>
          <Col lg={3}>
            <h2 className="form-header">Contact Details</h2>
          </Col>
          <Col>
            <EditContact
              formData={handleChangeEditContact}
              refId={editContactRef}
              initialValues={contactValues}
            />
          </Col>
        </Row>

        {/* Register Admin */}
        <Row>
          <Col lg={3}>
            <h2 className="form-header">Register Agency</h2>
          </Col>
          <Col>
            <div className="form-card callout-success">
              <Form
                id="register-admin-form"
                noValidate
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <Row>
                  <Form.Group
                    as={Col}
                    xl
                    className="mb-3"
                    controlId="formUsername"
                  >
                    <Form.Label>Username*</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      value={formik.values.username}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.touched.username && !!formik.errors.username
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.username}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    className="mb-3"
                    controlId="formNewAdminPassword"
                  >
                    <Form.Label>One-time Password*</Form.Label>
                    <Form.Control
                      type="text"
                      name="oneTimePassword"
                      value={formik.values.oneTimePassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.touched.oneTimePassword &&
                        !!formik.errors.oneTimePassword
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.oneTimePassword}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Col xs="auto" xl={4}>
                    <Button
                      onClick={generatePassword}
                      variant="secondary"
                      className="w-100 form-btn mb-3"
                    >
                      Generate Password
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      variant="success"
                      className="w-100 mb-3"
                      onClick={registerAdminUsernamePassword}
                      disabled={disabled}
                    >
                      Register Agency
                    </Button>
                    <p className="mb-0">
                      Create account with a one-time password.
                    </p>
                  </Col>
                </Row>
                <OrHR />

                <Row>
                  <Col md>
                    <Button
                      variant="primary"
                      className="w-100 mb-3"
                      onClick={submitEmailRegistration}
                      disabled={
                        formData.editContact.errors?.hasOwnProperty("email") ||
                        !formData.editContact.validated ||
                        !formData.editContact.values.email ||
                        disabled
                      }
                    >
                      Email Registration Link
                    </Button>
                  </Col>
                  <Col md>
                    <Button
                      variant="primary"
                      className="w-100 mb-3"
                      onClick={submitPhoneRegistration}
                      disabled={
                        formData.editContact.errors?.hasOwnProperty("phone") ||
                        !formData.editContact.validated ||
                        !formData.editContact.values.phone ||
                        disabled
                      }
                    >
                      Text Registration Link
                    </Button>
                  </Col>
                </Row>
                <p>
                  Send a registration link to the agency to finish account
                  registration.
                  <br />
                  Account will be created with a status of <strong>New</strong>
                </p>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
