import axios from "axios";

export const deactivateUser = async (userID: number) => {
  try {
    const response = axios.put(`${process.env.REACT_APP_API}/User/deactivate`, {
      userID: userID,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};
