import axios from "axios";

export const listCatchReports = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/CatchReport/list`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
