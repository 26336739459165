import React, { useEffect, useImperativeHandle } from "react";
import Table from "react-bootstrap/esm/Table";
import Button from "react-bootstrap/esm/Button";

import {
  useReactTable,
  getCoreRowModel,
  FilterFn,
  flexRender,
  ColumnDef,
} from "@tanstack/react-table";

import { RankingInfo } from "@tanstack/match-sorter-utils";
import { fuzzyFilter } from "./fuzzyFilter";
import { getFormattedDateMMDDYYYY } from "../../helpers/getFormattedDateMMDDYYYY";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }

  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

export default function FisherUnsubmittedReportsTable({
  lateCatchReports,
  submitCatchReportModalRef,
}: any) {
  // Set data and filter states
  const [data, setData] = React.useState([]);

  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorFn: (row) => row,
        id: "catchReportPeriod",
        header: "Catch Report Period",
        cell: (info) => {
          return (
            <strong>
              {getFormattedDateMMDDYYYY(info.getValue().beginDate)} -{" "}
              {getFormattedDateMMDDYYYY(info.getValue().endDate)}
            </strong>
          );
        },
        footer: (props) => props.column.id,
      },
      {
        accessorFn: (row) => row.tripCount,
        id: "tripsLogged",
        header: "Trips Logged",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        accessorFn: (row) => row,
        id: "action",
        header: () => {
          return <div className="ms-auto">Submit Report</div>;
        },
        cell: (info) => {
          let hasTrips = info.getValue().tripCount > 0;

          return (
            <div className="float-end">
              <Button
                variant={
                  hasTrips ? "success" : "outline-success bg-white text-dark"
                }
                size="sm"
                onClick={() =>
                  submitCatchReportModalRef.current?.handleShow(info.getValue())
                }
              >
                {hasTrips
                  ? "Submit this Catch Report"
                  : "Submit this NO FISHING Report"}
              </Button>
            </div>
          );
        },
        footer: (props) => props.column.id,
      },
    ],
    [submitCatchReportModalRef]
  );

  // Get and update data on load and org change
  useEffect(() => {
    setData(lateCatchReports);
  }, [lateCatchReports]);

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),

    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  });

  return (
    <>
      {/* Table */}
      <Table striped hover responsive>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ verticalAlign: "top" }}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div className="d-flex align-items-center">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} className="align-middle">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
