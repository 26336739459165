import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import { handleLogout } from "../../api/handleLogout";
import {
  getOrganizationID,
  getOrganizationName,
  getRoles,
  getUserName,
} from "../../helpers/authHelper";
import { FaHome, FaQuestionCircle, FaUserAlt } from "react-icons/fa";
import InstallPWA from "../installPWA";
import Button from "react-bootstrap/esm/Button";
import CoraLogo from "../coraLogo";
import CORAImg from "../../assets/logos/cora-logo.png";

export default function SideNav({
  className,
  promptInstall,
  supportsPWA,
}: any) {
  const token = localStorage.getItem("accessToken");
  const roles = getRoles();
  const username = getUserName();
  const logo = localStorage.getItem("organizationLogo");

  return (
    <div className={`d-flex flex-column pb-5 pt-3 px-3 h-100 ${className}`}>
      {token &&
        getOrganizationID() !== 0 &&
        logo &&
        !roles.includes("Agency") && (
          <div className="pb-4">
            <img
              src={logo}
              alt="Logo"
              title={getOrganizationName()}
              style={{ maxWidth: "12rem", width: "100%" }}
            />
          </div>
        )}

      {roles.includes("Agency") && (
        <>
          <div className="pb-4">
            <img
              src={CORAImg}
              alt="CORA Logo"
              style={{ maxWidth: "12rem", width: "100%" }}
              className="w-100 h-auto"
            />
          </div>
        </>
      )}

      <div className="pb-4">
        <p className="mb-1">
          <strong>{username}</strong>
        </p>
        <p className="mb-1 fst-italic">{getOrganizationName()}</p>
        <hr />
        {/* <p className="mb-1">{`${firstName} ${lastName}`}</p>
        {roles.length > 0 && (
          <p className="mb-1">{`Role: ${roles[0].replace("_", " ")}`}</p>
        )}
        <Link to="/" className="link">
          <span>Dashboard</span>
        </Link> */}
      </div>

      {/* Agency */}
      {token && roles.includes("Agency") && (
        <>
          <h4>Catch Reports</h4>
          <ul className="nav nav-pills flex-column mb-5 align-items-sm-start">
            <li className="nav-item">
              <Link to="/export-agency" className="nav-link align-middle px-0">
                <span className="ms-1">Export Agency Data</span>
              </Link>
            </li>
          </ul>
        </>
      )}

      {/* CORA and Tribe Admin */}
      {token &&
        (roles.includes("CORA_Admin") || roles.includes("Tribal_Admin")) && (
          <>
            <h4>Catch Reports</h4>
            <ul className="nav nav-pills flex-column mb-5 align-items-sm-start">
              <li className="nav-item">
                <Link to="/reports" className="nav-link align-middle px-0">
                  <span className="ms-1">View Reports</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/export" className="nav-link align-middle px-0">
                  <span className="ms-1">Export Tribal Data</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/export-agency"
                  className="nav-link align-middle px-0"
                >
                  <span className="ms-1">Export Agency Data</span>
                </Link>
              </li>
            </ul>

            <h4>Fishers</h4>
            <ul className="nav nav-pills flex-column mb-5 align-items-sm-start">
              <li className="nav-item">
                <Link to="/fishers" className="nav-link align-middle px-0">
                  <span className="ms-1">Fishers</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/helpers" className="nav-link align-middle px-0">
                  <span className="ms-1">Helpers</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/register/fisher"
                  className="nav-link align-middle px-0"
                >
                  <span className="ms-1">Register Fisher</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/register/helper"
                  className="nav-link align-middle px-0"
                >
                  <span className="ms-1">Register Helper</span>
                </Link>
              </li>
            </ul>

            <h4>Admin</h4>
            <ul className="nav nav-pills flex-column mb-5 align-items-sm-start">
              <li className="nav-item">
                <Link to="/users" className="nav-link align-middle px-0">
                  <span className="ms-1">All Users</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/admins" className="nav-link align-middle px-0">
                  <span className="ms-1">Administrators</span>
                </Link>
              </li>

              {roles.includes("CORA_Admin") && (
                <li className="nav-item">
                  <Link to="/agencies" className="nav-link align-middle px-0">
                    <span className="ms-1">Agency Users</span>
                  </Link>
                </li>
              )}

              <li className="nav-item">
                <Link to="/species" className="nav-link align-middle px-0">
                  <span className="ms-1">Species</span>
                </Link>
              </li>
              
              <li className="nav-item">
                <Link to="/managementunits" className="nav-link align-middle px-0">
                  <span className="ms-1">Management Units</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/grids" className="nav-link align-middle px-0">
                  <span className="ms-1">Grids</span>
                </Link>
              </li>

              {getOrganizationID() !== 0 && (
                <li className="nav-item">
                  <Link to="/settings" className="nav-link align-middle px-0">
                    <span className="ms-1">Settings</span>
                  </Link>
                </li>
              )}
            </ul>
          </>
        )}

      {/* Fisher */}
      {token && roles.includes("Fisher") && (
        <div className="mb-5">
          <ul
            id="side-nav"
            className="nav nav-pills flex-column  align-items-sm-start lead mb-3"
          >
            <li className="nav-item">
              <Link to="/" className="nav-link align-middle px-0">
                <span className="ms-1">
                  <FaHome className="me-2 fs-4" />
                  Dashboard
                </span>
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/support" className="nav-link align-middle px-0">
                <span className="ms-1">
                  <FaQuestionCircle className="me-2 fs-4" />
                  Support
                </span>
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/profile" className="nav-link align-middle px-0">
                <span className="ms-1">
                  <FaUserAlt className="me-2 fs-4" />
                  Profile Settings
                </span>
              </Link>
            </li>
          </ul>

          {supportsPWA && <InstallPWA promptInstall={promptInstall} />}
        </div>
      )}

      <div className="mb-5">
        <Button
          variant="outline-primary"
          className="w-100"
          onClick={handleLogout}
        >
          Log Out
        </Button>
      </div>
    </div>
  );
}
