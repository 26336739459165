import axios from "axios";
import { Organization } from "../models/organization";

export const saveSettings = async (organization: Organization) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/Settings/save`,
      organization
    );

    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
