import axios from "axios";

export const getPermits = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Permit/permits`
    );
    return response.data;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
