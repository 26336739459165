import axios, { AxiosRequestConfig } from "axios";

const config: AxiosRequestConfig = {
  responseType: "blob",
  headers: {
    Accept: "application/pdf",
  },
};

export const getCatchReportExportPDF = async (catchReportID: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/CatchReport/Export-PDF?CatchReportID=${catchReportID}`,
      config
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.style.display = "none";
    link.setAttribute("download", `catch_report_${catchReportID}.pdf`);

    document.body.appendChild(link);
    link.click();

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
