import axios from "axios";

export const getTripDates = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API}/Trip/dates`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
