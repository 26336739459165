import axios from "axios";
import { convertObjectToUrlParams } from "../helpers/convertObjToUrlParams";

export const listReportExcluded = async (params: any = {}) => {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_API
      }/CatchReport/report-excluded${convertObjectToUrlParams(params)}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
