import axios from "axios";

export const deleteHelper = async (helperID: number) => {
  try {
    const response = axios.delete(
      `${process.env.REACT_APP_API}/Helper/helper?HelperID=${helperID}`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
