import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { getRoles, getUserName } from "../../helpers/authHelper";
import {
  FaFileAlt,
  FaUsers,
  FaPeopleCarry,
  FaFileDownload,
} from "react-icons/fa";
import { Link } from "react-router-dom";

export default function AdminHome() {
  const roles = getRoles();
  return (
    <div className="py-5">
      <Container>
        <div className="d-flex mb-5 justify-content-between align-items-end">
          <h1 className="text-primary">Home</h1>
          <p className="lead mb-0">Hello, {getUserName()}</p>
        </div>
        <div id="admin-dashboard">
          <Row>
            {roles.includes("Agency") ? (
              <>
                {/* Agency export button */}
                <Col sm={6} md={4}>
                  <Link className="link-unstyled" to={"/export-agency"}>
                    <div className="dashboard-card">
                      <FaFileDownload className="dashboard-icon" />
                      <h2>Export Report Data</h2>
                    </div>
                  </Link>
                </Col>
              </>
            ) : (
              <>
                {/* Tribal Administrators */}
                <Col sm={6} md={4}>
                  <Link className="link-unstyled" to={"/reports"}>
                    <div className="dashboard-card">
                      <FaFileAlt className="dashboard-icon" />
                      <h2>Catch Reports</h2>
                    </div>
                  </Link>
                </Col>

                <Col sm={6} md={4}>
                  <Link className="link-unstyled" to={"/fishers"}>
                    <div className="dashboard-card">
                      <FaUsers className="dashboard-icon" />
                      <h2>Fishers</h2>
                    </div>
                  </Link>
                </Col>
                <Col sm={6} md={4}>
                  <Link className="link-unstyled" to={"/helpers"}>
                    <div className="dashboard-card">
                      <FaPeopleCarry className="dashboard-icon" />
                      <h2>Helpers</h2>
                    </div>
                  </Link>
                </Col>
              </>
            )}
          </Row>
        </div>
      </Container>
    </div>
  );
}
