import axios from "axios";

export const putCatchReportReview = async (catchReportID: number) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/CatchReport/review?CatchReportID=${catchReportID}`
    );
    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
