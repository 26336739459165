import { RankingInfo } from "@tanstack/match-sorter-utils";
import { ColumnDef } from "@tanstack/react-table";
import React, { useEffect, useImperativeHandle } from "react";
import Form from "react-bootstrap/Form";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/esm/Button";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Table from "react-bootstrap/esm/Table";
import Tooltip from "react-bootstrap/esm/Tooltip";
import { fuzzySort } from "../../components/tables/fuzzySort";
import { DailyTrip } from "../../models/dailyTrip";
import DebouncedInput from "./debouncedInput";
import { fuzzyFilter } from "./fuzzyFilter";
import Filter from "./tableHeaderFilter";

import {
  FaCheckCircle,
  FaColumns,
  FaEdit,
  FaExclamationCircle,
  FaEye,
  FaFilter,
  FaGreaterThan,
  FaLessThan,
  FaSort,
  FaSortDown,
  FaSortUp,
  FaTrashAlt,
} from "react-icons/fa";

import {
  ColumnFiltersState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { deleteTrip } from "../../api/deleteTrip";
import { listTrips } from "../../api/listTrips";
import { putTripInclude } from "../../api/putTripInclude";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }

  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

export default function TripsTable({
  dailyTripRef,
  reviewDailyTripRef,
  refID,
  dailyTrips,
  handleRefreshDailyTrips,
  loadingDailyTrips,
  readOnly = false,
  excluded = false,
  catchReportID = -1,
}: any) {
  // Set data and filter states
  const [data, setData] = React.useState(dailyTrips);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [showColumnFilter, setShowColumnFilter] = React.useState(false);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const [columnVisibility, setColumnVisibility] = React.useState({
    includeTrip: excluded && !readOnly,
  });
  const columns = React.useMemo<ColumnDef<DailyTrip, any>[]>(
    () => [
      {
        accessorFn: (row: any) => {
          return { tripID: row.id };
        },
        id: "includeTrip",
        accessorKey: "includeTrip",
        header: "Include Trip",
        cell: (info: any) => {
          return (
            // info.getValue().tripID
            <Button
              variant="primary"
              size="sm"
              onClick={() => handleIncludeTrip(info.getValue().tripID)}
            >
              {} Include Trip in Catch Report
            </Button>
          );
        },
        footer: (props: any) => props.column.id,
        enableColumnFilter: false,
        enableHiding: true,
      },
      {
        accessorFn: (row) => {
          return { complete: row.complete, excluded: row.excluded };
        },
        id: "status",
        header: "Status",
        cell: (info: any) => {
          return (
            <div className="d-flex justify-content-center ">
              {info.getValue().complete === false ? (
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      Trip is incomplete. Click Edit and complete all sections
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <FaExclamationCircle className="text-warning fs-5" />
                  </span>
                </OverlayTrigger>
              ) : info.getValue().excluded === true ? (
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      A catch report has already been submitted that covers this
                      date. Recall it below or contact an Admin
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <FaExclamationCircle className="text-danger fs-5" />
                  </span>
                </OverlayTrigger>
              ) : (
                <FaCheckCircle className="text-success fs-5" />
              )}
            </div>
          );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
        enableColumnFilter: false,
        enableHiding: false,
      },
      {
        accessorFn: (row) => row.fishingDate,
        id: "fishingDate",
        header: "Date",
        cell: (info) => {
          return (
            <strong>
              {new Date(info.getValue()).toLocaleDateString("en-US")}
            </strong>
          );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
        enableColumnFilter: false,
        enableHiding: false,
      },
      {
        accessorFn: (row) => row.lakeName,
        id: "lakeName",
        header: "Lake",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.grids,
        id: "gridNumber",
        header: "Grids",
        cell: (info) => {
          const last = info.getValue().length - 1;
          return info
            .getValue()
            .map(
              (g: any, i: number) => `${g.gridNumber}${i !== last ? ", " : ""}`
            );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.vesselName,
        id: "vessel",
        header: "Vessel",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },

      {
        accessorFn: (row) => row.effort,
        id: "gear",
        header: "Gear",
        cell: (info) => {
          const unique = info
            .getValue()
            .map((item: any) => item.gearName)
            .filter(
              (value: any, index: any, self: any) =>
                self.indexOf(value) === index
            );

          const last = unique.length - 1;
          return unique.map(
            (g: any, i: number) => `${g}${i !== last ? ", " : ""}`
          );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },

      {
        accessorFn: (row) => row.harvest,
        id: "species",
        header: "Species",
        cell: (info) => {
          const unique = info
            .getValue()
            .map((item: any) => item.speciesName)
            .filter(
              (value: any, index: any, self: any) =>
                self.indexOf(value) === index
            );

          const last = unique.length - 1;
          return unique.map(
            (s: any, i: number) => `${s}${i !== last ? ", " : ""}`
          );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.launchSite,
        id: "launchSite",
        header: "Launch Site",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.comments,
        id: "comments",
        header: "Comments",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },

      // Actions
      {
        accessorFn: (row: any) => {
          return { tripID: row.id };
        },
        id: "actions",
        header: () => {
          return <div className="mx-auto">Actions</div>;
        },
        cell: (info: any) => {
          return (
            <div className="users-table d-flex justify-content-center">
              {readOnly ? (
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip>Review Trip</Tooltip>}
                >
                  <Button
                    variant="secondary"
                    className="text-light ms-2"
                    size="sm"
                    onClick={() =>
                      reviewDailyTripRef.current?.loadDailyTrip(
                        info.getValue().tripID
                      )
                    }
                  >
                    <FaEye />
                  </Button>
                </OverlayTrigger>
              ) : (
                <>
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip>Delete</Tooltip>}
                  >
                    <Button
                      variant="danger"
                      className="text-light ms-2"
                      size="sm"
                      onClick={async (e) => {
                        if (window.confirm("Delete trip?") === true) {
                          let tripID = info.getValue().tripID;
                          let res = await deleteTrip(tripID);

                          if (res) {
                            const newData = data.filter(
                              (trip: DailyTrip) => trip.id !== tripID
                            );
                            setData([...newData]);
                            handleRefreshDailyTrips();
                          }
                        }
                      }}
                    >
                      <FaTrashAlt />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip>Edit</Tooltip>}
                  >
                    <Button
                      variant="secondary"
                      className="text-light ms-2"
                      size="sm"
                      onClick={(e) => {
                        dailyTripRef.current?.loadDailyTrip(
                          info.getValue().tripID
                        );
                        e.currentTarget.blur();
                      }}
                    >
                      <FaEdit />
                    </Button>
                  </OverlayTrigger>
                </>
              )}
            </div>
          );
        },
        footer: (props: any) => props.column.id,
        enableColumnFilter: false,
        enableHiding: false,
      },
    ],
    [dailyTripRef, readOnly, excluded, reviewDailyTripRef, data]
  );

  useEffect(() => {
    setColumnVisibility({
      includeTrip: excluded && !readOnly,
    });
  }, [excluded, readOnly]);

  useEffect(() => {
    setData(dailyTrips);
  }, [dailyTrips]);

  const handleIncludeTrip = async (tripID: number) => {
    if (catchReportID < 0) return;

    putTripInclude(tripID, catchReportID).then((res) => {
      if (res) handleRefreshDailyTrips();
    });
  };

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
      columnVisibility,
    },
    initialState: {
      pagination: {
        pageSize: 10,
      },
      columnVisibility: {
        includeTrip: excluded && !readOnly,
      },
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    //onColumnVisibilityChange: setColumnVisibility,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  });

  return (
    <div>
      {/* Table Header Controls */}
      <div className="table-header d-flex flex-wrap">
        {/* <OverlayTrigger
          trigger="click"
          placement="right-start"
          overlay={
            <Popover id={`popover-positioned-right-start`}>
              <Popover.Header as="h3">Daily Trip Columns</Popover.Header>
              <Popover.Body>

                <div className="border-bottom mb-1">
                  <label>
                    <input
                      {...{
                        type: "checkbox",
                        checked: table.getIsAllColumnsVisible(),
                        onChange: table.getToggleAllColumnsVisibilityHandler(),
                      }}
                    />{" "}
                    Toggle All
                  </label>
                </div>
                {table.getAllLeafColumns().map((column) => {
                  if (
                    column.id !== "actions" &&
                    column.id !== "fishingDate" &&
                    column.id !== "includeTrip"
                  )
                    return (
                      <div key={column.id}>
                        <label style={{ textTransform: "capitalize" }}>
                          <input
                            {...{
                              type: "checkbox",
                              checked: column.getIsVisible(),
                              onChange: column.getToggleVisibilityHandler(),
                            }}
                          />{" "}
                          {column.id}
                        </label>
                      </div>
                    );
                })}
              </Popover.Body>
            </Popover>
          }
        >
          <Button variant="secondary" size="sm" className="me-2 mb-3 d-inline">
            <FaColumns className="me-1" /> Columns
          </Button>
        </OverlayTrigger> */}

        <Button
          variant="secondary"
          size="sm"
          className="me-2 mb-3 d-inline"
          onClick={() => {
            setShowColumnFilter(!showColumnFilter);
          }}
        >
          <FaFilter className="me-1" />
          {showColumnFilter ? "Hide" : "Show"} Filters
        </Button>

        <DebouncedInput
          value={globalFilter ?? ""}
          onChange={(value) => setGlobalFilter(String(value))}
          className="form-control-sm border d-inline-block  w-auto mb-3"
          placeholder="Search everything..."
        />
      </div>

      {/* Table */}
      <Table striped hover responsive className="trips-table daily-trips-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ verticalAlign: "top" }}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer d-flex align-items-center"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <FaSortUp className="text-primary ms-1" />,
                            desc: <FaSortDown className="text-primary ms-1" />,
                          }[header.column.getIsSorted() as string] ?? (
                            <FaSort className="text-gray ms-1" />
                          )}
                        </div>
                        {header.column.getCanFilter() && showColumnFilter ? (
                          <div>
                            <Filter column={header.column} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        {loadingDailyTrips && (
          <tbody>
            <tr>
              <td colSpan={100}>
                <h2 className="text-gray w-100 text-center">
                  Loading Daily Trips...
                </h2>
              </td>
            </tr>
          </tbody>
        )}
        {!loadingDailyTrips && table.getRowModel().rows.length === 0 && (
          <tbody>
            <tr>
              <td colSpan={100}>
                <h2 className="text-gray w-100 text-center">No Daily Trips</h2>
              </td>
            </tr>
          </tbody>
        )}

        {table.getRowModel().rows.length > 0 && (
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr className="bg-primary" key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className={`
                        ${
                          row.original.adminEdited && !readOnly
                            ? "bg-edited"
                            : ""
                        }
                        align-middle `}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>

      {/* Table footer */}
      <div className="d-flex flex-row justify-content-end align-items-center mt-3">
        <span className="text-nowrap me-3">
          {`${
            table.getState().pagination.pageIndex *
            table.getState().pagination.pageSize
          }-${
            table.getState().pagination.pageIndex *
              table.getState().pagination.pageSize +
            table.getState().pagination.pageSize
          } of ${table.getPrePaginationRowModel().rows.length}`}
        </span>

        <Form.Select
          aria-label="Table rows per page"
          size="sm"
          style={{ maxWidth: "10rem" }}
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Rows per page {pageSize}
            </option>
          ))}
        </Form.Select>

        <Button
          variant="light-gray"
          size="sm"
          className="ms-2 text-primary"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <FaLessThan />
        </Button>
        <Button
          variant="light-gray"
          size="sm"
          className="ms-2"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <FaGreaterThan className="text-primary" />
        </Button>
      </div>
    </div>
  );
}
