import { useEffect, useImperativeHandle, useState } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import type { Value } from "react-multi-date-picker";
import { DailyTrip } from "../../models/dailyTrip";
import Alert from "react-bootstrap/Alert";

export default function TripDate({ initialValues, refId, formData }: any) {
  useImperativeHandle(refId, () => ({
    Submit: async () => {
      formData({
        values: values,
        validated: true,
      });
    },
  }));

  const [showHelp, setShowHelp] = useState(
    localStorage.getItem("dtTripDateHelp") === "true"
  );

  const [values, setValues] = useState<DailyTrip>({ ...initialValues });
  const [fishingDate, setFishingDate] = useState<Value>(
    new Date(values.fishingDate.replace(/-/g, "/"))
  );

  const isFuture = (dateString: string) => {
    const givenDate = new Date(dateString);
    const currentDate = new Date();

    return givenDate > currentDate;
  };

  useEffect(() => {
    if (fishingDate instanceof DateObject) {
      values.fishingDate = fishingDate.format("YYYY-MM-DD");
    }
  }, [fishingDate]);

  return (
    <>
      <h2 className="mb-3 fw-normal">
        What day did you go fishing?
        {/* {!showHelp && (
          <span
            onClick={() => {
              localStorage.setItem("dtTripDateHelp", "true");
              setShowHelp(true);
            }}
            className="link fs-6 ms-3"
          >
            Help
          </span>
        )} */}
      </h2>
      <div className="mb-5">
        <ol>
          <li>Select the day of the fishing trip using the calendar.</li>
          <li>
            Click <strong>Continue</strong> to move on to the next step.
          </li>
        </ol>
      </div>
      {/* {showHelp && (
        <>
          <Alert
            variant="info"
            onClose={() => {
              localStorage.setItem("dtTripDateHelp", "false");
              setShowHelp(false);
            }}
            className="mb-4"
            dismissible
          >
            <Alert.Heading>Enter the day of this trip.</Alert.Heading>
            <ol>
              <li>Select the day of the fishing trip using the calendar.</li>
              <li>
                Click <strong>Continue</strong> to move on to the next step.
              </li>
            </ol>
          </Alert>
        </>
      )} */}
      <div className="d-flex flex-column align-items-center">
        <Calendar
          value={fishingDate}
          format="MM/DD/YYYY"
          className="tripDateCalendar  border-xs-x-0 border-xs-y-0  border-lg-x border-lg-y border-primary mb-3"
          onChange={setFishingDate}
          mapDays={({ date }) => {
            if (isFuture(date.format()))
              return {
                disabled: true,
                style: { color: "#ccc" },
                onClick: () => alert("You cannot create a trip in the future"),
              };
          }}
        />
        <p className="lead">
          <strong>Selected Date: </strong>
          {fishingDate instanceof DateObject
            ? fishingDate.format("MM/DD/YYYY")
            : new Date(
                values.fishingDate.replace(/-/g, "/")
              ).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })}
        </p>
      </div>
    </>
  );
}
