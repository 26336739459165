import { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import passwordReg from "../../helpers/passwordReg";
import { usernameReg } from "../../helpers/usernameReg";
import { apiSuccess } from "../../helpers/apiSuccess";
import { getRegistrationInfo } from "../../api/getRegistrationInfo";
import { checkUsername } from "../../api/checkUsername";
import { getOrganization } from "../../api/getOrganization";
import { register } from "../../api/register";
import { handleLogin } from "../../api/handleLogin";
import { useSearchParams, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import CORAImg from "../../assets/logos/cora-logo.png";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function Register() {
  interface OrgData {
    logo: string;
    name: string;
    supportEmail: string;
  }

  interface UserData {
    firstName: string;
    userName: string;
  }

  const [searchParams] = useSearchParams();
  const [orgData, setOrgData] = useState<OrgData>();
  const [userData, setUserData] = useState<UserData>();
  const [nocode, setNocode] = useState(false);
  const [failed, setFailed] = useState(false);
  const [available, setAvailable] = useState(true);
  const [success, setSuccess] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showConfPass, setShowConfPass] = useState(false);

  const navigate = useNavigate();
  let urlId = searchParams.get("i");
  let urlCode = searchParams.get("c");

  useEffect(() => {
    if (parseInt(String(urlId)) < 1 || !urlCode) {
      setNocode(true);
    } else {
      const fetchData = async () => {
        const fetchOrg = await getOrganization(urlId, urlCode);
        const fetchUser = await getRegistrationInfo(urlId, urlCode);

        if (fetchOrg) setOrgData({ ...fetchOrg.data });
        if (fetchUser) setUserData({ ...fetchUser.data });
      };
      fetchData();
    }
  }, [urlId, urlCode]);

  const schema = Yup.object().shape({
    username: Yup.string()
      .min(3, "Username must be 3 characters or longer")
      .matches(usernameReg, "Only letters and numbers allowed")
      .required("Username is required"),
    password: Yup.string()
      .required("Password required")
      .min(10, "Minimum ten characters, at least one letter and one number")
      .test(
        "isValidPass",
        "At least one letter and one number",
        (value: any, context) => {
          return passwordReg(value, context);
        }
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  return (
    <>
      <div>
        {/* CORA Logo */}
        <Row className="gx-5 mb-5">
          <Col lg={4} className="d-flex justify-content-center">
            <div>
              <img
                src={orgData?.logo ? orgData?.logo : CORAImg}
                alt="Tribe Logo"
                style={{ maxWidth: "10rem", maxHeight: "12rem" }}
                className="w-100 h-auto"
              />
            </div>
          </Col>
          <Col lg={8} className="d-flex flex-column justify-content-center">
            <div className="text-center text-lg-start">
              <h1 className="h2 text-primary">
                {orgData?.name
                  ? `${orgData?.name} welcomes you!`
                  : ` Chippewa Ottawa Resource Authority`}
              </h1>
              <p className="lead mb-0">
                {userData?.firstName && `Hello, ${userData?.firstName}`}
              </p>
            </div>
          </Col>
        </Row>

        {/* Sign in form */}
        <h2 className="h1 mb-4 text-center text-sm-start">
          Account Registration
        </h2>

        <Formik
          initialValues={{
            username: userData?.userName ?? "",
            password: "",
            confirmPassword: "",
            checkPasswordReset: false,
          }}
          enableReinitialize={true}
          validationSchema={schema}
          onSubmit={(values) => {
            const submit = async () => {
              // Skip username check - they may register as a pre-set username and the registration call will fail if they try to take soemone else's
              //const availableUsername = await checkUsername(values.username);
              //setAvailable(apiSuccess(availableUsername.status));

              //// If username is available
              //if (apiSuccess(availableUsername.status)) {
              const response = await register({
                id: Number(urlId),
                code: String(urlCode),
                username: values.username,
                password: values.password,
              });

              // If register is successful
              if (apiSuccess(response.status)) {
                setSuccess(true);

                // Log in with new user
                const error = await handleLogin(values); //Runs Axios, on catch should return error
                if (error) {
                  // Log Out
                  localStorage.clear();
                  setTimeout(() => navigate("/"), 1500);
                }
              } else {
                setFailed(true);
              }
              //}
            };
            submit();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-4" controlId="validationFormikUsername">
                <Form.Label>Create Username</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.username && !!errors.username}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.username}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-4" controlId="validationFormikPassword">
                <Form.Label>Create Password*</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPass ? "text" : "password"}
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="password-tooltip">
                        {showPass ? "Hide password" : "Show password"}
                      </Tooltip>
                    }
                  >
                    <InputGroup.Text onClick={() => setShowPass(!showPass)}>
                      <>{showPass ? <FaEyeSlash /> : <FaEye />}</>
                    </InputGroup.Text>
                  </OverlayTrigger>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="mb-4"
                controlId="validationFormikConfirmPassword"
              >
                <Form.Label>Confirm Password*</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showConfPass ? "text" : "password"}
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.confirmPassword && !!errors.confirmPassword
                    }
                  />
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="password-tooltip">
                        {showConfPass ? "Hide password" : "Show password"}
                      </Tooltip>
                    }
                  >
                    <InputGroup.Text
                      onClick={() => setShowConfPass(!showConfPass)}
                    >
                      <>{showConfPass ? <FaEyeSlash /> : <FaEye />}</>
                    </InputGroup.Text>
                  </OverlayTrigger>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                variant="success"
                type="submit"
                size="lg"
                className="w-100 mb-3"
                disabled={nocode}
              >
                Register
              </Button>
              <p className="mt-3">
                *Password minimum length 10 characters with at least one letter
                and one number
              </p>

              {orgData?.supportEmail && (
                <p>
                  <a href={`mailto:${orgData?.supportEmail}`}>Need Help?</a>
                </p>
              )}

              {!available && (
                <Alert variant="danger" className="text-center mt-4">
                  Username is unavailable.
                </Alert>
              )}

              {(failed || nocode) && (
                <Alert variant="danger" className="text-center mt-4">
                  Invalid or expired link.
                </Alert>
              )}

              {success && (
                <Alert variant="success" className="text-center mt-4">
                  Registration successful.
                </Alert>
              )}
            </Form>
          )}
        </Formik>
      </div>

      <div className="pt-5">
        {/* Forgot Password / New Account */}
        {/* <p className="text-center mb-1">Don't have an account? Get access</p> */}
      </div>
    </>
  );
}
