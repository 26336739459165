import axios from "axios";
import { handleLogout } from "./handleLogout";
import { setAuthToken } from "./setAuthToken";

// Function that will be called to refresh authorization
export const refreshAuthLogic = async (failedRequest: any) => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    await axios
      .post(`${process.env.REACT_APP_API}/Auth/refresh-token`, {
        refreshToken: refreshToken,
      })
      .then((response) => {
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem(
          "accessTokenExpiration",
          response.data.accessTokenExpiration
        );
        setAuthToken(response.data.accessToken);
        failedRequest.response.config.headers["Authorization"] =
          "Bearer " + response.data.accessToken;
        return Promise.resolve();
      });
  } catch (error) {
    console.error(error);

    const username = localStorage.getItem("username");
    localStorage.clear();
    username && localStorage.setItem("username", username);
    window.location.href = "/";
  }
};
