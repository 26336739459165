import { Formik } from "formik";
import { useEffect, useImperativeHandle, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/esm/Card";
import Form from "react-bootstrap/esm/Form";
import Spinner from "react-bootstrap/esm/Spinner";
import { FaCalendar, FaCheckCircle, FaFileDownload } from "react-icons/fa";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { getCatchReportExportPDF } from "../../api/getCatchReportExportPDF";
import { listTripsRange } from "../../api/listTripsRange";
import { postCatchReportSubmit } from "../../api/postCatchReportSubmit";
import { apiSuccess } from "../../helpers/apiSuccess";
import { getFormattedDateMMDDYYYY } from "../../helpers/getFormattedDateMMDDYYYY";
import { CatchReport } from "../../models/catchReport";
import { CatchReportPeriod } from "../../models/catchReportPeriod";
import { DailyTrip } from "../../models/dailyTrip";
import FormToast, { ToastData } from "../formToast";
import { putCatchReport } from "../../api/putCatchReport";
import { putCatchReportSubmit } from "../../api/putCatchReportSubmit";

export default function SubmitEditedCatchReportModal({
  refId,
  handleLoadSubmittedCatchReport,
}: any) {
  useImperativeHandle(refId, () => ({
    handleShow,
  }));

  const incompleteTripsMessage =
    "You have incomplete trips in this reporting period. Please complete or delete them to submit a catch report.";
  const [catchReport, setCatchReport] = useState<CatchReport>();
  const [submittedCatchReportID, setSubmittedCatchReportID] = useState(0);
  const [noFish, setNoFish] = useState(false);
  const [show, setShow] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [dailyTrips, setDailyTrips] = useState<DailyTrip[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [hasIncompleteTrips, setHasIncompleteTrips] = useState(false);
  const [changeLog, setChangeLog] = useState("");

  const [toastData, setToastData] = useState<ToastData>({
    show: false,
    title: "",
    body: "",
    bg: "",
  });

  // Reset values when modal closes
  useEffect(() => {
    if (!show) {
      setDailyTrips([]);
      setNoFish(false);
      setSubmitError("");
      setChangeLog("");
      setHasIncompleteTrips(false);
      setSubmitted(false);
    }
  }, [show]);

  const handleShow = (catchReport: CatchReport) => {
    if (catchReport.tripIDs.length === 0) {
      setNoFish(true);
    }
    setCatchReport(catchReport);
    setShow(true);
  };

  const handleClose = () => {
    if (submitted && submittedCatchReportID) {
      // Load new catch report
      handleLoadSubmittedCatchReport();
    }

    setShow(false);
  };

  const downloadPDF = async (catchReportID: number) => {
    setIsDownloading(true);
    await getCatchReportExportPDF(catchReportID);
    setIsDownloading(false);
    setShow(false);
    handleLoadSubmittedCatchReport();
  };

  const submitCatchReport = async () => {
    setSubmitError("");

    //Todo: Check if we can submit incomplete trips
    if (!changeLog) {
      setSubmitError("Please enter a summary of changes made");
      return;
    }

    // Save Catch Report

    if (catchReport) {
      if (
        catchReport.trips?.some((trip: DailyTrip) => trip.complete === false)
      ) {
        setSubmitError(
          "You have incomplete trips in this reporting period. Please complete or delete them to submit a catch report."
        );
        return;
      }

      const submitData = {
        id: catchReport.id,
        changeSummary: changeLog,
      };

      console.log("SAVING CATCH REPORT:", catchReport);
      const resSave = await putCatchReport(catchReport);
      if (apiSuccess(resSave.status)) {
        // Submit Catch Report

        const resSubmit = await putCatchReportSubmit(submitData);
        if (apiSuccess(resSubmit.status)) {
          setSubmittedCatchReportID(submitData.id);
          setSubmitted(true);
        } else {
          setSubmitError("Error, unable to submit Catch Report");
        }
      } else {
        setSubmitError("Error, unable to save Catch Report");
      }
    } else {
      setSubmitError("Catch Report undefined.");
    }
  };

  return (
    <>
      <FormToast toastData={toastData} setData={setToastData} />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        dialogClassName="submit-catch-report-modal"
        keyboard={false}
      >
        <Modal.Header
          closeButton
          className="bg-primary text-light align-items-start px-3 px-lg-5"
        >
          <Modal.Title className="w-100 d-flex justify-content-between align-items-end flex-wrap h6">
            <div className="mb-lg-2">
              <div className="h1 mb-0">Submit Revised Catch Report</div>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="px-3 px-lg-5 border-none pb-3 pt-5 bg-background">
          <>
            {submitted ? (
              <div className="text-center">
                <h2 className="mt-5 mb-3">
                  Catch Report Updated Successfully!
                </h2>
                <Row className="justify-content-center">
                  <Col lg={3}>
                    <FaCheckCircle className="text-success mb-5 w-50 w-lg-100 h-100" />
                  </Col>
                </Row>
                <p>You may download a copy for your records.</p>
              </div>
            ) : (
              <div>
                {/* No Trips */}
                {noFish ? (
                  <>
                    <h1 className="text-center mb-5">
                      Submit a NO FISHING catch report
                    </h1>
                  </>
                ) : (
                  <>
                    {/* Multiple Trips */}
                    {hasIncompleteTrips && (
                      <Alert variant="danger">{incompleteTripsMessage}</Alert>
                    )}
                  </>
                )}

                <h3 className="fw-normal text-center mb-4">
                  Enter a summary of the changes made
                </h3>

                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Changelog Notes
                      <span className="text-danger text-small ms-2">
                        (Required)
                      </span>
                    </Form.Label>
                    <Form.Control
                      value={changeLog}
                      onChange={(e) => setChangeLog(e.currentTarget.value)}
                      as="textarea"
                      rows={3}
                    />
                  </Form.Group>
                </Form>

                {submitError.length > 0 && (
                  <Alert className="mt-4" variant="danger">
                    {submitError}
                  </Alert>
                )}
              </div>
            )}
          </>
        </Modal.Body>
        <Modal.Footer className="w-100 d-block bg-background">
          {submitted ? (
            <Row className="flex-column align-items-center justify-content-center">
              <Col lg={6}>
                <Link to="reports/print">
                  <Button
                    variant="outline-primary"
                    className="w-100 mb-3"
                    onClick={() => downloadPDF(submittedCatchReportID)}
                  >
                    {isDownloading && (
                      <Spinner
                        animation="border"
                        variant="light"
                        size="sm"
                        className="ms-2"
                      />
                    )}
                    <FaFileDownload className="me-2" />
                    Download Report & Close
                  </Button>
                </Link>
              </Col>
              <Col lg={6}>
                <Button
                  variant="primary"
                  className="w-100 mb-5"
                  onClick={handleClose}
                >
                  Close
                </Button>
              </Col>
            </Row>
          ) : (
            <Row className="d-flex flex-column-reverse flex-md-row">
              <Col className="mb-3" xs={12} md={6}>
                <Button
                  variant="outline-primary"
                  className="w-100"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Col>
              <Col className="mb-3" xs={12} md={6}>
                <Button
                  variant="success"
                  className="w-100"
                  onClick={submitCatchReport}
                >
                  Submit Changes
                </Button>
              </Col>
            </Row>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
