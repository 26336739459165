import * as L from "leaflet";
import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import { lakesGrid } from "../../assets/geojson/CORA-Grids-With-IDs";
import Col from "react-bootstrap/esm/Col";
import InputGroup from "react-bootstrap/InputGroup";
import { getLakeFromGrid } from "../../helpers/getLakeFromGrid";
import { Grid } from "../../models/grid";
import { MdSecurityUpdateWarning } from "react-icons/md";

export default function SelectGrid({
  formData,
  initialValues,
  lakesList,
  setMultipleLakesError,
  setGridsLoaded,
}: any) {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState("");
  const [manualGrid, setManualGrid] = useState("");
  const [lakeSelect, setLakeSelect] = useState(initialValues.lakeName);
  const [lakeID, setLakeID] = useState<any>(initialValues.lakeID);
  const [selectedGridFeatures, setSelectedGridFeatures] = useState<String[]>(
    initialValues.grids.map((grid: Grid) => grid.gridKey)
  );

  // master list of grid IDs
  const [masterGridIdList, setMasterGridIdList] = useState<String[]>([]);

  // public map feature
  const map: any = useRef();
  const masterGeoJson: any = useRef();

  // map fill colors
  const selectedStyle = {
    weight: 2,
    opacity: 1,
    color: "white",
    dashArray: "3",
    fillOpacity: 0.5,
    fillColor: "#074a81",
  };
  const defaultStyle = {
    weight: 2,
    opacity: 1,
    color: "white",
    dashArray: "3",
    fillOpacity: 0.3,
    fillColor: "gray",
  };

  // Required zoom level for user to see tooltips/labels
  const tooltipThreshold = 9;
  const [lastZoom, setLastZoom] = useState(tooltipThreshold - 1);
  const [mapZoom, setMapZoom] = useState(lastZoom);

  useEffect(() => {
    if (!loaded) {
      // Initialize the map
      map.current = L.map("map", { scrollWheelZoom: false }).setView(
        [45.834227, -84.7633114],
        8
      );

      // Add geojson to the map and make each feature have a clickable event.
      masterGeoJson.current = L.geoJSON(lakesGrid, {
        onEachFeature: onEachFeature,
        style: defaultStyle,
      }).addTo(map.current);
      setLoaded(true);
    }

    // set the base tile layer
    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(map.current);

    // When the map zoom changes we need to check and see if the threshold for the labels is met.
    // If the threshold is met, then we should display the labels.
    map.current.on("zoomend", function () {
      setMapZoom(map.current.getZoom());
    });
  }, []);

  useEffect(() => {
    if (
      mapZoom < tooltipThreshold &&
      (!lastZoom || lastZoom >= tooltipThreshold)
    ) {
      map.current.eachLayer(function (l: any) {
        if (l.getTooltip()) {
          var tooltip = l.getTooltip();
          l.unbindTooltip().bindTooltip(tooltip, {
            permanent: false,
          });
        }
      });
    } else if (
      mapZoom >= tooltipThreshold &&
      (!lastZoom || lastZoom < tooltipThreshold)
    ) {
      map.current.eachLayer(function (l: any) {
        if (l.getTooltip()) {
          var tooltip = l.getTooltip();
          l.unbindTooltip().bindTooltip(tooltip, {
            permanent: true,
          });
        }
      });
    }

    setLastZoom(mapZoom);
  }, [mapZoom]);

  // Update lakeID and view when dropdown is changed

  const updateLakeSelection = (lake: string) => {
    map.current.panTo(new L.LatLng(45.834227, -84.7633114));
    switch (lake) {
      case "Superior":
        setLakeSelect("Superior");
        setLakeID(3);
        map.current.panTo(new L.LatLng(47.1974138, -85.9718457));
        break;
      case "Michigan":
        setLakeSelect("Michigan");
        setLakeID(2);
        map.current.panTo(new L.LatLng(44.087585, -87.006083));
        break;
      case "Huron":
        setLakeSelect("Huron");
        setLakeID(1);
        map.current.panTo(new L.LatLng(45.5090614, -83.3131142));
        break;
    }
  };

  // When Selecting a grid, change lake fished to grid
  useEffect(() => {
    //Check if all the grids are in the same lake
    setError("");
    setMultipleLakesError(false);
    if (
      selectedGridFeatures.length > 0 &&
      selectedGridFeatures.every(
        (x) => x.split("_")[0] === selectedGridFeatures[0].split("_")[0]
      )
    ) {
      switch (selectedGridFeatures[0].split("_")[0]) {
        case "ls":
          setLakeSelect("Superior");
          setLakeID(3);
          break;
        case "lm":
          setLakeSelect("Michigan");
          setLakeID(2);
          break;
        case "lh":
          setLakeSelect("Huron");
          setLakeID(1);
          break;
      }
    } else if (selectedGridFeatures.length > 0) {
      setError("Warning: You have grids selected in different lakes");
      setMultipleLakesError(true);
    }
  }, [selectedGridFeatures]);

  // Update form data to parent component on grid or lake change
  useEffect(() => {
    const grids: any = [];

    selectedGridFeatures.map((grid) => {
      grids.push({
        gridKey: grid,
        gridNumber: parseInt(grid.split("_")[1]),
        lakeName: getLakeFromGrid(`${grid}`),
      });
    });

    formData({
      grids: grids,
      lakeID: lakeID,
      lakeName: lakesList.find((x: any) => x.id === lakeID).name,
    });
  }, [lakeSelect, selectedGridFeatures]);

  // function to be bound to each feature of geojson layer.
  function onEachFeature(feature: any, layer: any) {
    // An ID is needed for click events to reference the layer when manipulating the list manually
    layer.feature_id = feature.properties.GridKey;
    // Add feature ID to master list
    masterGridIdList.push(feature.properties.GridKey);
    setMasterGridIdList([...masterGridIdList]);

    // Used to set style when navigating back to map from a different component.
    if (selectedGridFeatures.includes(feature.properties.GridKey)) {
      layer.setStyle(selectedStyle);
    }

    // This allows for a permanent grid label/id
    // Note: String is required for the label.
    if (map.current.getZoom() >= tooltipThreshold) {
      layer.bindTooltip(String(layer.feature.properties.GridNumber), {
        permanent: true,
        opacity: 1,
        className: "grid-label",
        offset: [0, 0],
      });
    } else {
      layer.bindTooltip(String(layer.feature.properties.GridNumber), {
        permanent: false,
        opacity: 1,
        className: "grid-label",
        offset: [0, 0],
      });
    }

    // bind the click
    layer.on({
      click: function (e: any) {
        // determine fill color of feature.
        var currentStyle = selectedGridFeatures.includes(
          feature.properties.GridKey
        )
          ? defaultStyle
          : selectedStyle;
        layer.setStyle(currentStyle);
        // Add or remove selected feature from list.
        addOrRemoveFeatureToList(feature.properties);
      },
    });
  }

  const getLakePrefix = () => {
    switch (lakeSelect) {
      case "Superior":
        return "ls";
      case "Michigan":
        return "lm";
      case "Huron":
        return "lh";
    }
  };

  // Function for adding or removing grid IDs to/from list.
  const addOrRemoveFeatureToList = (feature?: any) => {
    // determine index of grid number if it exists
    const index = selectedGridFeatures.indexOf(feature.GridKey);

    if (index > -1) {
      // item needs to be removed
      selectedGridFeatures.splice(index, 1);
    } else {
      // new item needs to be added
      selectedGridFeatures.push(feature.GridKey);
    }

    // update value on front-end
    setError("");
    setSelectedGridFeatures([...selectedGridFeatures]);
  };

  // This function is used to add/remove entries from the feature list manually
  function addValueToFeatureList(gridID: string) {
    setManualGrid("");
    if (gridID.split("_").length < 2 && gridID) {
      gridID = `${getLakePrefix()}_${
        gridID.split("_")[1] ?? gridID.split("_")[0]
      }`;
    }

    // Get value from manual grid entry field
    const index = masterGridIdList.findIndex((el) => el === gridID);

    // validate the entry against the master ID list.
    if (index > -1) {
      // find the layer that matches a feature tag id and initiate a click event
      masterGeoJson.current.eachLayer(function (layer: any) {
        if (layer.feature_id === masterGridIdList[index]) {
          layer.fireEvent("click");
          return;
        }
      });
    } else {
      if (!gridID && selectedGridFeatures.length > 0) {
        setError(
          `Invalid grid for lake selected. Click Continue below the map if you are done selecting your Grids`
        );
      } else {
        setError(`Invalid grid for lake selected`);
      }
    }
  };

  // On render, tells parent component that grids have been edited in some way
  useEffect(() => {
    setGridsLoaded(true)
  }, [])

  return (
    <div>
      <Row className="mb-3">
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Lake fished</Form.Label>
            <Form.Select
              aria-label="Lake Selection"
              onChange={(e) => updateLakeSelection(e.target.value)}
              value={lakeSelect}
            >
              <option value="Michigan">Lake Michigan</option>
              <option value="Superior">Lake Superior</option>
              <option value="Huron">Lake Huron</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Label>Grids fished</Form.Label>
          <InputGroup className="flex-nowrap">
            <Form.Control
              type="number"
              id="manualGridFeature"
              className="border-end-0"
              value={manualGrid}
              onKeyUp={(e) => {
                if (e.key === "Enter") addValueToFeatureList(manualGrid);
              }}
              onChange={(e) => setManualGrid(e.target.value)}
            />
            <div className="sibling-input-border d-flex flex-wrap px-1 border-2 border-top border-bottom align-items-center">
              {Array.isArray(selectedGridFeatures) &&
                selectedGridFeatures.map((grid: any, i) => (
                  <Badge
                    key={i}
                    bg="secondary"
                    className="p-2 me-1 cursor-pointer"
                    onClick={() => addValueToFeatureList(grid)}
                  >
                    {grid.split("_")[1]}
                  </Badge>
                ))}
            </div>
            <Button
              variant="primary"
              onClick={() => addValueToFeatureList(manualGrid)}
            >
              Select
            </Button>
          </InputGroup>
          {error.length > 0 && <small className="text-danger">{error}</small>}
        </Col>
      </Row>
      <div id="map" className="rounded"></div>
    </div>
  );
}
