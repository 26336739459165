import axios from "axios";
import { convertObjectToUrlParams } from "../helpers/convertObjToUrlParams";

export const listTripsUserRange = async (range: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Trip/user-range${convertObjectToUrlParams(
        range
      )}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};
