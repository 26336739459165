import axios from "axios";

export const getTrip = async (tripID: number) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Trip/trip?TripID=${tripID}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
