import { Outlet } from "react-router-dom";
import FisherOnboarding from "../../pages/fisher/onboarding/fisherOnboarding";

export default function FisherLayout() {
  return (
    <>
      <div className="h-100 py-5">
        {localStorage.getItem("initialFlow") === "true" && <FisherOnboarding />}
        <Outlet />
      </div>
    </>
  );
}
