import axios from "axios";
import {
  getTribalOrganization,
  getOrganizationID,
  getRoles,
} from "../helpers/authHelper";

export const lookupAllTribes = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Lookup/organizations?IsTribe=true`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
