import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import EditVessels from "../../../components/formControls/editVessels";
import ToolTip from "../../../components/tooltip";
import EditPersonalInformation, {
  PersonalInformationValues,
} from "../../../components/formControls/editPersonalInformation";
import EditContact, {
  ContactValues,
} from "../../../components/formControls/editContact";
import { getProfile } from "../../../api/getProfile";
import { updateProfile } from "../../../api/updateProfile";
import { User } from "../../../models/user";
import { getUserID } from "../../../helpers/authHelper";

export default function FisherOnboardingStepTwo({
  setOnboardingStep,
}: {
  setOnboardingStep: Dispatch<SetStateAction<number>>;
}) {
  interface FormData {
    values: any;
    validated: boolean;
    errors: any;
  }

  useEffect(() => {
    const fetchUserInfo = async () => {
      const response = await getProfile();

      if (response)
        setPersonalInformationValues({
          firstName: response.firstName ?? "",
          middleName: response.middleName ?? "",
          lastName: response.lastName ?? "",
          address1: response.address1 ?? "",
          city: response.city ?? "",
          stateCode: response.stateCode ?? "",
          zip: response.zip ?? "",
        });

      if (response)
        setContactValues({
          email: response.email ?? "",
          phone: response.phone ?? "",
        });
    };

    fetchUserInfo();
  }, []);

  const [personalInformationValues, setPersonalInformationValues] =
    useState<PersonalInformationValues>({
      firstName: "",
      middleName: "",
      lastName: "",
      address1: "",
      city: "",
      stateCode: "",
      zip: "",
    });

  const [contactValues, setContactValues] = useState<ContactValues>({
    email: "",
    phone: "",
  });

  // Set all forms in single object
  const [formData, setFormData] = React.useState({
    editPersonalInformation: {
      values: personalInformationValues,
      validated: false,
    },
    editContact: { values: contactValues, validated: false, errors: {} },
  });

  // Create Refs for each form
  const editPersonalInformationRef = React.useRef<HTMLFormElement>(null);
  const editContactRef = React.useRef<HTMLFormElement>(null);

  // Handle form data when updated
  const handleChangeEditPersonalInformation = (data: FormData) => {
    formData.editPersonalInformation = { ...data };
    setFormData(formData);
  };

  const handleChangeEditContact = (data: FormData) => {
    formData.editContact = { ...data };
    setFormData(formData);
  };

  // Submit all forms
  async function handleSubmit() {
    try {
      await editPersonalInformationRef.current?.Submit();
      await editContactRef.current?.Submit();
    } catch (error) {
      console.error(error);
    }

    if (
      formData.editPersonalInformation.validated &&
      formData.editContact.validated
    ) {
      const data: User = {
        userID: getUserID(),
        ...formData.editPersonalInformation.values,
        ...formData.editContact.values,
      };
      // removeEmptyValues(data);
      updateProfile(data);
      setOnboardingStep(3);
    }
  }

  return (
    <>
      <Modal.Body className="py-5 px-2 px-sm-4 px-md-5 bg-background rounded">
        {/* Header */}
        <div className="onboarding-header">
          <h1 className="text-center text-primary mb-2">
            Complete your profile
          </h1>
          <p className="lead text-bold text-center mb-5">
            Please fill in any additional information to your profile
          </p>
        </div>

        {/* Body */}
        <div className="onboarding-body">
          {/* Personal Information */}
          <h2 className="h4">Personal Information</h2>
          <EditPersonalInformation
            formData={handleChangeEditPersonalInformation}
            refId={editPersonalInformationRef}
            initialValues={personalInformationValues}
          />

          {/* Contact */}
          <h2 className="h4">Contact Info</h2>
          <EditContact
            formData={handleChangeEditContact}
            refId={editContactRef}
            initialValues={contactValues}
          />

          <h2 className="h4">
            Add Vessels &amp; Vehicles
            <ToolTip
              content={
                "Vessels are what you fished with during your outing. This could be a boat or a snow mobile."
              }
            />
          </h2>
          <EditVessels />

          {/* Buttons */}
          <Row>
            <Col className="mb-3">
              <Button
                variant="success"
                size="lg"
                className="w-100 h-100"
                type="submit"
                form="personal-info-form"
                onClick={handleSubmit}
              >
                Looks Good
              </Button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </>
  );
}
