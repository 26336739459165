import React from "react";
import { useFormik, Formik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SelectState from "./selectState";
import { zipReg } from "../../helpers/zipReg";

export interface PersonalInformationValues {
  firstName: string;
  middleName: string;
  lastName: string;
  address1: string;
  city: string;
  stateCode: string;
  zip: string;
}

export default function EditPersonalInformation({
  formData,
  refId,
  initialValues,
}: any) {
  // Set init values against the interface
  const values: PersonalInformationValues = { ...initialValues };
  // Init Formik
  const formik = useFormik({
    initialValues: {
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      address1: values.address1,
      city: values.city,
      stateCode: values.stateCode,
      zip: values.zip,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First Name is required"),
      middleName: Yup.string().nullable(),
      lastName: Yup.string().required("Last Name is required"),
      address1: Yup.string().nullable(),
      city: Yup.string().nullable(),
      stateCode: Yup.string().nullable(),
      zip: Yup.string().matches(zipReg, "Must be a valid zip code").nullable(),
    }),
    onSubmit: () => {
      formData({
        values: formik.values,
        validated: formik.isSubmitting
          ? Object.keys(formik.errors).length === 0
          : false,
      });
    },
  });

  //Update values on change
  React.useEffect(() => {
    formData({
      values: formik.values,
      validated:
        formik.dirty && Object.keys(formik.errors).length === 0 ? true : false,
    });
  }, [formik.values, formik.errors]);

  // Submit form from parent component
  React.useImperativeHandle(refId, () => ({
    Submit: async () => {
      await formik.submitForm();
    },
  }));

  return (
    <div className="form-card">
      <Form
        id="personal-info-form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        ref={refId}
      >
        <Row>
          {/* First Name */}
          <Form.Group as={Col} md className="mb-3" controlId="formFirstName">
            <Form.Label>
              First Name
              <span className="text-danger text-small ms-2">(Required)</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.firstName && !!formik.errors.firstName}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.firstName}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Middle Name */}
          <Form.Group as={Col} md className="mb-3" controlId="formMiddleName">
            <Form.Label>Middle Name</Form.Label>
            <Form.Control
              type="text"
              name="middleName"
              value={formik.values.middleName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched.middleName && !!formik.errors.middleName
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.middleName}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Last Name */}
          <Form.Group as={Col} md className="mb-3" controlId="formLastName">
            <Form.Label>
              Last Name
              <span className="text-danger text-small ms-2">(Required)</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.lastName && !!formik.errors.lastName}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.lastName}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row>
          {/* Address */}
          <Form.Group as={Col} lg className="mb-3" controlId="formAddress">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="address1"
              value={formik.values.address1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.address1 && !!formik.errors.address1}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.address1}
            </Form.Control.Feedback>
          </Form.Group>

          {/* City */}
          <Form.Group as={Col} lg className="mb-3" controlId="formCity">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.city && !!formik.errors.city}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.city}
            </Form.Control.Feedback>
          </Form.Group>

          {/* State */}
          <Form.Group as={Col} sm className="mb-3" controlId="formState">
            <Form.Label>State</Form.Label>
            <SelectState
              name="stateCode"
              value={formik.values.stateCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.stateCode && formik.errors.stateCode}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.stateCode}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Zip */}
          <Form.Group as={Col} sm className="mb-3" controlId="formZip">
            <Form.Label>Zip</Form.Label>
            <Form.Control
              type="text"
              name="zip"
              value={formik.values.zip}
              onChange={formik.handleChange}
              // onChange={(e) => {
              //   formik.values.zip = e.target.value.replace(/[^\d]/, "");
              //   formik.handleChange(e);
              // }}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.zip && !!formik.errors.zip}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.zip}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        {/* <Button type="submit">Submit</Button> */}
      </Form>
    </div>
  );
}
