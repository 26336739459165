import axios from "axios";
import { CatchReport } from "../models/catchReport";

export const postCatchReportSubmit = async (catchReport: CatchReport) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/CatchReport/submit`,
      catchReport
    );
    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
