import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

import EditUsernamePassword from "../../components/formControls/editUsernamePassword";

export default function AdminAgencyProfile() {
  return (
    <div className="py-5">
      <Container>
        <h1 className="text-primary mb-5">Edit Profile</h1>

        {/* Change Password */}
        <Row>
          <Col lg={3}>
            <h2 className="form-header">Username / Password</h2>
          </Col>
          <Col>
            <EditUsernamePassword />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
