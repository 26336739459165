import { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/esm/Container";
import SelectOrganization from "../../components/formControls/selectOrganization";
import {
  getOrganizationID,
  getTribalOrganization,
} from "../../helpers/authHelper";
import { createHelper } from "../../api/createHelper";
import FormToast, { ToastData } from "../../components/formToast";
import { useNavigate } from "react-router-dom";
import { apiSuccess } from "../../helpers/apiSuccess";
import InputGroup from "react-bootstrap/esm/InputGroup";
import { lookupOrganizations } from "../../api/lookupOrganizations";
import { Organization } from "../../models/organization";

export default function RegisterHelper() {
  const navigate = useNavigate();
  const [tribes, setTribes] = useState<Organization[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<Organization>();
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchTribes = async () => {
      const response = await lookupOrganizations();
      if (response) {
        setTribes(response);

        const orgID = getOrganizationID() === 1 ? 2 : getOrganizationID();
        setSelectedOrg(
          response.find((tribe: Organization) => tribe.id === orgID)
        );
      }
    };
    fetchTribes();
  }, []);

  const [toastData, setToastData] = useState<ToastData>({
    show: false,
    title: "",
    body: "",
    bg: "",
  });

  const helperSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    middleName: Yup.string(),
    lastName: Yup.string().required("Last Name is required"),
    licenseNumber: Yup.number()
      .max(99999, "License cannot be greater than 5")
      .min(0, "License cannot be less than 1")
      .required("License Number is required"),
  });

  return (
    <>
      <div className="py-5">
        <Container>
          <h1 className="text-primary mb-5">Register New Helper</h1>

          <Row>
            <Col lg={3}>
              <h2 className="form-header">Helper Details</h2>
            </Col>
            <Col>
              <div className="form-card">
                <FormToast toastData={toastData} setData={setToastData} />
                <Formik
                  initialValues={{
                    firstName: "",
                    middleName: "",
                    lastName: "",
                    licenseNumber: "",
                    organizationID:
                      getOrganizationID() === 1 ? 2 : getOrganizationID(),
                  }}
                  enableReinitialize
                  validationSchema={helperSchema}
                  onSubmit={async (values) => {
                    setError("");
                    //Format license with prefix and suffix

                    if (selectedOrg?.helperLicensePattern) {
                      const formattedLicense = `${selectedOrg?.code}-${values.licenseNumber}H`;
                      const pattern = selectedOrg?.helperLicensePattern
                        .split("/")
                        .slice(1, -1)
                        .join("/");
                      const regex = new RegExp(pattern);

                      // Check the formatted license against the org regex
                      if (regex.test(formattedLicense)) {
                        const response = await createHelper({
                          ...values,
                          licenseNumber: formattedLicense,
                        });

                        if (apiSuccess(response.status)) {
                          setToastData({
                            show: true,
                            bg: "Success",
                            title: "Success",
                            body: "Saved Successfully",
                          });
                          setTimeout(() => navigate(-1), 2000);
                        }
                      } else {
                        setError(
                          "Error: License Number did not meet the correct format. Please try again or contact your administrator for support."
                        );
                      }
                    } else {
                      setError(
                        "Error: This Organization does not have a standardized license number format. Please try again or contact your administrator for support."
                      );
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Form
                      noValidate
                      onSubmit={handleSubmit}
                      className="mb-4"
                      autoComplete="off"
                    >
                      <Row>
                        <Form.Group
                          as={Col}
                          lg
                          className="mb-3"
                          controlId="formHelperFirstName"
                        >
                          <Form.Label>
                            First Name
                            <span className="text-danger text-small ms-2">
                              (Required)
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.firstName && !!errors.firstName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.firstName}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          lg
                          className="mb-3"
                          controlId="formHelperMiddleName"
                        >
                          <Form.Label>Middle Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="middleName"
                            value={values.middleName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.middleName && !!errors.middleName
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.middleName}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          lg
                          className="mb-3"
                          controlId="formHelperLastName"
                        >
                          <Form.Label>
                            Last Name
                            <span className="text-danger text-small ms-2">
                              (Required)
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.lastName && !!errors.lastName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.lastName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group
                          as={Col}
                          className="mb-3"
                          controlId="formTribe"
                        >
                          <SelectOrganization
                            name="organizationID"
                            includeCORA={false}
                            value={values.organizationID}
                            onChange={(e: any) => {
                              handleChange(e);
                              if (tribes)
                                setSelectedOrg(
                                  tribes.find(
                                    (tribe) =>
                                      tribe.id === parseInt(e.target.value)
                                  )
                                );
                            }}
                            onBlur={handleBlur}
                            enabled={!getTribalOrganization()}
                            isInvalid={
                              touched.organizationID && !!errors.organizationID
                            }
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          lg
                          className="mb-3"
                          controlId="formHelperLicense"
                        >
                          <Form.Label>License Number*</Form.Label>
                          <InputGroup>
                            <InputGroup.Text id="org-code">
                              {selectedOrg ? selectedOrg.code : ""}-
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              name="licenseNumber"
                              value={values.licenseNumber}
                              onChange={(e) => {
                                if (
                                  (e.target.value === "" ||
                                    e.target.value.match(/^\d+$/)) &&
                                  e.target.value.length < 6
                                ) {
                                  handleChange(e);
                                }
                              }}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.licenseNumber && !!errors.licenseNumber
                              }
                            />
                            <InputGroup.Text id="h">H</InputGroup.Text>
                          </InputGroup>
                          <em className=" ">
                            Ex: {selectedOrg?.helperLicenseExample}
                          </em>

                          <Form.Control.Feedback type="invalid">
                            {errors.licenseNumber}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Col lg>
                          <Button
                            className="w-100"
                            variant="success"
                            type="submit"
                          >
                            Add Helper
                          </Button>
                        </Col>
                      </Row>
                      {error !== "" && (
                        <p className="text-danger text-center mt-3">{error}</p>
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
