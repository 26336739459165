import { MeshSize } from "../models/meshSize";

export const getGearMeshSizes = (meshSizes: any, gearID: number) => {
  const ms = meshSizes
    .filter((x: MeshSize) => x.gearID === gearID)
    .sort((a: MeshSize, b: MeshSize) => b.sortOrder - a.sortOrder);
  ms.unshift({ id: "", name: "" });

  return ms;
};
