import axios from "axios";
import { CatchReport } from "../models/catchReport";

export const putCatchReport = async (report: CatchReport) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/CatchReport/report`,
      report
    );
    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
