import { RankingInfo } from "@tanstack/match-sorter-utils";
import { ColumnDef } from "@tanstack/react-table";
import { useEffect, useImperativeHandle, useMemo, useState } from "react";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/esm/Button";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Table from "react-bootstrap/esm/Table";
import Tooltip from "react-bootstrap/esm/Tooltip";
import { Link } from "react-router-dom";
import { DailyTrip } from "../../models/dailyTrip";
import DebouncedInput from "./debouncedInput";
import { fuzzyFilter } from "./fuzzyFilter";
import { fuzzySort } from "./fuzzySort";
import Filter from "./tableHeaderFilter";

import {
  FaEdit,
  FaExternalLinkAlt,
  FaEye,
  FaFileDownload,
  FaGreaterThan,
  FaLessThan,
  FaLock,
  FaLockOpen,
  FaQuestionCircle,
  FaSort,
  FaSortDown,
  FaSortUp,
  FaTrash,
  FaTrashAlt,
} from "react-icons/fa";

import {
  ColumnFiltersState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { deleteCatchReport } from "../../api/deleteCatchReport";
import { getCatchReport } from "../../api/getCatchReport";
import { getCatchReportExportPDF } from "../../api/getCatchReportExportPDF";
import { listTrips } from "../../api/listTrips";
import { apiSuccess } from "../../helpers/apiSuccess";
import { CatchReport } from "../../models/catchReport";
import { getCatchReportPreviewPDF } from "../../api/getCatchReportPreviewPDF";
import { listUserTrips } from "../../api/listUserTrips";
import { putCatchReportReview } from "../../api/putCatchReportReview";
import { putCatchReportUnreview } from "../../api/putCatchReportUnreview";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }

  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

export default function CatchReportsTable({
  reviewDailyTripRef,
  requestChangeModalRef,
  refID,
  period,
  active,
  isAdmin = false,
  userID,
}: any) {
  useImperativeHandle(refID, () => ({
    Refresh: async () => {
      fetchData();
    },
  }));

  const [columnVisibility, setColumnVisibility] = useState({});
  const columns = useMemo<ColumnDef<DailyTrip, any>[]>(
    () => [
      {
        accessorFn: (row) => row.fishingDate,
        id: "fishingDate",
        header: "Date",
        cell: (info) => {
          return (
            <strong>
              {new Date(info.getValue()).toLocaleDateString("en-US")}
            </strong>
          );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
        enableColumnFilter: false,
        enableHiding: false,
      },
      {
        accessorFn: (row) => row.lakeName,
        id: "lakeName",
        header: "Lake",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.grids,
        id: "gridNumber",
        header: "Grids",
        cell: (info) => {
          const last = info.getValue().length - 1;
          return info
            .getValue()
            .map(
              (g: any, i: number) => `${g.gridNumber}${i !== last ? ", " : ""}`
            );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.vesselName,
        id: "vessel",
        header: "Vessel",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },

      {
        accessorFn: (row) => row.effort,
        id: "gear",
        header: "Gear",
        cell: (info) => {
          const unique = info
            .getValue()
            .map((item: any) => item.gearName)
            .filter(
              (value: any, index: any, self: any) =>
                self.indexOf(value) === index
            );

          const last = unique.length - 1;
          return unique.map(
            (g: any, i: number) => `${g}${i !== last ? ", " : ""}`
          );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.harvest,
        id: "species",
        header: "Species",
        cell: (info) => {
          const unique = info
            .getValue()
            .map((item: any) => item.speciesName)
            .filter(
              (value: any, index: any, self: any) =>
                self.indexOf(value) === index
            );

          const last = unique.length - 1;
          return unique.map(
            (s: any, i: number) => `${s}${i !== last ? ", " : ""}`
          );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.launchSite,
        id: "launchSite",
        header: "Launch Site",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.comments,
        id: "comments",
        header: "Comments",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },

      // Actions
      {
        accessorFn: (row: any) => {
          return { tripID: row.id };
        },
        id: "actions",
        header: () => {
          return <div className="mx-auto">Actions</div>;
        },
        cell: (info: any) => {
          return (
            <div className="users-table d-flex justify-content-center">
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip>Review Trip</Tooltip>}
              >
                <Button
                  variant="secondary"
                  className="text-light ms-2"
                  size="sm"
                  onClick={() =>
                    reviewDailyTripRef.current?.loadDailyTrip(
                      info.getValue().tripID
                    )
                  }
                >
                  <FaEye />
                </Button>
              </OverlayTrigger>
            </div>
          );
        },
        footer: (props: any) => props.column.id,
      },
    ],
    []
  );

  // Set data and filter states
  const [data, setData] = useState([]);
  const [catchReport, setCatchReport] = useState<CatchReport>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [showColumnFilter, setShowColumnFilter] = useState(false);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);

  // Get and update data on load
  useEffect(() => {
    if (active && !data.length) {
      fetchData();
    }
  }, [active]);

  const fetchData = async () => {
    if (isAdmin && userID) {
      const resDailyTrips = await listUserTrips({
        catchReportID: period.id,
        tripTypeID: period.tripTypeID,
        complete: undefined,
      });

      const resCatchReport = await getCatchReport(period.id);

      if (resDailyTrips) setData(resDailyTrips);
      if (resCatchReport) setCatchReport(resCatchReport);
    } else {
      const resListTrips = await listTrips({ catchReportID: period.id });
      const resCatchReport = await getCatchReport(period.id);

      if (resListTrips) setData(resListTrips);
      if (resCatchReport) setCatchReport(resCatchReport);
    }

    setIsLoaded(true);
  };

  const downloadPDF = async () => {
    setIsDownloading(true);
    await getCatchReportExportPDF(period.id);
    setIsDownloading(false);
  };

  const previewPDF = async () => {
    setIsPreviewing(true);
    await getCatchReportPreviewPDF(period.id);
    setIsPreviewing(false);
  };

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
      columnVisibility,
    },
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: setColumnVisibility,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  });

  return (
    <div>
      {/* Table Header Controls */}
      <div className="table-header d-flex flex-wrap">
        {isAdmin && (
          <Link
            to={`/reports/edit/${period.id}`}
            // state={{ catchReport: period }}
          >
            <Button variant="warning" size="sm" className="me-2 mb-3 d-inline">
              <FaEdit className="me-2" />
              Edit Catch Report
            </Button>
          </Link>
        )}

        {/* Delete Catch Report for Admin */}
        {isAdmin && catchReport && (
          <Button
            variant="danger"
            size="sm"
            className="me-2 mb-3 d-inline"
            onClick={async () => {
              if (
                window.confirm(
                  "Are you sure you want to delete this Catch Report? You will need to enter the crew and re-submit this catch report again. These trips will not be deleted."
                )
              ) {
                const res = await deleteCatchReport(period.id);
                if (apiSuccess(res.status)) window.location.reload();
              }
            }}
          >
            <FaTrashAlt className="me-2" />
            Delete Catch Report
          </Button>
        )}

        {isAdmin && catchReport && !catchReport?.reviewed && (
          <Button
            variant="secondary"
            size="sm"
            className="me-2 mb-3 d-inline"
            onClick={async () => {
              const res = await putCatchReportReview(catchReport.id);
              if (apiSuccess(res.status)) {
                setCatchReport({ ...catchReport, reviewed: true });
              }
            }}
          >
            <FaLock className="me-2 mt-n1" />
            Lock Catch Report
          </Button>
        )}

        {isAdmin && catchReport && catchReport?.reviewed && (
          <Button
            variant="secondary"
            size="sm"
            className="me-2 mb-3 d-inline"
            onClick={async () => {
              const res = await putCatchReportUnreview(catchReport.id);
              if (apiSuccess(res.status)) {
                setCatchReport({ ...catchReport, reviewed: false });
              }
            }}
          >
            <FaLockOpen className="me-2 mt-n1" />
            Unlock Catch Report
          </Button>
        )}

        <Button
          variant="secondary"
          size="sm"
          className="me-2 mb-3 d-inline"
          onClick={downloadPDF}
        >
          <FaFileDownload className="me-2" />
          Download PDF{" "}
          {isDownloading && (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              className="ms-2"
            />
          )}
        </Button>

        <Button
          variant="secondary"
          size="sm"
          className="me-2 mb-3 d-none d-lg-inline"
          onClick={previewPDF}
        >
          <FaExternalLinkAlt className="me-2" />
          Preview Report
          {isPreviewing && (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              className="ms-2"
            />
          )}
        </Button>

        {!isAdmin && (
          <Button
            variant="secondary"
            size="sm"
            className="me-2 mb-3 d-inline"
            onClick={() => requestChangeModalRef.current?.requestChange(period)}
          >
            <FaQuestionCircle className="me-2" />
            Request a Change
          </Button>
        )}
        {/* Recall Catch Report for Fishers */}
        {!catchReport?.reviewed && !isAdmin && catchReport && (
          <Button
            variant="warning"
            size="sm"
            className="me-2 mb-3 d-inline"
            onClick={async () => {
              if (
                window.confirm(
                  "Are you sure you want to recall this Catch Report? You will need to enter your crew and re-submit this catch report again. Your trips will not be deleted."
                )
              ) {
                const res = await deleteCatchReport(period.id);
                if (apiSuccess(res.status)) window.location.reload();
              }
            }}
          >
            Recall Catch Report
          </Button>
        )}

        <DebouncedInput
          value={globalFilter ?? ""}
          onChange={(value) => setGlobalFilter(String(value))}
          className="form-control-sm border d-inline-block  w-auto mb-3"
          placeholder="Search everything..."
        />
      </div>

      {/* Table */}
      <Table striped hover responsive className="trips-table daily-trips-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ verticalAlign: "top" }}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer d-flex align-items-center"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <FaSortUp className="text-primary ms-1" />,
                            desc: <FaSortDown className="text-primary ms-1" />,
                          }[header.column.getIsSorted() as string] ?? (
                            <FaSort className="text-gray ms-1" />
                          )}
                        </div>
                        {header.column.getCanFilter() && showColumnFilter ? (
                          <div>
                            <Filter column={header.column} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        {table.getRowModel().rows.length > 0 ? (
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className="align-middle">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={100}>
                {isLoaded ? (
                  <h2 className="text-gray w-100 text-center">
                    No Daily Trips
                  </h2>
                ) : (
                  <h2 className="text-gray w-100 text-center">Loading...</h2>
                )}
              </td>
            </tr>
          </tbody>
        )}
      </Table>

      {/* Table footer */}
      <div className="d-flex flex-row justify-content-end align-items-center mt-3">
        <span className="text-nowrap me-3">
          {`${
            table.getState().pagination.pageIndex *
            table.getState().pagination.pageSize
          }-${
            table.getState().pagination.pageIndex *
              table.getState().pagination.pageSize +
            table.getState().pagination.pageSize
          } of ${table.getPrePaginationRowModel().rows.length}`}
        </span>

        <Form.Select
          aria-label="Table rows per page"
          size="sm"
          style={{ maxWidth: "10rem" }}
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Rows per page {pageSize}
            </option>
          ))}
        </Form.Select>

        <Button
          variant="light-gray"
          size="sm"
          className="ms-2 text-primary"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <FaLessThan />
        </Button>
        <Button
          variant="light-gray"
          size="sm"
          className="ms-2"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <FaGreaterThan className="text-primary" />
        </Button>
      </div>
    </div>
  );
}
