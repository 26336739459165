import axios from "axios";
import {
  getTribalOrganization,
  getRoles,
  getOrganizationID,
} from "../helpers/authHelper";

export const lookupOrganizations = async () => {
  try {
    // Check if user is CORA Admin
    if (getRoles().includes("CORA_Admin")) {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/Lookup/organizations`
      );
      return response.data;
    } else {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/Lookup/organizations?isTribe=true`
      );
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};
