import { Dispatch, SetStateAction, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { phoneReg } from "../../../helpers/phoneReg";
import { PatternFormat } from "react-number-format";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FormLabel from "react-bootstrap/esm/FormLabel";
import { forgotUsername } from "../../../api/forgotUsername";

const schema = Yup.object().shape(
  {
    email: Yup.string()
      .email("Must be a valid email address")
      .when("phone", {
        is: (phone: string) => !phone,
        then: Yup.string().required("At least one needs to be filled"),
      }),
    phone: Yup.string()
      .matches(phoneReg, "Phone number is not valid")
      .when("email", {
        is: (email: string) => !email,
        then: Yup.string().required("At least one needs to be filled"),
      }),
  },
  [
    ["email", "phone"],
    ["phone", "email"],
  ]
);

export default function ForgotUsername({
  setActiveComponent,
}: {
  setActiveComponent: Dispatch<SetStateAction<string>>;
}) {
  const [submitted, setSubmitted] = useState(false);

  return (
    <>
      <div className="text-center">
        <h1>Forgot Username</h1>
        <p className="lead">
          Enter your email or phone and we'll send you your username.
        </p>
      </div>

      <Formik
        initialValues={{ email: "", phone: "" }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          values.phone.replace(/\D/g, "");
          setSubmitting(true);
          forgotUsername(values);
          setSubmitted(true);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-4" controlId="formEmail">
              <div className="d-flex justify-content-between">
                <FormLabel className="text-start">Email Address</FormLabel>
                <FormLabel
                  className="text-end link"
                  onClick={() => setActiveComponent("ForgotPassword")}
                >
                  Forgot Password
                </FormLabel>
              </div>
              <Form.Control
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.email && !!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-4" controlId="formPhone">
              <FormLabel className="text-start">Phone Number</FormLabel>
              <PatternFormat
                customInput={Form.Control}
                format="(###) ### ####"
                type="tel"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.phone && !!errors.phone}
              />
              <Form.Control.Feedback type="invalid">
                {errors.phone}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              variant="success"
              type="submit"
              disabled={isSubmitting}
              size="lg"
              className="w-100"
            >
              Get Your Username
            </Button>

            {submitted && (
              <Alert variant="success" className="text-center mt-4">
                If an account exists for that associated email or phone, an
                e-mail or text will be sent with further instructions.
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}
