import { useState, useImperativeHandle } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/esm/Form";
import Row from "react-bootstrap/esm/Row";
import { CatchReportPeriod } from "../../models/catchReportPeriod";
import { getFormattedDateMMDDYYYY } from "../../helpers/getFormattedDateMMDDYYYY";
import {
  ChangeRequest,
  catchReportChangeRequest,
} from "../../api/catchReportChangeRequest";
import { getUserID } from "../../helpers/authHelper";
import FormToast, { ToastData } from "../formToast";
import { apiSuccess } from "../../helpers/apiSuccess";

export default function RequestChangeModal({ refId }: any) {
  useImperativeHandle(refId, () => ({
    requestChange,
  }));

  const [show, setShow] = useState(false);
  const [subject, setSubject] = useState("");
  const [issueSummary, setIssueSummary] = useState("");
  const [catchReport, setCatchReport] = useState<CatchReportPeriod>();
  const [toastData, setToastData] = useState<ToastData>({
    show: false,
    title: "",
    body: "",
    bg: "",
  });

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    resetForm();
  };
  const requestChange = (period: CatchReportPeriod) => {
    setSubject(
      `${getFormattedDateMMDDYYYY(
        period.beginDate
      )} - ${getFormattedDateMMDDYYYY(period.endDate)}`
    );
    setCatchReport(period);
    handleShow();
  };
  const submitIssue = async () => {
    if (catchReport) {
      const data: ChangeRequest = {
        userID: parseInt(getUserID()),
        catchReportID: catchReport.id,
        beginDate: catchReport.beginDate,
        endDate: catchReport.endDate,
        issueSummary: issueSummary,
      };

      const response = await catchReportChangeRequest(data);
      if (apiSuccess(response.status)) {
        setToastData({
          show: true,
          bg: "Success",
          title: "Success",
          body: "Change request sent",
        });

        handleClose();
      }
    }
  };

  const resetForm = () => {
    setSubject("");
    setIssueSummary("");
  };

  return (
    <>
      <FormToast toastData={toastData} setData={setToastData} />
      <Modal show={show} onHide={handleClose} backdrop="static" size="lg">
        <Modal.Header
          closeButton
          className="bg-primary text-light align-items-start px-5"
        >
          <Modal.Title>
            <div className="h1 mb-0">Request a change</div>
            <p className="fw-normal mb-0">{subject}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>
                Please describe what is wrong, and we will correct it.
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={issueSummary}
                onChange={(e) => setIssueSummary(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-block">
          <Row>
            <Col>
              <Button
                variant="outline-primary"
                className="w-100"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                variant="success"
                className="w-100"
                onClick={submitIssue}
                disabled={issueSummary === ""}
              >
                Request Change
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
}
