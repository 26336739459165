import Button from "react-bootstrap/esm/Button";
import {
  FaExternalLinkAlt,
  FaFileDownload,
  FaEdit,
  FaCalendarWeek,
  FaCheckCircle,
  FaTimesCircle,
  FaUserCircle,
  FaSave,
  FaTrash,
  FaLock,
  FaLockOpen,
} from "react-icons/fa";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import TripsTable from "../../components/tables/tripsTable";
import { useEffect, useRef, useState } from "react";
import DailyTripModal from "../../components/dailyTrips/dailyTripModal";
import ReadonlyReviewModal from "../../components/dailyTrips/readonlyReviewModal";
import RequestChangeModal from "../../components/otherModals/requestChangeModal";
import Container from "react-bootstrap/esm/Container";
import { useNavigate, useParams } from "react-router-dom";
import { listUserTrips } from "../../api/listUserTrips";
import { CatchReport } from "../../models/catchReport";
import { getCatchReport } from "../../api/getCatchReport";
import { getFormattedDateMMDDYYYY } from "../../helpers/getFormattedDateMMDDYYYY";
import { useLocation } from "react-router-dom";
import { putCatchReportUnreview } from "../../api/putCatchReportUnreview";
import { apiSuccess } from "../../helpers/apiSuccess";
import { putCatchReportReview } from "../../api/putCatchReportReview";
import { getCatchReportExportPDF } from "../../api/getCatchReportExportPDF";
import { getCatchReportPreviewPDF } from "../../api/getCatchReportPreviewPDF";
import Spinner from "react-bootstrap/Spinner";
import { putCatchReportEdit } from "../../api/putCatchReportEdit";
import { deleteCatchReport } from "../../api/deleteCatchReport";
import { DailyTrip } from "../../models/dailyTrip";
import Badge from "react-bootstrap/esm/Badge";
import Alert from "react-bootstrap/Alert";
import Condition from "yup/lib/Condition";
import { lookupConditions } from "../../api/lookupConditions";
import { lookupGear } from "../../api/lookupGear";
import { lookupLakes } from "../../api/lookupLakes";
import { lookupMeshSizes } from "../../api/lookupMeshSizes";
import { lookupSpecies } from "../../api/lookupSpecies";
import { lookupTripTypes } from "../../api/lookupTripTypes";
import { Gear } from "../../models/gear";
import { MeshSize } from "../../models/meshSize";
import { Species } from "../../models/species";
import EditCatchReportCrew from "../../components/formControls/editCatchReportCrew";
import { Helper } from "../../models/helper";
import Form from "react-bootstrap/esm/Form";
import { putCatchReport } from "../../api/putCatchReport";
import SubmitEditedCatchReportModal from "../../components/submitCatchReport/submitEditedCatchReportModal";
import { listTripsUserRange } from "../../api/listTripsUserRange";

export default function AdminEditCatchReport() {
  const { catchReportID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const loadCatchReport = new CatchReport();
  const catchReportProp = location.state?.catchReport;

  const [catchReport, setCatchReport] = useState<CatchReport>(
    catchReportProp?.id ? catchReportProp : loadCatchReport
    // new CatchReport()
  );
  // We're setting trips, helpers, assistName, and assist relationship separately so we don't trigger a render by changing catchReport
  const [dailyTrips, setDailyTrips] = useState<DailyTrip[]>([]);
  const [excludedTrips, setExcludedTrips] = useState<DailyTrip[]>([]);
  const [helpers, setHelpers] = useState<Helper[]>([]);
  const [assistName, setAssistName] = useState("");
  const [assistRelationship, setAssistRelationship] = useState("");

  // UI States
  const [isDownloading, setIsDownloading] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  // Child component references
  const dailyTripsModalRef = useRef<HTMLFormElement>(null);
  const submitEditedCatchReportModalRef = useRef<HTMLFormElement>(null);

  const readonlyReviewModalRef = useRef<HTMLFormElement>(null);
  const requestChangeModalRef = useRef<HTMLFormElement>(null);
  const tableRef = useRef<any>(null);

  //Lookups
  const [hasLoadedLookups, setHasLoadedLookups] = useState(false);
  const [tripTypes, setTripTypes] = useState([]);
  const [gearList, setGearList] = useState<Gear[]>([]);
  const [meshList, setMeshList] = useState<MeshSize[]>([]);
  const [speciesList, setSpeciesList] = useState<Species[]>([]);
  const [conditionsList, setConditionsList] = useState<Condition[]>([]);
  const [lakesList, setLakesList] = useState<any>([]);

  // On load
  useEffect(() => {
    const fetch = async () => {
      let tempCatchReport = catchReport;
      // Check to see if the catch report exists on first load. If not, fetch the catch report based on the URL ID parameter
      if (catchReport.id === -1) {
        tempCatchReport = await getCatchReport(catchReportID);
        setCatchReport(tempCatchReport);
      }
    };
    fetch();
  }, []);

  //When the catch report changes, make sure to update the daily trips, crew and assist to the new catch report.
  useEffect(() => {
    if (catchReport.id !== -1) {
      handleRefreshDailyTrips();
      setHelpers(catchReport.helpers);
      setAssistName(catchReport.assistName);
      setAssistRelationship(catchReport.assistRelationship);

      //Also, check if we're editing and need to load daily trip lookups
      if (isEditMode && !hasLoadedLookups) {
        const fetch = async () => {
          const resLookupTripTypes = await lookupTripTypes();
          const resGear = await lookupGear();
          const resMeshSizes = await lookupMeshSizes();
          const resSpecies = await lookupSpecies();
          const resConditions = await lookupConditions();
          const resLakes = await lookupLakes();

          if (resLookupTripTypes) setTripTypes(resLookupTripTypes);
          if (resGear) setGearList(resGear);
          if (resMeshSizes) setMeshList(resMeshSizes);
          if (resSpecies) setSpeciesList(resSpecies);
          if (resConditions) setConditionsList(resConditions);
          if (resLakes) setLakesList(resLakes);

          setHasLoadedLookups(true);
        };
        fetch();
      }
    }
  }, [catchReport]);

  const handleRefreshDailyTrips = async () => {
    const resDailyTrips = await listUserTrips({
      catchReportID: catchReport.id,
      tripTypeID: catchReport.tripTypeID,
      complete: undefined,
    });

    const resExcludedTrips = await listTripsUserRange({
      userID: catchReport.fisherID,
      beginDate: catchReport.beginDate,
      endDate: catchReport.endDate,
      complete: true,
    });

    if (resDailyTrips) setDailyTrips(resDailyTrips);
    if (resExcludedTrips) setExcludedTrips(resExcludedTrips);
  };

  const handleEditCatchReport = async () => {
    const resNewCatchReport = await putCatchReportEdit(catchReport.id);
    if (resNewCatchReport) {
      const newCatchReport = resNewCatchReport.data.data;
      setCatchReport(newCatchReport);
      setIsEditMode(true);
    }
  };

  const handleExitEditMode = async () => {
    const originalCatchReport = await getCatchReport(
      catchReport.previousReportID
    );
    if (originalCatchReport) setCatchReport(originalCatchReport);
    setIsEditMode(false);
  };

  const handleSubmitChanges = () => {
    submitEditedCatchReportModalRef.current?.handleShow({
      ...catchReport,
      helpers,
      assistName,
      assistRelationship,
      tripIDs: getTripIDs(),
    });
  };

  const handleSaveChanges = async () => {
    const fullReport = {
      ...catchReport,
      helpers,
      assistName,
      assistRelationship,
    };

    const res = await putCatchReport(fullReport);

    if (apiSuccess(res.status)) {
      alert("Saved Successfully");
    }
  };

  const handleDiscardChanges = async () => {
    if (window.confirm("Are you sure you want to discard this revision?")) {
      const res = await deleteCatchReport(catchReport.id);
      if (apiSuccess(res.status)) {
        // Change edit mode to false
        setIsEditMode(false);

        // Load original Catch Report the deleted one was cloned from
        const originalCatchReport = await getCatchReport(
          catchReport.previousReportID
        );
        setCatchReport(originalCatchReport);
      }
    }
  };

  const handleLoadSubmittedCatchReport = async () => {
    // load submitted catch report
    const updatedCatchReport = await getCatchReport(catchReport.id);
    setCatchReport(updatedCatchReport);

    // turn edit mode off
    setIsEditMode(false);

    // change url to the updated catch report ID
    navigate(`/reports/edit/${catchReport.id}`, { replace: true });
  };

  const handleDownloadPDF = async () => {
    setIsDownloading(true);
    await getCatchReportExportPDF(catchReportID);
    setIsDownloading(false);
  };

  const handlePreviewPDF = async () => {
    setIsPreviewing(true);
    await getCatchReportPreviewPDF(catchReportID);
    setIsPreviewing(false);
  };

  const getTripIDs = () => {
    const tripIDs: number[] = [];
    dailyTrips.forEach((dailyTrip) => {
      tripIDs.push(dailyTrip.id);
    });
    return tripIDs;
  };

  return (
    <>
      <Container>
        {isEditMode && (
          <div className="w-100 bg-warning mx-n2 rounded p-1 mt-3 text-center text-primary lead fw-bold">
            <em>Editing Report</em>
          </div>
        )}

        <DailyTripModal
          refId={dailyTripsModalRef}
          handleRefreshDailyTrips={handleRefreshDailyTrips}
          catchReportID={catchReport.id}
          userID={catchReport.userID}
          fisherID={catchReport.fisherID}
          gearList={gearList}
          meshList={meshList}
          speciesList={speciesList}
          conditionsList={conditionsList}
          lakesList={lakesList}
        />
        <SubmitEditedCatchReportModal
          refId={submitEditedCatchReportModalRef}
          handleLoadSubmittedCatchReport={handleLoadSubmittedCatchReport}
        />
        <ReadonlyReviewModal refId={readonlyReviewModalRef} />
        <RequestChangeModal refId={requestChangeModalRef} />

        <p className="mt-4 mb-5">
          {/* <span className="link" onClick={() => navigate(-1)}>
            <FaChevronLeft className="text-primary me-2 mt-n1 fs-6 link-unstyled" />
            Go Back
          </span>
          <span className="mx-4 text-gray">|</span> */}
          Catch Reports
          <span className="mx-2">/</span>
          <span>View Catch Report</span>
          <span className="mx-2">/</span>
          <span className="fw-bold">
            {catchReport.id === -1 ? "Loading..." : catchReport?.displayName}
          </span>
        </p>
        {catchReport.editInProgress && (
          <div className="callout-warning shadow">
            <Alert variant="warning" className="callout-warning">
              This catch report has pending edits. To view, submit or discard
              those changes click on "Resume Editing".
            </Alert>
          </div>
        )}
        {isEditMode && (
          <div className="callout-primary shadow">
            <Alert variant="info">
              <p>You are now editing a catch report.</p>
              <p className="mb-0">
                After you submit a revised catch report, the site will reflect
                updates that have been made. You can edit, save and discard
                changes without submitting the catch report.
              </p>
            </Alert>
          </div>
        )}
        <h1 className="text-primary fw-bold">
          {isEditMode ? "Edit" : "View"} Catch Report{" "}
          <span>
            {!isEditMode && (
              <Badge bg="gray" className="ms-3">
                {catchReport.reviewed ? (
                  <>
                    <FaLock className="me-2 mt-n2" />
                    Locked
                  </>
                ) : (
                  <>
                    <FaLockOpen className="me-2 mt-n2" />
                    Unlocked
                  </>
                )}
              </Badge>
            )}
          </span>
        </h1>

        <p className="lead mb-0">
          <FaUserCircle className="text-primary me-2 mt-n1 fs-5" />
          {catchReport.id === -1 ? "Loading..." : catchReport?.displayName}
        </p>
        <p className="lead mb-0">
          <FaCalendarWeek className="text-primary me-2 mt-n1 fs-5" />
          {catchReport.id === -1 ? (
            "Loading..."
          ) : (
            <>
              {getFormattedDateMMDDYYYY(catchReport?.beginDate)}
              {" — "}
              {getFormattedDateMMDDYYYY(catchReport?.endDate)}
            </>
          )}
        </p>
        {catchReport.version && catchReport.version > 1 && (
          <p className="lead">
            <FaEdit className="text-primary me-2 mt-n1 fs-5" />
            Version: {catchReport.version}
          </p>
        )}

        <hr className="my-4" />

        {/* Tool bar. Only show up when not editing */}
        {!isEditMode ? (
          <div className="mb-4">
            <Button
              variant="primary"
              className="me-2 mb-3 d-inline"
              onClick={handleEditCatchReport}
            >
              <FaEdit className="me-2 mt-n1" />
              {catchReport.editInProgress
                ? "Resume Editing"
                : "Edit Catch Report"}
            </Button>

            <Button
              variant="secondary"
              className="me-2 mb-3 d-inline"
              onClick={handleDownloadPDF}
            >
              <FaFileDownload className="me-2 mt-n1" />
              Download PDF{" "}
              {isDownloading && (
                <Spinner animation="border" variant="light" className="ms-2" />
              )}
            </Button>

            <Button
              variant="secondary"
              className="me-2 mb-3 d-none d-lg-inline"
              onClick={handlePreviewPDF}
            >
              <FaExternalLinkAlt className="me-2 mt-n1" />
              Preview Report
              {isPreviewing && (
                <Spinner animation="border" variant="light" className="ms-2" />
              )}
            </Button>

            {!catchReport.reviewed && (
              <Button
                variant="secondary"
                className="me-2 mb-3 d-inline"
                onClick={async () => {
                  const res = await putCatchReportReview(catchReport.id);
                  if (apiSuccess(res.status)) {
                    setCatchReport({ ...catchReport, reviewed: true });
                  }
                }}
              >
                <FaLock className="me-2 mt-n1" />
                Lock Catch Report
              </Button>
            )}

            {catchReport.reviewed && (
              <Button
                variant="secondary"
                className="me-2 mb-3 d-inline"
                onClick={async () => {
                  const res = await putCatchReportUnreview(catchReport.id);
                  if (apiSuccess(res.status)) {
                    setCatchReport({ ...catchReport, reviewed: false });
                  }
                }}
              >
                <FaLockOpen className="me-2 mt-n1" />
                Unlock Catch Report
              </Button>
            )}
          </div>
        ) : (
          <div className="mb-4">
            <Button
              onClick={handleSubmitChanges}
              variant="success"
              className="me-2 mb-3 d-inline"
            >
              <FaCheckCircle className="me-2 mt-n1" />
              Submit Revised Catch Report
            </Button>

            <Button
              onClick={handleSaveChanges}
              variant="primary"
              className="me-2 mb-3 d-inline"
            >
              <FaSave className="me-2 mt-n1" />
              Save Changes
            </Button>
            <Button
              onClick={handleExitEditMode}
              variant="secondary"
              className="me-2 mb-3 d-inline"
            >
              <FaTimesCircle className="me-2 mt-n1" />
              Exit Edit Mode
            </Button>
            <Button
              variant="secondary"
              className="me-2 mb-3 d-inline"
              onClick={handleDiscardChanges}
            >
              <FaTrash className="me-2 mt-n1" />
              Discard Revision
            </Button>
          </div>
        )}

        <div className="mb-5">
          <div className="mb-3">
            <Row className="flex-nowrap">
              <Col className="flex-grow-1 overflow-hidden">
                <h3 className="fw-normal">Daily Trips</h3>
                {/* Edited day key */}
                {isEditMode && (
                  <p>
                    <span
                      className="bg-warning border border-light shadow rounded d-inline-block mb-n1 me-2"
                      style={{ width: "18px", height: "18px" }}
                    ></span>{" "}
                    Edited Trip
                  </p>
                )}
                <div className={`form-card ${isEditMode ? "isEditing" : ""}`}>
                  <TripsTable
                    dailyTripRef={dailyTripsModalRef}
                    reviewDailyTripRef={readonlyReviewModalRef}
                    refID={tableRef}
                    dailyTrips={dailyTrips}
                    readOnly={!isEditMode}
                    loadingDailyTrips={catchReport.id === -1}
                  />
                  {isEditMode && (
                    <Button
                      variant="outline-primary"
                      disabled={tripTypes.length === 0}
                      onClick={() =>
                        dailyTripsModalRef.current?.newDailyTrip(tripTypes[0])
                      }
                    >
                      New Daily Trip
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </div>

          {excludedTrips.length > 0 && (
            <div className="mb-3">
              <Row className="flex-nowrap">
                <Col className="flex-grow-1 overflow-hidden">
                  <h3 className="fw-normal">Excluded Trips</h3>
                  {/* Edited day key */}
                  {isEditMode && (
                    <p>
                      <span
                        className="bg-warning border border-light shadow rounded d-inline-block mb-n1 me-2"
                        style={{ width: "18px", height: "18px" }}
                      ></span>{" "}
                      Edited Trip
                    </p>
                  )}
                  <div className={`form-card ${isEditMode ? "isEditing" : ""}`}>
                    <TripsTable
                      dailyTripRef={dailyTripsModalRef}
                      reviewDailyTripRef={readonlyReviewModalRef}
                      refID={tableRef}
                      dailyTrips={excludedTrips}
                      readOnly={!isEditMode}
                      excluded={true}
                      catchReportID={catchReport.id}
                      loadingDailyTrips={catchReport.id === -1}
                      handleRefreshDailyTrips={handleRefreshDailyTrips}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )}

          <div>
            <h3 className="fw-normal">Crew</h3>
            <EditCatchReportCrew
              handleSetCrewMembers={setHelpers}
              showRecentCrew={false}
              crew={helpers}
              readOnly={!isEditMode}
            />
          </div>

          <div>
            <h3 className="fw-normal">Catch Report Assistant</h3>
            <div className="form-card">
              <Row className="mb-2">
                <Form.Group as={Col} lg={8} className="mb-3">
                  <Form.Label>
                    Name of person who helped complete this report{" "}
                  </Form.Label>
                  <Form.Control
                    disabled={!isEditMode}
                    type="text"
                    name="assistName"
                    value={assistName}
                    onChange={(e) => setAssistName(e.currentTarget.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} lg={4} className="mb-3">
                  <Form.Label>Relationship</Form.Label>
                  <Form.Control
                    disabled={!isEditMode}
                    type="text"
                    name="assistRelationship"
                    value={assistRelationship}
                    onChange={(e) =>
                      setAssistRelationship(e.currentTarget.value)
                    }
                  />
                </Form.Group>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
