import { useEffect, useState } from "react";
import { getCatchReportExportPDF } from "../../api/getCatchReportExportPDF";
import { useParams } from "react-router-dom";

export default function DownloadCatchReport() {
  const { catchReportID } = useParams();

  useEffect(() => {
    const fetch = async () => {
      getCatchReportExportPDF(catchReportID);
    };
    fetch();
  }, [catchReportID]);

  return <></>;
}
