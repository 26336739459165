import axios from "axios";

export const listUserTrips = async (data: {
  catchReportID: any;
  tripTypeID: any;
  complete?: boolean;
}) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Trip/user-list?CatchReportID=${
        data.catchReportID
      }&TripTypeID=${data.tripTypeID}&Complete=${
        data.complete ? data.complete : ""
      }`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};
