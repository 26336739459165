import axios from "axios";
import { License } from "../models/license";

export const putLicense = async (license: License) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/License/license`,
      license
    );
    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
