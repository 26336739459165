export interface DiffResult<T> {
  val: T;
  added: boolean;
  removed: boolean;
}

export const getDifferentValues = <T>(
  arr1: T[],
  arr2: T[]
): DiffResult<T>[] => {
  const uniqueValues: T[] = [];
  const result: DiffResult<T>[] = [];

  for (const val of arr1) {
    if (!arr2.includes(val) && !uniqueValues.includes(val)) {
      uniqueValues.push(val);
      result.push({ val, added: true, removed: false });
    }
  }

  for (const val of arr2) {
    if (!arr1.includes(val) && !uniqueValues.includes(val)) {
      uniqueValues.push(val);
      result.push({ val, added: false, removed: true });
    }
  }

  return result;
};
