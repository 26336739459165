import CORALogo from "../../components/coraLogo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { handleLogout } from "../../api/handleLogout";

export default function Logout() {
  setTimeout(() => handleLogout(), 2000);

  return (
    <Container className="h-100 py-5 d-flex flex-column">
      <Row className="justify-content-center flex-grow-1">
        <Col md={10} lg={8}>
          <div className="form-card py-5 px-3 px-sm-4 px-md-5">

            <CORALogo />

            <h2 className="h1 mb-4 text-center text-sm-start">
              You have been logged out
            </h2>

          </div>
        </Col>
      </Row>
    </Container>
  );
}
