import axios from "axios";
import {
  getTribalOrganization,
  getOrganizationID,
  getRoles,
} from "../helpers/authHelper";

export const lookupTribes = async (returnAllOrgs: boolean = false) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Lookup/organizations?IsTribe=true`
    );

    if (returnAllOrgs) {
      return response.data;
    }

    // Check if user is CORA Admin
    if (!getTribalOrganization() && getRoles().includes("CORA_Admin")) {
      return response.data;
    } else {
      const organization = response.data.find(
        (tribe: any) => parseInt(tribe.id) === getOrganizationID()
      );
      return [organization];
    }
  } catch (error) {
    console.error(error);
  }
};
