import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import { ColumnDef } from "@tanstack/react-table";
import { Helper } from "../../models/helper";
import { listHelpers } from "../../api/listHelpers";
import { fuzzySort } from "../../components/tables/fuzzySort";
import HelpersTable from "../../components/tables/helpersTable";

export default function AdminAllHelpers() {
  const columns = React.useMemo<ColumnDef<Helper, any>[]>(
    () => [
      {
        accessorFn: (row) => row.displayName,
        id: "displayName",
        header: "Name",
        cell: (info) => {
          return <strong>{info.getValue()}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.licenseNumber,
        id: "licenseNumber",
        header: "License Number",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.status,
        id: "status",
        header: "Status",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.organizationCode,
        id: "orgCode",
        header: "Organization Code",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
    ],
    []
  );

  return (
    <div className="py-5">
      <Container fluid>
        <h1 className="text-primary mb-5">Helpers</h1>

        <HelpersTable
          columns={columns}
          lookupList={listHelpers}
          role={"helper"}
        />
      </Container>
    </div>
  );
}
