import axios from "axios";

export const getCrewRecent = async (userID: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Crew/recent?UserID=${userID}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
