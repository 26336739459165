import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { Outlet } from "react-router-dom";
import SideNav from "./sideNav";

export default function AdminLayout() {
  return (
    <>
      <Container fluid className="h-100">
        <Row className="flex-nowrap h-100">
          <Col md={3} xl={2} className="d-none d-md-block bg-light shadow-sm">
            <SideNav />
          </Col>
          <Col md={9} xl={10}>
            <Outlet />
          </Col>
        </Row>
      </Container>
    </>
  );
}
