import axios from "axios";

export const lookupSpecies = async (fishingDate?: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Lookup/species${
        fishingDate ? "?AsOfDate=" + fishingDate : ""
      }`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
