import { FaQuestionCircle } from "react-icons/fa";

import styled from "styled-components";

const StyledToolTip = styled.div`
  &:hover > .tool-tip-text {
    visibility: visible;
  }
`;
const ToolTipText = styled.span`
  visibility: hidden;
  width: 18rem;
  max-width: 90vw;
  z-index: 1000;
  left: 100%;
  top: -50%;
  background-clip: padding-box;

  &::before {
    content: "";
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  &::before {
    right: 100%;
  }

  &:hover {
    visibility: visible;
  }

  @media only screen and (max-width: 991px) {
    left: -8rem;
    top: 100%;

    &::before {
      top: -2rem;
      right: 46%;
    }
  }
`;

export default function ToolTip(props: any) {
  return (
    <StyledToolTip className="position-relative d-inline-block">
      <FaQuestionCircle className="ms-3 text-gray" />
      <ToolTipText className="tool-tip-text fw-normal fs-6 bg-white rounded shadow position-absolute m-3 p-3">
        {props.content}
      </ToolTipText>
    </StyledToolTip>
  );
}
