import {
  uniqueNamesGenerator,
  adjectives,
  //   colors,
  animals,
  NumberDictionary,
} from "unique-names-generator";

export const onetimePassword = () => {
  const numberDictionary = NumberDictionary.generate({ min: 100, max: 999 });
  const randomPass = uniqueNamesGenerator({
    dictionaries: [adjectives, animals, numberDictionary],
    length: 3,
    separator: "",
    style: "capital",
  });

  return randomPass;
};
