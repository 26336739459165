export const getLakeFromGrid = (gridID: string) => {
  const prefix = gridID.split("_")[0];

  switch (prefix) {
    case "lm":
      return "Michigan";

    case "ls":
      return "Superior";

    case "lh":
      return "Huron";

    default:
      return "Unknown Lake";
  }
};
