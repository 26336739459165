import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect, useState } from "react";
import { DailyTrip } from "../models/dailyTrip";
import { Grid } from "../models/grid";
import { TripGear } from "../models/tripGear";
import { GiFishingHook, GiDoubleFish } from "react-icons/gi";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/esm/Button";
import { getFormattedDateMMDDYYYY } from "../helpers/getFormattedDateMMDDYYYY";
import Badge from "react-bootstrap/esm/Badge";
import { deleteTrip } from "../api/deleteTrip";
import ReviewDailyTrip from "./dailyTrips/reviewDailyTrip";
import { getTrip } from "../api/getTrip";

export default function MobileDailyTrips({
  dailyTripRef,
  dailyTrips,
  loadingDailyTrips,
  handleRefreshDailyTrips,
  goBack,
  canEdit,
  canDelete,
  meshList,
}: any) {
  const [data, setData] = useState<DailyTrip[]>([]);
  const [selectedTrip, setSelectedTrip] = useState<DailyTrip>();
  const [dailyTrip, setDailyTrip] = useState<DailyTrip>();
  const [showTripOverlay, setShowTripOverlay] = useState(false);

  // load Daily Trip
  const loadDailyTrip = async (tripID: any) => {
    const trip = await getTrip(tripID);
    const newDT = new DailyTrip(trip);
    trip.fishingDate = trip.fishingDate?.split("T")[0];
    setDailyTrip(newDT);
  };

  const handleSelectTrip = async (dt: DailyTrip) => {
    await loadDailyTrip(dt.id);
    setSelectedTrip(dt);
    setShowTripOverlay(true);
  };

  const handleCloseOverlay = () => {
    setShowTripOverlay(false);
  };

  useEffect(() => {
    setData(dailyTrips);
  }, [dailyTrips]);

  const getMonthDay = (fishingDate: string) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(fishingDate);
    const month = months[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}`;
  };

  const getDOW = (fishingDate: string) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const date = new Date(fishingDate);
    const dayOfWeek = daysOfWeek[date.getDay()];

    return dayOfWeek;
  };

  const getGrids = (grids: Grid[]) => {
    const valuesArray = grids.map((obj) => obj.gridNumber);
    return valuesArray.join(", ");
  };

  const getSpecies = (harvest: any) => {
    const unique = harvest
      .map((item: any) => item.speciesName)
      .filter(
        (value: any, index: any, self: any) => self.indexOf(value) === index
      );

    const valuesArray = unique.map((obj: any) => obj);
    return valuesArray.join(", ");
  };

  const getGear = (gear: TripGear[]) => {
    const unique = gear
      .map((item: any) => item.gearName)
      .filter(
        (value: any, index: any, self: any) => self.indexOf(value) === index
      );

    const valuesArray = unique.map((obj: any) => obj);
    return valuesArray.join(", ");
  };

  const handleFormData = (data: any) => {};

  return (
    <div>
      {loadingDailyTrips && (
        <h2 className="text-gray w-100 text-center">Loading Daily Trips...</h2>
      )}
      {!loadingDailyTrips && data.length === 0 && (
        <h2 className="text-gray w-100 text-center">No Daily Trips</h2>
      )}

      {data.map((dt, i) => (
        <Button
          variant="light"
          key={i}
          className="mb-2 p-3 d-block w-100 text-start"
          onClick={() => handleSelectTrip(dt)}
        >
          {!dt.complete && (
            <Badge className="mb-2 me-2" pill bg="warning" text="dark">
              Incomplete
            </Badge>
          )}
          {dt.excluded && (
            <Badge className="mb-2 me-2" pill bg="danger" text="light">
              Not included with submitted catch report
            </Badge>
          )}
          <Row>
            <Col xs={3}>
              <div className="d-flex flex-column justify-content-center">
                <span className="d-block font-weight-bold">
                  <strong>{getMonthDay(dt.fishingDate)}</strong>
                </span>
                <span className="">{getDOW(dt.fishingDate)}</span>
              </div>
            </Col>
            <Col>
              <div>
                <strong> {`${dt.lakeName} - ${getGrids(dt.grids)}`}</strong>
              </div>
              <div>
                {dt.gear.length > 0 && (
                  <GiFishingHook className="mb-1 me-1 text-primary" />
                )}
                {`${getGear(dt.gear)}`}
              </div>
              <div>
                {dt.harvest.length > 0 && (
                  <GiDoubleFish className="mb-1 me-1 text-primary" />
                )}
                {`${getSpecies(dt.harvest)}`}
              </div>
            </Col>
          </Row>
        </Button>
      ))}

      <Offcanvas
        className="mobileDailyTripsOverlay"
        show={showTripOverlay}
        onHide={handleCloseOverlay}
      >
        <Offcanvas.Header closeButton className="shadow z-1000">
          <Offcanvas.Title>Trip Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="bg-background">
          {selectedTrip && (
            <div className="d-flex flex-column justify-content-between h-100">
              <div>
                <h1 className="display-2">
                  {getFormattedDateMMDDYYYY(selectedTrip.fishingDate)}
                </h1>
                <p>
                  {getDOW(selectedTrip.fishingDate)},{" "}
                  {getMonthDay(selectedTrip.fishingDate)}
                </p>
                <hr className="" />
                {!selectedTrip.complete && (
                  <Badge className="mb-2 me-2" pill bg="warning" text="dark">
                    Incomplete
                  </Badge>
                )}
                {selectedTrip.excluded && (
                  <Badge className="mb-2 me-2" pill bg="danger" text="light">
                    Not included with submitted catch report
                  </Badge>
                )}

                {DailyTrip ? (
                  <ReviewDailyTrip
                    initialValues={dailyTrip}
                    formData={handleFormData}
                    isReadonly={true}
                  />
                ) : (
                  // I left this one as a backup incase dailyTrip didn't load
                  <ul>
                    <li>
                      <strong>Lake: </strong>
                      {selectedTrip.lakeName}
                    </li>
                    <li>
                      <strong>Grid(s): </strong>
                      {getGrids(selectedTrip.grids)}
                    </li>
                    <li>
                      <strong>Vessel: </strong>
                      {selectedTrip.vesselName}
                    </li>
                    <li>
                      <strong>Gear: </strong>
                      {getGear(selectedTrip.gear)}
                    </li>
                    <li>
                      <strong>Species: </strong>
                      {getSpecies(selectedTrip.harvest)}
                    </li>
                    <li>
                      <strong>Launch site: </strong>
                      {selectedTrip.launchSite}
                    </li>
                    <li>
                      <strong>Comments: </strong>
                      {selectedTrip.comments}
                    </li>
                  </ul>
                )}
              </div>
              <div>
                {canEdit && (
                  <Button
                    variant="secondary"
                    className="w-100 mb-3"
                    onClick={() => {
                      console.log(selectedTrip);
                      dailyTripRef.current?.loadDailyTrip(selectedTrip?.id);
                      setTimeout(function () {
                        setShowTripOverlay(false);
                      }, 400);
                    }}
                  >
                    Edit Daily Trip
                  </Button>
                )}
                {canDelete && (
                  <Button
                    variant="outline-danger"
                    className="w-100 mb-3"
                    onClick={async (e) => {
                      if (window.confirm("Delete trip?") === true) {
                        let tripID = selectedTrip.id;
                        let res = await deleteTrip(tripID);

                        if (res) {
                          const newData = data.filter(
                            (trip: DailyTrip) => trip.id !== tripID
                          );
                          setData([...newData]);
                          handleRefreshDailyTrips();
                        }
                        setShowTripOverlay(false);
                      }
                    }}
                  >
                    Delete Daily Trip
                  </Button>
                )}

                {goBack && (
                  <Button
                    variant="outline-primary w-100 mb-3"
                    onClick={handleCloseOverlay}
                  >
                    Go Back
                  </Button>
                )}
              </div>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
