import axios from "axios";

export const getUserTrips = async (userID?: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Trip/user-list?UserID=${userID}
      `
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};
