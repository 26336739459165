import { Link, useLocation } from "react-router-dom";
import {
  getRoles,
  getUserName,
  getOrganizationID,
} from "../../helpers/authHelper";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import styled from "styled-components";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";

// Icons
import {
  FaBars,
  FaHome,
  FaCog,
  FaUserAlt,
  FaChartBar,
  FaQuestionCircle,
} from "react-icons/fa";
import { useEffect, useState } from "react";
import SideNav from "./sideNav";
import { handleLogout } from "../../api/handleLogout";

const NavLink = styled(Link)`
  color: var(--light-color);
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin-left: 1rem;
  margin-right: 1rem;

  &:hover {
    color: var(--light-color);
    opacity: 0.66;
  }

  svg {
    height: 1.5rem;
    width: auto;
    margin-right: 0.5rem;
  }
`;

const DropdownLink = styled.a`
  color: var(--light-color);
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin-left: 1.4rem;

  &:hover {
    color: var(--light-color);
    opacity: 0.66;
    cursor: pointer;
  }

  svg {
    height: 1.5rem;
    width: auto;
    margin-right: 0.5rem;
  }
`;

const MobileMenu = styled(FaBars)`
  color: var(--light-color);
  height: 1.5rem;
  width: auto;
`;

export default function Navigation({ version }: any) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState<any>(null);

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);
    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const location = useLocation();
  useEffect(() => {
    handleClose();
  }, [location]);

  const token = localStorage.getItem("accessToken");
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const roles = getRoles();
  const userName = getUserName();

  return (
    <Navbar bg="primary" variant="dark" className="px-3 px-sm-4 px-md-5 shadow">
      <Navbar.Brand className="fw-bold nav-logo">
        <Link to="/" className="link-unstyled" title={version?.message}>
          CORA Catch Reporting System
        </Link>
        <span
          id="version"
          className="ms-2"
          title="Click to copy version information to clipboard"
          data-version={version?.message}
          onClick={(e: React.MouseEvent<any>) => {
            if (e.currentTarget.getAttribute("data-version"))
              navigator.clipboard.writeText(
                e.currentTarget.getAttribute("data-version")
              );
          }}
        >
          &nbsp;
        </span>
      </Navbar.Brand>

      {token && (
        <>
          <Nav className="ms-auto d-none d-md-flex align-items-center">
            {/* Home */}
            <NavLink to="/">
              <FaHome />
              Dashboard
            </NavLink>

            {/* Admin Settings */}
            {getOrganizationID() !== 0 &&
              !roles.includes("Fisher") &&
              !roles.includes("Agency") && (
                <NavLink to="/settings">
                  <FaCog />
                  Settings
                </NavLink>
              )}

            {/* Support */}
            {!roles.includes("Agency") && (
              <NavLink to="/support">
                <FaQuestionCircle />
                Support
              </NavLink>
            )}

            {/* Profile */}
            <Dropdown className="nav-btn" as={ButtonGroup} align={"end"}>
              <Dropdown.Toggle
                id="nav-profile-dropdown"
                className="d-flex align-items-center"
              >
                <span>
                  <FaUserAlt className="text-light me-2" />
                  {userName !== "" ? userName : "Profile"}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <p className="my-2 mx-3">{`${firstName} ${lastName}`}</p>
                {roles.length > 0 && (
                  <p className="my-2 mx-3">{`Role: ${roles[0].replace(
                    "_",
                    " "
                  )}`}</p>
                )}
                <Dropdown.Divider />
                <Dropdown.Item as="span">
                  <Link to={`/profile`} className="d-block">
                    Profile Settings
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>
                  <span className="link">Logout</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>

          <Nav className="ms-auto d-block d-md-none">
            <MobileMenu onClick={handleShow} />
          </Nav>

          <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title className="text-primary">
                CORA Catch Reporting System
              </Offcanvas.Title>
            </Offcanvas.Header>
            <SideNav
              className="overflow-scroll"
              promptInstall={promptInstall}
              supportsPWA={supportsPWA}
            />
          </Offcanvas>
        </>
      )}
    </Navbar>
  );
}
