import axios from "axios";
import { createHashPassword } from "../helpers/createHashPassword";

export interface Fisher {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  userName?: string;
  oneTimePassword?: string;
  passwordHash?: string;
  createVia: string;
  organizationID: number;
  biaNumber: string;
  organizationCodeNumber: string;
  address1: string;
  city: string;
  stateCode: string;
  zip: string;
  commercialLicenseNumber: string;
  subsistenceLicenseNumber: string;
}

export const createFisher = async (payload: Fisher) => {
  if (payload.oneTimePassword) {
    const hashPassword = await createHashPassword(payload.oneTimePassword);
    payload.passwordHash = hashPassword;
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/User/create-fisher`,
      payload
    );
    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
