import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import { Buffer } from "buffer";
import { getRandomSalt } from "../api/getRandomSalt";

export const createHashPassword = async (password: string) => {
  function appendBase64(string1: any, string2: any) {
    const bytes1 = Buffer.from(string1, "base64");
    const bytes2 = Buffer.from(string2, "base64");
    return Buffer.concat([bytes1, bytes2]).toString("base64");
  }

  const utf8Encode = new TextEncoder();
  const salt = await getRandomSalt(); //Base64 string
  const message = appendBase64(
    salt,
    utf8Encode.encode("_Password_" + password)
  );
  const input = Base64.parse(message);
  let hash = sha256(input);
  for (var i = 2; i <= 976; i++) {
    hash = sha256(hash);
  }

  const result = appendBase64(salt, hash.toString(Base64));
  return result;
};
