import Button from "react-bootstrap/esm/Button";
import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Form from "react-bootstrap/Form";
import { CatchReportPeriod } from "../../models/catchReportPeriod";
import { getCatchReportPeriods } from "../../api/getCatchReportPeriods";
import { getFormattedDateMMDDYYYY } from "../../helpers/getFormattedDateMMDDYYYY";
import { FaFileDownload } from "react-icons/fa";
import Spinner from "react-bootstrap/Spinner";
import SelectOrganization from "../../components/formControls/selectOrganization";
import { getCatchReportPeriodsAdmin } from "../../api/getCatchReportPeriodsAdmin";
import { getFormattedDateYYYYMMDD } from "../../helpers/getFormattedDateYYYYMMDD";
import { getAgencyDataCSVDownload } from "../../api/getAgencyDataCSVDownload";
import { getAgencyDataChangeLogCSVDownload } from "../../api/getAgencyDataChangeLogCSVDownload";

export default function AdminExportAgencyReports() {
  const [catchReportPeriods, setCatchReportPeriods] = useState<
    CatchReportPeriod[]
  >([]);
  const [selectedCatchReportIndex, setSelectedCatchReportIndex] = useState(0);
  const [includeLateCheck, setIncludeLateCheck] = useState(true);
  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);
  const [isDownloadingChangelog, setIsDownloadingChangelog] = useState(false);
  const [beginDate, setBeginDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedOrg, setSelectedOrg] = useState(-1);

  useEffect(() => {
    const fetch = async () => {
      const resCatchReportPeriods = await getCatchReportPeriodsAdmin({
        organizationID: selectedOrg,
        excludeSubmitted: false,
      });

      if (resCatchReportPeriods)
        setCatchReportPeriods([...resCatchReportPeriods]);
    };
    fetch();
  }, [selectedOrg]);

  // When filter review changes, fetch the new trips and update the table
  useEffect(() => {
    getCatchReports();
  }, [beginDate, endDate, selectedCatchReportIndex, catchReportPeriods]);

  const getCatchReports = async () => {
    //If the end / start date differ from reporting period, set reporting period to blank
    if (catchReportPeriods[selectedCatchReportIndex]) {
      const isBeginDateSame =
        beginDate ===
        getFormattedDateYYYYMMDD(
          catchReportPeriods[selectedCatchReportIndex].beginDate
        );
      const isEndDateSame =
        endDate ===
        getFormattedDateYYYYMMDD(
          catchReportPeriods[selectedCatchReportIndex].endDate
        );

      if (!isBeginDateSame || !isEndDateSame) {
        setSelectedCatchReportIndex(-1);
      }
    }
  };

  const handleSelectChange = async (event: any) => {
    const selectedOption = parseInt(event.target.value);
    setSelectedCatchReportIndex(selectedOption);
    setBeginDate(
      getFormattedDateYYYYMMDD(catchReportPeriods[selectedOption].beginDate)
    );

    setEndDate(
      getFormattedDateYYYYMMDD(catchReportPeriods[selectedOption].endDate)
    );
  };

  const downloadCatchReportsAsCSV = async () => {
    setIsDownloadingCSV(true);
    const params = {
      organizationID: selectedOrg,
      beginDate: beginDate,
      endDate: endDate,
    };
    await getAgencyDataCSVDownload(params);
    setIsDownloadingCSV(false);
  };

  const downloadChangelog = async () => {
    setIsDownloadingChangelog(true);
    const params = {
      organizationID: selectedOrg,
      beginDate: beginDate,
      endDate: endDate,
      includeLate: includeLateCheck,
    };
    await getAgencyDataChangeLogCSVDownload(params);
    setIsDownloadingChangelog(false);
  };

  return (
    <div className="py-5">
      <Container fluid>
        <h1 className="text-primary mb-5 display-4 fw-bold">
          Export Agency Data
        </h1>
        <p>Includes only data required to be reported by the 2023 Decree.</p>

        <div className="form-card">
          <Row className="gx-3 mb-3">
            <Col xs="auto" lg={4}>
              <SelectOrganization
                allTribes
                includeCORA={false}
                returnAllOrgs={true}
                value={selectedOrg}
                onChange={(e: any) => setSelectedOrg(e.target.value)}
              />
            </Col>
            <Col xs="auto">
              <Form.Group className="mb-3">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={beginDate}
                  onChange={(e) => setBeginDate(e.currentTarget.value)}
                  isInvalid={isNaN(new Date(beginDate).getTime())}
                />
              </Form.Group>
            </Col>
            <Col xs="auto">
              <Form.Group className="mb-3">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.currentTarget.value)}
                  isInvalid={isNaN(new Date(endDate).getTime())}
                />
              </Form.Group>
            </Col>
            <Col xs="auto">
              <Form.Group className="mb-3">
                <Form.Label>Select by Reporting Period</Form.Label>
                <Form.Select
                  onChange={handleSelectChange}
                  aria-label="Reporting Period"
                  value={selectedCatchReportIndex}
                >
                  <option value={-1}>---</option>
                  {catchReportPeriods.map((period: CatchReportPeriod, i) => (
                    <option key={i} value={i}>
                      {getFormattedDateMMDDYYYY(period.beginDate)} -{" "}
                      {getFormattedDateMMDDYYYY(period.endDate)}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs="auto">
              <Form.Check
                type="checkbox"
                name="includeLateCheck"
                id="includeLateCheck"
                label="Include Late Submissions When Downloading Changelog"
                className="mb-2"
                checked={includeLateCheck}
                onChange={() => setIncludeLateCheck(!includeLateCheck)}
              ></Form.Check>
            </Col>
          </Row>
          <Button variant="secondary" onClick={downloadCatchReportsAsCSV}>
            {isDownloadingCSV ? (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                className="me-2"
              />
            ) : (
              <FaFileDownload className="mt-n1 me-2" />
            )}
            Download Trip Data as CSV
          </Button>

          <Button
            variant="outline-primary"
            className="ms-2"
            onClick={downloadChangelog}
          >
            {isDownloadingChangelog ? (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                className="me-2"
              />
            ) : (
              <FaFileDownload className="mt-n1 me-2" />
            )}
            Download Changelog as CSV
          </Button>
        </div>
      </Container>
    </div>
  );
}
