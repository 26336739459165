import { getUserID } from "../helpers/authHelper";
import { Buyer } from "./buyer";
import { Catch } from "./catch";
import { TripEffort } from "./tripEffort";
import { TripGear } from "./tripGear";
import { Grid } from "./grid";
import { Vessel } from "./vessel";

const today = new Date();
today.setHours(0, 0, 0, 0);

export class DailyTrip {
  id: number;
  userID: number;
  fisherName?: string;
  catchReportID?: number;
  licenseID?: number;
  licenseNumber?: string;
  fishingDate: string;
  tripTypeID?: number;
  lakeID?: number | null;
  lakeName?: string;
  grids: Grid[];
  launchSite?: string;
  vesselID?: number;
  vesselName?: string;
  vessel?: Vessel;
  comments?: string;
  effort: TripEffort[];
  gear: TripGear[];
  harvest: Catch[];
  buyers: Buyer[];
  manualEffort: Boolean;
  manualHarvest: Boolean;
  newEffortID: number;
  newGearID: number;
  newCatchID: number;
  newBuyerID: number;
  complete: boolean;
  excluded: boolean;

  constructor({
    id = -1,
    userID = parseInt(getUserID()),
    fisherName = undefined,
    catchReportID = undefined,
    licenseID = undefined,
    licenseNumber = undefined,
    fishingDate = today.toISOString(),
    // fishingDate = new Date().toISOString(),
    tripTypeID = undefined,
    lakeID = 2,
    lakeName = "",
    grids = [],
    launchSite = "",
    vesselID = undefined,
    vesselName = "",
    comments = "",
    effort = [],
    gear = [],
    harvest = [],
    buyers = [],
    manualEffort = false,
    manualHarvest = false,
    newEffortID = 0,
    newGearID = 0,
    newCatchID = -1,
    newBuyerID = -1,
    complete = false,
    excluded = false,
  } = {}) {
    this.id = id;
    this.userID = userID;
    this.fisherName = fisherName;
    this.catchReportID = catchReportID;
    this.licenseID = licenseID;
    this.licenseNumber = licenseNumber;
    this.fishingDate = fishingDate.split("T")[0];
    this.tripTypeID = tripTypeID;
    this.lakeID = lakeID;
    this.lakeName = lakeName;
    this.grids = grids;
    this.launchSite = launchSite;
    this.vesselID = vesselID;
    this.vesselName = vesselName;
    this.comments = comments;
    this.effort = effort;
    this.gear = gear;
    this.harvest = harvest;
    this.buyers = buyers;
    this.manualEffort = manualEffort;
    this.manualHarvest = manualHarvest;
    this.newEffortID = newEffortID;
    this.newGearID = newGearID;
    this.newCatchID = newCatchID;
    this.newBuyerID = newBuyerID;
    this.complete = complete;
    this.excluded = excluded;
  }
}
