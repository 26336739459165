import { Dispatch, SetStateAction, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FormLabel from "react-bootstrap/esm/FormLabel";
import Alert from "react-bootstrap/Alert";
import { forgotPassword } from "../../../api/forgotPassword";

const schema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
});

export default function ForgotPassword({
  setActiveComponent,
}: {
  setActiveComponent: Dispatch<SetStateAction<string>>;
}) {
  const [submitted, setSubmitted] = useState("");

  return (
    <>
      <div className="text-center">
        <h1>Forgot Password</h1>
        <p className="lead">
          Enter your username and we'll send you a link to reset your password.
        </p>
      </div>

      <Formik
        initialValues={{ username: "" }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          forgotPassword(values.username);
          setSubmitted(values.username);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-4" controlId="formUsername">
              <div className="d-flex justify-content-between">
                <FormLabel className="text-start">Username</FormLabel>
                <FormLabel
                  className="text-end link"
                  onClick={() => setActiveComponent("ForgotUsername")}
                >
                  Forgot Username
                </FormLabel>
              </div>
              <Form.Control
                type="text"
                name="username"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.username && !!errors.username}
              />
              <Form.Control.Feedback type="invalid">
                {errors.username}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              variant="success"
              type="submit"
              disabled={isSubmitting}
              size="lg"
              className="w-100"
            >
              Reset Your Password
            </Button>
            {submitted !== "" && (
              <Alert variant="success" className="text-center mt-4">
                If an account exists for {submitted}, an e-mail or text will be
                sent with further instructions.
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}
