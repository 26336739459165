import Button from "react-bootstrap/esm/Button";
import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import ToggleButton from "react-bootstrap/esm/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/esm/ToggleButtonGroup";
import Form from "react-bootstrap/Form";
import ReportsTable from "../../components/tables/reportsTable";
import { ColumnDef } from "@tanstack/react-table";
import { fuzzySort } from "../../components/tables/fuzzySort";
import { CatchReportPeriod } from "../../models/catchReportPeriod";
import { getCatchReportPeriods } from "../../api/getCatchReportPeriods";
import { getFormattedDateMMDDYYYY } from "../../helpers/getFormattedDateMMDDYYYY";
import { CatchReport } from "../../models/catchReport";
import { listCatchReportsAdmin } from "../../api/listCatchReportsAdmin";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/esm/Tooltip";
import Badge from "react-bootstrap/Badge";
import {
  FaFileDownload,
  FaExclamationCircle,
  FaExternalLinkAlt,
  FaLock,
  FaLockOpen,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { putCatchReportReview } from "../../api/putCatchReportReview";
import { apiSuccess } from "../../helpers/apiSuccess";
import { putCatchReportUnreview } from "../../api/putCatchReportUnreview";
import {
  getOrganizationID,
  getRoles,
  getTribalOrganization,
} from "../../helpers/authHelper";
import { getCatchReportExportPDF } from "../../api/getCatchReportExportPDF";
import Spinner from "react-bootstrap/Spinner";
import { listReportUnsubmitted } from "../../api/listReportUnsubmitted";
import Accordion from "react-bootstrap/esm/Accordion";
import SelectOrganization from "../../components/formControls/selectOrganization";
import { getCatchReportPeriodsAdmin } from "../../api/getCatchReportPeriodsAdmin";
import { getFormattedDateYYYYMMDD } from "../../helpers/getFormattedDateYYYYMMDD";
import { listReportExcluded } from "../../api/listReportExcluded";

export default function AdminReports() {
  const [catchReportPeriods, setCatchReportPeriods] = useState<
    CatchReportPeriod[]
  >([]);
  const [catchReports, setCatchReports] = useState<CatchReport[]>([]);
  const [catchReportsUnsubmitted, setCatchReportsUnsubmitted] = useState<
    CatchReport[]
  >([]);
  const [catchReportsExcluded, setCatchReportsExcluded] = useState<
    CatchReport[]
  >([]);
  const [tableCatchReports, setTableCatchReports] = useState<CatchReport[]>([]);
  const [selectedCatchReportIndex, setSelectedCatchReportIndex] = useState(0);
  const [reviewType, setReviewType] = useState<Boolean | undefined>();
  const [isDownloading, setIsDownloading] = useState(-1);
  const [isLoadingReports, setIsLoadingReports] = useState(false);
  const [beginDate, setBeginDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedOrg, setSelectedOrg] = useState(
    getRoles().includes("CORA_Admin") ? 2 : getOrganizationID()
  );

  useEffect(() => {
    if (getRoles().includes("CORA_Admin")) {
      const fetch = async () => {
        const resCatchReportPeriods = await getCatchReportPeriodsAdmin({
          organizationID: selectedOrg,
          excludeSubmitted: false,
        });

        if (resCatchReportPeriods) {
          setCatchReportPeriods([...resCatchReportPeriods]);
          setBeginDate(
            getFormattedDateYYYYMMDD(
              resCatchReportPeriods[selectedCatchReportIndex].beginDate
            )
          );
          setEndDate(
            getFormattedDateYYYYMMDD(
              resCatchReportPeriods[selectedCatchReportIndex].endDate
            )
          );
        }
      };
      fetch();
    } else {
      const fetch = async () => {
        const resCatchReportPeriods = await getCatchReportPeriods({
          excludeSubmitted: false,
        });

        if (resCatchReportPeriods) {
          setCatchReportPeriods([...resCatchReportPeriods]);
          setBeginDate(
            getFormattedDateYYYYMMDD(
              resCatchReportPeriods[selectedCatchReportIndex].beginDate
            )
          );
          setEndDate(
            getFormattedDateYYYYMMDD(
              resCatchReportPeriods[selectedCatchReportIndex].endDate
            )
          );
        }
      };
      fetch();
    }
  }, [selectedOrg]);

  // When filter review changes, fetch the new trips and update the table
  useEffect(() => {
    getCatchReports();
  }, [beginDate, endDate, selectedCatchReportIndex, catchReportPeriods]);

  const handleReviewedType = (value: number) => {
    let type;

    switch (value) {
      // Show All
      case 1:
        type = undefined;
        break;

      // Show Un-Reviewed
      case 2:
        type = false;
        break;

      // Show Reviewed
      case 3:
        type = true;
        break;
    }

    setReviewType(type);
    setTableCatchReports(filterCatchReportsByReview(catchReports, type));
  };

  const getCatchReports = async () => {
    const allOfTime =
      beginDate === "All of time" && endDate === "All of time" ? true : false;

    if (beginDate.length && endDate.length) {
      setIsLoadingReports(true);

      if (!allOfTime && selectedCatchReportIndex === -2) {
        setSelectedCatchReportIndex(-1);
      }

      //If the end / start date differ from reporting period, set reporting period to blank
      if (catchReportPeriods[selectedCatchReportIndex]) {
        const isBeginDateSame =
          beginDate ===
          getFormattedDateYYYYMMDD(
            catchReportPeriods[selectedCatchReportIndex].beginDate
          );
        const isEndDateSame =
          endDate ===
          getFormattedDateYYYYMMDD(
            catchReportPeriods[selectedCatchReportIndex].endDate
          );

        if (!isBeginDateSame || !isEndDateSame) {
          setSelectedCatchReportIndex(-1);
        }
      }

      const beginDateValue = isNaN(new Date(beginDate).getTime())
        ? ""
        : beginDate;
      const endDateValue = isNaN(new Date(endDate).getTime()) ? "" : endDate;

      const resCatchReports = await listCatchReportsAdmin({
        beginDate: beginDateValue,
        endDate: endDateValue,
        organizationID: selectedOrg,
        submitted: true,
        reviewed: "",
      });

      const resUnSubmittedReports = allOfTime
        ? false
        : await listReportUnsubmitted({
            beginDate: beginDateValue,
            endDate: endDateValue,
            organizationID: selectedOrg,
            tripTypeID: 1,
          });

      const resExcludedReports = await listReportExcluded({
        beginDate: beginDateValue,
        endDate: endDateValue,
        organizationID: selectedOrg,
        tripTypeID: 1,
      });

      if (resCatchReports) {
        setCatchReports([...resCatchReports]);
        setTableCatchReports(
          filterCatchReportsByReview(resCatchReports, reviewType)
        );
      }

      if (resUnSubmittedReports) {
        setCatchReportsUnsubmitted(resUnSubmittedReports);
      } else if (allOfTime) {
        setCatchReportsUnsubmitted([]);
      }

      if (resExcludedReports) {
        setCatchReportsExcluded(resExcludedReports);
      } else if (allOfTime) {
        setCatchReportsExcluded([]);
      }

      setIsLoadingReports(false);
    }
  };

  const handleSelectChange = async (event: any) => {
    const selectedOption = parseInt(event.target.value);
    setSelectedCatchReportIndex(selectedOption);

    if (selectedOption === -2) {
      setBeginDate("All of time");
      setEndDate("All of time");
      return;
    }

    setBeginDate(
      getFormattedDateYYYYMMDD(catchReportPeriods[selectedOption].beginDate)
    );

    setEndDate(
      getFormattedDateYYYYMMDD(catchReportPeriods[selectedOption].endDate)
    );
  };

  const handleReviewCatchReport = async (catchReportID: number) => {
    const response = await putCatchReportReview(catchReportID);
    if (apiSuccess(response.status)) filterCatchReportsByID(catchReportID);
  };

  const handleUnreviewCatchReport = async (catchReportID: number) => {
    const response = await putCatchReportUnreview(catchReportID);
    if (apiSuccess(response.status)) filterCatchReportsByID(catchReportID);
  };

  const filterCatchReportsByID = (catchReportID: number) => {
    const temp = catchReports.map((obj: CatchReport) => {
      if (obj.id === catchReportID) {
        return { ...obj, reviewed: !obj.reviewed };
      }
      return obj;
    });

    setCatchReports([...temp]);
    setTableCatchReports(filterCatchReportsByReview(temp, reviewType));
  };

  const filterCatchReportsByReview = (allCatchReports: any, reviewed: any) => {
    if (reviewed !== undefined) {
      const temp = allCatchReports.filter(
        (obj: CatchReport) => obj.reviewed === reviewed
      );

      return [...temp];
    } else {
      return [...allCatchReports];
    }
  };

  const downloadPDF = async (catchReportID: number) => {
    setIsDownloading(catchReportID);
    await getCatchReportExportPDF(catchReportID);
    setIsDownloading(-1);
  };

  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorFn: (row) => row,
        id: "displayName",
        header: "Fisher Name",
        cell: (info) => {
          return (
            <span>
              <Link to={`/fisher/${info.getValue().fisherID}`} target="_blank">
                <strong>{info.getValue().displayName}</strong>
              </Link>
              {info.getValue().editInProgress && (
                <Badge className="ms-2" bg="warning">
                  Pending Edits
                </Badge>
              )}
            </span>
          );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row: any) => {
          return `${getFormattedDateMMDDYYYY(
            row.beginDate
          )} - ${getFormattedDateMMDDYYYY(row.endDate)}`;
        },
        id: "reportingPeriod",
        header: "Reporting Period",
        cell: (info) => {
          return <strong>{info.getValue()}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.submittedDate,
        id: "submittedDate",
        header: "Submitted",
        cell: (info) => {
          return <strong>{getFormattedDateMMDDYYYY(info.getValue())}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.trips.length,
        id: "tripNum",
        header: "Trips",
        cell: (info) => {
          return <strong>{info.getValue()}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.helpers.length,
        id: "helperNum",
        header: "Helpers",
        cell: (info) => {
          return <strong>{info.getValue()}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row: any) => row,
        id: "actions",
        header: () => {
          return <div className="ms-auto">Actions</div>;
        },
        cell: (info: any) => {
          return (
            <div className="users-table d-flex justify-content-end">
              {/* Edit Button */}

              <OverlayTrigger
                placement="left"
                overlay={<Tooltip>View Catch Report</Tooltip>}
              >
                <Link
                  to={`/reports/edit/${info.getValue().id}`}
                  state={{ catchReport: info.getValue() }}
                  target="_blank"
                >
                  <Button
                    variant="secondary"
                    className="text-light ms-2"
                    size="sm"
                  >
                    <FaExternalLinkAlt />
                  </Button>
                </Link>
              </OverlayTrigger>

              {/* Print */}
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip>Download Catch Report</Tooltip>}
              >
                <Button
                  variant="secondary"
                  className="text-light ms-2"
                  size="sm"
                  onClick={() => downloadPDF(info.getValue().id)}
                >
                  {isDownloading === info.getValue().id ? (
                    <Spinner animation="border" variant="light" size="sm" />
                  ) : (
                    <FaFileDownload />
                  )}
                </Button>
              </OverlayTrigger>

              {/* If it's been reviewed or not */}
              {info.getValue().reviewed === false ? (
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip>Unlocked (Click to lock)</Tooltip>}
                >
                  <Button
                    variant="outline-gray"
                    className="text-gray ms-2"
                    size="sm"
                    onClick={() => handleReviewCatchReport(info.getValue().id)}
                  >
                    <FaLockOpen />
                  </Button>
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip>Locked (Click to unlock)</Tooltip>}
                >
                  <Button
                    variant="secondary"
                    className="text-light ms-2"
                    size="sm"
                    onClick={() =>
                      handleUnreviewCatchReport(info.getValue().id)
                    }
                  >
                    <FaLock />
                  </Button>
                </OverlayTrigger>
              )}
            </div>
          );
        },
        footer: (props: any) => props.column.id,
        enableColumnFilter: false,
      },
    ],
    [tableCatchReports, isDownloading]
  );

  const reportsUnsubmittedColumns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorFn: (row) => row,
        id: "displayName",
        header: "Fisher Name",
        cell: (info) => {
          return (
            <span>
              <Link to={`/fisher/${info.getValue().UserID}`} target="_blank">
                <strong>{info.getValue().DisplayName}</strong>
              </Link>
            </span>
          );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.CommercialTripCount_Complete,
        id: "tripsComplete",
        header: "Complete Trips",
        cell: (info) => {
          return <strong>{info.getValue()}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.CommercialTripCount_Partial,
        id: "tripsPartial",
        header: "Incomplete Trips",
        cell: (info) => {
          return <strong>{info.getValue()}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.LastLoginDate,
        id: "lastLoginDate",
        header: "Last Login",
        cell: (info) => {
          return <strong>{getFormattedDateMMDDYYYY(info.getValue())}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
    ],
    [tableCatchReports, isDownloading]
  );

  const reportsExcludedColumns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorFn: (row) => row,
        id: "displayName",
        header: "Fisher Name",
        cell: (info) => {
          return (
            <span>
              <Link to={`/fisher/${info.getValue().UserID}`} target="_blank">
                <strong>{info.getValue().DisplayName}</strong>
              </Link>
            </span>
          );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.ExcludedTripCount,
        id: "excludedTrips",
        header: "Excluded Trips",
        cell: (info) => {
          return <strong>{info.getValue()}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.ExcludedTripCount_Complete,
        id: "tripsComplete",
        header: "Complete",
        cell: (info) => {
          return <strong>{info.getValue()}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.ExcludedTripCount_Partial,
        id: "tripsPartial",
        header: "Incomplete",
        cell: (info) => {
          return <strong>{info.getValue()}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.LastLoginDate,
        id: "lastLoginDate",
        header: "Last Login",
        cell: (info) => {
          return <strong>{getFormattedDateMMDDYYYY(info.getValue())}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
    ],
    [tableCatchReports, isDownloading]
  );

  return (
    <div className="py-5">
      <Container fluid>
        <h1 className="text-primary mb-5 display-4 fw-bold">
          Submitted Catch Reports ({catchReports.length})
        </h1>
        <p>
          A <strong>locked</strong> catch report cannot be edited by the fisher.
          Both locked and unlocked reports will be included when exporting catch
          reports.
        </p>

        <div className="form-card">
          <Row className=" gx-3">
            {/* Only show this for CORA */}
            {getRoles().includes("CORA_Admin") && (
              <Col xs="auto" lg={3}>
                <SelectOrganization
                  includeCORA={false}
                  allTribes={true}
                  value={selectedOrg}
                  onChange={(e: any) => setSelectedOrg(e.target.value)}
                />
              </Col>
            )}
            <Col xs="auto">
              <Form.Group className="mb-3">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={beginDate}
                  onChange={(e) => setBeginDate(e.currentTarget.value)}
                  isInvalid={
                    selectedCatchReportIndex !== -2 &&
                    isNaN(new Date(beginDate).getTime())
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid date.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs="auto">
              <Form.Group className="mb-3">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.currentTarget.value)}
                  isInvalid={
                    selectedCatchReportIndex !== -2 &&
                    isNaN(new Date(endDate).getTime())
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid date.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs="auto">
              <Form.Group className="mb-3 w-auto ">
                <Form.Label>Reporting Period</Form.Label>
                <Form.Select
                  onChange={handleSelectChange}
                  aria-label="Reporting Period"
                  value={selectedCatchReportIndex}
                >
                  {catchReportPeriods.length > 0 && (
                    <option value={-2}>All of time</option>
                  )}
                  {catchReportPeriods.map((period: CatchReportPeriod, i) => (
                    <option key={i} value={i}>
                      {getFormattedDateMMDDYYYY(period.beginDate)} -{" "}
                      {getFormattedDateMMDDYYYY(period.endDate)}
                    </option>
                  ))}
                  {catchReportPeriods.length > 0 && (
                    <option value={-1}>---</option>
                  )}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs="auto">
              <Form.Group className="mb-3">
                <Form.Label>Filter Locked Reports</Form.Label>
                <ToggleButtonGroup
                  className="w-100 btn-group-secondary"
                  type="radio"
                  name="filterReviewedToggle"
                  defaultValue={1}
                >
                  <ToggleButton
                    id="allToggle"
                    variant="outline-secondary"
                    value={1}
                    onClick={() => handleReviewedType(1)}
                  >
                    Show all
                  </ToggleButton>
                  <ToggleButton
                    id="unReviewedToggle"
                    variant="outline-secondary"
                    value={2}
                    onClick={() => handleReviewedType(2)}
                  >
                    Show Unlocked
                  </ToggleButton>
                  <ToggleButton
                    id="reviewedToggle"
                    variant="outline-secondary"
                    value={3}
                    onClick={() => handleReviewedType(3)}
                  >
                    Show Locked
                  </ToggleButton>
                </ToggleButtonGroup>
              </Form.Group>
            </Col>
          </Row>
        </div>

        {isLoadingReports ? (
          <div className="d-flex justify-content-center align-items-center mb-5">
            <Spinner animation="border" variant="primary" className="ms-2" />
            <span className="h3 ms-3 mb-0 text-primary">
              Loading Reports...
            </span>
          </div>
        ) : (
          <>
            <ReportsTable
              className="mb-5"
              columns={columns}
              catchReports={tableCatchReports}
            />

            {catchReportPeriods.length > 0 && (
              <>
                {catchReportsUnsubmitted.length > 0 && (
                  <>
                    <h3 className="fw-normal pt-5 pb-0">
                      {/* <FaExclamationCircle className="text-warning me-3 fs-3" /> */}
                      Unsubmitted Catch Reports (
                      {catchReportsUnsubmitted.length})
                    </h3>
                    <p className="mb-4 fw-semibold text-body-tertiary">
                      Fishers with unsubmitted catch reports
                    </p>

                    <Accordion className="mb-4 submitted-catch-reports-accordion">
                      <Accordion.Item eventKey="0" className="mb-2 shadow-sm">
                        <Accordion.Header>
                          {getFormattedDateMMDDYYYY(beginDate)}
                          {" - "}
                          {getFormattedDateMMDDYYYY(endDate)}
                        </Accordion.Header>
                        <Accordion.Body>
                          <ReportsTable
                            columns={reportsUnsubmittedColumns}
                            catchReports={catchReportsUnsubmitted}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                )}

                {catchReportsExcluded.length > 0 && (
                  <>
                    <h3 className="fw-normal pt-5 pb-0">
                      Excluded Trips ({catchReportsExcluded.length})
                    </h3>
                    <p className="mb-4 fw-semibold text-body-tertiary">
                      Fishers with trips not included on a previously submitted
                      report
                    </p>
                    <Accordion className="mb-4 submitted-catch-reports-accordion">
                      <Accordion.Item eventKey="0" className="mb-2 shadow-sm">
                        <Accordion.Header>
                          {getFormattedDateMMDDYYYY(beginDate)}
                          {" - "}
                          {getFormattedDateMMDDYYYY(endDate)}
                        </Accordion.Header>
                        <Accordion.Body>
                          <ReportsTable
                            columns={reportsExcludedColumns}
                            catchReports={catchReportsExcluded}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Container>
    </div>
  );
}
