import { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/esm/Button";
import { onetimePassword } from "../../helpers/onetimePassword";
import passwordReg from "../../helpers/passwordReg";
import { setPassword } from "../../api/setPassword";
import { apiSuccess } from "../../helpers/apiSuccess";
import Alert from "react-bootstrap/Alert";
import { getUserID } from "../../helpers/authHelper";
import { changePassword } from "../../api/changePassword";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function EditPassword({
  userID,
  handleRequirePasswordChange,
}: any) {
  const [newPassword, setNewPassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    show: false,
    variant: "",
    message: "",
  });

  const onetimePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password required")
      .min(10, "Minimum ten characters, at least one letter and one number")
      .test(
        "isValidPass",
        "At least one letter and one number",
        (value: any, context) => {
          return passwordReg(value, context);
        }
      ),
  });

  const currentPasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Old Password is required"),
    newPassword: Yup.string()
      .required("Password required")
      .min(10, "Minimum ten characters, at least one letter and one number")
      .test(
        "isValidPass",
        "At least one letter and one number",
        (value: any, context) => {
          return passwordReg(value, context);
        }
      ),
  });

  return (
    <div className="form-card">
      {userID === getUserID() ? (
        // Edit self password
        <Formik
          initialValues={{ currentPassword: "", newPassword: "" }}
          enableReinitialize
          validationSchema={currentPasswordSchema}
          onSubmit={(values) => {
            const setPass = async () => {
              const response = await changePassword({
                current: values.currentPassword,
                new: values.newPassword,
              });

              if (apiSuccess(response.status)) {
                setAlertMessage({
                  show: true,
                  variant: "success",
                  message: "Password changed successfully",
                });
              } else {
                setAlertMessage({
                  show: true,
                  variant: "danger",
                  message: `Error, ${response.data.message}`,
                });
              }
            };
            setPass();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form
              id="reset-password"
              noValidate
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <Row className=" mb-3">
                <Form.Group
                  as={Col}
                  lg={true}
                  controlId="formCurrentPassword"
                  className="mb-3 mb-lg-0"
                >
                  <Form.Label>Current password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showPass ? "text" : "password"}
                      name="currentPassword"
                      value={values.currentPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        touched.currentPassword && !!errors.currentPassword
                      }
                    />
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="password-tooltip">
                          {showPass ? "Hide password" : "Show password"}
                        </Tooltip>
                      }
                    >
                      <InputGroup.Text onClick={() => setShowPass(!showPass)}>
                        <>{showPass ? <FaEyeSlash /> : <FaEye />}</>
                      </InputGroup.Text>
                    </OverlayTrigger>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {errors.currentPassword}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  lg={true}
                  controlId="formNewPassword"
                  className="mb-3 mb-lg-0"
                >
                  <Form.Label>New password*</Form.Label>
                  <Form.Control
                    type="text"
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.newPassword && !!errors.newPassword}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.newPassword}
                  </Form.Control.Feedback>
                </Form.Group>
                <Col lg>
                  <Button
                    variant="secondary"
                    className="w-100 form-btn"
                    type="submit"
                  >
                    Change Password
                  </Button>
                </Col>
              </Row>
              <p>
                *Password minimum length 10 characters with at least one letter
                and one number
              </p>
              {alertMessage.show && (
                <Alert variant={alertMessage.variant}>
                  {alertMessage.message}
                </Alert>
              )}
            </Form>
          )}
        </Formik>
      ) : (
        //Edit users password
        <Formik
          initialValues={{
            password: newPassword,
            requirePasswordChangeCheck: true,
          }}
          validationSchema={onetimePasswordSchema}
          onSubmit={(values) => {
            const setPass = async () => {
              const response = await setPassword({
                userID: userID,
                new: values.password,
                requireChange: values.requirePasswordChangeCheck,
              });
              if (apiSuccess(response.status)) {
                handleRequirePasswordChange(values.requirePasswordChangeCheck);
                setAlertMessage({
                  show: true,
                  variant: "success",
                  message: "Password changed successfully",
                });
              } else {
                setAlertMessage({
                  show: true,
                  variant: "danger",
                  message: `Error, ${response.data.message}`,
                });
              }
            };
            setPass();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => (
            <Form
              id="reset-password"
              noValidate
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <Row className="align-items-center mb-3">
                <Form.Group
                  as={Col}
                  lg={true}
                  controlId="formNewAdminPassword"
                  className="mb-3 mb-lg-0"
                >
                  <Form.Label>New password*</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.password && !!errors.password}
                    />

                    <Button
                      onClick={() => {
                        setNewPassword(onetimePassword());
                        resetForm({
                          values: {
                            password: onetimePassword(),
                            requirePasswordChangeCheck:
                              values.requirePasswordChangeCheck,
                          },
                        });
                      }}
                      variant="gray"
                      className=""
                    >
                      Generate Random
                    </Button>
                  </InputGroup>

                  <span className="small">
                    *Password minimum length 10 characters with at least one
                    letter and one number
                  </span>

                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Col lg>
                  <Form.Check
                    type="checkbox"
                    name="requirePasswordChangeCheck"
                    id="requirePasswordChangeCheck"
                    label="Require password change"
                    checked={values.requirePasswordChangeCheck}
                    onChange={handleChange}
                  ></Form.Check>
                  <p className="mb-0 small">
                    You can uncheck this when administratively logging in with
                    this account to enter trips on a fishers' behalf
                  </p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg>
                  <Button variant="secondary" className="w-100" type="submit">
                    Change Password
                  </Button>
                </Col>
              </Row>
              {alertMessage.show && (
                <Alert variant={alertMessage.variant}>
                  {alertMessage.message}
                </Alert>
              )}
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
