import styled from "styled-components";
const StyledHR = styled.hr`
opacity: 100%;
margin: 0;
height: 2px;
width: 100%;
border: var(--primary-color) 1px solid;
background-color: var(--primary-color);
border-radius 1rem;
`;

export default function OrHR() {
  return (
    <div className="d-flex w-100 justify-content-center align-items-center">
      <StyledHR />
      <span className="text-primary fs-2 text-center bg-white p-3 z-10">
        OR
      </span>
      <StyledHR />
    </div>
  );
}
