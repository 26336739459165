import axios from "axios";

export const getCatchReportExportHTML = async (catchReportID: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/CatchReport/Export-HTML?CatchReportID=${catchReportID}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
