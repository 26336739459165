import axios from "axios";
import { convertObjectToUrlParams } from "../helpers/convertObjToUrlParams";

export interface listTripParams {
  catchReportID?: any;
  tripTypeID?: number;
  complete?: boolean;
}

export const listTrips = async (data: listTripParams) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Trip/list?${
        data.catchReportID ? "CatchReportID=" + data.catchReportID : ""
      }${data.catchReportID && data.tripTypeID ? "&" : ""}${
        data.tripTypeID ? "TripTypeID=" + data.tripTypeID : ""
      }${data.complete === true ? "&Complete=true" : ""}
      `
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};
// //
