import axios from "axios";

export const deleteTrip = async (tripID: number) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API}/Trip/trip?TripID=${tripID}`
    );
    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
