import { Formik } from "formik";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/esm/Tooltip";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import * as Yup from "yup";
import { deleteLicense } from "../../api/deleteLicense";
import { getLicenses } from "../../api/getLicenses";
import { getUserLicenses } from "../../api/getUserLicenses";
import { postLicense } from "../../api/postLicense";
import { putLicense } from "../../api/putLicense";
import { getUserID } from "../../helpers/authHelper";
import { getFormattedDateYYYYMMDD } from "../../helpers/getFormattedDateYYYYMMDD";
import { License } from "../../models/license";
import SelectLicense from "./selectLicense";

export default function EditLicenseDetails({ userID, organization }: any) {
  const thisYear = new Date().getFullYear();
  const [selectLicense, setSelectLicense] = useState("C");
  const [licenses, setLicenses] = useState<License[]>([]);
  const [licenseForm, setLicenseForm] = useState({
    licenseTypeCode: "C",
    licenseNumber: "",
    licenseYear: thisYear,
  });

  // On Load
  useEffect(() => {
    fetchLicenses();
  }, [userID]);

  // After licenses are loaded
  useEffect(() => {
    updateAddLicenseNumber("C");
  }, [licenses]);

  const updateAddLicenseNumber = (licenseTypeCode: string) => {
    const license = licenses.find(
      (obj: License) => obj.licenseTypeCode === licenseTypeCode
    );

    if (license) {
      setLicenseForm({
        ...licenseForm,
        licenseNumber: `${license.licenseNumber}`,
      });
    }
  };

  const fetchLicenses = async () => {
    if (userID === getUserID() || userID === undefined) {
      const response = await getLicenses();
      if (response) setLicenses(response);
    } else {
      const response = await getUserLicenses(userID);
      if (response) setLicenses(response);
    }
  };

  // Add License
  const addLicense = (license: any) => {
    const submit = async () => {
      if (userID === getUserID() || userID === undefined) {
        await postLicense({ userID: getUserID(), ...license });
      } else {
        await postLicense({ userID: userID, ...license });
      }

      fetchLicenses();
    };
    submit();
    resetForm();
  };

  // Remove License
  const removeLicense = (license: License) => {
    const submit = async () => {
      await deleteLicense(license.licenseID);
      fetchLicenses();
    };
    submit();
  };

  // Edit License
  const editLicense = (license: License) => {
    const submit = async () => {
      await putLicense({
        ...license,
        licenseNumber: `${
          organization.organizationCode
        }-${license.licenseNumber.replace(/\D/g, "")}${
          license.licenseTypeCode
        }`,
      });
      fetchLicenses();
    };
    submit();
  };

  // Reset Form
  const resetForm = () => {
    setLicenseForm({
      licenseTypeCode: "C",
      licenseNumber: "",
      licenseYear: thisYear,
    });

    setSelectLicense("C");
  };

  const licenseSchema = Yup.object().shape({
    licenseNumber: Yup.string().required("License Number is required"),
    licenseYear: Yup.number().required("Year is required"),
  });

  const licenseDetailsSchema = Yup.object().shape({
    licenseNumber: Yup.string().required("License Number is required"),
  });

  return (
    <div className="form-card">
      <p className="fw-bold mb-0">Current & Previous Licenses</p>

      {Array.isArray(licenses) &&
        licenses.map((license: License, i) => (
          <Formik
            key={i}
            initialValues={license}
            enableReinitialize
            validationSchema={licenseDetailsSchema}
            onSubmit={(values) => editLicense(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            }) => (
              <Form
                noValidate
                onSubmit={handleSubmit}
                autoComplete="off"
                className="border-bottom border-primary pb-2 pt-3"
              >
                <Row
                  className={
                    license.isEditing || license.isDeleting
                      ? "shadow-lg rounded px-2 py-4 my-4 mx-2 border border-primary"
                      : ""
                  }
                >
                  <Col lg={4} className="d-flex align-items-center">
                    {/* {license.licenseTypeCode === "C" && !license.isEditing && (
                      <GiFishingBoat className="color-secondary fs-3 text-primary me-2" />
                    )}
                    {license.licenseTypeCode === "S" && !license.isEditing && (
                      <GiBoatFishing className="color-secondary fs-3 text-primary me-2" />
                    )} */}

                    <SelectLicense
                      className={`${!license.isEditing ? "readOnly" : ""}`}
                      disabled={license.isEditing}
                      readOnly={!license.isEditing}
                      type="text"
                      name="licenseTypeCode"
                      value={values.licenseTypeCode}
                      onChange={(e: any) => {
                        handleChange(e);
                        let updatedValues = [...licenses];
                        updatedValues[i].licenseTypeCode = e.target.value;
                        setLicenses(updatedValues);
                      }}
                      onBlur={handleBlur}
                      isInvalid={
                        touched.licenseTypeCode && !!errors.licenseTypeCode
                      }
                    />
                  </Col>

                  <Col lg={4}>
                    <InputGroup>
                      <InputGroup.Text id="tribal-code-subsistence">
                        {organization?.organizationCode}-
                      </InputGroup.Text>
                      <Form.Control
                        // plaintext={!license.isEditing}
                        // readOnly={!license.isEditing}
                        disabled={!license.isEditing}
                        type="text"
                        name="licenseNumber"
                        value={values.licenseNumber.replace(/\D/g, "")}
                        onChange={(e) => {
                          if (
                            (e.target.value === "" ||
                              e.target.value.match(/^\d+$/)) &&
                            e.target.value.length < 6
                          ) {
                            handleChange(e);
                          }
                        }}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.licenseNumber && !!errors.licenseNumber
                        }
                      />
                      <InputGroup.Text id="tribal-code-subsistence">
                        {licenses[i].licenseTypeCode}
                      </InputGroup.Text>
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.licenseNumber}
                    </Form.Control.Feedback>
                  </Col>
                  <Col lg={4}>
                    <InputGroup className="flex-nowrap">
                      <Form.Control
                        // plaintext={!license.isEditing}
                        // readOnly={!license.isEditing}
                        disabled={!license.isEditing}
                        type="date"
                        name="activeDate"
                        value={getFormattedDateYYYYMMDD(
                          values.activeDate ?? ""
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.activeDate && !!errors.activeDate}
                      />

                      <Form.Control
                        // plaintext={!license.isEditing}
                        // readOnly={!license.isEditing}
                        disabled={!license.isEditing}
                        type="date"
                        name="expireDate"
                        value={getFormattedDateYYYYMMDD(
                          values.expireDate ?? ""
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.expireDate && !!errors.expireDate}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.activeDate}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.expireDate}
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm={12} className="pt-2">
                    {!license.isEditing && !license.isDeleting && (
                      <div className="text-lg-end">
                        <OverlayTrigger
                          placement="left"
                          overlay={<Tooltip>Edit</Tooltip>}
                        >
                          <Button
                            variant="secondary"
                            className="text-light me-2"
                            size="sm"
                            onClick={() => {
                              licenses[i].isEditing = true;
                              setLicenses([...licenses]);
                            }}
                          >
                            <FaEdit />
                            <span className="d-inline-block d-lg-none mx-2">
                              Edit
                            </span>
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="left"
                          overlay={<Tooltip>Delete</Tooltip>}
                        >
                          <Button
                            variant="danger"
                            className="text-light"
                            size="sm"
                            onClick={() => {
                              licenses[i].isDeleting = true;
                              setLicenses([...licenses]);
                            }}
                          >
                            <FaTrashAlt />
                            <span className="d-inline-block d-lg-none mx-2">
                              Remove
                            </span>
                          </Button>
                        </OverlayTrigger>
                      </div>
                    )}
                    {license.isEditing && (
                      <div className="d-flex">
                        <Button
                          variant="secondary"
                          className="w-100 me-1"
                          type="submit"
                        >
                          Save
                        </Button>
                        <Button
                          variant="warning"
                          className="w-100 ms-1"
                          onClick={() => {
                            licenses[i].isEditing = false;
                            setLicenses([...licenses]);
                            handleReset();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                    {license.isDeleting && (
                      <div className="d-flex">
                        <Button
                          variant="danger"
                          className="w-100 me-1"
                          onClick={() => {
                            removeLicense(license);
                          }}
                        >
                          Delete
                        </Button>
                        <Button
                          variant="warning"
                          className="w-100 ms-1"
                          onClick={() => {
                            licenses[i].isDeleting = false;
                            handleReset();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ))}

      <p className="fw-bold mt-4 mb-2">Add License</p>
      <Formik
        initialValues={licenseForm}
        enableReinitialize
        validationSchema={licenseSchema}
        onSubmit={(values, { resetForm }) => {
          addLicense({
            ...values,
            licenseNumber: `${
              organization.organizationCode
            }-${values.licenseNumber.replace(/\D/g, "")}${
              values.licenseTypeCode
            }`,
          });
          resetForm();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form
            noValidate
            onSubmit={handleSubmit}
            className="mb-4"
            autoComplete="off"
          >
            <Row>
              <Form.Group
                as={Col}
                sm={4}
                className="mb-3"
                controlId="formVesselType"
              >
                <Form.Label>License Type</Form.Label>
                <SelectLicense
                  type="text"
                  name="licenseTypeCode"
                  value={values.licenseTypeCode}
                  onChange={(e: any) => {
                    setSelectLicense(e.target.value);
                    handleChange(e);
                    const license = licenses.find(
                      (obj: License) => obj.licenseTypeCode === e.target.value
                    );
                    if (license)
                      values.licenseNumber = license.licenseNumber.replace(
                        /\D/g,
                        ""
                      );
                  }}
                  onBlur={handleBlur}
                  isInvalid={
                    touched.licenseTypeCode && !!errors.licenseTypeCode
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.licenseTypeCode}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                sm={4}
                className="mb-3"
                controlId="formVesselNumber"
              >
                <Form.Label>License Number</Form.Label>
                <InputGroup>
                  <InputGroup.Text id="tribal-code-subsistence">
                    {organization?.organizationCode}-
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="licenseNumber"
                    placeholder="1234"
                    value={values.licenseNumber.replace(/\D/g, "")}
                    onChange={(e) => {
                      if (
                        (e.target.value === "" ||
                          e.target.value.match(/^\d+$/)) &&
                        e.target.value.length < 6
                      ) {
                        handleChange(e);
                      }
                    }}
                    onBlur={handleBlur}
                    isInvalid={touched.licenseNumber && !!errors.licenseNumber}
                  />
                  <InputGroup.Text id="tribal-code-subsistence">
                    {selectLicense}
                  </InputGroup.Text>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors.licenseNumber}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                sm={4}
                className="mb-3"
                controlId="formVesselRegistrationYear"
              >
                <Form.Label>Active Year</Form.Label>
                <Form.Select
                  aria-label="License Year"
                  name="licenseYear"
                  value={values.licenseYear}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.licenseYear && !!errors.licenseYear}
                >
                  <option value={thisYear - 1}>{thisYear - 1}</option>
                  <option value={thisYear}>{thisYear}</option>
                  <option value={thisYear + 1}>{thisYear + 1}</option>
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                  {errors.licenseYear}
                </Form.Control.Feedback>
              </Form.Group>

              <Col sm={12} className="d-flex justify-content-center">
                <Button variant="secondary" className="w-100 " type="submit">
                  Add New License Record
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}
