import Button from "react-bootstrap/esm/Button";
import { FaPeopleCarry } from "react-icons/fa";
import { GiCaptainHatProfile } from "react-icons/gi";

export default function CrewMemberCardButton({ helper, onClick }: any) {
  return (
    <Button
      variant="background"
      className="text-dark border-light-gray text-start w-100 mb-3 p-3"
      size="sm"
      onClick={() => onClick()}
    >
      <div className="d-flex">
        <div className="d-flex justify-content-center align-items-center flex-column">
          {helper.crewCode === "C" ? (
            <GiCaptainHatProfile className="fs-1 text-secondary" />
          ) : (
            <FaPeopleCarry className="fs-1 text-primary" />
          )}

          {helper.typeDisplay}
        </div>
        <ul className="list-unstyled mb-0 ms-4">
          <li>
            <span className="lead">{helper.displayName}</span>
          </li>
          <li>
            <strong>License: </strong>
            {helper.biaLicense}
          </li>
          <li>
            <strong>Tribe: </strong>
            {helper.organizationName}
          </li>
        </ul>
      </div>
    </Button>
  );
}
