import axios from "axios";
import { getUserName } from "../helpers/authHelper";
import { createHashPassword } from "../helpers/createHashPassword";
import { hashPassword } from "../helpers/hashPassword";

export interface NewPassword {
  current: string;
  new: string;
}

export const changePassword = async (newPassword: NewPassword) => {
  const currentHashPassword = await hashPassword(
    getUserName(),
    newPassword.current
  );
  const newHashPassword = await createHashPassword(newPassword.new);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/Auth/change-password`,
      { currentHash: currentHashPassword, newHash: newHashPassword }
    );

    return response;
  } catch (error: any) {
    console.error("error", error);
    return error.response;
  }
};
