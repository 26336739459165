import React from "react";
import Container from "react-bootstrap/esm/Container";
import UsersTable from "../../components/tables/usersTable";
import { ColumnDef } from "@tanstack/react-table";
import { User, listOrganizationUsers } from "../../api/listOrganizationUsers";
import { fuzzySort } from "../../components/tables/fuzzySort";

export default function AdminAllUsers() {
  const columns = React.useMemo<ColumnDef<User, any>[]>(
    () => [
      {
        accessorFn: (row) => row.displayName,
        id: "displayName",
        header: "Name",
        cell: (info) => {
          return <strong>{info.getValue()}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.userName,
        id: "userName",
        header: "Username",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.roles,
        id: "roles",
        header: "Role",
        cell: (info) => info.getValue().replace("_", " "),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.email,
        id: "email",
        header: "Email",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      // {
      //   accessorFn: (row) => row.profile.biaNumber,
      //   id: "bia",
      //   header: "BIA Number",
      //   cell: (info) => info.getValue(),
      //   footer: (props) => props.column.id,
      //   filterFn: "fuzzy",
      //   sortingFn: fuzzySort,
      // },

      {
        accessorFn: (row) => row.profile.organizationCodeNumber,
        id: "code",
        header: "Tribal Code",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.status,
        id: "status",
        header: "Status",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
    ],
    []
  );
  return (
    <div className="py-5">
      <Container fluid>
        <h1 className="text-primary mb-5">Users</h1>
        <UsersTable
          columns={columns}
          lookupList={listOrganizationUsers}
          role={"user"}
          unaffiliated
        />
      </Container>
    </div>
  );
}
