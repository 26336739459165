import { useEffect, useImperativeHandle, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaPeopleCarry, FaTrashAlt, FaUserPlus } from "react-icons/fa";
import Table from "react-bootstrap/esm/Table";
import Tooltip from "react-bootstrap/esm/Tooltip";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import { Field, Formik } from "formik";
import Alert from "react-bootstrap/Alert";
import { Organization } from "../../api/getOrganization";
import { lookupAllTribes } from "../../api/LookupAllTribes";
import { getCrewSearchAll } from "../../api/getCrewSearchAll";
import { GiCaptainHatProfile } from "react-icons/gi";
import CrewMemberCardButton from "../CrewMemberCardButton";
import { getCrewRecent } from "../../api/getCrewRecent";
import { getUserID } from "../../helpers/authHelper";
import { arraysOfObjectsEqual } from "../../helpers/objectsEqual";

export default function EditCatchReportCrew({
  handleSetCrewMembers,
  showRecentCrew = true,
  readOnly = false,
  crew = [],
}: any) {
  const [addedHelpers, setAddedHelpers] = useState<any[]>(crew);
  const [helpersSearchList, setHelpersSearchList] = useState<any[]>([]);
  const [noCrewFound, setNoCrewFound] = useState(false);
  const [tribes, setTribes] = useState<Organization[]>([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [recentCrew, setRecentCrew] = useState<any[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const resTribes = await lookupAllTribes();
      if (resTribes) setTribes(resTribes);

      if (showRecentCrew) {
        const resRecentCrew = await getCrewRecent(getUserID());
        if (resRecentCrew) setRecentCrew(resRecentCrew);
      }
    };
    fetch();
  }, [showRecentCrew]);

  useEffect(() => {
    handleSetCrewMembers(addedHelpers);
  }, [addedHelpers]);

  useEffect(() => {
    if (!arraysOfObjectsEqual(crew, addedHelpers)) setAddedHelpers(crew);
  }, [crew]);

  const addCrewMember = (helper: any) => {
    // Check if crew member is not already in crew
    if (!addedHelpers.some((c) => c.id === helper.id)) {
      // Add crew member
      const newCrew = [...addedHelpers, helper];
      setAddedHelpers(newCrew);
      setHelpersSearchList([]);
    }
  };

  const removeCrewMember = (helper: any) => {
    const newCrew = addedHelpers.filter((c) => c.id !== helper.id);
    setAddedHelpers(newCrew);
  };

  return (
    <>
      {addedHelpers.length ? (
        <div>
          <div className="d-lg-none">
            {addedHelpers.map((c, i) => (
              <div key={i}>
                <div className="text-start w-100 mb-3 p-3 bg-light shadow-sm p-3 rounded">
                  <div className="d-flex">
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      {c.crewCode === "C" ? (
                        <GiCaptainHatProfile className="fs-1 text-secondary" />
                      ) : (
                        <FaPeopleCarry className="fs-1 text-primary" />
                      )}

                      <span>{c.typeDisplay}</span>
                    </div>
                    <ul className="list-unstyled mb-0 ms-4 w-100">
                      <li>
                        <div className="d-flex justify-content-between w-100">
                          <span className="lead">{c.displayName}</span>
                          {!readOnly && (
                            <Button
                              variant="danger"
                              onClick={() => removeCrewMember(c)}
                            >
                              <FaTrashAlt />
                            </Button>
                          )}
                        </div>
                      </li>
                      <li>
                        <strong>License: </strong>
                        {c.commercialLicense}
                      </li>
                      <li>
                        <strong>Tribe: </strong>
                        {c.organizationName}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-none d-lg-block form-card">
            <Table striped hover responsive className="trips-table">
              <thead>
                <tr className="fw-bold">
                  <td>Name</td>
                  <td>License</td>
                  {!readOnly && (
                    <td className="text-end">Remove Crew Member</td>
                  )}
                </tr>
              </thead>
              <tbody>
                {addedHelpers.map((c, i) => (
                  <tr key={i}>
                    <td>{c.displayName}</td>
                    <td>{c.commercialLicense}</td>
                    {!readOnly && (
                      <td className="text-end">
                        <OverlayTrigger
                          placement="left"
                          overlay={<Tooltip>Remove Crew Member</Tooltip>}
                        >
                          <Button
                            variant="danger"
                            className="text-light ms-2"
                            size="sm"
                            onClick={() => removeCrewMember(c)}
                          >
                            <FaTrashAlt />
                          </Button>
                        </OverlayTrigger>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <div className="text-center text-gray mt-3 form-card">
          <h2>No Crew Added</h2>
          {!readOnly && <p className="lead">Add crew using the search below</p>}
        </div>
      )}

      {recentCrew.length > 0 && showRecentCrew && (
        <div className="mt-4">
          <p className="fw-bold mb-1">Recent crew members</p>

          <div className="form-card">
            <Table striped hover responsive className="trips-table">
              <thead>
                <tr className="fw-bold">
                  <td>Name</td>
                  <td>License</td>
                  <td className="text-end">Add crew member</td>
                </tr>
              </thead>
              <tbody>
                {recentCrew.map((c, i) => (
                  <tr key={i}>
                    <td>{c.displayName}</td>
                    <td>{c.commercialLicense}</td>
                    <td className="text-end">
                      <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip>Add Crew Member</Tooltip>}
                      >
                        <Button
                          variant="success"
                          className="text-light ms-2"
                          size="sm"
                          onClick={() => addCrewMember(c)}
                        >
                          <FaUserPlus />
                        </Button>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      )}

      {!readOnly && (
        <div className="mt-4">
          <p className="fw-bold mb-1">Search for a crew member</p>
          <Formik
            enableReinitialize
            initialValues={{
              name: "",
              licenseNumber: "",
              crewCodes: "",
              organizationID: -1,
            }}
            onSubmit={async (values) => {
              let response = await getCrewSearchAll(values);
              setHasSearched(true);
              if (response) {
                setHelpersSearchList(response);
                setNoCrewFound(response.length === 0);
              }
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <Form
                noValidate
                onSubmit={handleSubmit}
                autoComplete="off"
                className="form-card"
              >
                <Row>
                  <Form.Group as={Col} lg={4} className="mb-3">
                    <Form.Label>Search by name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg={4} className="mb-3">
                    <Form.Label>Search by license</Form.Label>
                    <Form.Control
                      type="text"
                      name="licenseNumber"
                      value={values.licenseNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>

                  <Form.Group as={Col} lg={4} className="mb-3">
                    <Form.Label>Tribe</Form.Label>
                    <Form.Select
                      name="organizationID"
                      value={values.organizationID}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      aria-label="Default select example"
                    >
                      <option value={-1}>All Tribes</option>
                      {tribes.map((tribe: any, i) => (
                        <option key={i} value={tribe.id}>
                          {tribe.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group role="group" as={Col} lg={8} className="mb-3">
                    <Form.Label>User Type</Form.Label>
                    <div className="inline-radio">
                      <Form.Check
                        inline
                        name="crewCodes"
                        type="radio"
                        value=""
                        id={`radio-helpers-captains`}
                        label="Helpers & Captains"
                        as={Field}
                      />
                      <Form.Check
                        inline
                        name="crewCodes"
                        type="radio"
                        value="H"
                        id={`radio-helpers`}
                        label="Helpers"
                        as={Field}
                      />
                      <Form.Check
                        inline
                        name="crewCodes"
                        type="radio"
                        value="C"
                        id={`radio-captains`}
                        label="Captains"
                        as={Field}
                      />
                    </div>
                  </Form.Group>

                  <Col lg={4}>
                    <Button
                      variant="secondary"
                      className="w-100 form-btn-lg"
                      type="submit"
                    >
                      Search
                    </Button>
                  </Col>
                </Row>

                {hasSearched && (
                  <>
                    <hr className="mb-4" />
                    <div className="mb-5">
                      <p className="fw-bold">Search Results</p>
                      {helpersSearchList.map((helper, i) => (
                        <div key={i}>
                          {/* Mobile */}
                          <CrewMemberCardButton
                            helper={helper}
                            onClick={() => {
                              addCrewMember(helper);
                              values.licenseNumber = "";
                              values.name = "";
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    {noCrewFound && (
                      <Alert variant="warning">No search results</Alert>
                    )}
                  </>
                )}
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}
