import { useFormik } from "formik";
import { useImperativeHandle } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/esm/Form";
import InputGroup from "react-bootstrap/esm/InputGroup";
import Row from "react-bootstrap/esm/Row";
import * as Yup from "yup";
import { convertNumericStringToInt } from "../../helpers/convertNumericStringsToInts";
import { getGearMeshSizes } from "../../helpers/getGearMeshSizes";
import { removeUndefined } from "../../helpers/removeUndefined";
import { MeshSize } from "../../models/meshSize";
import { TripEffort } from "../../models/tripEffort";

export default function GearAttributes({
  gear,
  meshSizes,
  effort,
  updateEffort: updateGear,
  refID,
}: any) {
  // Submit form from parent component
  useImperativeHandle(refID, () => ({
    Submit: async () => {
      await formik.submitForm();
      const errors = await formik.validateForm();
      const gearEffort = removeUndefined(
        convertNumericStringToInt(formik.values)
      );

      const data: TripEffort = {
        validated: Object.keys(errors).length === 0,
        ...gearEffort,
      };

      updateGear(data);
    },
  }));

  const attributeByGear = {
    // Required
    meshSize: ["GL", "GS", "GX", "TN"], // Also Trap Size
    meshLength: ["PS", "BS"],
    height: ["PS", "BS"],
    // Preferred
    meshDeep: ["GL"],
    netHeight: ["TN"],
  };

  const formik = useFormik({
    initialValues: {
      gearKey: gear.code,
      meshSizeID: effort.meshSizeID ?? undefined,
      // meshSize: effort.meshSize ?? undefined,
      // meshName: effort.meshName ?? undefined,
      meshLength: effort.meshLength ?? undefined,
      trapSize: effort.trapSize ?? undefined,
      meshDeep: effort.meshDeep ?? undefined,
      netHeight: effort.netHeight ?? undefined,
      height: effort.height ?? undefined,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      meshSizeID: Yup.string()
        .when("gearKey", {
          is: "GL",
          then: Yup.string().required("Please select an option"),
        })
        .when("gearKey", {
          is: "GS",
          then: Yup.string().required("Please select an option"),
        })
        .when("gearKey", {
          is: "GX",
          then: Yup.string().required("Please select an option"),
        })
        .when("gearKey", {
          is: "TN",
          then: Yup.string().required("Please select an option"),
        }),
      meshLength: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .when("gearKey", {
          is: "PS",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required")
            .integer("Net Length must be a whole number")
            .min(40, "Net Length has a minimum of 40 feet")
            .max(6500, "Net Length has a maximum of 6,500 feet"),
        })
        .when("gearKey", {
          is: "BS",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required")
            .integer("Net Length must be a whole number")
            .min(1, "Net Length has a minimum of 1 feet")
            .max(500, "Net Length has a maximum of 500 feet"),
        }),
      meshDeep: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .integer("Mesh Depth must be a whole number")
        .min(1, "Mesh Depth has a minimum of 1 mesh")
        .max(100, "Mesh Depth has a maximum of 100 meshes"),
      netHeight: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .when("gearKey", {
          is: "TN",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .integer("Net Height must be a whole number")
            .min(1, "Net Height has a minimum of 1 foot")
            .max(100, "Net Height has a maximum of 100 feet"),
        }),
      height: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .when("gearKey", {
          is: "PS",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .integer("Height must be a whole number")
            .min(5, "Height has a minimum of 5 feet")
            .max(600, "Height has a maximum of 600 feet"),
        })
        .when("gearKey", {
          is: "BS",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .integer("Height must be a whole number")
            .min(1, "Height has a minimum of 1 foot")
            .max(10, "Height has a maximum of 10 feet"),
        }),
    }),
    onSubmit: () => {
      const values = removeUndefined(convertNumericStringToInt(formik.values));
      let gearEffort = { ...values };

      // Updates gear only moving forward. Not backward.

      // Update meshName and meshSize
      if (attributeByGear.meshSize.includes(gear.code)) {
        gearEffort = {
          ...values,

          meshName: getGearMeshSizes(meshSizes, effort.gearID).find(
            (m: any) => m.id === values.meshSizeID
          ).name,

          meshSize: getGearMeshSizes(meshSizes, effort.gearID).find(
            (m: any) => m.id === values.meshSizeID
          ).size,
        };
      }

      const data: TripEffort = {
        validated: false,
        ...gearEffort,
      };

      updateGear(data);
    },
  });

  return (
    <Form
      noValidate
      onSubmit={formik.handleSubmit}
      ref={refID}
      autoComplete="off"
    >
      <Row>
        {/* Has no gear attributes */}

        {!Object.values(attributeByGear).flat().includes(gear.code) && (
          <>
            <h3 className="text-center">
              No attributes associated with this gear
            </h3>
            <p className="text-center">
              Click <strong>Continue</strong> to go to the next step
            </p>
          </>
        )}

        {/* Mesh Size */}
        {attributeByGear.meshSize.includes(gear.code) && (
          <Form.Group as={Col} lg={4} md={6}>
            <Form.Label>
              {gear.code === "TN" ? "Trap Size" : "Mesh Size"}
              <span className="text-danger text-small ms-2">(Required)</span>
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Select
                aria-label="Mesh Size"
                name="meshSizeID"
                value={formik.values.meshSizeID}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.touched.meshSizeID && !!formik.errors.meshSizeID
                }
              >
                {getGearMeshSizes(meshSizes, gear.id).map(
                  (m: MeshSize, i: number) => (
                    <option key={i} value={m.id}>
                      {m.name}
                    </option>
                  )
                )}
              </Form.Select>
            </InputGroup>
          </Form.Group>
        )}

        {/* Mesh Length */}
        {attributeByGear.meshLength.includes(gear.code) && (
          <Form.Group as={Col} lg={4} md={6}>
            <Form.Label>
              Mesh Length
              <span className="text-danger text-small ms-2">(Required)</span>
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                aria-label="Net Length"
                type="number"
                min={0}
                name="meshLength"
                value={formik.values.meshLength}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.touched.meshLength && !!formik.errors.meshLength
                }
              />
              <InputGroup.Text>Feet</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        )}

        {/* Meshes Deep */}
        {attributeByGear.meshDeep.includes(gear.code) && (
          <Form.Group as={Col} lg={4} md={6}>
            <Form.Label>Meshes Deep</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                aria-label="Meshes Deep"
                type="number"
                min={0}
                name="meshDeep"
                value={formik.values.meshDeep}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.meshDeep && !!formik.errors.meshDeep}
              />
            </InputGroup>
          </Form.Group>
        )}

        {/* Net Height */}
        {attributeByGear.netHeight.includes(gear.code) && (
          <Form.Group as={Col} lg={4} md={6}>
            <Form.Label>Net Height</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                aria-label="Net Height"
                type="number"
                min={0}
                name="netHeight"
                value={formik.values.netHeight}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.touched.netHeight && !!formik.errors.netHeight
                }
              />
              <InputGroup.Text>Feet</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        )}

        {/* Height */}
        {attributeByGear.height.includes(gear.code) && (
          <Form.Group as={Col} lg={4} md={6}>
            <Form.Label>Height</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                aria-label="Height"
                type="number"
                min={0}
                name="height"
                value={formik.values.height}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.height && !!formik.errors.height}
              />
              <InputGroup.Text>Feet</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        )}
      </Row>

      <ul className="text-danger">
        {Object.entries(formik.errors).map(([key, value]: any, i) => {
          if (value !== "Required" && value !== "Please select an option") {
            return <li key={i}>{value}</li>;
          } else {
            return "";
          }
        })}
      </ul>
    </Form>
  );
}
