import axios from "axios";

export interface ManagementUnit {
  id: number;
  code: string;
  name: string;
  typeCode: string;
  typeName: string;
  activeDate: string;
  expireDate: string;
  isActive: boolean;
  gridCount: number;
}

export const listManagementUnits = async (
  lakeID?: number,
  typeID?: number,
  active?: boolean
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Lookup/managementunits?lakeID=${lakeID || ''}&typeID=${typeID || ''}&active=${active || ''
}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    const result: string[] = [];
    return result;
  }
};
