import { useEffect, useState } from "react";

export default function EffortAttributesList({ effort }: any) {
  interface aL {
    depthStart: any;
    depthEnd: any;
    waterTemp: any;
    meshLength: any;
    trapSize: any;
    meshDeep: any;
    netHeight: any;
    height: any;
    feetNetLifted: any;
    numTrapsLifted: any;
    numHooks: any;
    numLines: any;
    hoursFished: any;
    numHauls: any;
    numLifts: any;
    nightsOut: any;
  }

  const attributesList: aL = {
    depthStart: {
      prefix: "Depth fished from",
      suffix: "ft.",
    },
    depthEnd: {
      prefix: "Depth fished to",
      suffix: "ft.",
    },
    waterTemp: {
      prefix: "Water temperature",
      suffix: "F",
    },
    meshLength: {
      prefix: "Mesh length",
      suffix: "ft.",
    },
    trapSize: {
      prefix: "Trap size",
      suffix: "in.",
    },
    meshDeep: {
      prefix: "Meshes deep",
      suffix: "",
    },
    netHeight: {
      prefix: "Net height",
      suffix: "ft.",
    },
    height: {
      prefix: "Height",
      suffix: "ft.",
    },
    feetNetLifted: {
      prefix: "Net lifted",
      suffix: "ft.",
    },
    numTrapsLifted: {
      prefix: "Traps lifted",
      suffix: "lift(s)",
    },
    numHooks: {
      prefix: "Hooks",
      suffix: "",
    },
    numLines: {
      prefix: "Lines",
      suffix: "",
    },
    hoursFished: {
      prefix: "Hours fished",
      suffix: "hrs.",
    },
    numHauls: {
      prefix: "Hauls",
      suffix: "",
    },
    numLifts: {
      prefix: "Lifts",
      suffix: "",
    },
    nightsOut: {
      prefix: "Nights out",
      suffix: "",
    },
  };

  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    let result: any = [];

    Object.entries(effort).forEach(([key, value], i) => {
      if (key in attributesList && value) {
        result.push([
          `${attributesList[key as keyof aL].prefix}: `,
          `${value} ${attributesList[key as keyof aL].suffix}`,
        ]);
      }

      if (key === "meshName") {
        result.push(["Mesh: ", value]);
      }
    });

    setAttributes(result);
  }, [effort]);

  return (
    <ul className="list-unstyled">
      {attributes.map((attr, i) => {
        return (
          <li
            key={i}
            // className="bg-white px-3 py-2 me-2 mb-2 d-inline-block rounded shadow-sm"
          >
            <strong>{attr[0]}</strong>
            {attr[1]}
          </li>
        );
      })}
    </ul>
  );
}
