import axios from "axios";
import { createHashPassword } from "../helpers/createHashPassword";

export interface CoraAdmin {
  userName?: string;
  oneTimePassword?: string;
  passwordHash?: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  createVia: string;
  biaNumber?: string;
  organizationCodeNumber?: string;
  address1: string;
  city: string;
  stateCode: string;
  zip: string;
  organizationID: number;
}

export const createCoraAdmin = async (coraAdmin: CoraAdmin) => {
  if (coraAdmin.oneTimePassword) {
    const hashPassword = await createHashPassword(coraAdmin.oneTimePassword);
    coraAdmin.passwordHash = hashPassword;
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/User/create-cora-admin`,
      coraAdmin
    );

    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
