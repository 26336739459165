import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SelectRole from "./selectRole";

export interface AccessInformationValues {
  userName: string;
  roleID: number;
  enabled: boolean;
  lockedOut: boolean;
  requirePasswordChange: boolean;
  initialFlow: boolean;
}

export default function EditAccessInformation({
  formData,
  refId,
  initialValues,
  allowChangeRole,
  showInitialFlow,
  isSelf,
}: any) {
  // Set init values against the interface
  const values: AccessInformationValues = { ...initialValues };
  // Init Formik
  const formik = useFormik({
    initialValues: {
      userName: values.userName,
      roleID: values.roleID,
      enabled: values.enabled,
      lockedOut: values.lockedOut,
      requirePasswordChange: values.requirePasswordChange,
      initialFlow: values.initialFlow,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      userName: Yup.string().required("User Name is required"),
      enabled: Yup.boolean(),
      lockedOut: Yup.boolean(),
      requirePasswordChange: Yup.boolean(),
      initialFlow: Yup.boolean(),
    }),
    onSubmit: () => {
      formData({
        values: formik.values,
        validated: formik.isSubmitting
          ? Object.keys(formik.errors).length === 0
          : false,
      });
    },
  });

  //Update values on change
  React.useEffect(() => {
    formData({
      values: formik.values,
      validated:
        formik.dirty && Object.keys(formik.errors).length === 0 ? true : false,
    });
  }, [formik.values, formik.errors]);

  // Submit form from parent component
  React.useImperativeHandle(refId, () => ({
    Submit: async () => {
      await formik.submitForm();
    },
  }));

  return (
    <div className="form-card">
      <Form
        id="access-info-form"
        noValidate
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        ref={refId}
      >
        <Row>
          {/* User Name */}
          <Form.Group as={Col} md className="mb-3" controlId="formUserName">
            <Form.Label>User Name*</Form.Label>
            <Form.Control
              type="text"
              name="userName"
              disabled
              value={formik.values.userName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.userName && !!formik.errors.userName}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.userName}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Roles */}
          <Form.Group as={Col} className="mb-3" controlId="formRole">
            <SelectRole
              name="roleID"
              value={formik.values.roleID}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.roleID && !!formik.errors.roleID}
              enabled={allowChangeRole}
            />
          </Form.Group>
        </Row>

        {/* Toggles */}
        {!isSelf && (
          <Row>
            {/* Enabled */}
            <Form.Group
              as={Col}
              lg={6}
              className="mb-3"
              controlId="formEnabled"
            >
              <Form.Check
                type="switch"
                name="enabled"
                checked={formik.values.enabled}
                onChange={formik.handleChange}
                label="Enabled"
              />
            </Form.Group>

            {/* Locked Out */}
            <Form.Group
              as={Col}
              lg={6}
              className="mb-3"
              controlId="formLockedOut"
            >
              <Form.Check
                type="switch"
                name="lockedOut"
                checked={formik.values.lockedOut}
                onChange={formik.handleChange}
                label="Locked Out"
              />
            </Form.Group>

            {/* Require Password Change */}
            <Form.Group
              as={Col}
              lg={6}
              className="mb-3"
              controlId="formRequirePasswordChange"
            >
              <Form.Check
                type="switch"
                name="requirePasswordChange"
                checked={formik.values.requirePasswordChange}
                onChange={formik.handleChange}
                label="Require Password Change"
              />
            </Form.Group>

            {/* Initial Flow */}
            {showInitialFlow && (
              <Form.Group
                as={Col}
                lg={6}
                className="mb-3"
                controlId="formInitialFlow"
              >
                <Form.Check
                  type="switch"
                  name="initialFlow"
                  checked={formik.values.initialFlow}
                  onChange={formik.handleChange}
                  label="Initial Flow"
                />
              </Form.Group>
            )}
          </Row>
        )}
      </Form>
    </div>
  );
}
