import axios from "axios";
import { createHashPassword } from "../helpers/createHashPassword";

export interface Password {
  userID: number;
  new: string;
  requireChange?: boolean;
}

export const setPassword = async (password: Password) => {
  const hashPassword = await createHashPassword(password.new);
  try {
    const response = axios.post(
      `${process.env.REACT_APP_API}/Auth/set-password`,
      {
        userID: password.userID,
        newHash: hashPassword,
        oneTimePassword: password.new,
        requireChange: password.requireChange,
      }
    );
    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
