import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { lookupStates } from "../../api/lookupStates";

export default function SelectState({
  name,
  value,
  onChange,
  onBlur,
  isInvalid,
}: any) {
  const [states, setStates] = useState([]);

  useEffect(() => {
    const fetchStates = async () => {
      const response = await lookupStates();
      if (response) setStates(response);
    };
    fetchStates();
  }, []);

  return (
    <Form.Select
      aria-label="State Select"
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      isInvalid={isInvalid}
    >
      <option></option>
      {Array.isArray(states) &&
        states.map((state: { stateName: string; stateCode: string }) => (
          <option key={state.stateCode} value={state.stateCode}>
            {state.stateName}
          </option>
        ))}
    </Form.Select>
  );
}
