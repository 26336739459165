import { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputGroup from "react-bootstrap/esm/InputGroup";

export default function HarvestSale({
    key,
    sale,
    index,
    updateSale,
    isReadonly,
}: any) {
    // Init Formik
    const formik = useFormik({
        initialValues: {
            weight: sale.weight,
            pricePerPound: sale.pricePerPound,
        },
        enableReinitialize: false,
        validationSchema: Yup.object().shape({
            weight: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, "Pounds Sold cannot be negative"),
            pricePerPound: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, "Price Per Pound cannot be negative")
        }),
        onSubmit: () => { },
    });

    useEffect(() => {
        const data = {
            weight: formik.values.weight,
            pricePerPound: formik.values.pricePerPound
        };

        updateSale(index, data);
    }, [formik.values]);

    return (
        <div key={key}>
            <Row key={`${sale.id}-${sale.speciesID}`} className="py-2">
                <Col sm={3} className="d-flex align-items-center">
                    {sale.speciesName}
                </Col>
                {/* <input type="hidden" value={h.speciesID} name="catch" /> */}
                <Form.Group 
                    as={Col} 
                    md={3} 
                    lg={true} 
                    className="mb-2"
                    controlId="poundsSold">
                    <InputGroup>
                        <Form.Control
                            type="number"
                            min={0}
                            name="weight"
                            value={formik.values.weight}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={isReadonly}
                            isInvalid={formik.touched.weight && !!formik.errors.weight}
                        />
                        <InputGroup.Text>lbs</InputGroup.Text>
                    </InputGroup>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                    as={Col}
                    md={3}
                    lg={true}
                    className="mb-2"
                    controlId="pricePerPound"
                >
                    <InputGroup>
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control
                            type="number"
                            min={0}
                            name="pricePerPound"
                            value={formik.values.pricePerPound}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={isReadonly}
                            isInvalid={formik.touched.pricePerPound && !!formik.errors.pricePerPound}
                        />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>
                <Col sm="3">
                    $
                    {formik.values.weight > 0 &&
                        formik.values.pricePerPound > 0
                        ? (
                            formik.values.weight *
                            formik.values.pricePerPound
                        ).toFixed(2)
                        : "0.00"}
                </Col>
            </Row>
            <ul className="text-danger">
                {Object.entries(formik.errors).map(([key, value]: any, i) => {
                    if (value !== "Required") {
                        return <li key={i}>{value}</li>
                    } else {
                        return "";
                    }
                })}
            </ul>
        </div>
    )
};