import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import SelectOrganization from "./selectOrganization";

export interface TribeValues {
  organizationID: number;
}

export default function EditAdminTribe({
  formData,
  refId,
  initialValues,
  allowChange,
}: any) {
  // Set init values against the interface
  const values: TribeValues = { ...initialValues };
  // Init Formik
  const formik = useFormik({
    initialValues: {
      organizationID: values.organizationID,
    },
    enableReinitialize: true,
    onSubmit: () => {
      formData({
        values: formik.values,
        validated: true,
      });
    },
  });

  //Update values on change
  React.useEffect(() => {
    formData({
      values: formik.values,
      validated:
        formik.dirty && Object.keys(formik.errors).length === 0 ? true : false,
    });
  }, [formik.values, formik.errors]);

  // Submit form from parent component
  React.useImperativeHandle(refId, () => ({
    Submit: async () => {
      await formik.submitForm();
    },
  }));

  return (
    <div className="form-card">
      <Form
        id="tribe-form"
        noValidate
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        ref={refId}
      >
        <Row>
          <Form.Group as={Col} xs={12} className="" controlId="formTribe">
            <SelectOrganization
              name="organizationID"
              value={formik.values.organizationID}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              enabled={allowChange}
              isInvalid={
                formik.touched.organizationID && !!formik.errors.organizationID
              }
            />
          </Form.Group>
        </Row>
      </Form>
    </div>
  );
}
