import axios, { AxiosError } from "axios";
import { hashPassword } from "../helpers/hashPassword";
import { setAuthToken } from "./setAuthToken";

interface LoginPayLoad {
  username: string;
  password: string;
  checkPasswordReset?: boolean;
}

export const handleLogin = async (payload: LoginPayLoad) => {
  const passwordHash = await hashPassword(payload.username, payload.password);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/Auth/login`,
      {
        username: payload.username,
        passwordHash: passwordHash,
      }
    );

    payload.checkPasswordReset = payload.checkPasswordReset ?? true;

    //set JWT token to local
    localStorage.setItem("accessToken", response.data.accessToken);
    localStorage.setItem(
      "accessTokenExpiration",
      response.data.accessTokenExpiration
    );
    localStorage.setItem("refreshToken", response.data.refreshToken);
    localStorage.setItem(
      "refreshTokenExpiration",
      response.data.refreshTokenExpiration
    );

    // Set the cache buster refresh limit back to 0 when the user logs in
    localStorage.setItem("cacheBusterRefreshIteration", "0");

    // User info
    localStorage.setItem("username", payload.username);
    localStorage.setItem("firstName", response.data.firstName);
    localStorage.setItem("middleName", response.data.middleName);
    localStorage.setItem("lastName", response.data.lastName);

    // Organization info
    localStorage.setItem(
      "organizationSupportEmail",
      response.data.organizationSupportEmail
    );
    localStorage.setItem("organizationLogo", response.data.organizationLogo);

    // Daily Trip help
    localStorage.setItem("dtTripDateHelp", "true");
    localStorage.setItem("dtFishingLocation", "true");
    localStorage.setItem("dtGearHelp", "true");
    localStorage.setItem("dtEffortHelp", "true");
    localStorage.setItem("dtFishCaughtHelp", "true");
    localStorage.setItem("dtVesselHelp", "true");

    //set token to axios common header
    setAuthToken(response.data.accessToken);

    // Check if user needs to reset their password
    if (payload.checkPasswordReset && response.data.requirePasswordChange) {
      localStorage.setItem("requirePasswordChange", "true");
    }

    if (response.data.initialFlow) {
      localStorage.setItem("initialFlow", "true");
    }

    //redirect user to home page
    window.location.href = "/";
  } catch (error) {
    if (error instanceof AxiosError) {
      return error.response?.data.message;
    }
  }
};
