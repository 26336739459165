import DebouncedInput from "./debouncedInput";

export default function Filter({ column }: any) {
  const columnFilterValue = column.getFilterValue();

  return (
    <>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? "") as string}
        onChange={(value) => column.setFilterValue(value)}
        className="form-control form-control-sm"
      />
    </>
  );
}
