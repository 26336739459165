import axios from "axios";

export interface Role {
  roleID: number;
  roleName: string;
  displayName: string;
}

export const lookupRoles = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Lookup/roles`
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
