import axios from "axios";

export const postEmailTechnicalSupport = async (summary: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/Email/technical-support`,
      { summary: summary }
    );
    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
