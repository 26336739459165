import axios from "axios";

export const getUserVessels = async (userID: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Vessel/user-vessels?UserID=${userID}`
    );
    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
