export const getFormattedDateMMDDYYYY = (iso: string) => {
  if (iso === "All of time") {
    return iso;
  }

  const date = new Date(iso);

  if (isNaN(date.getTime())) {
    return "Unknown Date";
  }

  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${month}/${day}/${year}`;
};
