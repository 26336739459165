import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import SelectOrganization from "./selectOrganization";

export interface BIACodeValues {
  organizationID: number;
  biaNumber: string;
  organizationCodeNumber: string;
  organizationCode?: string;
}

export default function EditBIACode({
  formData,
  refId,
  initialValues,
  allowChangeTribe,
  includeCORA,
}: any) {
  // Set init values against the interface
  const values: BIACodeValues = { ...initialValues };
  // Init Formik

  const formik = useFormik({
    initialValues: {
      organizationID: values.organizationID,
      biaNumber: values.biaNumber,
      organizationCodeNumber: values.organizationCodeNumber,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      // biaNumber: Yup.string().nullable().required("BIA Number is required"),
      organizationCodeNumber: Yup.string()
        .nullable()
        .required("Tribal Code is required"),
    }),
    onSubmit: () => {
      formData({
        values: formik.values,
        validated: formik.isSubmitting
          ? Object.keys(formik.errors).length === 0
          : false,
      });
    },
  });

  //Update values on change
  React.useEffect(() => {
    formData({
      values: formik.values,
      validated:
        formik.dirty && Object.keys(formik.errors).length === 0 ? true : false,
    });
  }, [formik.values, formik.errors]);

  // Submit form from parent component
  React.useImperativeHandle(refId, () => ({
    Submit: async () => {
      await formik.submitForm();
    },
  }));

  return (
    <div className="form-card">
      <Form
        id="bia-code-form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        ref={refId}
      >
        <Row className="mb-2">
          <Form.Group as={Col} xs={12} className="mb-3" controlId="formTribe">
            <SelectOrganization
              name="organizationID"
              value={formik.values.organizationID}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              enabled={allowChangeTribe}
              isInvalid={
                formik.touched.organizationID && !!formik.errors.organizationID
              }
              includeCORA={includeCORA}
            />
          </Form.Group>

          {/* <Form.Group as={Col} sm className="mb-3" controlId="formBIA">
            <Form.Label>
              BIA Number
              <span className="text-danger text-small ms-2">(Required)</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="biaNumber"
              value={formik.values.biaNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.biaNumber && !!formik.errors.biaNumber}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.biaNumber}
            </Form.Control.Feedback>
          </Form.Group> */}

          <Form.Group as={Col} sm className="mb-3" controlId="formCode">
            <Form.Label>
              Tribal Code
              <span className="text-danger text-small ms-2">(Required)</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="organizationCodeNumber"
              value={formik.values.organizationCodeNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched.organizationCodeNumber &&
                !!formik.errors.organizationCodeNumber
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.organizationCodeNumber}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
      </Form>
    </div>
  );
}
