import axios from "axios";

export const unlockUser = async (userID: number) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/User/unlock`,
      { userID: userID }
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
