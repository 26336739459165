export const convertNumericStringToInt = (obj: any): any => {
  if (typeof obj === "object" && obj !== null) {
    const result: any = Array.isArray(obj) ? [] : {};

    for (let key in obj) {
      if (typeof obj[key] === "string" && !isNaN(Number(obj[key]))) {
        result[key] = parseInt(obj[key], 10);
      } else if (typeof obj[key] === "object") {
        result[key] = convertNumericStringToInt(obj[key]);
      } else {
        result[key] = obj[key];
      }
    }

    return result;
  } else {
    return obj;
  }
};
