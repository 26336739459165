import axios from "axios";

export interface State {
  stateID: number;
  stateCode: string;
  stateName: string;
  sortOrder: number;
}

export const lookupStates = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Lookup/states`
    );

    // Sort by sort order, then alphabetically
    // const sortedData = response.data.sort((a: State, b: State) => {
    //   return (
    //     b.sortOrder - a.sortOrder || a.stateName.localeCompare(b.stateName)
    //   );
    // });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
