import axios from "axios";

export interface License {
  licenseID: number;
  userID: number;
  userProfileID: number;
  organizationID: number;
  licenseNumber: string;
  licenseTypeCode: string;
  activeDate?: string;
  expireDate?: string;
}

export interface LicensePayload {
  UserID?: number;
  OrganizationID?: number;
  TypeCode?: string;
}

export const getUserLicenses = async (userID: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/License/user-licenses?UserID=${userID}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
