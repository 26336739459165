import { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { resetPassword } from "../../../api/resetPassword";
import passwordReg from "../../../helpers/passwordReg";
import { apiSuccess } from "../../../helpers/apiSuccess";
import { useNavigate } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const schema = Yup.object().shape({
  password: Yup.string()
    .required("Password required")
    .min(10, "Minimum ten characters, at least one letter and one number")
    .test(
      "isValidPass",
      "At least one letter and one number",
      (value: any, context) => {
        return passwordReg(value, context);
      }
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export default function ResetPassword({ urlId, urlCode }: any) {
  const [failed, setFailed] = useState(false);
  const [nocode, setNocode] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showConfPass, setShowConfPass] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (parseInt(String(urlId)) < 1 || !urlCode) setNocode(true);
  }, [urlId, urlCode]);

  return (
    <>
      <div className="text-center">
        <h1>Reset Password</h1>
        <p className="lead">Please enter your new password.</p>
      </div>

      <Formik
        initialValues={{ password: "", confirmPassword: "" }}
        validationSchema={schema}
        onSubmit={(values) => {
          const submit = async () => {
            const response = await resetPassword({
              id: urlId,
              code: urlCode,
              new: values.password,
            });

            // Reset Form
            if (apiSuccess(response.status)) {
              setSuccess(true);
              localStorage.clear();
              setTimeout(() => navigate("/"), 1500);
            } else setFailed(true);
          };
          submit();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-4" controlId="validationFormikPassword">
              <Form.Label>Password*</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPass ? "text" : "password"}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.password && !!errors.password}
                />
                <OverlayTrigger
                  overlay={
                    <Tooltip id="password-tooltip">
                      {showPass ? "Hide password" : "Show password"}
                    </Tooltip>
                  }
                >
                  <InputGroup.Text onClick={() => setShowPass(!showPass)}>
                    <>{showPass ? <FaEyeSlash /> : <FaEye />}</>
                  </InputGroup.Text>
                </OverlayTrigger>
              </InputGroup>
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="mb-4"
              controlId="validationFormikConfirmPassword"
            >
              <Form.Label>Confirm Password*</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showConfPass ? "text" : "password"}
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    touched.confirmPassword && !!errors.confirmPassword
                  }
                />
                <OverlayTrigger
                  overlay={
                    <Tooltip id="password-tooltip">
                      {showConfPass ? "Hide password" : "Show password"}
                    </Tooltip>
                  }
                >
                  <InputGroup.Text
                    onClick={() => setShowConfPass(!showConfPass)}
                  >
                    <>{showConfPass ? <FaEyeSlash /> : <FaEye />}</>
                  </InputGroup.Text>
                </OverlayTrigger>
              </InputGroup>
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              variant="success"
              type="submit"
              disabled={isSubmitting || nocode}
              size="lg"
              className="w-100"
            >
              Reset your Password
            </Button>
            <p className="mt-3">
              *Password minimum length 10 characters with at least one letter
              and one number
            </p>

            {(failed || nocode) && (
              <Alert variant="danger" className="text-center mt-4">
                Invalid or expired link.
              </Alert>
            )}

            {success && (
              <Alert variant="success" className="text-center mt-4">
                Password reset successfully.
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}
