import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/esm/Button";
import { useEffect, useState } from "react";
import { lookupOrganization } from "../../api/lookupOrganization";
import {
  getOrganizationID,
  getOrganizationName,
  getRoles,
} from "../../helpers/authHelper";
import { Organization } from "../../models/organization";
import FAQs from "./components/faqs";
import Modal from "react-bootstrap/esm/Modal";
import Form from "react-bootstrap/esm/Form";
import { postEmailTechnicalSupport } from "../../api/postEmailTechicalSupport";
import FormToast, { ToastData } from "../../components/formToast";
import { apiSuccess } from "../../helpers/apiSuccess";
import { postEmailTribalSupport } from "../../api/postEmailTribalSupport";
import { postEmailCORASupport } from "../../api/postEmailCORASupport";

function TribalSupportModal(props: any) {
  const [tribalSupportEmailBody, setTribalSupportEmailBody] = useState("");
  const [toastData, setToastData] = useState<ToastData>({
    show: false,
    title: "",
    body: "",
    bg: "",
  });

  const sendTribalSupportEmail = async () => {
    // Send text to API for email

    if (tribalSupportEmailBody !== "") {
      const response = await postEmailTribalSupport(tribalSupportEmailBody);
      if (apiSuccess(response.status)) {
        setToastData({
          show: true,
          bg: "Success",
          title: "Success",
          body: "Tribal Support ticket sent",
        });

        setTribalSupportEmailBody("");
        props.onHide();
      }
    }
  };

  return (
    <>
      <FormToast toastData={toastData} setData={setToastData} />
      <Modal
        {...props}
        size="lg"
        aria-labelledby="tribal-support-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="tribal-support-modal">Tribal Support</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>
                Have an issue that needs to be resolved with your tribe? Submit
                a tribal support ticket.
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={tribalSupportEmailBody}
                onChange={(e) => setTribalSupportEmailBody(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-block">
          <Row>
            <Col>
              <Button
                variant="outline-primary"
                className="w-100"
                onClick={props.onHide}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Row>
                <Button
                  variant="primary"
                  className="w-100"
                  onClick={sendTribalSupportEmail}
                  disabled={tribalSupportEmailBody === ""}
                >
                  Submit Ticket
                </Button>
              </Row>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function CORASupportModal(props: any) {
  const [coraSupportEmailBody, setCoraSupportEmailBody] = useState("");
  const [toastData, setToastData] = useState<ToastData>({
    show: false,
    title: "",
    body: "",
    bg: "",
  });

  const sendCORASupportEmail = async () => {
    // Send text to API for email

    if (coraSupportEmailBody !== "") {
      const response = await postEmailCORASupport(coraSupportEmailBody);
      if (apiSuccess(response.status)) {
        setToastData({
          show: true,
          bg: "Success",
          title: "Success",
          body: "CORA Support ticket sent",
        });

        setCoraSupportEmailBody("");
        props.onHide();
      }
    }
  };

  return (
    <>
      <FormToast toastData={toastData} setData={setToastData} />
      <Modal {...props} size="lg" aria-labelledby="cora-support-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title id="cora-support-modal">CORA Support</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>
                Have an issue that needs to be resolved with CORA? Submit a CORA
                support ticket.
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={coraSupportEmailBody}
                onChange={(e) => setCoraSupportEmailBody(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-block">
          <Row>
            <Col>
              <Button
                variant="outline-primary"
                className="w-100"
                onClick={props.onHide}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Row>
                <Button
                  variant="primary"
                  className="w-100"
                  onClick={sendCORASupportEmail}
                  disabled={coraSupportEmailBody === ""}
                >
                  Submit Ticket
                </Button>
              </Row>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function TechnicalSupportModal(props: any) {
  const [technicalSupportEmailBody, setTechnicalSupportEmailBody] =
    useState("");
  const [toastData, setToastData] = useState<ToastData>({
    show: false,
    title: "",
    body: "",
    bg: "",
  });

  const sendTechnicalSupportEmail = async () => {
    if (technicalSupportEmailBody !== "") {
      const response = await postEmailTechnicalSupport(
        technicalSupportEmailBody
      );
      if (apiSuccess(response.status)) {
        setToastData({
          show: true,
          bg: "Success",
          title: "Success",
          body: "Technical Support ticket sent",
        });

        setTechnicalSupportEmailBody("");
        props.onHide();
      }
    }
  };

  return (
    <>
      <FormToast toastData={toastData} setData={setToastData} />
      <Modal
        {...props}
        size="lg"
        aria-labelledby="technical-support-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="technical-support-modal">
            Technical Support
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>
                Found a bug or need help? Submit a technical support ticket.
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={technicalSupportEmailBody}
                onChange={(e) => setTechnicalSupportEmailBody(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-block">
          <Row>
            <Col>
              <Button
                variant="outline-primary"
                className="w-100"
                onClick={props.onHide}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Row>
                <Button
                  variant="primary"
                  className="w-100"
                  onClick={sendTechnicalSupportEmail}
                  disabled={technicalSupportEmailBody === ""}
                >
                  Submit Ticket
                </Button>
              </Row>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default function Support() {
  const [org, setOrg] = useState<Organization>();
  const [coraOrg, setCoraOrg] = useState<Organization>();
  const [technicalModalShow, setTechnicalModalShow] = useState(false);
  const [coraModalShow, setCoraModalShow] = useState(false);
  const [tribalModalShow, setTribalModalShow] = useState(false);
  const roles = getRoles();

  useEffect(() => {
    // Users ORG
    lookupOrganization(getOrganizationID()).then((res) => {
      if (res) setOrg(res);
    });
    // CORA
    lookupOrganization(1).then((res) => {
      if (res) setCoraOrg(res);
    });
  }, []);

  return (
    <Container className="h-100 py-5 d-flex flex-column">
      <TechnicalSupportModal
        show={technicalModalShow}
        onHide={() => setTechnicalModalShow(false)}
      />
      <TribalSupportModal
        show={tribalModalShow}
        onHide={() => setTribalModalShow(false)}
      />
      <CORASupportModal
        show={coraModalShow}
        onHide={() => setCoraModalShow(false)}
      />

      <h1 className="text-primary mb-5">Support</h1>
      <h3 className="fw-normal">Contact</h3>
      <Row className="text-center">
        <Col lg={6}>
          <div className="form-card">
            {org?.logo && (
              <>
                <img
                  src={org.logo}
                  alt="Tribal Logo"
                  style={{ maxWidth: "8rem", maxHeight: "8rem" }}
                  className="w-auto h-100 m-3"
                />
              </>
            )}
            <h4 className="fw-normal">{getOrganizationName()}</h4>
            <hr />

            {org?.link && (
              <>
                <div className="fw-bold">Website</div>
                <p>
                  <a href={org.link} target="_blank" rel="noreferrer">
                    {org?.link}
                  </a>
                </p>
              </>
            )}

            {org?.address1 && (
              <>
                <div className="fw-bold">Address</div>
                <p>{org?.address1}</p>
                <p>{org?.address2}</p>
              </>
            )}

            {org?.phone && (
              <>
                <div className="fw-bold">Phone</div>
                <p>
                  <a href={`tel:${org.phone}`}>{org.phone}</a>
                </p>
              </>
            )}

            {org?.email && (
              <>
                <div className="fw-bold">Email</div>
                <p>
                  <a href={`mailto:${org.email}`}>{org.email}</a>
                </p>
              </>
            )}
          </div>
        </Col>
        <Col lg={6}>
          <div className="form-card">
            <h4 className="fw-normal">Send Email</h4>
            <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
              {org?.supportEmail && (
                <>
                  <p className="mb-1">Email your tribe about an issue</p>
                  <div className="mb-4 w-75">
                    <Button
                      className="w-100"
                      onClick={() => setTribalModalShow(true)}
                    >
                      Tribal Support (Preferred)
                    </Button>
                  </div>
                </>
              )}

              <p className="mb-1">Email CORA about an issue</p>
              <div className="mb-4 w-75">
                <Button
                  className="w-100"
                  onClick={() => setCoraModalShow(true)}
                >
                  CORA Support
                </Button>
                {coraOrg?.phone && (
                  <p>
                    If you need phone assistance,
                    <br />
                    CORA's phone number is:{" "}
                    <a href={`tel:${coraOrg.phone}`}>{coraOrg.phone}</a>
                  </p>
                )}
              </div>

              {(roles.includes("CORA_Admin") ||
                roles.includes("Tribal_Admin")) && (
                <>
                  <p className="mb-1">
                    Found a bug with the website or need technical help?
                  </p>
                  <div className="mb-4 w-75">
                    <Button
                      className="w-100"
                      onClick={() => setTechnicalModalShow(true)}
                    >
                      Technical Support (Resource Data)
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>

      <h3 className="fw-normal">How-To Videos</h3>
      <Row className="mb-5">
        <Col md={4}>
          <div className="video-container">
            <iframe
              className="video"
              src="https://www.youtube.com/embed/nNuYQTtCbgI"
              title="CORAFish Account Registration"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </Col>
        <Col md={4}>
          <div className="video-container">
            <iframe
              className="video"
              src="https://www.youtube.com/embed/gqK8GUofhNg"
              title="Daily Trip Quick Start"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </Col>
        <Col md={4}>
          <div className="video-container">
            <iframe
              className="video"
              src="https://www.youtube.com/embed/6r0QSW_kyOU"
              title="Submit a Catch Report"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </Col>
      </Row>

      <h3 className="fw-normal">Frequently Asked Questions</h3>
      <FAQs />
    </Container>
  );
}
