import { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { MdSnowmobile, MdDirectionsBoat } from "react-icons/md";
import { Vessel } from "../../models/vessel";
import { getUserID } from "../../helpers/authHelper";
import { postVessel } from "../../api/postVessel";
import { getVessels } from "../../api/getVessels";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Tooltip from "react-bootstrap/esm/Tooltip";
import { deleteVessel } from "../../api/deleteVessel";
import { putVessel } from "../../api/putVessel";
import { getUserVessels } from "../../api/getUserVessels";

export default function EditVessels({ userID }: any) {
  const [vessels, setVessels] = useState<Vessel[]>([]);
  const [vesselForm, setVesselForm] = useState({
    name: "",
    length: "",
    registrationNumber: "",
    isSnowmobile: false,
  });

  // On Load
  useEffect(() => {
    fetchVessels();
  }, [userID]);

  const fetchVessels = async () => {
    if (userID === getUserID() || userID === undefined) {
      const response = await getVessels();
      if (response) setVessels(response.data);
    } else {
      const response = await getUserVessels(userID);
      if (response) setVessels(response.data);
    }
  };

  // Add Vessel
  const addVessel = (vessel: any) => {
    const submitVessel = async () => {
      if (userID === getUserID() || userID === undefined) {
        await postVessel({ userID: getUserID(), ...vessel });
      } else {
        await postVessel({ userID: userID, ...vessel });
      }
      fetchVessels();
    };
    submitVessel();
  };

  // Remove Vessel
  const removeVessel = (vessel: Vessel) => {
    const submitVessel = async () => {
      await deleteVessel(vessel.vesselID);
      fetchVessels();
    };
    submitVessel();
  };

  // Edit Vessel
  const editVessel = (vessel: Vessel) => {
    const submitVessel = async () => {
      await putVessel(vessel);
      fetchVessels();
    };
    submitVessel();
  };

  const vesselSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    length: Yup.number()
      .min(9, "Minimum length is 9 feet")
      .max(200, "Maximum length is 200 feet")
      .required("Length is required"),
    registrationNumber: Yup.string(),
  });

  return (
    <div className="form-card">
      <Formik
        initialValues={vesselForm}
        enableReinitialize
        validationSchema={vesselSchema}
        onSubmit={(values, { resetForm }) => {
          addVessel(values);
          resetForm();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form
            noValidate
            onSubmit={handleSubmit}
            className="mb-4"
            autoComplete="off"
          >
            <Row>
              <Form.Group
                as={Col}
                lg
                className="mb-3"
                controlId="formVesselName"
              >
                <Form.Label>
                  Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.name && !!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                sm={6}
                lg={true}
                className="mb-3"
                controlId="formVesselLength"
              >
                <Form.Label>
                  Length (Feet)<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  name="length"
                  value={values.length}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.length && !!errors.length}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.length}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                sm={6}
                lg={true}
                className="mb-3"
                controlId="formVesselRegistrationNumber"
              >
                <Form.Label>CORA Registration #</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="CORA-1234"
                  name="registrationNumber"
                  value={values.registrationNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    touched.registrationNumber && !!errors.registrationNumber
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.registrationNumber}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                xs={12}
                lg={{ order: "last" }}
                className="mb-3 mb-lg-0"
              >
                <Form.Check
                  name="isSnowmobile"
                  label="Is Snowmobile"
                  type="checkbox"
                  id="FormikIsSnowMobile"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.isSnowmobile}
                />
              </Form.Group>
              <Col lg={3}>
                <Button
                  variant="secondary"
                  className="w-100 form-btn-lg"
                  type="submit"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>

      {Array.isArray(vessels) &&
        vessels.map((vessel: Vessel, i) => (
          <Formik
            key={i}
            initialValues={vessel}
            enableReinitialize
            validationSchema={vesselSchema}
            onSubmit={(values) => editVessel(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            }) => (
              <Form
                noValidate
                onSubmit={handleSubmit}
                autoComplete="off"
                className="border-bottom border-primary pb-2 pt-3"
              >
                <Row>
                  <Col lg={3} className="d-flex align-items-center">
                    {vessel.isSnowmobile && !vessel.isEditing && (
                      <MdSnowmobile className="color-secondary fs-3 text-primary me-3" />
                    )}
                    {!vessel.isSnowmobile && !vessel.isEditing && (
                      <MdDirectionsBoat className="color-secondary fs-3 text-primary me-3" />
                    )}
                    <Form.Control
                      plaintext={!vessel.isEditing}
                      readOnly={!vessel.isEditing}
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.name && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Col>

                  <Col lg={3}>
                    <Form.Control
                      plaintext={!vessel.isEditing}
                      readOnly={!vessel.isEditing}
                      type="number"
                      name="length"
                      value={values.length}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.length && !!errors.length}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.length}
                    </Form.Control.Feedback>
                  </Col>
                  <Col lg={3}>
                    <Form.Control
                      plaintext={!vessel.isEditing}
                      readOnly={!vessel.isEditing}
                      type="text"
                      name="registrationNumber"
                      value={values.registrationNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        touched.registrationNumber &&
                        !!errors.registrationNumber
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.registrationNumber}
                    </Form.Control.Feedback>
                  </Col>

                  <Col lg={3} className="pt-2 pt-lg-0">
                    {!vessel.isEditing && !vessel.isDeleting && (
                      <div className="text-lg-end">
                        <OverlayTrigger
                          placement="left"
                          overlay={<Tooltip>Edit</Tooltip>}
                        >
                          <Button
                            variant="secondary"
                            className="text-light me-2"
                            size="sm"
                            onClick={() => {
                              vessels[i].isEditing = true;
                              setVessels([...vessels]);
                            }}
                          >
                            <FaEdit />
                            <span className="d-inline-block d-lg-none mx-2">
                              Edit
                            </span>
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="left"
                          overlay={<Tooltip>Delete</Tooltip>}
                        >
                          <Button
                            variant="danger"
                            className="text-light"
                            size="sm"
                            onClick={() => {
                              vessels[i].isDeleting = true;
                              setVessels([...vessels]);
                            }}
                          >
                            <FaTrashAlt />
                            <span className="d-inline-block d-lg-none mx-2">
                              Remove
                            </span>
                          </Button>
                        </OverlayTrigger>
                      </div>
                    )}
                    {vessel.isEditing && (
                      <div className="d-flex">
                        <Button
                          variant="secondary"
                          className="w-100 me-1"
                          type="submit"
                        >
                          Save
                        </Button>
                        <Button
                          variant="warning"
                          className="w-100 ms-1"
                          onClick={() => {
                            vessels[i].isEditing = false;
                            setVessels([...vessels]);
                            handleReset();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                    {vessel.isDeleting && (
                      <div className="d-flex">
                        <Button
                          variant="danger"
                          className="w-100 me-1"
                          onClick={() => {
                            removeVessel(vessel);
                          }}
                        >
                          Delete
                        </Button>
                        <Button
                          variant="warning"
                          className="w-100 ms-1"
                          onClick={() => {
                            vessels[i].isDeleting = false;
                            setVessels([...vessels]);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
                <div className={vessel.isEditing ? "my-3" : "d-none"}>
                  <Form.Check
                    label="Is Snowmobile"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="isSnowmobile"
                    type="checkbox"
                    id={"FormikIsSnowMobile-" + i}
                    checked={values.isSnowmobile}
                  />
                </div>
              </Form>
            )}
          </Formik>
        ))}
    </div>
  );
}
