import { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { FaDownload } from "react-icons/fa";

export default function InstallPWA({ promptInstall }: any) {
  const onClick = (evt: any) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  return (
    <Button variant="primary" onClick={onClick}>
      <FaDownload className="text-light me-2 mt-n1" />
      Install the CORA App
    </Button>
  );
}
