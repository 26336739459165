import axios from "axios";

export const handleLogout = async () => {
  const username = localStorage.getItem("username");

  try {
    await axios
      // Revokes your tokens so you need a new access and refresh token
      .post(`${process.env.REACT_APP_API}/Auth/logout`);
  } catch (error) {
    console.error(error);
  }

  // Clear everything from local storage
  localStorage.clear();

  // Set username back for login
  username && localStorage.setItem("username", username);

  // Navigate back home
  window.location.href = "/";
};
