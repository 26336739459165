import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export interface LicenseValues {
  commercialLicenseNumber: string;
  commercialYear: string;
  subsistenceLicenseNumber: string;
  subsistenceYear: string;
}

export default function EditLicenses({
  formData,
  refId,
  initialValues,
  organization,
}: any) {
  // Submit form from parent component
  React.useImperativeHandle(refId, () => ({
    Submit: async () => {
      await formik.submitForm();
    },
  }));

  // Set init values against the interface
  const values: LicenseValues = { ...initialValues };

  // Init Formik
  const formik = useFormik({
    initialValues: {
      commercialLicenseNumber: values.commercialLicenseNumber,
      commercialYear: values.commercialYear,
      subsistenceLicenseNumber: values.subsistenceLicenseNumber,
      subsistenceYear: values.subsistenceYear,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      commercialLicenseNumber: Yup.string()
        .max(5)
        .nullable()
        .required("Commercial License is required"),
      subsistenceLicenseNumber: Yup.string().max(5),
    }),
    onSubmit: () => {
      const cLicense =
        formik.values.commercialLicenseNumber.length > 0
          ? `${organization?.code}-${formik.values.commercialLicenseNumber}C`
          : undefined;
      const sLicense =
        formik.values.subsistenceLicenseNumber.length > 0
          ? `${organization?.code}-${formik.values.subsistenceLicenseNumber}S`
          : undefined;
      const biaNumber =
        formik.values.commercialLicenseNumber.length > 0
          ? formik.values.commercialLicenseNumber
          : undefined;

      formData({
        values: {
          commercialYear: formik.values.commercialYear,
          subsistenceYear: formik.values.subsistenceYear,
          commercialLicenseNumber: cLicense,
          subsistenceLicenseNumber: sLicense,
          biaNumber: biaNumber,
        },
        validated: formik.isSubmitting
          ? Object.keys(formik.errors).length === 0
          : false,
      });
    },
  });

  //Update values on change
  React.useEffect(() => {
    const cLicense =
      formik.values.commercialLicenseNumber.length > 0
        ? `${organization?.code}-${formik.values.commercialLicenseNumber}C`
        : undefined;
    const sLicense =
      formik.values.subsistenceLicenseNumber.length > 0
        ? `${organization?.code}-${formik.values.subsistenceLicenseNumber}S`
        : undefined;
    const biaNumber =
      formik.values.commercialLicenseNumber.length > 0
        ? formik.values.commercialLicenseNumber
        : undefined;

    formData({
      values: {
        commercialYear: formik.values.commercialYear,
        subsistenceYear: formik.values.subsistenceYear,
        commercialLicenseNumber: cLicense,
        subsistenceLicenseNumber: sLicense,
        biaNumber: biaNumber,
      },
      validated:
        formik.dirty && Object.keys(formik.errors).length === 0 ? true : false,
    });
  }, [formik.values, formik.errors]);

  return (
    <div className="form-card">
      <Form
        id="license-form"
        noValidate
        autoComplete="off"
        ref={refId}
        onSubmit={formik.handleSubmit}
      >
        <Row>
          {/* Commercial */}
          <Form.Group as={Col} sm className="mb-3" controlId="formCommercial">
            <Form.Label>
              Commercial License Number (BIA)
              <span className="text-danger text-small ms-2">(Required)</span>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="tribal-code-commercial">
                {organization?.code}-
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="commercialLicenseNumber"
                placeholder="1234"
                value={formik.values.commercialLicenseNumber}
                onChange={(e) => {
                  if (
                    (e.target.value === "" || e.target.value.match(/^\d+$/)) &&
                    e.target.value.length < 6
                  ) {
                    formik.handleChange(e);
                  }
                }}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.touched.commercialLicenseNumber &&
                  !!formik.errors.commercialLicenseNumber
                }
              />
              <InputGroup.Text>C</InputGroup.Text>
            </InputGroup>
            <div className="invalid-feedback d-block">
              {formik.errors.commercialLicenseNumber}
            </div>
          </Form.Group>

          {/* Subsistence */}
          <Form.Group as={Col} sm className="mb-3" controlId="formSubsistence">
            <Form.Label>Subsistence License Number</Form.Label>

            <InputGroup>
              <InputGroup.Text id="tribal-code-subsistence">
                {organization?.code}-
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="subsistenceLicenseNumber"
                placeholder="1234"
                value={formik.values.subsistenceLicenseNumber}
                onChange={(e) => {
                  if (
                    (e.target.value === "" || e.target.value.match(/^\d+$/)) &&
                    e.target.value.length < 6
                  ) {
                    formik.handleChange(e);
                  }
                }}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.touched.subsistenceLicenseNumber &&
                  !!formik.errors.subsistenceLicenseNumber
                }
              />
              <InputGroup.Text>S</InputGroup.Text>
            </InputGroup>
            <Form.Control.Feedback type="invalid">
              {formik.errors.subsistenceLicenseNumber}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
      </Form>
    </div>
  );
}
