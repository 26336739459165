import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getOrganizationName, getUserID } from "../../../helpers/authHelper";
import { getProfile } from "../../../api/getProfile";
import { User } from "../../../models/user";
import { onboardingIssue } from "../../../api/onboardingIssue";
import { getLicenses } from "../../../api/getLicenses";
import { License } from "../../../models/license";

export default function FisherOnboardingStepOne({
  setOnboardingStep,
}: {
  setOnboardingStep: Dispatch<SetStateAction<number>>;
}) {
  const [userInfo, setUserInfo] = useState<User>();
  const [licenses, setLicenses] = useState([]);
  const [incorrectRecord, setIncorrectRecord] = useState(false);
  const [issueSummary, setIssueSummary] = useState("");

  // On Load
  useEffect(() => {
    const fetchUserInfo = async () => {
      const response = await getProfile();
      if (response) setUserInfo(response);
    };

    const fetchLicenses = async () => {
      const response = await getLicenses();
      if (response) setLicenses(response);
    };

    fetchLicenses();
    fetchUserInfo();
  }, []);

  const getDate = (date: any) => {
    if (date !== null) {
      return new Date(
        date.split("T")[0].replace(/-/g, "/")
      ).toLocaleDateString();
    } else {
      return "Not Set";
    }
  };

  return (
    <>
      <Modal.Body className="py-5 px-2 px-sm-4 px-md-5 bg-background rounded">
        {/* Header */}
        <div className="onboarding-header">
          <h1 className="text-center text-primary mb-2">
            Welcome aboard, {localStorage.getItem("firstName")}!
          </h1>
          <p className="lead text-bold text-center mb-5">
            Please verify that these records are correct
          </p>
        </div>

        {/* Body */}
        <div className="onboarding-body">
          <h2 className="h4">Organization</h2>
          <div className="form-card">
            <Row className="m-1">
              <Col>
                <strong>Tribe:</strong> {getOrganizationName()}
              </Col>
              {/* <Col sm={4}>
                <strong>BIA#:</strong> {userInfo?.biaNumber}
              </Col> */}
            </Row>
          </div>

          <h2 className="h4">Licenses</h2>
          <div className="form-card">
            {Array.isArray(licenses) &&
              licenses.map((license: License, i) => (
                <Row key={i} className="border-bottom border-primary m-1 py-3">
                  <Col sm={4}>
                    <strong>
                      {license.licenseTypeCode === "C"
                        ? "Commercial"
                        : "Subsistence"}
                      :
                    </strong>{" "}
                    {license.licenseNumber}
                  </Col>
                  <Col sm={4}>
                    <>
                      <strong>Active:</strong> {getDate(license.activeDate)}
                    </>
                  </Col>
                  <Col sm={4}>
                    <>
                      <strong>Expires:</strong> {getDate(license.expireDate)}
                    </>
                  </Col>
                </Row>
              ))}
          </div>

          {incorrectRecord && (
            <>
              <h2 className="h4">Tell us what's wrong</h2>
              <div className="form-card">
                <Form>
                  <Form.Group>
                    <Form.Label>
                      Please describe what is wrong, and we will correct it.
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={issueSummary}
                      onChange={(e) => setIssueSummary(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </div>

              <Row>
                <Col className="mb-3">
                  <Button
                    variant="success"
                    size="lg"
                    className="w-100 h-100"
                    onClick={() => {
                      if (issueSummary !== "") {
                        const submitIssue = async () =>
                          await onboardingIssue(issueSummary);
                        submitIssue();
                      }
                      setOnboardingStep(2);
                    }}
                  >
                    All Set
                  </Button>
                </Col>
              </Row>
            </>
          )}

          {!incorrectRecord && (
            <Row>
              <Col md={6} className="mb-3">
                <Button
                  variant="danger"
                  size="lg"
                  className="w-100 h-100"
                  onClick={() => setIncorrectRecord(true)}
                >
                  No, Something Is Incorrect
                </Button>
              </Col>
              <Col md={6} className="mb-3">
                <Button
                  variant="success"
                  size="lg"
                  className="w-100 h-100"
                  onClick={() => setOnboardingStep(2)}
                >
                  Yes, This Is Correct
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </Modal.Body>
    </>
  );
}
