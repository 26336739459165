import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { phoneReg } from "../../helpers/phoneReg";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { PatternFormat } from "react-number-format";

export interface ContactValues {
  email: string;
  phone: string;
}

export default function EditContact({
  formData,
  refId,
  initialValues,
  isAdmin,
  isUnaffiliated,
}: any) {
  // Set init values against the interface
  const values: ContactValues = { ...initialValues };
  // Init Formik
  const formik = useFormik({
    initialValues: {
      isAdmin: isAdmin,
      isUnaffiliated: isUnaffiliated,
      email: values.email,
      phone: values.phone,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape(
      {
        isAdmin: Yup.boolean().nullable(),
        email: Yup.string()
          .email("Must be a valid email address")
          .nullable()
          .when("phone", {
            is: (phone: string) => !phone && !isUnaffiliated,
            then: Yup.string().required("Email or Phone is required"),
          })
          .when("isAdmin", {
            is: true,
            then: Yup.string().required("Email is required"),
          })
          .when("isUnaffiliated", {
            is: true,
            then: Yup.string().notRequired(),
          }),

        phone: Yup.string()
          .matches(phoneReg, "Phone number is not valid")
          .nullable()
          .when("email", {
            is: (email: string) => !email && !isUnaffiliated,
            then: Yup.string().required("Email or Phone is required"),
          })
          .when("isAdmin", {
            is: true,
            then: Yup.string().required("Phone number is required"),
          })
          .when("isUnaffiliated", {
            is: true,
            then: Yup.string().notRequired(),
          }),
      },
      [
        ["email", "phone"],
        ["phone", "email"],
      ]
    ),
    onSubmit: () => {
      formData({
        values: {
          email: formik.values.email,
          phone: formik.values.phone.replace(/\D/g, ""),
        },
        validated: formik.isSubmitting
          ? Object.keys(formik.errors).length === 0
          : false,
      });
    },
  });

  //Update values on change
  React.useEffect(() => {
    formData({
      values: {
        email: formik.values.email,
        phone: formik.values.phone,
      },
      validated:
        formik.dirty && Object.keys(formik.errors).length === 0 ? true : false,
      errors: formik.errors,
    });
  }, [formik.values, formik.errors]);

  // Submit form from parent component
  React.useImperativeHandle(refId, () => ({
    Submit: async () => {
      await formik.submitForm();
    },
  }));

  return (
    <div className="form-card">
      <Form
        id="contact-form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        ref={refId}
      >
        <Row>
          {/* Email */}
          <Form.Group as={Col} sm className="mb-3" controlId="formEmail">
            <Form.Label>
              Email
              {!isUnaffiliated && (
                <span className="text-danger text-small ms-2">
                  (Email or Phone Required)
                </span>
              )}
            </Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.email && !!formik.errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.email}
            </Form.Control.Feedback>
            <p className="mb-0">
              {isAdmin ? "" : "*At least one contact method is required"}
            </p>
          </Form.Group>

          {/* Phone */}
          <Form.Group as={Col} sm className="mb-3" controlId="formPhone">
            <Form.Label>
              Phone
              {!isUnaffiliated && (
                <span className="text-danger text-small ms-2">
                  (Email or Phone Required)
                </span>
              )}
            </Form.Label>
            <PatternFormat
              customInput={Form.Control}
              type="tel"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              format="(###) ### ####"
              isInvalid={formik.touched.phone && !!formik.errors.phone}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.phone}
            </Form.Control.Feedback>
            <p className="mb-0">
              *By adding a phone number, you agree to receive mobile texts.
            </p>
          </Form.Group>
        </Row>
      </Form>
    </div>
  );
}
