import axios from "axios";

import { User } from "../models/user";

export const updateUser = async (user: User) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/User/update-user`,
      user
    );

    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
