import {
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { DailyTrip } from "../../models/dailyTrip";
import { Species } from "../../models/species";
import { Condition } from "../../models/condition";
import { Catch } from "../../models/catch";
import { FaFish, FaPlus, FaMinus, FaPlusCircle } from "react-icons/fa";
import HarvestEffort from "./harvest";
import ToggleButton from "react-bootstrap/esm/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/esm/ToggleButtonGroup";
import Card from "react-bootstrap/esm/Card";

export default function FishCaught({
  initialValues,
  refId,
  formData,
  speciesList,
  conditionsList,
}: any) {
  // Submit form from parent component
  useImperativeHandle(refId, () => ({
    Submit: async () => {
      // Empty Errors Array
      const formErrors: SetStateAction<string[]> = [];

      //Submit all forms
      for (const key in harvestRefs.current) {
        if (harvestRefs.current[key] !== null)
          await harvestRefs.current[key].Submit();
      }

      // Check if all species have > 0 pounds
      selectedHarvest.forEach((h) => {
        if (!h.validated)
          formErrors.push(
            `${h.speciesName}: Total weight landed or discarded weight must be greater than 0 lbs.`
          );
      });

      // Check if harvest has at least one grid selected
      selectedHarvest.forEach((h) => {
        if (h.grids?.length === 0) {
          formErrors.push(
            `${h.speciesName}: Must have at least one grid selected.`
          );
        }
      });

      if (formErrors.length === 0) {
        formData({
          values: { ...values, harvest: selectedHarvest },
          validated: true,
        });
      }
      setErrors(formErrors);
    },
  }));

  // Set init values against the interface
  const [values, setValues] = useState<DailyTrip>({ ...initialValues });
  const [errors, setErrors] = useState<string[]>([]);
  const listRef = useRef<any>(null);
  const [listHeight, setListHeight] = useState(0);
  const harvestRefs = useRef<any>({});
  const moreBtnRef = useRef<any>(null);
  const [btnDistance, setBtnDistance] = useState<any>({ "--buttonOffset": 0 });
  const [species, setSpecies] = useState<Species[]>(speciesList);
  const [selectedHarvest, setSelectedHarvest] = useState<Catch[]>(
    values.harvest
  );
  const [recentSpeciesIDAdd, setRecentSpeciesIDAdd] = useState(0);
  const [harvestID, setHarvestID] = useState(0);
  const [showFormList, setShowFormList] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [conditions, setConditions] = useState<Condition[]>(conditionsList);
  const [isSplitEvenly, setIsSplitEvenly] = useState(
    selectedHarvest.every((x) => x.grids?.length === values.grids.length)
  );
  const [splitHarvestByGrids, setSplitHarvestByGrids] = useState(false);

  // Default Trap Net to only enter harvest manually. Disable split evenly
  useEffect(() => {
    if (values.gear[0].gearName === "Trap Net") {
      enterManually();
      setSplitHarvestByGrids(true);
    }
  }, []);

  const getVisibleFormButtons = () => {
    const visible: Species[] = [];
    const speciesCount = showMore ? species.length : 5;

    // If there's less than four, fill with non selected items
    let i = 0;
    while (visible.length < speciesCount && i < species.length + 1) {
      if (!visible.includes(species[i])) {
        visible.push(species[i]);
      }
      i++;
    }
    return visible;
  };

  const getFormButtonsList = () => {
    const moreButtons = species.filter((s) => {
      return !getVisibleFormButtons().some((v: Species) => v.id === s.id);
    });
    return moreButtons;
  };

  const addHarvest = (species: Species, newlyAddedSpecies = false) => {
    const newHarvestsByGrid: Catch[] = [];
    let newID = Math.min(...selectedHarvest.map((e) => e.id), harvestID) - 1;

    const createNewHarvest = (harvestIDProp = 0) => {
      const newHarvest: Catch = {
        id: newID,
        speciesID: species.id,
        speciesName: species.name,
        grids: values.grids,
      };

      return newHarvest;
    };

    // Default, else is only for trap nets
    if (!splitHarvestByGrids) {
      const newHarvest: Catch = createNewHarvest();
      newHarvest.grids = values.grids;
      newHarvestsByGrid.push(newHarvest);
    } else {
      // Trap Nets
      // If we're adding the species from the top, auto populate harvest components for each grid
      if (newlyAddedSpecies) {
        values.grids.forEach((grid) => {
          newID = newID - 1;
          const newHarvest: Catch = createNewHarvest();
          newHarvest.id = newID;
          newHarvest.grids = [grid];
          newHarvestsByGrid.push(newHarvest);
        });
      } else {
        const newHarvest: Catch = createNewHarvest();
        newHarvest.grids = [];
        newHarvestsByGrid.push(newHarvest);
      }
    }

    setHarvestID(newID);
    setSelectedHarvest([...selectedHarvest, ...newHarvestsByGrid]);
    setRecentSpeciesIDAdd(species.id);
  };

  const removeHarvest = (harvestID: number) => {
    setSelectedHarvest(selectedHarvest.filter((h) => h.id !== harvestID));
  };

  const updateHarvest = (harvestID: number, harvestData: Catch) => {
    const index = selectedHarvest.findIndex((x) => x.id === harvestID);

    selectedHarvest[index] = { ...selectedHarvest[index], ...harvestData };
    setSelectedHarvest(selectedHarvest);

    formData({
      values: { ...values, harvest: selectedHarvest },
      validated: false,
    });
  };

  const splitEvenly = () => {
    // Check if already split evenly
    if (!isSplitEvenly) {
      // Add all grids to the harvest
      selectedHarvest.forEach((sh, i) => {
        selectedHarvest[i].grids = values.grids;
      });

      setSelectedHarvest(selectedHarvest);
      setIsSplitEvenly(true);
      setValues({ ...values, manualHarvest: false });
    }
  };

  const enterManually = () => {
    setIsSplitEvenly(false);
    setValues({ ...values, manualHarvest: true });
  };

  return (
    <>
      <h2 className="mb-3 fw-normal">Select All Harvested Species</h2>
      <div className="mb-5">
        <ol>
          <li>Select the species for each fish caught.</li>

          {values.grids.length > 1 && (
            <>
              <li>
                If unsure about the amount of a species caught in each grid:
                <ul>
                  <li>
                    Choose <strong>Split Evenly</strong>.
                  </li>
                  <li>
                    Enter the total amount landed and discarded for that species
                    throughout the trip.
                  </li>
                  <li>
                    The system will distribute the amounts evenly across grids.
                  </li>
                </ul>
              </li>

              <li>
                If aware of the amounts caught in each grid:
                <ul>
                  <li>
                    Choose <strong>Enter Manually</strong>.
                  </li>
                  <li>
                    Select the grid number and enter the landed and discarded
                    amounts.
                  </li>
                  <li>
                    Click <strong>Add additional 'species'</strong> for more
                    grids.
                  </li>
                </ul>
              </li>
            </>
          )}

          <li>
            Choose the appropriate Catch Condition for each amount of landed or
            discarded fish.
          </li>
          <li>
            To report different catch conditions for different amounts:
            <ul>
              <li>
                Click <strong>Add additional 'species'</strong>.
              </li>
            </ul>
          </li>
        </ol>
      </div>

      {/* Visible Buttons */}
      <Row
        // ref={parentList}
        className="g-2"
      >
        {getVisibleFormButtons().length > 0 && (
          <>
            {getVisibleFormButtons().map((s) => (
              <Col lg={2} xs={4} key={s.id}>
                <div
                  onClick={() => {
                    if (
                      !selectedHarvest.some((h: Catch) => h.speciesID === s.id)
                    )
                      addHarvest(s, splitHarvestByGrids);
                  }}
                  className={`form-button ${
                    selectedHarvest.some((x) => x.speciesID === s.id)
                      ? "selected"
                      : ""
                  }`}
                >
                  <FaFish />
                  <strong>{s.name}</strong>
                </div>
              </Col>
            ))}

            <Col lg={2} xs={4}>
              <div
                onClick={() => setShowMore(!showMore)}
                className="form-button"
                ref={moreBtnRef}
              >
                {showMore ? <FaMinus /> : <FaPlus />}
                <strong>Show {showMore ? "Less" : "More"} Species</strong>
              </div>
            </Col>
          </>
        )}
      </Row>

      {/* More Species List */}
      <div
        ref={refId}
        className="position-relative mb-4"
        style={{ minHeight: `${listHeight}px` }}
      >
        {/* Form Button List */}
        {showFormList && (
          <div ref={listRef} className="form-button-list" style={btnDistance}>
            <div className="popover-arrow"></div>
            <Row className="g-3">
              {getFormButtonsList().map((s) => (
                <Col md={3} key={s.id}>
                  <div
                    onClick={() => {
                      // Add effort if it's not already there
                      if (
                        !selectedHarvest.some(
                          (h: Catch) => h.speciesID === s.id
                        )
                      )
                        addHarvest(s);
                    }}
                    className="form-button-small"
                  >
                    {/* <FaFish /> */}
                    <strong>{s.name}</strong>
                  </div>
                </Col>
              ))}
            </Row>
            <p className="lead text-end mb-0 pt-4">
              <span
                className="cursor-pointer text-primary"
                onClick={() => setShowFormList(false)}
              >
                CLOSE DROPDOWN
              </span>
            </p>
          </div>
        )}
      </div>

      {/* Show split option if there are multiple grids */}
      {values.grids.length > 1 && values.gear[0].gearName !== "Trap Net" && (
        <div>
          <label className="form-label">Split harvest by grids</label>
          <ToggleButtonGroup
            className="w-100 mb-4"
            type="radio"
            name="splitHarvest"
            defaultValue={values.manualHarvest ? 2 : 1}
          >
            <ToggleButton
              id="splitEvenly1"
              variant="outline-primary"
              value={1}
              onClick={() => splitEvenly()}
            >
              Split Evenly
            </ToggleButton>
            <ToggleButton
              id="splitEvenly2"
              variant="outline-primary"
              value={2}
              onClick={() => enterManually()}
            >
              Enter Manually
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      )}

      {/* Submitted harvest */}

      {selectedHarvest.length === 0 ? (
        <h1 className="text-gray text-center my-5">No Species Selected</h1>
      ) : (
        <div
        // ref={parentCard}
        >
          {
            // Create array of species thats in the selected harvest
            species
              .filter((s: Species) =>
                // Filter species to only ones selected in the harvest
                selectedHarvest.map((h) => h.speciesID).includes(s.id)
              )
              //Loop through those selected harvests
              .map((sh: Species, j) => (
                <Card key={j} className="mb-4 shadow border-gray">
                  <Card.Header>
                    <span className="fw-bold">{sh.name}</span>
                  </Card.Header>

                  <Card.Body className="gear-card">
                    {/* Loop through all the same gear types */}
                    {selectedHarvest
                      .filter((x) => x.speciesID === sh.id)
                      .map((h: Catch, i) => {
                        return (
                          <>
                            <HarvestEffort
                              index={i}
                              key={`effort-${h.id}`}
                              grids={values.grids}
                              isSplitEvenly={isSplitEvenly}
                              harvest={h}
                              conditions={conditions}
                              removeHarvest={removeHarvest}
                              updateHarvest={updateHarvest}
                              useRadioControl={splitHarvestByGrids}
                              refID={(ref: any) =>
                                (harvestRefs.current[h.id] = ref)
                              }
                              newlyAdded={
                                h.speciesID === recentSpeciesIDAdd
                                  ? true
                                  : false
                              }
                            />
                          </>
                        );
                      })}

                    <hr />
                    <span
                      onClick={() => addHarvest(sh)}
                      className="ms-auto text-secondary fw-bold ms-3 cursor-pointer"
                    >
                      <FaPlusCircle className="mt-n1 me-1" />
                      Add additional {sh.name}
                    </span>
                  </Card.Body>
                </Card>
              ))
          }
        </div>
      )}

      {errors.length > 0 && (
        <Alert variant="danger" className="mt-4 mb-0">
          <Alert.Heading>Error</Alert.Heading>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </Alert>
      )}
    </>
  );
}
