import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { lookupOrganizations } from "../../api/lookupOrganizations";
import { lookupTribes } from "../../api/LookupTribes";

export default function SelectOrganization({
  name,
  value,
  onChange,
  onBlur,
  isInvalid,
  enabled = true,
  includeCORA = true,
  includeUnaffiliated = false,
  allTribes = false,
  returnAllOrgs = false,
}: any) {
  const [tribes, setTribes] = useState([]);

  useEffect(() => {
    const fetchTribes = async () => {
      let response;

      if (includeCORA) {
        response = await lookupOrganizations();
      } else {
        response = await lookupTribes(returnAllOrgs);
      }

      if (response) {
        if (!enabled) {
          response = response.filter(
            (tribe: { id: number; name: string }) => tribe.id === value
          );
        }
        setTribes(response);
      }
    };
    fetchTribes();
  }, [enabled, includeCORA, value]);

  return (
    <Form.Group
      className="mb-3 formSelectOrganization"
      controlId="formSelectOrganization"
    >
      <Form.Label>Organization</Form.Label>
      <Form.Select
        aria-label="Select Organization"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        isInvalid={isInvalid}
        disabled={!enabled}
      >
        {allTribes && <option value={-1}>All Tribes</option>}
        {includeUnaffiliated && <option value={-1}>Unaffiliated</option>}
        {Array.isArray(tribes) &&
          tribes.map((tribe: { id: number; name: string }, i) => (
            <option key={i} value={tribe.id}>
              {tribe.name}
            </option>
          ))}
      </Form.Select>
    </Form.Group>
  );
}
