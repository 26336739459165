import React from "react";
import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { setAuthToken } from "./api/setAuthToken";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { refreshAuthLogic } from "./api/handleRefreshToken";
import axios from "axios";
import { getRoles } from "./helpers/authHelper";
import { getVersion } from "./api/getVersion";

import Navigation from "./components/layout/navbar";
import LoginRegister from "./pages/shared/components/loginRegister";
import Login from "./pages/shared/login";
import Logout from "./pages/shared/logout";
import Register from "./pages/shared/register";
import AccountRecovery from "./pages/shared/accountRecovery";

// Auth
import RouteGuard from "./components/routeGuard";

// Admin
import AdminLayout from "./components/layout/adminLayout";
import AdminAllFishers from "./pages/admin/adminAllFishers";
import AdminAllAdmins from "./pages/admin/adminAllAdmins";
import AdminAllUsers from "./pages/admin/adminAllUsers";
import AdminSettings from "./pages/admin/adminSettings";
import RegisterFisher from "./pages/admin/adminRegisterFisher";
import AdminAllHelpers from "./pages/admin/adminAllHelpers";
import AdminHome from "./pages/admin/adminHome";

// Fisher
import FisherHomeDesktop from "./pages/fisher/fisherHome";
import EditUser from "./pages/admin/adminEditUser";
import RegisterAdmin from "./pages/admin/adminRegisterAdmin";
import ChangeOnetimePassword from "./pages/shared/changeOnetimePassword";
import FisherLayout from "./components/layout/fisherLayout";
import DevBanner from "./components/devBanner";
import FisherProfile from "./pages/fisher/fisherProfile";
import RegisterHelper from "./pages/admin/adminRegisterHelper";
import EditHelper from "./pages/admin/adminEditHelper";
import AdminReports from "./pages/admin/adminReports";
import AdminEditCatchReport from "./pages/admin/adminEditCatchReport";
import PrintCatchReport from "./pages/shared/printCatchReport";
import DownloadCatchReport from "./pages/shared/downloadCatchReport";
import Support from "./pages/shared/support";
import ToastContainer from "react-bootstrap/esm/ToastContainer";
import Toast from "react-bootstrap/esm/Toast";
import AdminExportReports from "./pages/admin/adminExportReports";
import AdminExportAgencyReports from "./pages/admin/adminExportReportsAgency";
import AdminAgencyProfile from "./pages/admin/adminAgencyProfile";
import useCacheBuster from "./helpers/cacheBuster";
import AdminAllAgencies from "./pages/admin/adminAllAgencies";
import RegisterAgency from "./pages/admin/adminRegisterAgency";
import FisherOverview from "./pages/admin/adminFisherOverview";
import AdminSpeciesList from "./pages/admin/adminSpeciesList";
import AdminManagementUnitList from "./pages/admin/adminManagementUnitList";
import AdminGridList from "./pages/admin/adminGridList";
import { getAuthCheck } from "./api/getAuthCheck";

function App() {
  const [showAPIErrorToast, setShowAPIErrorToast] = useState(false);
  const [dataAPIErrorToast, setDataAPIErrorToast] = useState<string[]>([]);

  // Add hook for checking web app versions, and hard reloading if there is a mismatch
  // useCacheBuster();

  //check jwt token
  const token = localStorage.getItem("accessToken");
  if (token) {
    setAuthToken(token);
    createAuthRefreshInterceptor(axios, refreshAuthLogic);
  }

  // Check current login status
  getAuthCheck();

  // Show toast on all failed api requests
  axios.interceptors.response.use(
    (res) => res,
    (err) => {
      //Logged in
      if (token) {
        const errors: string[] = [];

        if (err.config.url && err.message)
          errors.push(`${err.message}: ${err.config.url}`);

        if (err.response.data.title) errors.push(`${err.response.data.title}`);

        if (err.response.data.message)
          errors.push(`${err.response.data.message}`);

        if (err.response.data.errors)
          Object.entries(err.response.data.errors).forEach(
            ([key, value], i) => {
              errors.push(`${key}: ${value}`);
            }
          );

        setDataAPIErrorToast(errors);
        setShowAPIErrorToast(true);
      }
      return Promise.reject(err);
    }
  );

  const roles = getRoles();
  const [version, setVersion] = useState();

  useEffect(() => {
    const fetch = async () => {
      const ver = await getVersion();
      if (ver) {
        setVersion(ver);
        console.log(ver.message.replace(", ", "\n"));
      }
    };
    fetch();
  }, []);

  return (
    <div className="App">
      <div className="d-flex flex-column h-100">
        <Navigation version={version} />
        {/* <DevBanner /> */}
        <ToastContainer position={"bottom-start"}>
          <Toast
            onClose={() => setShowAPIErrorToast(false)}
            show={showAPIErrorToast}
            delay={8000}
            autohide
            className="ms-4 mb-4"
            bg="danger"
          >
            <Toast.Header>
              <img src="" className="rounded me-2" alt="" />
              <strong className="me-auto">Error</strong>
            </Toast.Header>
            <Toast.Body className="text-white">
              <ul>
                {dataAPIErrorToast.map((error, i) => (
                  <li key={i}>{error}</li>
                ))}
              </ul>
            </Toast.Body>
          </Toast>
        </ToastContainer>
        <div className="flex-grow-1">
          <Routes>
            {/* Logged Off */}
            {!token && (
              <>
                <Route path="/" element={<LoginRegister />}>
                  <Route path="/" element={<Login />} />
                  <Route path="/r" element={<Register />} />
                  <Route path="/register" element={<Register />} />
                </Route>
                <Route path="/f" element={<AccountRecovery />} />
                <Route path="/recover" element={<AccountRecovery />} />
              </>
            )}

            {/* Force password change */}
            {token &&
              localStorage.getItem("requirePasswordChange") === "true" && (
                <>
                  <Route
                    path="/changePassword"
                    element={<ChangeOnetimePassword />}
                  />

                  <Route
                    path="*"
                    element={<Navigate to="/changePassword" replace />}
                  />
                </>
              )}

            {/* Agency Routes */}
            {token &&
              !localStorage.getItem("requirePasswordChange") &&
              roles.includes("Agency") && (
                <>
                  <Route element={<RouteGuard />}>
                    <Route element={<AdminLayout />}>
                      <Route path="/" element={<AdminHome />} />
                      <Route
                        path="/export-agency"
                        element={<AdminExportAgencyReports />}
                      />
                      <Route path="/profile" element={<AdminAgencyProfile />} />
                    </Route>
                  </Route>
                </>
              )}

            {/* Admin Routes */}
            {token &&
              !localStorage.getItem("requirePasswordChange") &&
              (roles.includes("CORA_Admin") ||
                roles.includes("Tribal_Admin")) && (
                <>
                  <Route element={<RouteGuard />}>
                    <Route element={<AdminLayout />}>
                      <Route path="/" element={<AdminHome />} />
                      <Route path="/reports" element={<AdminReports />} />
                      <Route path="/export" element={<AdminExportReports />} />
                      <Route path="/species" element={<AdminSpeciesList />} />
                      <Route path="/managementunits" element={<AdminManagementUnitList />} />
                      <Route path="/grids" element={<AdminGridList />} />

                      <Route
                        path="/export-agency"
                        element={<AdminExportAgencyReports />}
                      />
                      <Route path="/helpers" element={<AdminAllHelpers />} />
                      <Route path="/fishers" element={<AdminAllFishers />} />
                      <Route path="/admins" element={<AdminAllAdmins />} />
                      <Route path="/users" element={<AdminAllUsers />} />
                      <Route path="/user/edit/:userID" element={<EditUser />} />
                      <Route
                        path="/fisher/:userID"
                        element={<FisherOverview />}
                      />

                      <Route
                        path="/reports/edit/:catchReportID"
                        element={<AdminEditCatchReport />}
                      />
                      <Route
                        path="/helper/edit/:helperID"
                        element={<EditHelper />}
                      />
                      <Route path="/profile" element={<EditUser />} />

                      <Route
                        path="/register/helper"
                        element={<RegisterHelper />}
                      />
                      <Route
                        path="/register/fisher"
                        element={<RegisterFisher />}
                      />
                      <Route
                        path="/register/admin"
                        element={<RegisterAdmin />}
                      />

                      <Route path="/settings" element={<AdminSettings />} />

                      {/* CORA Only */}
                      {roles.includes("CORA_Admin") && (
                        <>
                          <Route
                            path="/agencies"
                            element={<AdminAllAgencies />}
                          />

                          <Route
                            path="/register/agency"
                            element={<RegisterAgency />}
                          />
                        </>
                      )}
                    </Route>
                  </Route>
                </>
              )}

            {/* Fisher Routes */}
            {token &&
              roles.includes("Fisher") &&
              !localStorage.getItem("requirePasswordChange") && (
                <>
                  <Route element={<FisherLayout />}>
                    <Route path="/" element={<FisherHomeDesktop />} />
                    <Route path="/profile" element={<FisherProfile />} />
                  </Route>
                </>
              )}

            {/* Logged in or out Routes */}
            {
              <>
                <Route element={<LoginRegister />}>
                  <Route path="/r" element={<Register />} />
                </Route>
                <Route path="/f" element={<AccountRecovery />} />
              </>
            }

            {token && !roles.includes("Agency") && (
              <>
                <Route path="/support" element={<Support />} />
                <Route
                  path="reports/preview/:catchReportID"
                  element={<PrintCatchReport />}
                />
                <Route
                  path="reports/download/:catchReportID"
                  element={<DownloadCatchReport />}
                />
              </>
            )}

            {/* All Roles */}
            <Route path="/logout" element={<Logout />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
