import { getUserID } from "../helpers/authHelper";
import { DailyTrip } from "./dailyTrip";
import { Helper } from "./helper";

export class CatchReport {
  userID: number;
  fisherID?: number;
  id: number;
  displayName?: string;
  tripTypeID: number;
  tripTypeCode: string;
  beginDate: string;
  endDate: string;
  noFishReport: boolean;
  assistName: string;
  assistRelationship: string;
  comments: string;
  tripIDs: number[];
  trips?: DailyTrip[];
  helpers: Helper[];
  reviewDate?: string;
  reviewed?: boolean;
  originReportID?: number;
  previousReportID?: number;
  version?: number;
  revisions: number;
  editInProgress: boolean;

  constructor({
    userID = parseInt(getUserID()),
    fisherID = undefined,
    id = -1,
    displayName = "",
    tripTypeID = 1,
    tripTypeCode = "COMM",
    beginDate = "",
    endDate = "",
    noFishReport = true,
    assistName = "",
    assistRelationship = "",
    comments = "",
    tripIDs = [],
    trips = [],
    helpers = [],
    reviewDate = "",
    reviewed = false,
    originReportID = undefined,
    previousReportID = undefined,
    version = 1,
    revisions = 0,
    editInProgress = false,
  } = {}) {
    this.userID = userID;
    this.fisherID = fisherID;
    this.id = id;
    this.displayName = displayName;
    this.tripTypeID = tripTypeID;
    this.tripTypeCode = tripTypeCode;
    this.beginDate = beginDate;
    this.endDate = endDate;
    this.noFishReport = noFishReport;
    this.assistName = assistName;
    this.assistRelationship = assistRelationship;
    this.comments = comments;
    this.tripIDs = tripIDs;
    this.trips = trips;
    this.helpers = helpers;
    this.reviewDate = reviewDate;
    this.reviewed = reviewed;
    this.originReportID = originReportID;
    this.previousReportID = previousReportID;
    this.version = version;
    this.revisions = revisions;
    this.editInProgress = editInProgress;
  }
}
