import { useRef, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { FaArrowLeft, FaArrowRight, FaCircle } from "react-icons/fa";
import type { Value } from "react-multi-date-picker";
import { Calendar, DateObject } from "react-multi-date-picker";
import { getFormattedDateYYYYMMDD } from "../helpers/getFormattedDateYYYYMMDD";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

export default function MultiMonthReportCalendar({
  dailyTripRef,
  tripType,
  tripDates,
  nextDeadline,
  deadlines,
}: any) {
  const [calendarOne, setCalendarOne] = useState<DateObject>(new DateObject());
  const [calendarTwo, setCalendarTwo] = useState<DateObject>(
    new DateObject().add(1, "month")
  );

  const calendarOneRef = useRef<any>();
  const calendarTwoRef = useRef<any>();

  function update(value: number) {
    let dateOne = calendarOneRef.current?.date;
    let dateTwo = calendarTwoRef.current?.date;

    calendarOneRef.current.set("month", dateOne["month"] + value);
    calendarTwoRef.current.set("month", dateTwo["month"] + value);

    setCalendarOne(new DateObject(dateOne));
    setCalendarTwo(new DateObject(dateTwo));
  }

  const clickDay = (date: Value) => {
    if (date instanceof DateObject) {
      date = date.toDate();
      dailyTripRef.current?.newDailyTrip(
        tripType,
        getFormattedDateYYYYMMDD(String(date))
      );
    }
  };

  return (
    <div className="multi-month-report-calendar">
      <Row className="d-flex d-lg-block">
        <Col xs={12} md={6} lg={12} className="mb-3">
          <Calendar
            ref={calendarOneRef}
            buttons={false}
            className="shadow-sm w-100 h-100 d-flex justify-content-center"
            disableMonthPicker
            disableYearPicker
            currentDate={calendarOne}
            onChange={clickDay}
            mapDays={({ date }) => {
              // Styles for daily trips
              if (
                tripDates.find(
                  (trip: any) =>
                    getFormattedDateYYYYMMDD(trip.date) ===
                      date.format("YYYY-MM-DD") && trip.submitted === false
                )?.complete === true
              ) {
                return { className: "cal-daily-trip" };
              }

              // Styles for incomplete daily trips
              if (
                tripDates.find(
                  (trip: any) =>
                    getFormattedDateYYYYMMDD(trip.date) ===
                      date.format("YYYY-MM-DD") && trip.submitted === false
                )?.complete === false
              ) {
                return { className: "cal-incomplete-daily-trip" };
              }

              // Styles for next deadline
              if (
                getFormattedDateYYYYMMDD(nextDeadline) ===
                date.format("YYYY-MM-DD")
              ) {
                return { className: "next-catch-due-date" };
              }

              // Styles for all deadlines
              if (
                deadlines.some(
                  (dl: any) =>
                    getFormattedDateYYYYMMDD(dl) === date.format("YYYY-MM-DD")
                )
              ) {
                return { className: "catch-due-date" };
              }
            }}
          />
        </Col>
        <Col xs={12} md={6} lg={12} className="mb-3 d-none d-md-block">
          <Calendar
            buttons={false}
            ref={calendarTwoRef}
            className="shadow-sm w-100 h-100 d-flex justify-content-center"
            disableMonthPicker
            disableYearPicker
            currentDate={calendarTwo}
            onChange={clickDay}
            mapDays={({ date }) => {
              // Styles for daily trips
              if (
                tripDates.find(
                  (trip: any) =>
                    getFormattedDateYYYYMMDD(trip.date) ===
                      date.format("YYYY-MM-DD") && trip.submitted === false
                )?.complete === true
              ) {
                return { className: "cal-daily-trip" };
              }

              // Styles for incomplete daily trips
              if (
                tripDates.find(
                  (trip: any) =>
                    getFormattedDateYYYYMMDD(trip.date) ===
                      date.format("YYYY-MM-DD") && trip.submitted === false
                )?.complete === false
              ) {
                return { className: "cal-incomplete-daily-trip" };
              }

              // Styles for next deadline
              if (
                getFormattedDateYYYYMMDD(nextDeadline) ===
                date.format("YYYY-MM-DD")
              ) {
                return { className: "next-catch-due-date" };
              }

              // Styles for all deadlines
              if (
                deadlines.some(
                  (dl: any) =>
                    getFormattedDateYYYYMMDD(dl) === date.format("YYYY-MM-DD")
                )
              ) {
                return { className: "catch-due-date" };
              }
            }}
          />
        </Col>
      </Row>

      <div className="form-card mb-3">
        <ul className="list-unstyled d-flex flex-wrap d-lg-block mb-0">
          <li className="me-3 me-lg-0">
            <FaCircle className="text-secondary me-1 me-lg-2 mb-1" />
            Daily Trip
          </li>
          <li className="me-3 me-lg-0">
            <FaCircle className="text-warning me-1 me-lg-2 mb-1" />
            Incomplete Daily Trip
          </li>
          <li className="me-3 me-lg-0">
            <FaCircle className="text-danger me-1 me-lg-2 mb-1" />
            Next Catch Report Due
          </li>
          <li className="me-3 me-lg-0">
            <FaCircle className="text-danger me-1 me-lg-2 mb-1 opacity-5" />
            All Deadlines
          </li>
        </ul>
      </div>

      <div className="d-flex mb-3">
        <Button
          variant="secondary"
          size="sm"
          className="w-100 me-2"
          onClick={() => update(-1)}
        >
          <FaArrowLeft /> Previous
        </Button>
        <Button
          variant="secondary"
          size="sm"
          className="w-100 ms-2"
          onClick={() => update(1)}
        >
          Next <FaArrowRight />
        </Button>
      </div>
    </div>
  );
}
