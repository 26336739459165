import axios from "axios";
import { createHashPassword } from "../helpers/createHashPassword";

export interface Register {
  id: number;
  code: string;
  username: string;
  password: string;
}

export const register = async (payload: Register) => {
  const passwordHash = await createHashPassword(payload.password);

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/User/complete-registration`,
      {
        id: payload.id,
        code: payload.code,
        username: payload.username,
        passwordHash: passwordHash,
      }
    );

    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
