import axios from "axios";

import { Permit } from "../models/permit";

export const postPermit = async (permit: Permit) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/Permit/permit`,
      permit
    );
    return response;
  } catch (error: any) {
    console.error(error);
    return error.response;
  }
};
