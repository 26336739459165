import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";
import EditContact, {
  ContactValues,
} from "../../components/formControls/editContact";
import EditPersonalInformation, {
  PersonalInformationValues,
} from "../../components/formControls/editPersonalInformation";
import { BIACodeValues } from "../../components/formControls/editBIACode";
import { apiSuccess } from "../../helpers/apiSuccess";
import { User } from "../../models/user";
import {
  getOrganizationID,
  getOrganizationName,
  getUserID,
} from "../../helpers/authHelper";
import EditVessels from "../../components/formControls/editVessels";
import FormToast, { ToastData } from "../../components/formToast";
import { useNavigate } from "react-router-dom";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { getProfile } from "../../api/getProfile";
import EditUsernamePassword from "../../components/formControls/editUsernamePassword";
import { updateProfile } from "../../api/updateProfile";
import ReadonlyPermits from "../../components/formControls/readonlyPermits";
import { getPermits } from "../../api/getPermits";
import ReadonlyLicenseDetails from "../../components/formControls/readonlyLicensesDetails";

export default function FisherProfile() {
  const userID = getUserID();

  interface FormData {
    values: any;
    validated: boolean;
    errors: any;
  }

  // Toast notification and Navigation
  const navigate = useNavigate();
  const [hasPermits, setHasPermits] = useState(false);
  const [toastData, setToastData] = useState<ToastData>({
    show: false,
    title: "",
    body: "",
    bg: "",
  });

  useEffect(() => {
    const getInitialValues = async () => {
      const response = await getProfile();
      const permits = await getPermits();

      setHasPermits(permits.length > 0);

      setPersonalInformationValues({
        firstName: response.firstName ?? undefined,
        middleName: response.middleName ?? undefined,
        lastName: response.lastName ?? undefined,
        address1: response.address1 ?? undefined,
        city: response.city ?? undefined,
        stateCode: response.stateCode ?? undefined,
        zip: response.zip ?? undefined,
      });

      setBiaCodeValues({
        organizationID: response.organizationID ?? 0,
        biaNumber: response.biaNumber ?? undefined,
        organizationCodeNumber: response.organizationCodeNumber ?? undefined,
      });

      setContactValues({
        email: response.email ?? undefined,
        phone: response.phone ?? undefined,
      });
    };
    getInitialValues();
  }, []);

  // Set initial values
  const [personalInformationValues, setPersonalInformationValues] =
    useState<PersonalInformationValues>({
      firstName: "",
      middleName: "",
      lastName: "",
      address1: "",
      city: "",
      stateCode: "",
      zip: "",
    });

  const [biaCodeValues, setBiaCodeValues] = useState<BIACodeValues>({
    organizationID: getOrganizationID(),
    biaNumber: "",
    organizationCodeNumber: "",
  });

  const [contactValues, setContactValues] = useState<ContactValues>({
    email: "",
    phone: "",
  });

  // Set all forms in single object
  const [formData, setFormData] = React.useState({
    editPersonalInformation: {
      values: personalInformationValues,
      validated: false,
      errors: {},
    },
    editContact: { values: contactValues, validated: false, errors: {} },
  });

  // Create Refs for each form
  const editPersonalInformationRef = React.useRef<HTMLFormElement>(null);
  const editContactRef = React.useRef<HTMLFormElement>(null);

  // Handle form data when updated
  const handleChangeEditPersonalInformation = (data: FormData) => {
    formData.editPersonalInformation = { ...data };
    setFormData(formData);
  };

  const handleChangeEditContact = (data: FormData) => {
    formData.editContact = { ...data };
    setFormData(formData);
  };

  // Submit all forms
  async function handleSubmit(back: boolean = false) {
    try {
      await editPersonalInformationRef.current?.Submit();
      await editContactRef.current?.Submit();

      if (
        formData.editPersonalInformation.validated &&
        formData.editContact.validated
      ) {
        let data: User = {
          userID: userID,
          ...formData.editPersonalInformation.values,
          ...formData.editContact.values,
        };

        const submit = async () => {
          const response = await updateProfile(data);

          if (apiSuccess(response.status)) {
            setToastData({
              show: true,
              bg: "Success",
              title: "Success",
              body: "Saved Successfully",
            });

            if (back) {
              setTimeout(() => navigate(-1), 2000);
            }
          }
        };

        submit();
      } else {
        // Form Validation Errors
        setToastData({
          show: true,
          bg: "Danger",
          title: "Error",
          body: "There were validation errors",
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Container>
      <FormToast toastData={toastData} setData={setToastData} />

      <h1 className="text-primary mb-5">Edit Profile</h1>

      {/* BIA & Code - Fisher Only */}
      <Row>
        <Col lg={3}>
          <h2 className="form-header">Tribe</h2>
        </Col>
        <Col>
          <div className="form-card">
            <Row className="mb-2">
              <Form.Group
                as={Col}
                xs={12}
                className="mb-3"
                controlId="formTribeOrg"
              >
                <Form.Label>Tribe</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={getOrganizationName()}
                />
              </Form.Group>

              {/* <Form.Group as={Col} sm className="mb-3" controlId="formBIA">
                <Form.Label>BIA Number</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={biaCodeValues.biaNumber}
                />
              </Form.Group> */}
            </Row>
          </div>
        </Col>
      </Row>

      {/* Personal Information */}
      <Row>
        <Col lg={3}>
          <h2 className="form-header">Personal Information</h2>
        </Col>
        <Col>
          <EditPersonalInformation
            formData={handleChangeEditPersonalInformation}
            refId={editPersonalInformationRef}
            initialValues={personalInformationValues}
          />
        </Col>
      </Row>

      {/* Access Information */}
      <Row>
        <Col lg={3}>
          <h2 className="form-header">Account Settings</h2>
        </Col>
        <Col>
          <EditUsernamePassword />
        </Col>
      </Row>

      {/* Contact Details */}
      <Row>
        <Col lg={3}>
          <h2 className="form-header">Contact Details</h2>
        </Col>
        <Col>
          <EditContact
            formData={handleChangeEditContact}
            refId={editContactRef}
            initialValues={contactValues}
          />
        </Col>
      </Row>

      {/* Licenses */}
      <Row>
        <Col lg={3}>
          <h2 className="form-header">Licenses</h2>
        </Col>
        <Col>
          <ReadonlyLicenseDetails userID={userID} />
        </Col>
      </Row>

      {/* Permits */}
      {hasPermits && (
        <Row>
          <Col lg={3}>
            <h2 className="form-header">Permits</h2>
          </Col>
          <Col>
            <ReadonlyPermits userID={userID} />
          </Col>
        </Row>
      )}

      {/* Vessels */}
      <Row>
        <Col lg={3}>
          <h2 className="form-header">Vessels &amp; Vehicles</h2>
        </Col>
        <Col>
          <EditVessels userID={userID} />
        </Col>
      </Row>

      <Row className="position-sticky bottom-0 pb-3" style={{ zIndex: "10" }}>
        <Col lg={3} />
        <Col>
          <ButtonGroup className="w-100">
            <Button
              variant="success"
              size="lg"
              className="w-100"
              onClick={() => handleSubmit(false)}
            >
              Save Profile
            </Button>

            <DropdownButton
              as={ButtonGroup}
              title=""
              variant="success"
              id="bg-nested-dropdown"
              align="end"
            >
              <Dropdown.Item
                eventKey="1"
                className="text-success"
                onClick={() => handleSubmit(true)}
              >
                Save and go back
              </Dropdown.Item>
            </DropdownButton>
          </ButtonGroup>
        </Col>
      </Row>
    </Container>
  );
}
