import axios from "axios";

export const lookupSpeciesList = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Lookup/species`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
