import Form from "react-bootstrap/esm/Form";
import InputGroup from "react-bootstrap/esm/InputGroup";
import { useFormik } from "formik";
import * as Yup from "yup";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/Col";
import { useEffect, useImperativeHandle, useState } from "react";
import { TripEffort } from "../../models/tripEffort";
import { removeUndefined } from "../../helpers/removeUndefined";
import { convertNumericStringToInt } from "../../helpers/convertNumericStringsToInts";

export default function GearEffort({
  //grids,
  gear,
  effort,
  updateEffort,
  refID,
}: any) {
  // Submit form from parent component
  useImperativeHandle(refID, () => ({
    Submit: async () => {
      await formik.submitForm();
      const errors = await formik.validateForm();
      const gearEffort = removeUndefined(
        convertNumericStringToInt(formik.values)
      );

      const data: TripEffort = {
        validated: Object.keys(errors).length === 0,
        ...gearEffort,
      };

      updateEffort(effort.id, data);
    },
  }));

  const attributeByGear = {
    // Required
    feetNetLifted: ["GL", "GS", "GX"],
    numTrapsLifted: ["TN"],
    numLines: ["CH", "RH", "TR"],
    numHauls: ["PS", "BS"],
    numLifts: ["HN", "CN"],
    numHooks: ["SH"],
    depthFished: ["GL", "GS", "GX", "TN"],
    // Preferred
    nightsOut: ["GL", "GS", "GX", "TN", "CN", "HN", "SH", "SP", "BS", "PS"],
    hoursFished: ["TR", "CH", "RH"],
  };

  const formik = useFormik({
    initialValues: {
      gearKey: gear.code,
      depthStart: effort.depthStart ?? undefined,
      depthEnd: effort.depthEnd ?? undefined,
      waterTemp: effort.waterTemp ?? undefined,
      feetNetLifted: effort.feetNetLifted ?? undefined,
      numTrapsLifted: effort.numTrapsLifted ?? undefined,
      numHooks: effort.numHooks ?? undefined,
      numLines: effort.numLines ?? undefined,
      hoursFished: effort.hoursFished ?? undefined,
      numHauls: effort.numHauls ?? undefined,
      numLifts: effort.numLifts ?? undefined,
      nightsOut: effort.nightsOut ?? undefined,
    },
    enableReinitialize: false,
    validationSchema: Yup.object().shape({
      feetNetLifted: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .integer("Net Lifted must be a whole number")
        .max(50000, "Maximum of 50,000 feet")
        .when("gearKey", {
          is: "GL",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required")
            .positive("Net Lifted must be positive"),
        })
        .when("gearKey", {
          is: "GS",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required")
            .positive("Net Lifted must be positive"),
        })
        .when("gearKey", {
          is: "GX",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required")
            .positive("Net Lifted must be positive"),
        }),
      numTrapsLifted: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .when("gearKey", {
          is: "TN",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required")
            .integer("Number of Traps Lifted must be a whole number")
            .min(1, "Number of Traps Lifted has a minimum of 1")
            .max(20, "Number of Traps Lifted has a maximum of 20"),
        }),
      numLines: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()

        .when("gearKey", {
          is: "CH",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()

            .required("Required"),
        })
        .when("gearKey", {
          is: "RH",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()

            .required("Required"),
        })
        .when("gearKey", {
          is: "TR",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required"),
        }),
      numHauls: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .when("gearKey", {
          is: "SN",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required")
            .integer("Number of Hauls must be a whole number")
            .positive("Number of Hauls must be positive")
            .max(50, "Number of Hauls must be less than 50"),
        })
        .when("gearKey", {
          is: "BS",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required")
            .integer("Number of Hauls must be a whole number")
            .positive("Number of Hauls must be positive")
            .max(50, "Number of Hauls must be less than 50"),
        }),
      numHooks: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .when("gearKey", {
          is: "SH",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required"),
        }),
      numLifts: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .integer("Number of Lifts must be a whole number")
        .when("gearKey", {
          is: "HN",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required")
            .min(1, "Number of Lifts has a minimum of 1"),
        })
        .when("gearKey", {
          is: "CN",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required")
            .min(1, "Number of Lifts has a minimum of 1"),
        }),
      depthStart: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .integer("Minimum Depth must be a whole number")
        .max(1000, "Minimum Depth has a maximum of 1,000 feet")
        .max(
          Yup.ref("depthEnd"),
          "Minimum Depth must be less than or equal to Maximum Depth"
        )
        .when("gearKey", {
          is: "GL",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required")
            .min(1, "Minimum Depth has a minimum of 1 foot"),
        })
        .when("gearKey", {
          is: "GS",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required")
            .min(1, "Minimum Depth has a minimum of 1 foot"),
        })
        .when("gearKey", {
          is: "GX",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required")
            .min(1, "Minimum Depth has a minimum of 1 foot"),
        })
        .when("gearKey", {
          is: "TN",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required")
            .min(1, "Minimum Depth has a minimum of 1 foot"),
        }),
      depthEnd: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .integer("Maximum Depth must be a whole number")
        .max(1000, "Maximum Depth has a maximum of 1,000 feet")
        .when("gearKey", {
          is: "GL",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required")
            .min(1, "Maximum Depth has a minimum of 1 foot"),
        })
        .when("gearKey", {
          is: "GS",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required")
            .min(1, "Maximum Depth has a minimum of 1 foot"),
        })
        .when("gearKey", {
          is: "GX",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required")
            .min(1, "Maximum Depth has a minimum of 1 foot"),
        })
        .when("gearKey", {
          is: "TN",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .required("Required")
            .min(1, "Maximum Depth has a minimum of 1 foot"),
        }),
      hoursFished: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()

        .max(99, "Hours Fished has a maximum of 99 hours")
        .min(0, "Hours Fished cannot be negative"),
      waterTemp: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .min(
          -25,
          "Water Temp has a minimum of -25" + String.fromCharCode(176) + "F"
        )
        .max(
          100,
          "Water Temp has a maximum of 100" + String.fromCharCode(176) + "F"
        ),
      nightsOut: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .integer("Nights Out must be a whole number")
        .min(0, "Nights Out has a minimum of 0 nights")
        .max(30, "Nights Out has a maximum of 30 nights")
        .when("gearKey", {
          is: "GL",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .max(15, "Nights Out has a maximum of 15 nights"),
        })
        .when("gearKey", {
          is: "GS",
          then: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .max(15, "Nights Out has a maximum of 15 nights"),
        }),
    }),
    onSubmit: () => {},
  });

  useEffect(() => {
    const gearEffort = removeUndefined(
      convertNumericStringToInt(formik.values)
    );

    const data: TripEffort = {
      validated: false,
      ...gearEffort,
    };

    updateEffort(effort.id, data);
  }, [formik.values]);

  return (
    <Form
      noValidate
      onSubmit={formik.handleSubmit}
      ref={refID}
      autoComplete="off"
    >
      <Row>
        {/* Net Lifted */}
        {attributeByGear.feetNetLifted.includes(gear.code) && (
          <Form.Group as={Col} lg={4} md={6}>
            <Form.Label>
              Net Lifted
              <span className="text-danger text-small ms-2">(Required)</span>
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                aria-label="Net Lifted"
                type="number"
                min={0}
                name="feetNetLifted"
                value={formik.values.feetNetLifted}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.touched.feetNetLifted && !!formik.errors.feetNetLifted
                }
              />
              <InputGroup.Text>ft.</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        )}

        {/* Number Traps Lifted */}
        {attributeByGear.numTrapsLifted.includes(gear.code) && (
          <Form.Group as={Col} lg={4} md={6}>
            <Form.Label>
              Number Traps Lifted
              <span className="text-danger text-small ms-2">(Required)</span>
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                aria-label="Number Traps Lifted"
                type="number"
                min={0}
                name="numTrapsLifted"
                value={formik.values.numTrapsLifted}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.touched.numTrapsLifted &&
                  !!formik.errors.numTrapsLifted
                }
              />
              <InputGroup.Text>Traps</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        )}

        {/* Number Hooks */}
        {attributeByGear.numHooks.includes(gear.code) && (
          <Form.Group as={Col} lg={4} md={6}>
            <Form.Label>
              Number of Hooks
              <span className="text-danger text-small ms-2">(Required)</span>
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                aria-label="Number of Hooks"
                type="number"
                min={0}
                name="numHooks"
                value={formik.values.numHooks}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.numHooks && !!formik.errors.numHooks}
              />
              <InputGroup.Text>Hooks</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        )}

        {/* Number Lines */}
        {attributeByGear.numLines.includes(gear.code) && (
          <Form.Group as={Col} lg={4} md={6}>
            <Form.Label>
              Number of Lines
              <span className="text-danger text-small ms-2">(Required)</span>
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                aria-label="Number of Lines"
                type="number"
                min={0}
                name="numLines"
                value={formik.values.numLines}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.numLines && !!formik.errors.numLines}
              />
              <InputGroup.Text>Lines</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        )}

        {/* Hours Fished */}
        {attributeByGear.hoursFished.includes(gear.code) && (
          <Form.Group as={Col} lg={4} md={6}>
            <Form.Label>Hours Fished</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                aria-label="Hours Fished"
                type="number"
                min={0}
                name="hoursFished"
                value={formik.values.hoursFished}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.touched.hoursFished && !!formik.errors.hoursFished
                }
              />
              <InputGroup.Text>Hours</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        )}

        {/* Number of Hauls */}
        {attributeByGear.numHauls.includes(gear.code) && (
          <Form.Group as={Col} lg={4} md={6}>
            <Form.Label>
              Number of Hauls
              <span className="text-danger text-small ms-2">(Required)</span>
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                aria-label="Number of Hauls"
                type="number"
                min={0}
                name="numHauls"
                value={formik.values.numHauls}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.numHauls && !!formik.errors.numHauls}
              />
              <InputGroup.Text>Hauls</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        )}

        {/* Number of Lifts */}
        {attributeByGear.numLifts.includes(gear.code) && (
          <Form.Group as={Col} lg={4} md={6}>
            <Form.Label>
              Number of Lifts
              <span className="text-danger text-small ms-2">(Required)</span>
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                aria-label="Number of Lifts"
                type="number"
                min={0}
                name="numLifts"
                value={formik.values.numLifts}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.numLifts && !!formik.errors.numLifts}
              />
              <InputGroup.Text>Lifts</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        )}

        {/* Depth Start */}
        {attributeByGear.depthFished.includes(gear.code) && (
          <Form.Group as={Col} lg={4} md={6}>
            <Form.Label>
              Minimum Depth
              <span className="text-danger text-small ms-2">(Required)</span>
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                aria-label="Depth Start"
                type="number"
                min={0}
                name="depthStart"
                value={formik.values.depthStart}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.touched.depthStart && !!formik.errors.depthStart
                }
              />
              <InputGroup.Text>ft.</InputGroup.Text>
            </InputGroup>
            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
          </Form.Group>
        )}

        {/* Depth Fished */}
        {attributeByGear.depthFished.includes(gear.code) && (
          <Form.Group as={Col} lg={4} md={6}>
            <Form.Label>
              Maximum Depth
              <span className="text-danger text-small ms-2">(Required)</span>
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                aria-label="Depth End"
                type="number"
                min={0}
                name="depthEnd"
                value={formik.values.depthEnd}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.depthEnd && !!formik.errors.depthEnd}
              />
              <InputGroup.Text>ft.</InputGroup.Text>
            </InputGroup>
            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
          </Form.Group>
        )}

        {/* Water Temp */}
        {/* {gear.code !== "SP" && ( */}
        <Form.Group as={Col} lg={4} md={6}>
          <Form.Label>Water Temp</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              aria-label="Water Temp"
              type="number"
              min={0}
              name="waterTemp"
              value={formik.values.waterTemp}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.waterTemp && !!formik.errors.waterTemp}
            />
            <InputGroup.Text>F</InputGroup.Text>
          </InputGroup>
        </Form.Group>
        {/* )} */}

        {/* Nights Out */}
        {attributeByGear.nightsOut.includes(gear.code) && (
          <Form.Group as={Col} lg={4} md={6}>
            <Form.Label>Nights Out</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                aria-label="Nights Out"
                type="number"
                min={0}
                name="nightsOut"
                value={formik.values.nightsOut}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.touched.nightsOut && !!formik.errors.nightsOut
                }
              />
              <InputGroup.Text>Nights</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        )}
      </Row>
      <ul className="text-danger">
        {Object.entries(formik.errors).map(([key, value]: any, i) => {
          if (value !== "Required") {
            return <li key={i}>{value}</li>;
          } else {
            return "";
          }
        })}
      </ul>
    </Form>
  );
}
