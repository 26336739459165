import axios from "axios";
import { getRoles } from "../helpers/authHelper";

export interface Admin {
  userID: string;
  userName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  displayName: string;
  email: string;
  phone?: string;
  profile: {
    biaNumber: number;
    organizationCodeNumber: number;
  };
  status: string;
}

export const listOrganizationAdmins = async (OrgID: number) => {
  try {
    let endpoint = "";
    if (getRoles().includes("CORA_Admin"))
      endpoint = `${process.env.REACT_APP_API}/User/list-organization-admins?OrganizationID=${OrgID}`;
    else endpoint = `${process.env.REACT_APP_API}/User/list-admins`;

    const response = await axios.get(endpoint);

    return response.data;
  } catch (error) {
    console.error(error);
    const result: string[] = [];
    return result;
  }
};
