import Alert from "react-bootstrap/Alert";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Card from "react-bootstrap/Card";
import { DailyTrip } from "../../models/dailyTrip";
import { Gear } from "../../models/gear";
import { Grid } from "../../models/grid";
import { TripEffort } from "../../models/tripEffort";
import GearEffort from "../formControls/effortAttributes";

export default function GridEffort({
  initialValues,
  refId,
  formData,
  gearList,
  meshList,
}: any) {
  // Submit form from parent component
  useImperativeHandle(refId, () => ({
    Submit: async () => {
      // Empty Errors Array
      const formErrors: SetStateAction<string[]> = [];

      // Check if we have at least one gear
      if (selectedEffort.length > 0) {
        //Submit all forms
        for (const key in effortRefs.current) {
          if (effortRefs.current[key] !== null)
            await effortRefs.current[key].Submit();
        }

        // Check if all our grids are selected
        const reqGrids = values.grids?.map((grid) => grid.gridKey) ?? [];
        for (let i = reqGrids?.length ?? 1 - 1; i >= 0; i--) {
          if (
            selectedEffort.some((e) => {
              return e.grids.some((g) => {
                return g.gridKey === reqGrids[i];
              });
            })
          ) {
            reqGrids.splice(i, 1);
          }
        }

        if (reqGrids.length > 0) {
          reqGrids.forEach((rq: any) => {
            formErrors.push(`Missing gear for grid ` + rq.split("_")[1]);
          });
        }

        // Check if all gears have required field filled
        if (!selectedEffort.every((e) => e.validated)) {
          formErrors.push("Please fill all required fields");
        }

        if (formErrors.length === 0) {
          formData({
            values: { ...values, effort: selectedEffort },
            validated: true,
          });
        }
        setErrors(formErrors);
      } else {
        formErrors.push("At least one gear is required");
        setErrors(formErrors);
      }
    },
  }));

  const [values, setValues] = useState<DailyTrip>({ ...initialValues });
  const [buttonsList, listAnimation] =
    useAutoAnimate<any>(/* optional config */);
  const [gear, setGear] = useState<Gear[]>(gearList);
  const [selectedEffort, setSelectedEffort] = useState<TripEffort[]>([
    ...values.effort,
  ]);
  const [showFormList, setShowFormList] = useState(false);
  const [listHeight, setListHeight] = useState(0);
  const listRef = useRef<any>(null);
  const [effortID, setEffortID] = useState(0);
  const effortRefs = useRef<any>({});
  const [showHelp, setShowHelp] = useState(
    localStorage.getItem("dtEffortHelp") === "true"
  );
  const [errors, setErrors] = useState<string[]>([]);

  // Default Trap Net to only enter effort manually. Disable split evenly
  useEffect(() => {
    if (values.gear[0].gearName === "Trap Net") {
      enterManually();
    }
  }, []);

  useEffect(() => {
    if (values.effort.length < 1) {
      const newID =
        Math.min(...values.effort.map((e) => e.id), values.newEffortID) - 1;

      const newEffort: TripEffort = {
        id: newID,
        tripGearID: values.gear[0].id,
        gearID: values.gear[0].gearID,
        gearKey: values.gear[0].gearKey,
        gearName: values.gear[0].gearName,
        grids: values.grids,
      };

      setValues({ ...values, effort: [newEffort] });
      setSelectedEffort([newEffort]);
    }
  }, [values]);

  // Set the distance for the list arrow to be below/center the "More" button
  useLayoutEffect(() => {
    // Change height of modal body so the list doesn't overflow
    setListHeight(listRef?.current?.offsetHeight ?? 0);
  }, [showFormList, selectedEffort]);

  const addEffort = (gear: TripEffort, grids: Grid[]) => {
    // Create a new id that's negative
    const newID = Math.min(...selectedEffort.map((e) => e.id), effortID) - 1;
    const newEffort: TripEffort = {
      ...gear,
      id: newID,
      grids: grids,
    };

    setEffortID(newID);
    return newEffort;
  };

  const updateEffort = (effortID: number, gearData: TripEffort) => {
    const index = selectedEffort.findIndex((x) => x.id === effortID);
    selectedEffort[index] = { ...selectedEffort[index], ...gearData };
    setSelectedEffort(selectedEffort);

    formData({
      values: { ...values, effort: selectedEffort },
      validated: false,
    });
  };

  const splitEvenly = () => {
    // Check if already split evenly
    if (selectedEffort.length > 1) {
      // Add all selected grids to the effort
      selectedEffort[0].grids = [...values.grids];

      // Keep first effort and remove the rest
      setSelectedEffort([selectedEffort[0]]);
      setValues({ ...values, manualEffort: false });
    }
  };

  const enterManually = () => {
    // Check if already entered manually
    if (selectedEffort.length === 1) {
      // Loop through selected grids
      values.grids.forEach((grid, i) => {
        // If effort index exists (should only be the first), just update the selected grid to the first grid
        if (selectedEffort[i] !== undefined) {
          selectedEffort[i].grids = [grid];
        } else {
          // For the rest of the grids, create a new effort and assign its respected grid
          selectedEffort[i] = addEffort(selectedEffort[0], [grid]);

          //addEffort(selectedEffort[0], [grid]);
        }

        setSelectedEffort(selectedEffort);
        setValues({ ...values, manualEffort: true });
      });
    }
  };

  const gridsLabel = (e: TripEffort) => {
    if (e.grids.length > 1) {
      let label = `Lake ${e.grids[0].lakeName}: Grids `;

      e.grids.forEach((g, i) => {
        if (e.grids.length > i + 1) {
          label += `${g.gridNumber}, `;
        } else {
          label += `${g.gridNumber}`;
        }
      });

      return label;
    } else {
      return `Lake ${e.grids[0].lakeName}: Grid ${e.grids[0].gridNumber}`;
    }
  };

  return (
    <>
      <h2 className="mb-3 fw-normal">
        Enter the effort for this gear type
        {/* {!showHelp && (
          <span
            onClick={() => {
              localStorage.setItem("dtEffortHelp", "true");
              setShowHelp(true);
            }}
            className="link fs-6 ms-3"
          >
            Help
          </span>
        )} */}
      </h2>
      <div className="mb-5">
        <ul>
          <li>Enter any effort that is associated with the gear selected.</li>
          {values.grids.length > 1 && (
            <>
              <li>
                If multiple grids were fished, and you do not know the exact
                effort expended in each grid, enter the total effort into the
                fields provided and choose “Split Evenly” to let the system
                calculate the effort expended evenly for each grid.
              </li>
              <li>
                If multiple grids were fished and you know the effort expended
                in each grid, choose “Enter Manually” to type in the effort
                separately for each grid.
              </li>
            </>
          )}
        </ul>
      </div>

      {/* Help Info */}
      {/* {showHelp && (
          <Alert
            variant="info"
            onClose={() => {
              localStorage.setItem("dtEffortHelp", "false");
              setShowHelp(false);
            }}
            className="mb-4"
            dismissible
          >
            <Alert.Heading>Enter the effort for this gear type</Alert.Heading>
            <ul>
              <li>
                If multiple grids were fished, and you do not know the exact
                effort expended in each grid, enter the total effort into the
                fields provided and choose “Split Evenly” to let the system
                calculate the effort expended evenly for each grid.
              </li>
              <li>
                If multiple grids were fished and you know the effort expended
                in each grid, choose “Enter Manually” to type in the effort
                separately for each grid.
              </li>
            </ul>
          </Alert>
        )} */}

      {/* Show split option if there are multiple grids AND gear type is not Trap Net */}
      {values.grids.length > 1 && values.gear[0].gearName !== "Trap Net" && (
        <div>
          <label className="form-label">Split effort by grids</label>
          <ToggleButtonGroup
            className="w-100 mb-4"
            type="radio"
            name="splitEffort"
            defaultValue={values.manualEffort ? 2 : 1}
          >
            <ToggleButton
              id="splitEvenly1"
              variant="outline-primary"
              value={1}
              onClick={() => splitEvenly()}
            >
              Split Evenly
            </ToggleButton>
            <ToggleButton
              id="splitEvenly2"
              variant="outline-primary"
              value={2}
              onClick={() => enterManually()}
            >
              Enter Manually
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      )}

      {/* Loop by items in effort array */}
      {/* If Split Evenly is selected, then there should only be one effort with all grids included */}
      {/* If Enter Manually is selected, then there should be one effort per grid */}

      {selectedEffort.map((e: TripEffort, i) => (
        <Card className="mb-4 shadow border-gray" key={i}>
          <Card.Header>
            <span className="fw-bold">
              {e.gearName}
              <br />
              <span className="fw-normal">{gridsLabel(e)}</span>
            </span>
          </Card.Header>

          <Card.Body className="gear-card">
            <GearEffort
              key={`effort-${e.id}`}
              gear={gear.find((g) => g.id === e.gearID)}
              effort={e}
              updateEffort={updateEffort}
              refID={(ref: any) => (effortRefs.current[e.id] = ref)}
            />
          </Card.Body>
        </Card>
      ))}

      {errors.length > 0 && (
        <Alert variant="danger" className="mt-4 mb-0">
          <Alert.Heading>Error</Alert.Heading>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </Alert>
      )}
    </>
  );
}
