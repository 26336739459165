import { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { FaIdCard } from "react-icons/fa";
import { getUserPermits } from "../../api/getUserPermits";
import { getPermits } from "../../api/getPermits";
import { getUserID } from "../../helpers/authHelper";
import InputGroup from "react-bootstrap/InputGroup";
import { getFormattedDateYYYYMMDD } from "../../helpers/getFormattedDateYYYYMMDD";
import { Permit } from "../../models/permits";

export default function ReadonlyPermits({ userID }: any) {
  const [permits, setPermits] = useState<Permit[]>([]);

  // On Load
  useEffect(() => {
    fetchPermits();
  }, [userID]);

  const fetchPermits = async () => {
    if (userID === getUserID() || userID === undefined) {
      const response = await getPermits();
      if (response) setPermits(response);
    } else {
      const response = await getUserPermits(userID);
      if (response) setPermits(response);
    }
  };

  return (
    <>
      {permits.length > 0 && (
        <div className="form-card">
          <Row className="d-none d-lg-flex mb-4">
            <Col>
              <Form.Label>Permit Type</Form.Label>
            </Col>

            <Col>
              <Form.Label>Permit Number</Form.Label>
            </Col>

            <Col>
              <Form.Label>Active / Expire Date</Form.Label>
            </Col>
          </Row>

          {Array.isArray(permits) &&
            permits.map((permit: Permit, i) => (
              <Form
                key={i}
                className="border-bottom border-primary"
                autoComplete="off"
              >
                <Row>
                  <Col lg={4} className="d-flex align-items-center">
                    <FaIdCard className="color-secondary fs-4 text-primary me-2" />

                    <Form.Group controlId="formPermitType">
                      <Form.Control
                        plaintext
                        readOnly
                        type="text"
                        name="permitType"
                        value={permit.permitType}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <Form.Control
                      plaintext
                      readOnly
                      type="text"
                      name="permitNumber"
                      value={permit.permitNumber}
                    />
                  </Col>
                  <Col lg={4}>
                    <InputGroup className="flex-nowrap">
                      <Form.Control
                        plaintext
                        readOnly
                        type="date"
                        name="activeDate"
                        value={getFormattedDateYYYYMMDD(
                          permit.activeDate ?? ""
                        )}
                      />

                      <Form.Control
                        plaintext
                        readOnly
                        type="date"
                        name="expireDate"
                        value={getFormattedDateYYYYMMDD(
                          permit.expireDate ?? ""
                        )}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Form>
            ))}
        </div>
      )}
    </>
  );
}
