export default function passwordReg(value: any, context: any) {
  const hasString = /[a-z]/i.test(value);
  const hasNumber = /[0-9]/i.test(value);
  let validConditions = 0;
  const numberOfMustBeValidConditions = 2;
  const conditions = [hasString, hasNumber];
  conditions.forEach((condition) => (condition ? validConditions++ : null));
  if (validConditions >= numberOfMustBeValidConditions) {
    return true;
  }
  return false;
}
