import Button from "react-bootstrap/esm/Button";
import { FaCalendarAlt, FaExclamationCircle } from "react-icons/fa";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import TripsTable from "../../components/tables/tripsTable";
import MultiMonthReportCalendar from "../../components/multiMonthReportCalendar";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { lookupTripTypes } from "../../api/lookupTripTypes";
import DailyTripModal from "../../components/dailyTrips/dailyTripModal";
import SubmitCatchReportModal from "../../components/submitCatchReport/submitCatchReportModal";
import CatchReportsTable from "../../components/tables/catchReportsTable";
import Accordion from "react-bootstrap/Accordion";
import ReadonlyReviewModal from "../../components/dailyTrips/readonlyReviewModal";
import RequestChangeModal from "../../components/otherModals/requestChangeModal";
import Container from "react-bootstrap/esm/Container";
import styled from "styled-components";
import LakeImage from "../../assets/images/lake-image.jpg";
import { listTrips } from "../../api/listTrips";
import { getCatchReportDeadlines } from "../../api/getCatchReportDeadlines";
import { getCatchReportNextDeadline } from "../../api/getCatchReportNextDeadline";
import { getCatchReportPeriods } from "../../api/getCatchReportPeriods";
import { CatchReportPeriod } from "../../models/catchReportPeriod";
import { getFormattedDateMMDDYYYY } from "../../helpers/getFormattedDateMMDDYYYY";
import { listCatchReports } from "../../api/listCatchReports";
import { getUserID } from "../../helpers/authHelper";
import { getTripDates } from "../../api/getTripDates";
import MobileDailyTrips from "../../components/mobileDailyTrips";
import ToggleButtonGroup from "react-bootstrap/esm/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/esm/ToggleButton";
import Condition from "yup/lib/Condition";
import { lookupConditions } from "../../api/lookupConditions";
import { lookupGear } from "../../api/lookupGear";
import { lookupLakes } from "../../api/lookupLakes";
import { lookupMeshSizes } from "../../api/lookupMeshSizes";
import { lookupSpecies } from "../../api/lookupSpecies";
import { Gear } from "../../models/gear";
import { MeshSize } from "../../models/meshSize";
import { Species } from "../../models/species";
import { Vessel } from "../../models/vessel";
import Spinner from "react-bootstrap/esm/Spinner";
import ReportsTable from "../../components/tables/reportsTable";
import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import FisherUnsubmittedReportsTable from "../../components/tables/fisherUnsubmittedReportsTable";
import { CatchReport } from "../../models/catchReport";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getFormattedDateMMMDD } from "../../helpers/getFormattedDateMMMDD";

// Styled Components
const Hero = styled.div`
  background: linear-gradient(
      0deg,
      rgba(7, 74, 129, 0.8),
      rgba(7, 74, 129, 0.8)
    ),
    url("${LakeImage}");
  background-size: cover;
  background-position: center;
`;

// Tooltips for submit button
const renderSubmitTooltip = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    You must add at least one daily trip entry for this period before submitting
    the report
  </Tooltip>
);

const renderNoFishTooltip = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    You must delete all daily trip entries for this period before submitting the
    report
  </Tooltip>
);

export default function FisherHomeDesktop() {
  const userID = getUserID();
  const [loadingDailyTrips, setLoadingDailyTrips] = useState(true);
  const [loadingCatchReports, setLoadingCatchReports] = useState(true);
  const [dailyTrips, setDailyTrips] = useState([]);
  const [tripDates, setTripDates] = useState([]);
  const [tripTypes, setTripTypes] = useState([]);
  const [currentCatchReportPeriod, setCurrentCatchReportPeriod] =
    useState<CatchReportPeriod>();
  const [catchReportPeriods, setCatchReportPeriods] = useState<
    CatchReportPeriod[]
  >([]);
  const [submittedCatchReports, setSubmittedCatchReports] = useState([]);
  const [deadlines, setDeadlines] = useState([]);
  const [nextDeadline, setNextDeadline] = useState();
  const [isMobile, setIsMobile] = useState(true);
  const [dailyTripView, setDailyTripView] = useState(1);
  const [activeAccordionKey, setActiveAccordionKey] = useState<string[]>([]);
  const [loadMoreCatchReportsLimit, setLoadMoreCatchReportsLimit] =
    useState<number>(6);

  // Child component references
  const dailyTripsModalRef = useRef<HTMLFormElement>(null);
  const submitCatchReportModalRef = useRef<HTMLFormElement>(null);
  const readonlyReviewModalRef = useRef<HTMLFormElement>(null);
  const requestChangeModalRef = useRef<HTMLFormElement>(null);

  //Lookups
  const [gearList, setGearList] = useState<Gear[]>([]);
  const [meshList, setMeshList] = useState<MeshSize[]>([]);
  const [speciesList, setSpeciesList] = useState<Species[]>([]);
  const [conditionsList, setConditionsList] = useState<Condition[]>([]);
  const [vesselsList, setVesselsList] = useState<Vessel[]>([]);
  const [lakesList, setLakesList] = useState<any>([]);

  // On load
  useEffect(() => {
    listTrips({ tripTypeID: 1 }).then((res) => {
      if (res) {
        setDailyTrips(res);
        setLoadingDailyTrips(false);
      }
    });
    listCatchReports().then((res) => {
      if (res) {
        setSubmittedCatchReports(res);
        setLoadingCatchReports(false);
      }
    });
    lookupTripTypes().then((res) => {
      if (res) setTripTypes(res);
    });
    getCatchReportPeriods().then((res) => {
      if (res) {
        setCatchReportPeriods(
          res.filter((r: CatchReportPeriod) => r.current === false)
        );
        setCurrentCatchReportPeriod(
          res.find((r: CatchReportPeriod) => r.current === true)
        );
      }
    });
    getCatchReportDeadlines().then((res) => {
      if (res) setDeadlines(res);
    });
    getCatchReportNextDeadline().then((res) => {
      if (res) setNextDeadline(res);
    });
    getTripDates().then((res) => {
      if (res) setTripDates(res);
    });
    lookupGear().then((res) => {
      if (res) setGearList(res);
    });
    lookupMeshSizes().then((res) => {
      if (res) setMeshList(res);
    });
    lookupSpecies().then((res) => {
      if (res) setSpeciesList(res);
    });
    lookupConditions().then((res) => {
      if (res) setConditionsList(res);
    });
    lookupLakes().then((res) => {
      if (res) setLakesList(res);
    });

    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleRefreshDailyTrips = async () => {
    listTrips({ tripTypeID: 1 }).then((res) => {
      if (res) setDailyTrips(res);
    });

    getCatchReportPeriods().then((res) => {
      if (res) {
        setCatchReportPeriods(
          res.filter((r: CatchReportPeriod) => r.current === false)
        );
        setCurrentCatchReportPeriod(
          res.find((r: CatchReportPeriod) => r.current === true)
        );
      }
    });
  };

  const loadMoreCatchReports = () => {
    setLoadMoreCatchReportsLimit(loadMoreCatchReportsLimit + 6);
  };

  const handleAccordionSelect = (eventKey: any) => {
    setActiveAccordionKey(eventKey);
  };

  const handleToggleDailyTripView = (val: number) => setDailyTripView(val);

  return (
    <>
      <Hero
        id="fisher-dashboard"
        className="py-4 mb-4 mb-lg-5 mt-n5 w-100 text-white"
      >
        <Container>
          <div className="d-flex flex-wrap w-100 justify-content-between align-items-center mb-4">
            <h1 className="display-4 fw-bold">Daily Trips & Reports</h1>
            <p className="d-flex align-items-center">
              {nextDeadline && (
                <>
                  <FaCalendarAlt className="me-2 d-none d-md-block" /> Catch
                  Report Due:
                  <strong className="ms-2">
                    {getFormattedDateMMDDYYYY(nextDeadline)}
                  </strong>
                </>
              )}
            </p>
          </div>
        </Container>
      </Hero>
      <Container>
        <DailyTripModal
          refId={dailyTripsModalRef}
          handleRefreshDailyTrips={handleRefreshDailyTrips}
          userID={userID}
          gearList={gearList}
          meshList={meshList}
          speciesList={speciesList}
          conditionsList={conditionsList}
          vesselsList={vesselsList}
          lakesList={lakesList}
        />
        <SubmitCatchReportModal
          refId={submitCatchReportModalRef}
          handleRefreshDailyTrips={handleRefreshDailyTrips}
        />
        <ReadonlyReviewModal refId={readonlyReviewModalRef} />
        <RequestChangeModal refId={requestChangeModalRef} />

        <div className="mb-5">
          {/* Intro */}
          <div className="mb-5">
            <p className="lead d-flex align-items-center flex-wrap">
              {nextDeadline && (
                <>
                  <span className="me-2">
                    <FaCalendarAlt className="me-2" />
                    Current Reporting Period:
                  </span>
                  <strong>
                    {/* {getFormattedDateMMDDYYYY(nextDeadline)} */}

                    {currentCatchReportPeriod ? (
                      <>
                        {getFormattedDateMMDDYYYY(
                          currentCatchReportPeriod.beginDate
                        )}{" "}
                        -{" "}
                        {getFormattedDateMMDDYYYY(
                          currentCatchReportPeriod.endDate
                        )}
                      </>
                    ) : (
                      "Catch Report Submitted"
                    )}
                  </strong>
                </>
              )}
            </p>
            <h6>Instructions</h6>
            <ol>
              <li>
                Report your daily trips by clicking the New Daily Trip button,
                or clicking a day on the Calendar to the right.
              </li>
              <li>
                Submit your daily trips by clicking the Submit Current Catch
                Report button, or by submitting a late report below.
              </li>
              <li>
                If your report is already submitted, you may have the option to
                recall it with the yellow <strong>Recall Catch Report</strong>{" "}
                button after expanding a submitted report at the bottom of the
                page.
              </li>
            </ol>
          </div>
          <Row className="mb-5">
            <Col xs={12} lg={6} className="flex-grow-1">
              <Row className="mb-4 flex-md-row-reverse">
                <Col className="mb-3" xs={12} lg={6}>
                  <div className="form-card d-flex flex-column h-100 mb-3">
                    <h5>Submit Catch Report </h5>
                    <p>
                      Submit the current catch report with your logged trips or
                      as a NO FISH report
                    </p>
                    <div className="h-100 d-flex flex-grow-1 align-items-center">
                      <div className="w-100">
                        {currentCatchReportPeriod ? (
                          <>
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                currentCatchReportPeriod.tripCount === 0 ? (
                                  renderSubmitTooltip
                                ) : (
                                  <></>
                                )
                              }
                            >
                              <Button
                                title="Submit Catch Report"
                                variant="success"
                                className="w-100 shadow-sm mb-3"
                                disabled={
                                  currentCatchReportPeriod.tripCount === 0
                                }
                                onClick={() =>
                                  submitCatchReportModalRef.current?.handleShow(
                                    currentCatchReportPeriod
                                  )
                                }
                              >
                                Submit Current Catch Report
                                {currentCatchReportPeriod && (
                                  <>
                                    {" "}
                                    (
                                    {getFormattedDateMMMDD(
                                      currentCatchReportPeriod.beginDate
                                    )}{" "}
                                    -{" "}
                                    {getFormattedDateMMMDD(
                                      currentCatchReportPeriod.endDate
                                    )}
                                    )
                                  </>
                                )}
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                currentCatchReportPeriod.tripCount > 0 ? (
                                  renderNoFishTooltip
                                ) : (
                                  <></>
                                )
                              }
                            >
                              <Button
                                title="Submit Catch Report"
                                variant="outline-success"
                                className="w-100 shadow-sm text-dark"
                                disabled={
                                  currentCatchReportPeriod.tripCount > 0
                                }
                                onClick={() =>
                                  submitCatchReportModalRef.current?.handleShow(
                                    currentCatchReportPeriod
                                  )
                                }
                              >
                                Submit NO FISH Report
                                {currentCatchReportPeriod && (
                                  <>
                                    {" "}
                                    (
                                    {getFormattedDateMMMDD(
                                      currentCatchReportPeriod.beginDate
                                    )}{" "}
                                    -{" "}
                                    {getFormattedDateMMMDD(
                                      currentCatchReportPeriod.endDate
                                    )}
                                    )
                                  </>
                                )}
                              </Button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <Button
                            title="Submit Catch Report"
                            variant="outline-dark"
                            className="w-100 shadow-sm mb-3"
                            disabled
                          >
                            No Catch Report Due
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-3" xs={12} lg={6}>
                  <div className="form-card d-flex flex-column h-100 mb-3">
                    <h5>Add New Daily Trip</h5>
                    <p>
                      Log a new trip entry by clicking the{" "}
                      <strong>New Daily Trip</strong> button below.
                    </p>
                    <div className="h-100 d-flex flex-grow-1 align-items-center">
                      <Button
                        variant="primary"
                        className="w-100 shadow-sm"
                        disabled={tripTypes.length === 0}
                        onClick={() =>
                          dailyTripsModalRef.current?.newDailyTrip(tripTypes[0])
                        }
                      >
                        + New Daily Trip
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* Daily Trips */}
              <div className="mb-5">
                <div>
                  <h3 className="fw-normal">Daily Trips</h3>

                  {/* Mobile trip view selector */}
                  <ToggleButtonGroup
                    className="w-100 mb-4 d-flex d-lg-none"
                    size="sm"
                    type="radio"
                    name="mobileTripView"
                    defaultValue={1}
                    onChange={handleToggleDailyTripView}
                  >
                    <ToggleButton
                      id="mobileTripView1"
                      variant="outline-primary"
                      value={1}
                    >
                      List
                    </ToggleButton>
                    <ToggleButton
                      id="mobileTripView2"
                      variant="outline-primary"
                      value={2}
                    >
                      Calendar
                    </ToggleButton>
                    <ToggleButton
                      id="mobileTripView3"
                      variant="outline-primary"
                      value={3}
                    >
                      Table
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>

                {/* Mobile List */}
                <div
                  className={
                    isMobile && dailyTripView === 1 ? "d-block" : "d-none"
                  }
                >
                  <MobileDailyTrips
                    dailyTripRef={dailyTripsModalRef}
                    dailyTrips={dailyTrips}
                    loadingDailyTrips={loadingDailyTrips}
                    meshList={meshList}
                    handleRefreshDailyTrips={handleRefreshDailyTrips}
                    canDelete
                    canEdit
                  />
                </div>

                {/* Mobile Calendar */}
                <div
                  className={
                    isMobile && dailyTripView === 2 ? "d-block" : "d-none"
                  }
                >
                  <MultiMonthReportCalendar
                    dailyTripRef={dailyTripsModalRef}
                    tripDates={tripDates}
                    tripType={tripTypes[0]}
                    nextDeadline={nextDeadline}
                    deadlines={deadlines}
                  />
                </div>

                {/* Table */}
                <div
                  className={
                    !isMobile || (isMobile && dailyTripView === 3)
                      ? "d-block"
                      : "d-none"
                  }
                >
                  <div className="form-card mb-3">
                    <TripsTable
                      dailyTripRef={dailyTripsModalRef}
                      dailyTrips={dailyTrips}
                      loadingDailyTrips={loadingDailyTrips}
                      handleRefreshDailyTrips={handleRefreshDailyTrips}
                    />
                  </div>
                </div>
              </div>

              {/* Late Catch Reports */}
              {catchReportPeriods.length > 0 && (
                <div>
                  <h3 className="fw-normal">
                    Late Catch Reports
                    <FaExclamationCircle className="text-danger ms-2 fs-4" />
                  </h3>
                  {/* Table */}
                  {isMobile ? (
                    catchReportPeriods.map((p: CatchReportPeriod, i) => {
                      let hasTrips = p.tripCount > 0;
                      return (
                        <Button
                          key={i}
                          variant="light"
                          className="mb-2 p-3 d-block w-100 text-start"
                          onClick={() =>
                            submitCatchReportModalRef.current?.handleShow(p)
                          }
                        >
                          <div className="fw-bold">
                            {getFormattedDateMMDDYYYY(p.beginDate)} -{" "}
                            {getFormattedDateMMDDYYYY(p.endDate)}
                          </div>
                          <p>Trips logged: {p.tripCount}</p>
                          <span
                            className={`${
                              hasTrips
                                ? "btn-success"
                                : "btn-outline-success bg-white text-dark"
                            } btn btn-sm`}
                          >
                            {hasTrips
                              ? "Submit this Catch Report"
                              : "Submit this NO FISHING Report"}
                          </span>
                        </Button>
                      );
                    })
                  ) : (
                    <div className="form-card mb-3">
                      <FisherUnsubmittedReportsTable
                        lateCatchReports={catchReportPeriods}
                        submitCatchReportModalRef={submitCatchReportModalRef}
                      />
                    </div>
                  )}
                </div>
              )}
            </Col>

            <Col xs={12} lg="auto">
              {/* Calendar */}
              <div className={!isMobile ? "d-block" : "d-none"}>
                <MultiMonthReportCalendar
                  dailyTripRef={dailyTripsModalRef}
                  tripDates={tripDates}
                  tripType={tripTypes[0]}
                  nextDeadline={nextDeadline}
                  deadlines={deadlines}
                />
              </div>
            </Col>
          </Row>

          <div>
            <h3 className="fw-normal">Submitted Catch Reports</h3>
            {loadingCatchReports && (
              <div className="">
                <Spinner animation="border" variant="primary" size="sm" />
                <span className="h6 ms-2 mb-0 text-primary">
                  Loading Catch Reports...
                </span>
              </div>
            )}
            {submittedCatchReports.length > 0 && (
              <>
                <Accordion
                  alwaysOpen
                  className="mb-4 submitted-catch-reports-accordion"
                  activeKey={activeAccordionKey}
                  onSelect={handleAccordionSelect}
                >
                  {submittedCatchReports
                    .slice(0, loadMoreCatchReportsLimit)
                    .map((period: CatchReportPeriod, i) => (
                      <Accordion.Item
                        key={i}
                        eventKey={`${i}`}
                        className="mb-2 shadow-sm"
                      >
                        <Accordion.Header>
                          {getFormattedDateMMDDYYYY(period.beginDate)} -{" "}
                          {getFormattedDateMMDDYYYY(period.endDate)}
                        </Accordion.Header>
                        <Accordion.Body>
                          <CatchReportsTable
                            period={period}
                            active={activeAccordionKey.includes(i.toString())}
                            reviewDailyTripRef={readonlyReviewModalRef}
                            requestChangeModalRef={requestChangeModalRef}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                </Accordion>
                {loadMoreCatchReportsLimit < submittedCatchReports.length && (
                  <div className="text-center">
                    <Button
                      variant="outline-primary"
                      onClick={loadMoreCatchReports}
                    >
                      Load More Catch Reports
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}
