import React, { useEffect, useImperativeHandle } from "react";
import Table from "react-bootstrap/esm/Table";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";
import DebouncedInput from "./debouncedInput";
import Filter from "./tableHeaderFilter";
import { Link } from "react-router-dom";
import { User } from "../../api/listOrganizationUsers";

import {
  FaSort,
  FaSortUp,
  FaSortDown,
  FaGreaterThan,
  FaLessThan,
} from "react-icons/fa";

import {
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  flexRender,
} from "@tanstack/react-table";

import { RankingInfo } from "@tanstack/match-sorter-utils";
import { fuzzyFilter } from "./fuzzyFilter";
import { CatchReport } from "../../models/catchReport";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }

  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

export default function ReportsTable({ columns, catchReports, refId }: any) {
  useImperativeHandle(refId, () => ({
    removeReport,
  }));

  // Set data and filter states
  const [data, setData] = React.useState([]);
  const [allData, setAllData] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [showColumnFilter, setShowColumnFilter] = React.useState(false);
  const [showInactive, setShowInactive] = React.useState(false);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const removeReport = (catchReportID: number) => {
    const filtered = data.filter((row: CatchReport) => {
      return row.id !== catchReportID;
    });
    setData([...filtered]);
  };

  // Get and update data on load and org change
  useEffect(() => {
    setData(catchReports);
  }, [catchReports]);

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    initialState: {
      pagination: {
        pageSize: 15,
      },
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  });

  return (
    <>
      {/* Table Header Controls */}
      <div className="w-100 table-header d-flex flex-wrap">
        <Button
          variant="secondary"
          className="me-2 mb-3 d-inline"
          onClick={() => {
            setShowColumnFilter(!showColumnFilter);
          }}
        >
          {showColumnFilter ? "Hide" : "Show"} Filters
        </Button>

        <DebouncedInput
          value={globalFilter ?? ""}
          onChange={(value) => setGlobalFilter(String(value))}
          className="form-control d-inline-block  w-auto mb-3"
          placeholder="Search everything..."
        />
      </div>

      {/* Table */}
      <Table striped hover responsive>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ verticalAlign: "top" }}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer d-flex align-items-center"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <FaSortUp className="text-primary ms-1" />,
                            desc: <FaSortDown className="text-primary ms-1" />,
                          }[header.column.getIsSorted() as string] ?? (
                            <FaSort className="text-gray ms-1" />
                          )}
                        </div>
                        {header.column.getCanFilter() && showColumnFilter ? (
                          <div>
                            <Filter column={header.column} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} className="align-middle">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      {/* Table footer */}
      <div className="d-flex flex-row justify-content-end align-items-center">
        <span className="text-nowrap me-3">
          {`${
            table.getState().pagination.pageIndex *
            table.getState().pagination.pageSize
          }-${
            table.getState().pagination.pageIndex *
              table.getState().pagination.pageSize +
            table.getState().pagination.pageSize
          } of ${table.getPrePaginationRowModel().rows.length}`}
        </span>

        <Form.Select
          aria-label="Table rows per page"
          size="sm"
          style={{ maxWidth: "10rem" }}
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[15, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Rows per page {pageSize}
            </option>
          ))}
        </Form.Select>

        <Button
          variant="light-gray"
          size="sm"
          className="ms-2 text-primary"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <FaLessThan />
        </Button>
        <Button
          variant="light-gray"
          size="sm"
          className="ms-2"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <FaGreaterThan className="text-primary" />
        </Button>
      </div>
    </>
  );
}
