import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/esm/Button";
import EditAdminTribe, {
  TribeValues,
} from "../../components/formControls/editAdminTribe";
import EditContact, {
  ContactValues,
} from "../../components/formControls/editContact";
import EditPersonalInformation, {
  PersonalInformationValues,
} from "../../components/formControls/editPersonalInformation";
import EditAccessInformation, {
  AccessInformationValues,
} from "../../components/formControls/editAccessInformation";
import EditBIACode, {
  BIACodeValues,
} from "../../components/formControls/editBIACode";
import { useParams, useSearchParams } from "react-router-dom";
import { updateUser } from "../../api/updateUser";
import { apiSuccess } from "../../helpers/apiSuccess";
import { User } from "../../models/user";
import { getOrganizationID, getUserID } from "../../helpers/authHelper";
import EditVessels from "../../components/formControls/editVessels";
import FormToast, { ToastData } from "../../components/formToast";
import { getFisher } from "../../api/getFisher";
import EditLicenseDetails from "../../components/formControls/editLicensesDetails";
import { useNavigate } from "react-router-dom";
import EditPermits from "../../components/formControls/editPermits";
import EditPassword from "../../components/formControls/editPassword";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { getUser } from "../../api/getUser";
import { Organization } from "../../models/organization";
import { lookupOrganization } from "../../api/lookupOrganization";

export default function EditUser() {
  let { userID } = useParams();
  let isSelf = false;

  if (userID === undefined || userID === getUserID()) {
    userID = getUserID();
    isSelf = true;
  }

  interface FormData {
    values: any;
    validated: boolean;
    errors: any;
  }

  const [org, setOrg] = useState<Organization>();

  // Toast notification and Navigation
  const navigate = useNavigate();
  const [toastData, setToastData] = useState<ToastData>({
    show: false,
    title: "",
    body: "",
    bg: "",
  });

  useEffect(() => {
    const getInitialValues = async () => {
      const response = await getUser(userID);

      // console.log(response);

      setPersonalInformationValues({
        firstName: response.firstName ?? undefined,
        middleName: response.middleName ?? undefined,
        lastName: response.lastName ?? undefined,
        address1: response.profile.address1 ?? undefined,
        city: response.profile.city ?? undefined,
        stateCode: response.profile.stateCode ?? undefined,
        zip: response.profile.zip ?? undefined,
      });

      setAccessInformationValues({
        userName: response.userName ?? undefined,
        roleID: isNaN(parseInt(response.roleIDs.split(",")[0]))
          ? 0
          : parseInt(response.roleIDs.split(",")[0]),
        enabled: response.enabled,
        lockedOut: response.lockedOut,
        requirePasswordChange: response.requirePasswordChange,
        initialFlow: response.initialFlow,
      });

      setTribeValues({
        organizationID: response.profile.organizationID ?? 0,
      });

      setBiaCodeValues({
        organizationID: response.profile.organizationID ?? 0,
        biaNumber: response.profile.biaNumber ?? undefined,
        organizationCodeNumber:
          response.profile.organizationCodeNumber ?? undefined,
        organizationCode: response.profile.organizationCode ?? undefined,
      });

      setContactValues({
        email: response.email ?? undefined,
        phone: response.phone ?? undefined,
      });
    };
    getInitialValues();
  }, [userID]);

  // Set initial values
  const [personalInformationValues, setPersonalInformationValues] =
    useState<PersonalInformationValues>({
      firstName: "",
      middleName: "",
      lastName: "",
      address1: "",
      city: "",
      stateCode: "",
      zip: "",
    });

  const [accessInformationValues, setAccessInformationValues] =
    useState<AccessInformationValues>({
      userName: "",
      roleID: 0,
      enabled: false,
      lockedOut: false,
      requirePasswordChange: false,
      initialFlow: false,
    });

  const [tribeValues, setTribeValues] = useState<TribeValues>({
    organizationID: getOrganizationID(),
  });

  const [biaCodeValues, setBiaCodeValues] = useState<BIACodeValues>({
    organizationID: getOrganizationID(),
    biaNumber: "",
    organizationCodeNumber: "",
    organizationCode: "",
  });

  const [contactValues, setContactValues] = useState<ContactValues>({
    email: "",
    phone: "",
  });

  // Set all forms in single object
  const [formData, setFormData] = React.useState({
    editPersonalInformation: {
      values: personalInformationValues,
      validated: false,
      errors: {},
    },
    editAccessInformation: {
      values: accessInformationValues,
      validated: false,
      errors: {},
    },
    editTribe: { values: tribeValues, validated: false, errors: {} },
    editBIACode: { values: biaCodeValues, validated: false, errors: {} },
    editContact: { values: contactValues, validated: false, errors: {} },
  });

  useEffect(() => {
    const fetchOrg = async () => {
      const response = await lookupOrganization(
        formData.editBIACode.values.organizationID
      );
      if (response) setOrg(response);
    };
    fetchOrg();
  }, [formData.editBIACode.values.organizationID]);

  // Create Refs for each form
  const editPersonalInformationRef = React.useRef<HTMLFormElement>(null);
  const editAccessInformationRef = React.useRef<HTMLFormElement>(null);
  const editTribeRef = React.useRef<HTMLFormElement>(null);
  const editBIACodeRef = React.useRef<HTMLFormElement>(null);
  const editContactRef = React.useRef<HTMLFormElement>(null);

  // Handle form data when updated
  const handleChangeEditPersonalInformation = (data: FormData) => {
    formData.editPersonalInformation = { ...data };
    setFormData(formData);
  };

  const handleChangeEditAccessInformation = (data: FormData) => {
    formData.editAccessInformation = { ...data };
    setFormData(formData);
  };

  const handleChangeEditBIACode = (data: FormData) => {
    formData.editBIACode = { ...data };
    setFormData(formData);
  };

  const handleChangeEditTribe = (data: FormData) => {
    formData.editTribe = { ...data };
    setFormData(formData);
  };

  const handleChangeEditContact = (data: FormData) => {
    formData.editContact = { ...data };
    setFormData(formData);
  };

  // Submit all forms
  async function handleSubmit(back: boolean = false) {
    try {
      await editPersonalInformationRef.current?.Submit();
      await editAccessInformationRef.current?.Submit();
      await editBIACodeRef.current?.Submit();
      await editContactRef.current?.Submit();

      if (
        formData.editPersonalInformation.validated &&
        formData.editAccessInformation.validated &&
        formData.editContact.validated &&
        // Fisher Only
        (formData.editBIACode.validated || accessInformationValues.roleID !== 3)
      ) {
        let data: User = {
          userID: userID,
          ...formData.editPersonalInformation.values,
          ...formData.editAccessInformation.values,
          ...formData.editTribe.values,
          ...formData.editBIACode.values,
          ...formData.editContact.values,
        };

        const submit = async () => {
          const response = await updateUser(data);

          if (apiSuccess(response.status)) {
            setToastData({
              show: true,
              bg: "Success",
              title: "Success",
              body: "Saved Successfully",
            });

            if (back) {
              setTimeout(() => navigate(-1), 2000);
            }
          }
        };

        submit();
      } else {
        // Form Validation Errors
        setToastData({
          show: true,
          bg: "Danger",
          title: "Error",
          body: "There were validation errors",
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Toggle the Account Settings password change required if the password was changed below
  const handleRequirePasswordChange = (changeRequired: boolean) => {
    // if changeRequired is different than current, update account settings
    if (accessInformationValues.requirePasswordChange !== changeRequired) {
      setAccessInformationValues({
        ...accessInformationValues,
        requirePasswordChange: changeRequired,
      });
    }
  };

  return (
    <div className="py-5">
      <Container>
        <FormToast toastData={toastData} setData={setToastData} />

        <h1 className="text-primary mb-5">
          Edit {getUserID() === userID ? "Profile" : "User"}
        </h1>

        {/* Personal Information */}
        <Row>
          <Col lg={3}>
            <h2 className="form-header">Personal Information</h2>
          </Col>
          <Col>
            <EditPersonalInformation
              formData={handleChangeEditPersonalInformation}
              refId={editPersonalInformationRef}
              initialValues={personalInformationValues}
            />
          </Col>
        </Row>

        {/* Access Information */}
        <Row>
          <Col lg={3}>
            <h2 className="form-header">Account Settings</h2>
          </Col>
          <Col>
            <EditAccessInformation
              formData={handleChangeEditAccessInformation}
              refId={editAccessInformationRef}
              initialValues={accessInformationValues}
              allowChangeRole={false}
              showInitialFlow={accessInformationValues.roleID === 3}
              isSelf={isSelf}
            />
          </Col>
        </Row>

        {/* Change Password */}
        <Row>
          <Col lg={3}>
            <h2 className="form-header">Change Password</h2>
          </Col>
          <Col>
            <EditPassword
              userID={userID}
              handleRequirePasswordChange={handleRequirePasswordChange}
            />
          </Col>
        </Row>

        {/* Organization - Admin Only */}
        {accessInformationValues.roleID !== 3 &&
          accessInformationValues.roleID !== 4 && (
            <Row>
              <Col lg={3}>
                <h2 className="form-header">Organization</h2>
              </Col>
              <Col>
                <EditAdminTribe
                  formData={handleChangeEditTribe}
                  allowChange={false}
                  refId={editTribeRef}
                  initialValues={tribeValues}
                />
              </Col>
            </Row>
          )}

        {/* BIA & Code - Fisher Only */}
        {accessInformationValues.roleID === 3 && (
          <Row>
            <Col lg={3}>
              <h2 className="form-header">Tribe & Code</h2>
            </Col>
            <Col>
              <EditBIACode
                formData={handleChangeEditBIACode}
                refId={editBIACodeRef}
                initialValues={biaCodeValues}
                allowChangeTribe={false}
              />
            </Col>
          </Row>
        )}

        {/* Contact Details */}
        <Row>
          <Col lg={3}>
            <h2 className="form-header">Contact Details</h2>
          </Col>
          <Col>
            <EditContact
              formData={handleChangeEditContact}
              refId={editContactRef}
              initialValues={contactValues}
              isAdmin={
                accessInformationValues.roleID === 1 ||
                accessInformationValues.roleID === 2
              }
              isUnaffiliated={accessInformationValues.roleID === 4}
            />
          </Col>
        </Row>

        {/* FISHER ONLY FIELDS */}
        {accessInformationValues.roleID === 3 && (
          <>
            {/* Licenses */}
            <Row>
              <Col lg={3}>
                <h2 className="form-header">Licenses</h2>
              </Col>
              <Col>
                <EditLicenseDetails
                  userID={userID}
                  organization={biaCodeValues}
                />
              </Col>
            </Row>

            {/* Permits */}
            <Row>
              <Col lg={3}>
                <h2 className="form-header">Permits</h2>
              </Col>
              <Col>
                <EditPermits userID={userID} />
              </Col>
            </Row>

            {/* Vessels */}
            <Row>
              <Col lg={3}>
                <h2 className="form-header">Vessels &amp; Vehicles</h2>
              </Col>
              <Col>
                <EditVessels userID={userID} />
              </Col>
            </Row>
          </>
        )}

        <Row className="position-sticky bottom-0 pb-3" style={{ zIndex: "10" }}>
          <Col lg={3} />
          <Col>
            <ButtonGroup className="w-100">
              <Button
                variant="success"
                size="lg"
                className="w-100"
                onClick={() => handleSubmit(false)}
              >
                Save User Settings
              </Button>

              <DropdownButton
                as={ButtonGroup}
                title=""
                variant="success"
                id="bg-nested-dropdown"
                align="end"
              >
                <Dropdown.Item
                  eventKey="1"
                  className="text-success"
                  onClick={() => handleSubmit(true)}
                >
                  Save and go back
                </Dropdown.Item>
              </DropdownButton>
            </ButtonGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
