import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import CORALogo from "../../components/coraLogo";
import { handleLogin } from "../../api/handleLogin";
import { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function Login() {
  const loginSchema = Yup.object().shape({
    username: Yup.string().required("Username required"),
    password: Yup.string().required("Password required"),
  });

  // Error Handling
  const [loginError, setLoginError] = useState("");
  const [showPass, setShowPass] = useState(false);
  const localUsername = localStorage.getItem("username");

  return (
    <>
      <div>
        {/* CORA Logo */}
        <CORALogo />

        {/* Sign in form */}
        <h2 className="h1 mb-4 text-center text-sm-start">
          Sign in to your account
        </h2>

        <Formik
          initialValues={{
            username: `${localUsername ? localUsername : ""}`,
            password: "",
          }}
          validationSchema={loginSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            const error = await handleLogin(values); //Runs Axios, on catch should return error
            if (error) {
              setLoginError(`${error}`); // Set error message if one returns
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-4" controlId="validationFormikUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.username && !!errors.username}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.username}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-4" controlId="validationFormikPassword">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPass ? "text" : "password"}
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="password-tooltip">
                        {showPass ? "Hide password" : "Show password"}
                      </Tooltip>
                    }
                  >
                    <InputGroup.Text onClick={() => setShowPass(!showPass)}>
                      <>{showPass ? <FaEyeSlash /> : <FaEye />}</>
                    </InputGroup.Text>
                  </OverlayTrigger>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                variant="success"
                type="submit"
                disabled={isSubmitting}
                size="lg"
                className="w-100 mb-4"
              >
                Log In
              </Button>
              {loginError.length > 0 && (
                <>
                  <Alert variant="danger" className="w-100">
                    {loginError}
                  </Alert>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>

      <div className="pt-5">
        {/* Forgot Password / New Account */}
        <p className="text-center mb-1">
          <Link to="/recover">Forgot Password?</Link>
        </p>
        {/* <p className="text-center mb-1">Don't have an account? Get access</p> */}
      </div>
    </>
  );
}
