import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import { Organization } from "../../models/organization";
import { phoneReg } from "../../helpers/phoneReg";
import Alert from "react-bootstrap/Alert";
import { useState } from "react";
import { apiSuccess } from "../../helpers/apiSuccess";
import { saveSettings } from "../../api/saveSettings";
import SelectState from "./selectState";
import { PatternFormat } from "react-number-format";

export default function EditOrgEmail({ organizationID, value }: any) {
  const [alert, setAlert] = useState({
    show: false,
    variant: "",
    message: "",
  });

  const emailSchema = Yup.object().shape({
    email: Yup.string().email("Must be a valid email"),
    supportEmail: Yup.string(),
    phone: Yup.string().matches(phoneReg, "Phone number is not valid"),
    zip: Yup.string().matches(/^\d{5}(?:[-\s]\d{4})?$/, "Invalid ZIP code"),
  });

  return (
    <div className="form-card">
      <Formik
        enableReinitialize
        initialValues={{
          email: value.email,
          supportEmail: value.supportEmail,
          phone: value.phone,
          address1: value.address1,
          address2: value.address2,
          city: value.city,
          stateCode: value.stateCode,
          zip: value.zip,
        }}
        validationSchema={emailSchema}
        onSubmit={async (values) => {
          const data: Organization = {
            id: organizationID,
            ...values,
            phone: values.phone.replace(/\D/g, ""),
          };

          const response = await saveSettings(data);

          if (apiSuccess(response.status)) {
            setAlert({
              show: true,
              variant: "success",
              message: "Contact Information updated successfully",
            });
          } else {
            setAlert({
              show: true,
              variant: "danger",
              message: "Whoops, something went wrong",
            });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form noValidate onSubmit={handleSubmit} autoComplete="off">
            {/* Main Email */}
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Main Tribe Email (Public)</Form.Label>
              <Form.Control
                type="text"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.email && !!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {`${errors.email}`}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Support Email */}
            <Form.Group className="mb-3" controlId="formSupportEmail">
              <Form.Label>Support Emails (Private)</Form.Label>
              <Form.Control
                type="text"
                name="supportEmail"
                value={values.supportEmail}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.supportEmail && !!errors.supportEmail}
              />
              <Form.Control.Feedback type="invalid">
                {`${errors.supportEmail}`}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Phone */}
            <Form.Group className="mb-3" controlId="formSupportPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <PatternFormat
                customInput={Form.Control}
                type="tel"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                format="(###) ### ####"
                isInvalid={touched.phone && !!errors.phone}
              />
              <Form.Control.Feedback type="invalid">
                {`${errors.phone}`}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Address1 */}
            <Form.Group className="mb-3" controlId="formAddress">
              <Form.Label>Address 1</Form.Label>
              <Form.Control
                type="text"
                name="address1"
                value={values.address1}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.address1 && !!errors.address1}
              />
              <Form.Control.Feedback type="invalid">
                {`${errors.address1}`}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Address2 */}
            <Form.Group className="mb-3" controlId="formAddress2">
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                type="text"
                name="address2"
                value={values.address2}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.address2 && !!errors.address2}
              />
              <Form.Control.Feedback type="invalid">
                {`${errors.address2}`}
              </Form.Control.Feedback>
            </Form.Group>

            {/* City */}
            <Form.Group className="mb-3" controlId="formCity">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.city && !!errors.city}
              />
              <Form.Control.Feedback type="invalid">
                {`${errors.city}`}
              </Form.Control.Feedback>
            </Form.Group>

            {/* State */}
            <Form.Group as={Col} sm className="mb-3" controlId="formState">
              <Form.Label>State</Form.Label>
              <SelectState
                name="stateCode"
                value={values.stateCode}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.stateCode && errors.stateCode}
              />
              <Form.Control.Feedback type="invalid">
                {`${errors.stateCode}`}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Zip */}
            <Form.Group className="mb-3" controlId="formZip">
              <Form.Label>Zip</Form.Label>
              <Form.Control
                type="text"
                name="zip"
                value={values.zip}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.zip && !!errors.zip}
              />
              <Form.Control.Feedback type="invalid">
                {`${errors.zip}`}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              type="submit"
              variant="secondary"
              className="w-100 form-btn-sm"
            >
              Save Contact Information
            </Button>
            {alert.show && (
              <Alert className="mt-3" variant={alert.variant}>
                {alert.message}
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
