import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CORAImg from "../assets/logos/cora-logo.png";

export default function CoraLogo() {
  return (
    <Row className="gx-5 mb-5">
      <Col lg={4} className="d-flex justify-content-center">
        <div>
          <img
            src={CORAImg}
            alt="CORA Logo"
            style={{ maxWidth: "10rem" }}
            className="w-100 h-auto"
          />
        </div>
      </Col>
      <Col lg={8} className="d-flex flex-column justify-content-center">
        <div className="text-center text-lg-start">
          <h1 className="h2 text-primary">
            Chippewa Ottawa <br />
            Resource Authority
          </h1>
          <p className="lead mb-0">Catch Reporting System</p>
        </div>
      </Col>
    </Row>
  );
}
