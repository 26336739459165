import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { apiSuccess } from "../../helpers/apiSuccess";
import { toBase64 } from "../../helpers/toBase64";
import { Organization } from "../../models/organization";
import Alert from "react-bootstrap/Alert";
import { updateOrganization } from "../../api/updateOrganization";
import { saveSettings } from "../../api/saveSettings";

export default function EditLogo({ base64Image, organizationID }: any) {
  const acceptedFiles = ["image/png", "image/jpg", "image/jpeg"];
  const [orgLogo, setOrgLogo] = useState("");
  const [alert, setAlert] = useState({
    show: false,
    variant: "",
    message: "",
  });

  useEffect(() => {
    setOrgLogo(base64Image);
  }, [base64Image]);

  const logoSchema = Yup.object().shape({
    logo: Yup.mixed().required(),
  });

  return (
    <div className="form-card">
      <Formik
        enableReinitialize
        initialValues={{ logo: null }}
        validationSchema={logoSchema}
        onSubmit={async (values) => {
          const base64Logo = await toBase64(values.logo);
          const data: Organization = {
            id: organizationID,
            logo: base64Logo,
          };

          const response = await saveSettings(data);

          if (apiSuccess(response.status)) {
            setOrgLogo(base64Logo);
            setAlert({
              show: true,
              variant: "success",
              message: "Logo updated successfully",
            });
          } else {
            setAlert({
              show: true,
              variant: "danger",
              message: "Whoops, something went wrong",
            });
          }
        }}
      >
        {({ errors, touched, handleBlur, handleSubmit, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit} autoComplete="off">
            <Row>
              <Col className="text-center text-sm-start">
                <div>
                  <img
                    src={orgLogo}
                    alt="Tribal Logo"
                    style={{ maxWidth: "8rem", maxHeight: "8rem" }}
                    className="w-auto h-100 m-3"
                  />
                </div>
              </Col>
              <Form.Group
                controlId="formFile"
                as={Col}
                className="d-flex flex-column justify-content-end"
              >
                <Form.Label>Select Image (.png and .jpg only)</Form.Label>
                <Form.Control
                  className="mb-3"
                  onChange={(event) => {
                    const target = event.currentTarget as HTMLInputElement;
                    if (
                      target.files?.length &&
                      acceptedFiles.includes(target.files[0].type)
                    ) {
                      setFieldValue("logo", target.files[0]);
                    }
                  }}
                  name="logo"
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  onBlur={handleBlur}
                  isInvalid={touched.logo && !!errors.logo}
                />
              </Form.Group>
              <Col
                sm="auto"
                xl={3}
                className="d-flex flex-column justify-content-end"
              >
                <Button
                  type="submit"
                  variant="secondary"
                  className="w-100 mb-3"
                >
                  Replace Image
                </Button>
              </Col>
            </Row>
            {alert.show && (
              <Alert variant={alert.variant}>{alert.message}</Alert>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
