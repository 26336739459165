import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { MdSnowmobile, MdDirectionsBoat } from "react-icons/md";
import { FaMinus, FaPlus, FaBan } from "react-icons/fa";
import { useFormik, Formik } from "formik";
import * as Yup from "yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Alert from "react-bootstrap/Alert";
import { DailyTrip } from "../../models/dailyTrip";
import { Vessel } from "../../models/vessel";
import { getVessels } from "../../api/getVessels";
import { postVessel } from "../../api/postVessel";
// import { getUserID } from "../../helpers/authHelper";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import Offcanvas from "react-bootstrap/esm/Offcanvas";
import Container from "react-bootstrap/esm/Container";
import { getUserID } from "../../helpers/authHelper";

export default function SelectVessel({
  userID,
  fisherID,
  initialValues,
  refId,
  formData,
  vesselsList,
  setVesselsList,
  handleUserVessels,
}: any) {
  // Submit form from parent component
  useImperativeHandle(refId, () => ({
    Submit: async () => {
      await formik.submitForm();
    },
  }));

  const [showHelp, setShowHelp] = useState(
    localStorage.getItem("dtVesselHelp") === "true"
  );

  const [parentList, listAnimation] =
    useAutoAnimate<any>(/* optional config */);
  const [values, setValues] = useState<DailyTrip>({ ...initialValues });
  const listRef = useRef<any>(null);
  const [btnDistance, setBtnDistance] = useState<any>({ "--buttonOffset": 0 });
  const moreBtnRef = useRef<any>(null);
  const [showNewVessel, setShowNewVessel] = useState(false);
  const [fisherVessels, setFisherVessels] = useState<Vessel[]>(vesselsList);
  const [listHeight, setListHeight] = useState(0);
  const [isMobile, setIsMobile] = useState(true);

  const vesselSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    length: Yup.string().required("Length is required"),
    registrationNumber: Yup.string(),
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    //const offset = moreBtnRef?.current?.offsetLeft ?? 0;
    //const width = parentList?.current?.offsetWidth ?? 0;
    //const style = `${width - offset - 80}px`;
    //setBtnDistance({ "--buttonOffset": style });

    // Change height of modal body so the list doesn't overflow
    setListHeight(listRef?.current?.offsetHeight ?? 0);
  }, [showNewVessel]);

  useEffect(() => {
    setFisherVessels(vesselsList);
  }, [vesselsList]);

  // Add Vessel
  const addVessel = (vessel: any) => {
    const submitVessel = async () => {
      const id = fisherID ? fisherID : userID ? userID : getUserID();
      await postVessel({ userID: id, ...vessel });
      await handleUserVessels();
      setShowNewVessel(false);
    };
    submitVessel();
  };

  const formik = useFormik({
    initialValues: {
      vesselID: values.vesselID ?? -1,
      launchSite: values.launchSite ?? undefined,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      vesselID: Yup.number().required("Vessel required"),
    }),
    onSubmit: () => {
      // Get Vessel Name
      const vessel = fisherVessels.find(
        (x) => x.vesselID == formik.values.vesselID
      ) ?? { vesselID: -1 };

      formData({
        values: {
          ...values,
          vessel: vessel,
          vesselID: parseInt(`${formik.values.vesselID}`),
          launchSite: formik.values.launchSite,
        },
        validated: formik.isSubmitting
          ? Object.keys(formik.errors).length === 0
          : false,
      });
    },
  });

  useEffect(() => {
    const vessel = fisherVessels.find(
      (x) => x.vesselID == formik.values.vesselID
    ) ?? { vesselID: -1 };

    formData({
      values: {
        ...values,
        vessel: vessel,
        vesselID: parseInt(`${formik.values.vesselID}`),
        launchSite: formik.values.launchSite,
      },
      validated: false,
    });
  }, [formik.values]);

  return (
    <>
      <h2 className="mb-3 fw-normal">
        Select the vessel you fished with
        {/* {!showHelp && (
          <span
            onClick={() => {
              localStorage.setItem("dtVesselHelp", "true");
              setShowHelp(true);
            }}
            className="link fs-6 ms-3"
          >
            Help
          </span>
        )} */}
      </h2>
      <div className="mb-5">
        <ol>
          <li>
            Select the vessel you fished with. If you didn't fish with a vessel,
            select <strong>No Vessel</strong>.
          </li>
          <li>
            If your vessel is not in the list, you can add a new vessel by
            clicking <strong>New Vessel</strong>.
          </li>
        </ol>
      </div>
      {/* {showHelp && (
        <Alert
          variant="info"
          onClose={() => {
            localStorage.setItem("dtVesselHelp", "false");
            setShowHelp(false);
          }}
          className="mb-4"
          dismissible
        >
          <Alert.Heading>Select the vessel you fished with.</Alert.Heading>
          <ol>
            <li>
              Select the vessel you fished with. If you didn't fish with a
              vessel, select <strong>No Vessel</strong>.
            </li>
            <li>
              If your vessel is not in the list, you can add a new vessel by
              clicking <strong>New Vessel</strong>.
            </li>
          </ol>
        </Alert>
      )} */}

      <Form.Group controlId="vesselID" className="mb-4">
        <Form.Label>What vessel did you fish with?</Form.Label>

        <Row className="select-vessel g-2">
          {fisherVessels.map((vessel, i) => (
            <Col lg={2} xs={4} key={vessel.vesselID}>
              <Form.Check className="mb-2">
                <Form.Check.Input
                  id={`vessel-radio-${i}`}
                  name="vesselID"
                  type="radio"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={vessel.vesselID}
                  checked={formik.values.vesselID == vessel.vesselID}
                />
                <Form.Check.Label
                  htmlFor={`vessel-radio-${i}`}
                  className="form-button"
                >
                  {vessel.isSnowmobile ? (
                    <MdSnowmobile />
                  ) : (
                    <MdDirectionsBoat />
                  )}
                  <strong>{vessel.name}</strong>
                  <span style={{ minHeight: "24px" }}>
                    {vessel.registrationNumber}
                  </span>
                </Form.Check.Label>
              </Form.Check>
            </Col>
          ))}

          <Col lg={2} xs={4}>
            <Form.Check className="mb-2">
              <Form.Check.Input
                id={`vessel-radio-no-vessel`}
                name="vesselID"
                type="radio"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={-1}
                checked={formik.values.vesselID == -1}
              />
              <Form.Check.Label
                htmlFor={`vessel-radio-no-vessel`}
                className="form-button"
              >
                <FaBan />
                <strong className="mb-4">No Vessel</strong>
              </Form.Check.Label>
            </Form.Check>
          </Col>

          <Col lg={2} xs={4}>
            <div
              onClick={() => setShowNewVessel(!showNewVessel)}
              className="form-button"
              ref={moreBtnRef}
            >
              {showNewVessel ? <FaMinus /> : <FaPlus />}
              <strong className="mb-4">New Vessel</strong>
            </div>
          </Col>
        </Row>

        {formik.touched.vesselID && (
          <Form.Control.Feedback type="invalid" className="d-block mt-n3">
            {formik.errors.vesselID}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      {/* Add new vessel on the fly */}

      {isMobile ? (
        <Offcanvas
          className="addNewVesselOffCanvas"
          show={showNewVessel}
          placement="bottom"
          onHide={() => setShowNewVessel(false)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Add New Vessel</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Container>
              <NewVesselForm
                vesselSchema={vesselSchema}
                addVessel={addVessel}
              />
            </Container>
          </Offcanvas.Body>
        </Offcanvas>
      ) : (
        <div
          ref={refId}
          className="position-relative mb-4"
          style={{ minHeight: `${listHeight}px` }}
        >
          {showNewVessel && (
            <div ref={listRef} className="form-button-list" style={btnDistance}>
              <h5>Add New Vessel</h5>
              <NewVesselForm
                vesselSchema={vesselSchema}
                addVessel={addVessel}
              />
            </div>
          )}
        </div>
      )}

      <Form.Group className="mb-4" controlId="launchSite">
        <Form.Label>Launch Site</Form.Label>
        <Form.Control
          list="datalistOptions"
          type="text"
          name="launchSite"
          value={formik.values.launchSite}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {/* <datalist id="datalistOptions">
          <option value="Previous place one" />
          <option value="Previous place two" />
          <option value="Previous place three" />
        </datalist> */}
      </Form.Group>
    </>
  );
}

const NewVesselForm = ({ vesselSchema, addVessel }: any) => {
  return (
    <Formik
      initialValues={{
        name: "",
        length: "",
        registrationNumber: "",
        isSnowmobile: false,
      }}
      enableReinitialize
      validationSchema={vesselSchema}
      onSubmit={(values, { resetForm }) => {
        addVessel(values);
        resetForm();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <div>
          <Form
            noValidate
            onSubmit={handleSubmit}
            className="mb-4"
            autoComplete="off"
          >
            <Row>
              <Form.Group
                as={Col}
                lg
                className="mb-3"
                controlId="formVesselName"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.name && !!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                sm={6}
                lg={true}
                className="mb-3"
                controlId="formVesselLength"
              >
                <Form.Label>Length (Feet)</Form.Label>
                <Form.Control
                  type="number"
                  name="length"
                  value={values.length}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.length && !!errors.length}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.length}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                sm={6}
                lg={true}
                className="mb-3"
                controlId="formVesselRegistrationNumber"
              >
                <Form.Label>CORA Registration #</Form.Label>
                <Form.Control
                  type="text"
                  name="registrationNumber"
                  value={values.registrationNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    touched.registrationNumber && !!errors.registrationNumber
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.registrationNumber}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                xs={12}
                lg={{ order: "last" }}
                className="mb-3 mb-lg-0"
              >
                <Form.Check
                  name="isSnowmobile"
                  label="Is Snowmobile"
                  type="checkbox"
                  id="FormikIsSnowMobile"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.isSnowmobile}
                />
              </Form.Group>
              <Col lg={3}>
                <Button
                  variant="secondary"
                  className="w-100 form-btn-lg"
                  type="submit"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
          {/* <p className="lead text-end mb-0 pt-4">
                  <span
                    className="cursor-pointer text-primary"
                    onClick={() => setShowNewVessel(false)}
                  >
                    CLOSE DROPDOWN
                  </span>
                </p> */}
        </div>
      )}
    </Formik>
  );
};
