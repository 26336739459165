import { Formik } from "formik";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/esm/Tooltip";
import { FaEdit, FaIdCard, FaTrashAlt } from "react-icons/fa";
import * as Yup from "yup";
import { deletePermit } from "../../api/deletePermit";
import { getPermits } from "../../api/getPermits";
import { getUserPermits } from "../../api/getUserPermits";
import { postPermit } from "../../api/postPermit";
import { putPermit } from "../../api/putPermit";
import { getUserID } from "../../helpers/authHelper";
import { getFormattedDateYYYYMMDD } from "../../helpers/getFormattedDateYYYYMMDD";
import { Permit } from "../../models/permits";

export default function EditPermits({ userID }: any) {
  const [permits, setPermits] = useState<Permit[]>([]);
  const [permitForm, setPermitForm] = useState({
    permitType: "",
    permitNumber: "",
    activeDate: "",
    expireDate: "",
  });

  // On Load
  useEffect(() => {
    fetchPermits();
  }, [userID]);

  const fetchPermits = async () => {
    if (userID === getUserID() || userID === undefined) {
      const response = await getPermits();
      if (response) setPermits(response);
    } else {
      const response = await getUserPermits(userID);
      if (response) setPermits(response);
    }
  };

  // Add Permit
  const addPermit = (permit: any) => {
    const submit = async () => {
      if (userID === getUserID() || userID === undefined) {
        await postPermit({ userID: getUserID(), ...permit });
      } else {
        await postPermit({ userID: userID, ...permit });
      }

      fetchPermits();
    };
    submit();
    resetForm();
  };

  // Remove Permits
  const removePermit = (permit: Permit) => {
    const submit = async () => {
      await deletePermit(permit.permitID);
      fetchPermits();
    };
    submit();
  };

  // Edit Permits
  const editPermit = (permit: Permit) => {
    const submit = async () => {
      await putPermit({ ...permit });
      fetchPermits();
    };
    submit();
  };

  // Reset Form
  const resetForm = () => {
    setPermitForm({
      permitType: "",
      permitNumber: "",
      activeDate: "",
      expireDate: "",
    });
  };

  const tenYearsAgo = new Date();
  tenYearsAgo.setFullYear(tenYearsAgo.getFullYear() - 10);

  const tenYearsFuture = new Date();
  tenYearsFuture.setFullYear(tenYearsFuture.getFullYear() + 10);

  const permitSchema = Yup.object().shape({
    permitType: Yup.string().required("Permit type is required"),
    permitNumber: Yup.string()
      .max(10, "Max length 10 characters")
      .required("Permit Number is required"),
    activeDate: Yup.date()
      .required("Active / Expire Date is required")
      .max(tenYearsFuture, "Active date must be within the next 10 years")
      .min(tenYearsAgo, "Active date must be within the last 10 years")
      .when("expireDate", (expireDate, schema) => {
        return expireDate
          ? schema.max(expireDate, "Active Date must be before Expire Date")
          : schema;
      }),
    expireDate: Yup.date()
      .max(tenYearsFuture, "Expire date must be within the next 10 years")
      .min(tenYearsAgo, "Expire date must be within the last 10 years")
      .required("Active / Expire Date is required"),
  });

  return (
    <div className="form-card">
      <p className="fw-bold mb-1">Current & Previous Permits</p>
      {Array.isArray(permits) && permits.length ? (
        permits.map((permit: Permit, i) => (
          <Formik
            key={i}
            initialValues={permit}
            enableReinitialize
            validationSchema={permitSchema}
            onSubmit={(values) => editPermit(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            }) => (
              <Form
                noValidate
                onSubmit={handleSubmit}
                autoComplete="off"
                className="border-bottom border-primary pb-2 pt-3"
              >
                <Row className="gx-1">
                  <Col lg={2} className="d-flex align-items-center">
                    <FaIdCard className="color-secondary fs-4 text-primary me-2" />

                    <Form.Group controlId="formPermitType">
                      <Form.Control
                        plaintext={!permit.isEditing}
                        readOnly={!permit.isEditing}
                        type="text"
                        name="permitType"
                        value={values.permitType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.permitType && !!errors.permitType}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.permitType}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={2}>
                    <Form.Control
                      plaintext={!permit.isEditing}
                      readOnly={!permit.isEditing}
                      type="text"
                      name="permitNumber"
                      value={values.permitNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.permitNumber && !!errors.permitNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.permitNumber}
                    </Form.Control.Feedback>
                  </Col>
                  <Col lg={5}>
                    <InputGroup className="flex-nowrap">
                      <Form.Control
                        plaintext={!permit.isEditing}
                        readOnly={!permit.isEditing}
                        type="date"
                        name="activeDate"
                        value={getFormattedDateYYYYMMDD(
                          values.activeDate ?? ""
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.activeDate && !!errors.activeDate}
                      />

                      <Form.Control
                        plaintext={!permit.isEditing}
                        readOnly={!permit.isEditing}
                        type="date"
                        name="expireDate"
                        value={getFormattedDateYYYYMMDD(
                          values.expireDate ?? ""
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.expireDate && !!errors.expireDate}
                      />
                    </InputGroup>
                  </Col>

                  <Col lg={3} className="pt-2 pt-lg-0">
                    {!permit.isEditing && !permit.isDeleting && (
                      <div className="text-lg-end">
                        <OverlayTrigger
                          placement="left"
                          overlay={<Tooltip>Edit</Tooltip>}
                        >
                          <Button
                            variant="secondary"
                            className="text-light me-2"
                            size="sm"
                            onClick={() => {
                              permits[i].isEditing = true;
                              setPermits([...permits]);
                            }}
                          >
                            <FaEdit />
                            <span className="d-inline-block d-lg-none mx-2">
                              Edit
                            </span>
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="left"
                          overlay={<Tooltip>Delete</Tooltip>}
                        >
                          <Button
                            variant="danger"
                            className="text-light"
                            size="sm"
                            onClick={() => {
                              permits[i].isDeleting = true;
                              setPermits([...permits]);
                            }}
                          >
                            <FaTrashAlt />
                            <span className="d-inline-block d-lg-none mx-2">
                              Remove
                            </span>
                          </Button>
                        </OverlayTrigger>
                      </div>
                    )}
                    {permit.isEditing && (
                      <div className="d-flex">
                        <Button
                          variant="secondary"
                          className="w-100 me-1"
                          type="submit"
                        >
                          Save
                        </Button>
                        <Button
                          variant="warning"
                          className="w-100 ms-1"
                          onClick={() => {
                            permits[i].isEditing = false;
                            setPermits([...permits]);
                            handleReset();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                    {permit.isDeleting && (
                      <div className="d-flex">
                        <Button
                          variant="danger"
                          className="w-100 me-1"
                          onClick={() => {
                            removePermit(permit);
                          }}
                        >
                          Delete
                        </Button>
                        <Button
                          variant="warning"
                          className="w-100 ms-1"
                          onClick={() => {
                            permits[i].isDeleting = false;
                            handleReset();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ))
      ) : (
        <p className="text-center">No Permits</p>
      )}

      <p className="fw-bold mt-4 mb-1">Add Permit</p>
      <Formik
        initialValues={permitForm}
        enableReinitialize
        validationSchema={permitSchema}
        onSubmit={(values, { resetForm }) => {
          addPermit(values);
          resetForm();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form
            noValidate
            onSubmit={handleSubmit}
            className="mb-4"
            autoComplete="off"
          >
            <Row>
              <Form.Group
                as={Col}
                md={6}
                xl={3}
                className="mb-3"
                controlId="formPermitType"
              >
                <Form.Label>Permit Type</Form.Label>
                <Form.Control
                  type="text"
                  name="permitType"
                  value={values.permitType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.permitType && !!errors.permitType}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.permitType}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                md={6}
                xl={3}
                className="mb-3"
                controlId="formPermitNumber"
              >
                <Form.Label>Permit #</Form.Label>
                <Form.Control
                  type="text"
                  name="permitNumber"
                  value={values.permitNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.permitNumber && !!errors.permitNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.permitNumber}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                xl={6}
                className="mb-3"
                controlId="formPermitActiveExpireDate"
              >
                <Form.Label>Active / Expire Date</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="date"
                    name="activeDate"
                    value={values.activeDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.activeDate}
                  />
                  <Form.Control
                    type="date"
                    name="expireDate"
                    value={values.expireDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.expireDate && !!errors.expireDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.activeDate ?? errors.expireDate}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Row>
            <Button variant="secondary" className="w-100" type="submit">
              Add New Permit Record
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
