import axios from "axios";

export const getVersion = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Config/Version`
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
