import axios from "axios";

export const lookupMeshSizes = async (gearID?: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Lookup/meshSizes${
        gearID ? "?pGearID=" + gearID : ""
      }`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
