import axios, { AxiosRequestConfig } from "axios";

const config: AxiosRequestConfig = {
  responseType: "blob",
  headers: {
    Accept: "application/pdf",
  },
};

export const getCatchReportPreviewPDF = async (catchReportID: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/CatchReport/Export-PDF?CatchReportID=${catchReportID}`,
      config
    );

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: "application/pdf" })
    );
    const newWindow = window.open(url, "_blank");

    // Check if the new window was blocked by a popup blocker
    if (!newWindow) {
      alert(
        "The new window was blocked by a popup blocker. Please allow popups for this site."
      );
    }

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
