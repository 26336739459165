import SelectGrid from "../formControls/selectGrid";

import { useFormik } from "formik";
import { SetStateAction, useImperativeHandle, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { DailyTrip } from "../../models/dailyTrip";

export default function FishingLocation({
  initialValues,
  refId,
  formData,
  lakesList,
}: any) {
  // Submit form from parent component
  useImperativeHandle(refId, () => ({
    Submit: async () => {
      // Empty Errors Array
      const formErrors: SetStateAction<string[]> = [];

      //Submit grid form
      await formik.submitForm();

      for (let e in formik.errors) {
        formErrors.push(e);
      }

      // If there are multiple lakes selected
      if (multipleLakesError) {
        formErrors.push("You can only have grids selected in one lake.");
      }

      if (values.grids.length === 0) {
        formErrors.push("At least one grid needs to be selected");
      }

      if (formErrors.length === 0) {
        formData({
          values: {
            ...values,
          },
          validated: true,
        });
      } else {
        setErrors(formErrors);
      }
    },
  }));

  // Set init values
  const [values, setValues] = useState<DailyTrip>({ ...initialValues });
  const [multipleLakesError, setMultipleLakesError] = useState(false);
  const [showHelp, setShowHelp] = useState(
    localStorage.getItem("dtFishingLocation") === "true"
  );
  const [gridsLoaded, setGridsLoaded] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  // Handle form data when updated
  const handleChangeSelectGrid = (data: any) => {
      if (gridsLoaded) {
      // When changing the grids, update effort to be split evenly
      if (values.effort.length > 0) {
          values.effort.splice(1);
          values.effort[0].grids = data.grids;
      }

      // When changing the grids, update harvest to be split evenly
      values.harvest.forEach((harvest, i) => {
          values.harvest[i].grids = data.grids;
      });

      values.complete = false;
      values.manualEffort = false;
      values.manualHarvest = false;

      setValues({
          ...values,
          ...formik.values,
          lakeID: data.lakeID,
          lakeName: data.lakeName,
          grids: data.grids,
      });
    }
  };

  // Init Formik
  const formik = useFormik({
    initialValues: {
      grids: values.grids,
    },
    enableReinitialize: true,
    // validationSchema: Yup.object().shape({
    //   grids: Yup.array().min(1, "At least one grid needs to be selected"),
    // }),
    onSubmit: () => {},
  });

  return (
    <>
      <h2 className="mb-3 fw-normal">
        Select the lake and grids you fished in
        {/* {!showHelp && (
          <span
            onClick={() => {
              localStorage.setItem("dtFishingLocation", "true");
              setShowHelp(true);
            }}
            className="link fs-6 ms-3"
          >
            Help
          </span>
        )} */}
      </h2>
      <div className="mb-5">
        <ol>
          <li>Select the lake you fished in from the dropdown.</li>
          <li>
            Select where you fished (You can enter in the text field or click on
            the map).
          </li>
        </ol>
      </div>
      {/* {showHelp && (
        <Alert
          variant="info"
          onClose={() => {
            localStorage.setItem("dtFishingLocation", "false");
            setShowHelp(false);
          }}
          className="mb-4"
          dismissible
        >
          <Alert.Heading>
            Select the lake and grids you fished in.
          </Alert.Heading>
          <ol>
            <li>Select the lake you fished in from the dropdown.</li>
            <li>
              Select where you fished (You can enter in the text field or click
              on the map).
            </li>
          </ol>
        </Alert>
      )} */}

      <div id="trip-details" className="mb-2" ref={refId}>
        <SelectGrid
          formData={handleChangeSelectGrid}
          initialValues={{
            grids: values.grids,
            lakeID: values.lakeID,
            lakeName: values.lakeName,
          }}
          lakesList={lakesList}
          setMultipleLakesError={setMultipleLakesError}
          setGridsLoaded={setGridsLoaded}
        />
      </div>

      {/* Error Message */}
      {errors.length > 0 && (
        <Alert variant="danger" className="mt-4 mb-0">
          <Alert.Heading>Error</Alert.Heading>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </Alert>
      )}
    </>
  );
}
