import Accordion from "react-bootstrap/esm/Accordion";

export default function FAQs() {
  return (
    <Accordion
      defaultActiveKey="0"
      className="mb-4 submitted-catch-reports-accordion"
    >
      <Accordion.Item eventKey="0" className="mb-2 shadow-sm">
        <Accordion.Header>
          How do I submit a catch report using the system?
        </Accordion.Header>
        <Accordion.Body>
          <p>
            In order to submit a catch report using the system, you must first
            enter a daily trip record for every day of fishing during the
            reporting period. Please refer to the list of quick start videos
            above for step-by-step instructions on how to enter daily trips and
            submit catch reports.
          </p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1" className="mb-2 shadow-sm">
        <Accordion.Header>
          Can I submit my monthly catch reports online using this system instead
          of filling out my reports on paper?
        </Accordion.Header>
        <Accordion.Body>
          <p>
            Yes. You can use the system to submit your catch reports instead of
            filling the reports out on paper. After completing your report
            online, a copy of the report in PDF format will automatically be
            sent to your tribal administrator which will fulfil your reporting
            requirements.
          </p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
