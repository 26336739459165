import Button from "react-bootstrap/esm/Button";
import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Form from "react-bootstrap/Form";
import ReportsTable from "../../components/tables/reportsTable";
import { ColumnDef } from "@tanstack/react-table";
import { fuzzySort } from "../../components/tables/fuzzySort";
import { CatchReportPeriod } from "../../models/catchReportPeriod";
import { getCatchReportPeriods } from "../../api/getCatchReportPeriods";
import { getFormattedDateMMDDYYYY } from "../../helpers/getFormattedDateMMDDYYYY";
import { CatchReport } from "../../models/catchReport";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/esm/Tooltip";
import { FaExternalLinkAlt, FaEye, FaFileDownload } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getOrganizationID, getRoles } from "../../helpers/authHelper";
import { getCatchReportExportPDF } from "../../api/getCatchReportExportPDF";
import Spinner from "react-bootstrap/Spinner";
import SelectOrganization from "../../components/formControls/selectOrganization";
import { getCatchReportPeriodsAdmin } from "../../api/getCatchReportPeriodsAdmin";
import { getFormattedDateYYYYMMDD } from "../../helpers/getFormattedDateYYYYMMDD";
import { listCatchReportsExport } from "../../api/listCatchReportsExport";
import { getTripDataCSVDownload } from "../../api/getTripDataCSVDownload";
import { getTripDataChangeLogCSVDownload } from "../../api/getTripDataChangelogCSVDownload";

export default function AdminExportReports() {
  const [catchReportPeriods, setCatchReportPeriods] = useState<
    CatchReportPeriod[]
  >([]);
  const [tableCatchReports, setTableCatchReports] = useState<CatchReport[]>([]);
  const [selectedCatchReportIndex, setSelectedCatchReportIndex] = useState(0);
  const [includeLateCheck, setIncludeLateCheck] = useState(false);
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(-1);
  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);
  const [isLoadingReports, setIsLoadingReports] = useState(false);
  const [isDownloadingChangelog, setIsDownloadingChangelog] = useState(false);
  const [beginDate, setBeginDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedOrg, setSelectedOrg] = useState(
    getRoles().includes("CORA_Admin") ? -1 : getOrganizationID()
  );

  useEffect(() => {
    if (getRoles().includes("CORA_Admin")) {
      const fetch = async () => {
        const resCatchReportPeriods = await getCatchReportPeriodsAdmin({
          organizationID: selectedOrg,
          excludeSubmitted: false,
        });

        if (resCatchReportPeriods)
          setCatchReportPeriods([...resCatchReportPeriods]);
      };
      fetch();
    } else {
      const fetch = async () => {
        const resCatchReportPeriods = await getCatchReportPeriods({
          excludeSubmitted: false,
        });

        if (resCatchReportPeriods)
          setCatchReportPeriods([...resCatchReportPeriods]);
      };
      fetch();
    }
  }, [selectedOrg]);

  // When filter review changes, fetch the new trips and update the table
  useEffect(() => {
    getCatchReports();
  }, [beginDate, endDate, selectedCatchReportIndex, catchReportPeriods]);

  const getCatchReports = async () => {
    setIsLoadingReports(true);
    //If the end / start date differ from reporting period, set reporting period to blank
    if (catchReportPeriods[selectedCatchReportIndex]) {
      const isBeginDateSame =
        beginDate ===
        getFormattedDateYYYYMMDD(
          catchReportPeriods[selectedCatchReportIndex].beginDate
        );
      const isEndDateSame =
        endDate ===
        getFormattedDateYYYYMMDD(
          catchReportPeriods[selectedCatchReportIndex].endDate
        );

      if (!isBeginDateSame || !isEndDateSame) {
        setSelectedCatchReportIndex(-1);
      }
    }

    const resCatchReports = await listCatchReportsExport({
      beginDate: beginDate,
      endDate: endDate,
      organizationID: selectedOrg,
    });

    if (resCatchReports) {
      setTableCatchReports(resCatchReports);
    }

    setIsLoadingReports(false);
  };

  const handleSelectChange = async (event: any) => {
    const selectedOption = parseInt(event.target.value);
    setSelectedCatchReportIndex(selectedOption);
    setBeginDate(
      getFormattedDateYYYYMMDD(catchReportPeriods[selectedOption].beginDate)
    );

    setEndDate(
      getFormattedDateYYYYMMDD(catchReportPeriods[selectedOption].endDate)
    );
  };

  const downloadCatchReportsAsCSV = async () => {
    setIsDownloadingCSV(true);
    const params = {
      organizationID: selectedOrg,
      beginDate: beginDate,
      endDate: endDate,
    };
    await getTripDataCSVDownload(params);
    setIsDownloadingCSV(false);
  };

  const downloadChangelog = async () => {
    setIsDownloadingChangelog(true);
    const params = {
      organizationID: selectedOrg,
      beginDate: beginDate,
      endDate: endDate,
      includeLate: includeLateCheck,
    };
    await getTripDataChangeLogCSVDownload(params);
    setIsDownloadingChangelog(false);
  };

  const downloadPDF = async (catchReportID: number) => {
    setIsDownloadingPDF(catchReportID);
    await getCatchReportExportPDF(catchReportID);
    setIsDownloadingPDF(-1);
  };

  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorFn: (row) => row.displayName,
        id: "displayName",
        header: "Fisher Name",
        cell: (info) => {
          return <strong>{info.getValue()}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row: any) => {
          return `${getFormattedDateMMDDYYYY(
            row.beginDate
          )} - ${getFormattedDateMMDDYYYY(row.endDate)}`;
        },
        id: "reportingPeriod",
        header: "Reporting Period",
        cell: (info) => {
          return <strong>{info.getValue()}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.submittedDate,
        id: "submittedDate",
        header: "Submitted",
        cell: (info) => {
          return <strong>{getFormattedDateMMDDYYYY(info.getValue())}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.trips.length,
        id: "tripNum",
        header: "Trips",
        cell: (info) => {
          return <strong>{info.getValue()}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.helpers.length,
        id: "helperNum",
        header: "Helpers",
        cell: (info) => {
          return <strong>{info.getValue()}</strong>;
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row: any) => row,
        id: "actions",
        header: () => {
          return <div className="ms-auto">Actions</div>;
        },
        cell: (info: any) => {
          return (
            <div className="users-table d-flex justify-content-end">
              {/* Edit Button */}

              <OverlayTrigger
                placement="left"
                overlay={<Tooltip>View Catch Report</Tooltip>}
              >
                <Link
                  to={`/reports/edit/${info.getValue().id}`}
                  state={{ catchReport: info.getValue() }}
                  target="_blank"
                >
                  <Button
                    variant="secondary"
                    className="text-light ms-2"
                    size="sm"
                  >
                    <FaExternalLinkAlt />
                  </Button>
                </Link>
              </OverlayTrigger>

              {/* Print */}
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip>Download Catch Report</Tooltip>}
              >
                <Button
                  variant="secondary"
                  className="text-light ms-2"
                  size="sm"
                  onClick={() => downloadPDF(info.getValue().id)}
                >
                  {isDownloadingPDF === info.getValue().id ? (
                    <Spinner animation="border" variant="light" size="sm" />
                  ) : (
                    <FaFileDownload />
                  )}
                </Button>
              </OverlayTrigger>
            </div>
          );
        },
        footer: (props: any) => props.column.id,
        enableColumnFilter: false,
      },
    ],
    [tableCatchReports, isDownloadingPDF]
  );

  return (
    <div className="py-5">
      <Container fluid>
        <h1 className="text-primary mb-5 display-4 fw-bold">
          Export Tribal Data
        </h1>
        <p>Includes all fields for daily trips and catch reports.</p>

        <div className="form-card">
          <Row className="gx-3 mb-3">
            {getRoles().includes("CORA_Admin") && (
              <Col xs="auto" lg={4}>
                <SelectOrganization
                  allTribes
                  returnAllOrgs
                  includeCORA={false}
                  value={selectedOrg}
                  onChange={(e: any) => setSelectedOrg(e.target.value)}
                />
              </Col>
            )}

            <Col xs="auto">
              <Form.Group className="mb-3">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={beginDate}
                  onChange={(e) => setBeginDate(e.currentTarget.value)}
                  isInvalid={isNaN(new Date(beginDate).getTime())}
                />
              </Form.Group>
            </Col>
            <Col xs="auto">
              <Form.Group className="mb-3">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.currentTarget.value)}
                  isInvalid={isNaN(new Date(endDate).getTime())}
                />
              </Form.Group>
            </Col>
            <Col xs="auto">
              <Form.Group className="mb-3">
                <Form.Label>Select by Reporting Period</Form.Label>
                <Form.Select
                  onChange={handleSelectChange}
                  aria-label="Reporting Period"
                  value={selectedCatchReportIndex}
                >
                  <option value={-1}>---</option>
                  {catchReportPeriods.map((period: CatchReportPeriod, i) => (
                    <option key={i} value={i}>
                      {getFormattedDateMMDDYYYY(period.beginDate)} -{" "}
                      {getFormattedDateMMDDYYYY(period.endDate)}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs="auto" className="d-flex align-items-end">
              <Form.Check
                type="checkbox"
                name="includeLateCheck"
                id="includeLateCheck"
                label="Include Late Submissions When Downloading Changelog"
                className="mb-2"
                checked={includeLateCheck}
                onChange={() => setIncludeLateCheck(!includeLateCheck)}
              ></Form.Check>
            </Col>
          </Row>
          <Button variant="secondary" onClick={downloadCatchReportsAsCSV}>
            {isDownloadingCSV ? (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                className="me-2"
              />
            ) : (
              <FaFileDownload className="mt-n1 me-2" />
            )}
            Download Trip Data as CSV
          </Button>

          <Button
            variant="outline-primary"
            className="ms-2"
            onClick={downloadChangelog}
          >
            {isDownloadingChangelog ? (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                className="me-2"
              />
            ) : (
              <FaFileDownload className="mt-n1 me-2" />
            )}
            Download Changelog as CSV
          </Button>
        </div>

        {isLoadingReports ? (
          <div className="d-flex justify-content-center align-items-center mb-5">
            <Spinner animation="border" variant="primary" className="ms-2" />
            <span className="h3 ms-3 mb-0 text-primary">
              Loading Reports...
            </span>
          </div>
        ) : (
          <>
            <h2 className="mb-3">Included Catch Reports</h2>
            <ReportsTable
              className="mb-5"
              columns={columns}
              catchReports={tableCatchReports}
            />
          </>
        )}
      </Container>
    </div>
  );
}
