import axios, { AxiosRequestConfig } from "axios";
import { convertObjectToUrlParams } from "../helpers/convertObjToUrlParams";
import { getFormattedDateMMDDYYYY } from "../helpers/getFormattedDateMMDDYYYY";

const config: AxiosRequestConfig = {
  responseType: "blob",
  headers: {
    Accept: "text/csv",
  },
};

interface params {
  organizationID: number;
  beginDate?: string;
  endDate?: string;
}

export const getTripDataChangeLogCSVDownload = async (params: params) => {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_API
      }/Export/TripData-ChangeLog-CSV-Download${convertObjectToUrlParams(
        params
      )}`,
      config
    );

    let fileName;

    if (params.beginDate && params.endDate) {
      fileName = `Catch Reports Changelog Export ${getFormattedDateMMDDYYYY(
        params.beginDate
      )} - ${getFormattedDateMMDDYYYY(params.endDate)}`;
    } else {
      fileName = "Catch Reports Changelog Export";
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.style.display = "none";
    link.setAttribute("download", `${fileName}.csv`);

    document.body.appendChild(link);
    link.click();

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
