import jwtDecode from "jwt-decode";

interface Token {
  UserID: string;
  UserName: string;
  Phone: string;
  Email: string;
  OrganizationID: string;
  OrganizationName: string;
  TribalOrganization: string;
  role: string;
}

function auth() {
  const accessToken = localStorage.getItem("accessToken");

  if (accessToken) {
    const decoded = jwtDecode<Token>(accessToken);
    return decoded;
  } else {
    return null;
  }
}

export const getUserID = () => {
  return auth()?.UserID ?? "";
};

export const getUserName = () => {
  return auth()?.UserName ?? "";
};

export const getPhone = () => {
  return auth()?.Phone ?? "";
};

export const getEmail = () => {
  return auth()?.Email ?? "";
};

export const getOrganizationID = () => {
  const orgID = auth()?.OrganizationID;

  if (orgID) {
    return parseInt(orgID);
  }
  return 0;
};

export const getOrganizationName = () => {
  return auth()?.OrganizationName ?? "";
};

export const getTribalOrganization = () => {
  const tribeOrg = auth()?.TribalOrganization;

  if (tribeOrg === "True") {
    return true;
  } else {
  }
  return false;
};

export const getRoles = () => {
  return (auth()?.role ?? "").split(",");
};
