import axios from "axios";

export interface ManagementUnitGrid {
  id: number;
  gridnumber: number;
  lakeCode: string;
  lakeName: string;
  ltu: string;
  wfm: string;
  dist: string;
}

export const listManagementUnitGrids = async (
  lakeID?: any
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/Lookup/managementunitgrids?lakeID=${lakeID || ''}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    const result: string[] = [];
    return result;
  }
};
