import React from "react";
import Container from "react-bootstrap/esm/Container";
import UsersTable from "../../components/tables/usersTable";
import { ColumnDef } from "@tanstack/react-table";
import { fuzzySort } from "../../components/tables/fuzzySort";
import {
  Fisher,
  listOrganizationFishers,
} from "../../api/listOrganizationFishers";
import { Link } from "react-router-dom";

export default function AdminAllFishers() {
  const columns = React.useMemo<ColumnDef<Fisher, any>[]>(
    () => [
      {
        accessorFn: (row: any) => {
          return { displayName: row.displayName, userID: row.userID };
        },
        id: "displayName",
        header: "Name",
        cell: (info) => {
          return (
            <strong>
              <Link to={`/fisher/${info.getValue().userID}`} target="_blank" rel="noopener noreferrer">
                {info.getValue().displayName}
              </Link>
            </strong>
          );
        },
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.userName,
        id: "userName",
        header: "Username",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.email,
        id: "email",
        header: "Email",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      //{
      //  accessorFn: (row) => row.profile.biaNumber,
      //  id: "bia",
      //  header: "BIA Number",
      //  cell: (info) => info.getValue(),
      //  footer: (props) => props.column.id,
      //  filterFn: "fuzzy",
      //  sortingFn: fuzzySort,
      //},
      {
        accessorFn: (row) => row.profile.organizationCodeNumber,
        id: "code",
        header: "Tribal Code",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.commercialLicense,
        id: "commercial",
        header: "Commercial",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.subsistenceLicense,
        id: "subsistence",
        header: "Subsistence",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.status,
        id: "status",
        header: "Status",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => row.licenseStatus,
        id: "licenseStatus",
        header: "License Status",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
    ],
    []
  );

  return (
    <div className="py-5">
      <Container fluid>
        <h1 className="text-primary mb-5">Fishers</h1>
        <UsersTable
          columns={columns}
          lookupList={listOrganizationFishers}
          role={"fisher"}
        />
      </Container>
    </div>
  );
}
