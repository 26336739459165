import { Dispatch, SetStateAction } from "react";

import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { onboardingComplete } from "../../../api/onboardingComplete";
import FAQs from "../../shared/components/faqs";
import InstallPWA from "../../../components/installPWA";

export default function FisherOnboardingStepThree({
  setOnboardingStep,
  promptInstall,
  supportsPWA,
}: any) {
  return (
    <>
      <Modal.Body className="py-5 px-2 px-sm-4 px-md-5 bg-background rounded">
        {/* Header */}
        <div className="onboarding-header">
          <h1 className="text-center text-primary mb-2">Getting started</h1>
          <p className="lead text-bold text-center mb-5">
            Here are some helpful resources to get started.
            <br className="d-none d-md-block" />
            If you ever get lost or need these resources again, you can find
            them again on the Support page.
          </p>
          <div className="text-center mb-5 d-block d-lg-none">
            {supportsPWA && <InstallPWA promptInstall={promptInstall} />}
          </div>
        </div>

        {/* Body */}
        <div className="onboarding-body">
          <div className="form-card mb-3">
            <h2 className="h4 mb-3">Walkthrough videos</h2>
            <Row className="mb-5">
              <Col md={4}>
                <div className="video-container">
                  <iframe
                    className="video"
                    src="https://www.youtube.com/embed/nNuYQTtCbgI"
                    title="CORAFish Account Registration"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              </Col>
              <Col md={4}>
                <div className="video-container">
                  <iframe
                    className="video"
                    src="https://www.youtube.com/embed/gqK8GUofhNg"
                    title="Daily Trip Quick Start"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              </Col>
              <Col md={4}>
                <div className="video-container">
                  <iframe
                    className="video"
                    src="https://www.youtube.com/embed/6r0QSW_kyOU"
                    title="Submit a Catch Report"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              </Col>
            </Row>
          </div>

          <div className="form-card mb-3">
            <h2 className="h4 mb-3">Common FAQs</h2>
            <FAQs />
          </div>

          {/* Buttons */}
          <Row>
            <Col className="mb-3">
              <Button
                variant="success"
                size="lg"
                className="w-100 h-100"
                onClick={() => {
                  setOnboardingStep(0);
                  onboardingComplete();
                  localStorage.setItem("initialFlow", "False");
                }}
              >
                Let's dive in!
              </Button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </>
  );
}
