import { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/esm/Button";
import passwordReg from "../../helpers/passwordReg";
import { changePassword } from "../../api/changePassword";
import Alert from "react-bootstrap/Alert";
import { getUserName } from "../../helpers/authHelper";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { apiSuccess } from "../../helpers/apiSuccess";

export default function EditUsernamePassword({ userID }: any) {
  const [showPass, setShowPass] = useState(false);

  const [alertMessage, setAlertMessage] = useState({
    show: false,
    variant: "",
    message: "",
  });

  const schema = Yup.object().shape({
    currentPassword: Yup.string().required("Old Password is required"),
    newPassword: Yup.string()
      .required("Password required")
      .min(10, "Minimum ten characters, at least one letter and one number")
      .test(
        "isValidPass",
        "At least one letter and one number",
        (value: any, context) => {
          return passwordReg(value, context);
        }
      ),
  });

  return (
    <div className="form-card">
      <Formik
        initialValues={{ currentPassword: "", newPassword: "" }}
        enableReinitialize
        validationSchema={schema}
        onSubmit={(values) => {
          const setPass = async () => {
            const response = await changePassword({
              current: values.currentPassword,
              new: values.newPassword,
            });

            if (apiSuccess(response.status)) {
              setAlertMessage({
                show: true,
                variant: "success",
                message: "Password changed successfully",
              });
            } else {
              setAlertMessage({
                show: true,
                variant: "danger",
                message: `Error, ${response.data.message}`,
              });
            }
          };
          setPass();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form id="reset-password" noValidate onSubmit={handleSubmit}>
            <Row className=" mb-3">
              <Form.Group
                as={Col}
                xs={12}
                className="mb-3"
                controlId="formTribe"
              >
                <Form.Label>Username</Form.Label>
                <Form.Control type="text" disabled value={getUserName()} />
              </Form.Group>

              <Form.Group
                as={Col}
                lg={true}
                controlId="formCurrentPassword"
                className="mb-3 mb-lg-0"
              >
                <Form.Label>Current password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPass ? "text" : "password"}
                    name="currentPassword"
                    value={values.currentPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.currentPassword && !!errors.currentPassword
                    }
                  />
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="password-tooltip">
                        {showPass ? "Hide password" : "Show password"}
                      </Tooltip>
                    }
                  >
                    <InputGroup.Text onClick={() => setShowPass(!showPass)}>
                      <>{showPass ? <FaEyeSlash /> : <FaEye />}</>
                    </InputGroup.Text>
                  </OverlayTrigger>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors.currentPassword}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                lg={true}
                controlId="formNewPassword"
                className="mb-3 mb-lg-0"
              >
                <Form.Label>New password*</Form.Label>
                <Form.Control
                  type="text"
                  name="newPassword"
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.newPassword && !!errors.newPassword}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.newPassword}
                </Form.Control.Feedback>
              </Form.Group>
              <Col lg>
                <Button
                  variant="secondary"
                  className="w-100 form-btn"
                  type="submit"
                >
                  Change Password
                </Button>
              </Col>
            </Row>
            <p>
              *Password minimum length 10 characters with at least one letter
              and one number
            </p>
            {alertMessage.show && (
              <Alert variant={alertMessage.variant}>
                {alertMessage.message}
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
