import React, { useEffect } from "react";
import Table from "react-bootstrap/esm/Table";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";
import DebouncedInput from "./debouncedInput";
import Filter from "./tableHeaderFilter";
import { Link } from "react-router-dom";

import {
  FaSort,
  FaSortUp,
  FaSortDown,
  FaGreaterThan,
  FaLessThan,
  FaEdit,
  FaTrashAlt,
} from "react-icons/fa";

import {
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  flexRender,
} from "@tanstack/react-table";

import { RankingInfo } from "@tanstack/match-sorter-utils";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/esm/Tooltip";
import { fuzzyFilter } from "./fuzzyFilter";
import { deleteHelper } from "../../api/deleteHelper";
import { getOrganizationID } from "../../helpers/authHelper";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }

  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

export default function HelpersTable({ columns, lookupList }: any) {
  const [selectedOrg, setSelectedOrg] = React.useState(getOrganizationID());

  // Add Actions Column to end of the Table
  if (!columns.find((c: any) => c.id === "actions")) {
    columns.push({
      accessorFn: (row: any) => {
        return { helperID: row.helperID, status: row.status };
      },
      id: "actions",
      header: () => {
        return <div className="ms-auto">Actions</div>;
      },
      cell: (info: any) => {
        return (
          <div className="users-table d-flex justify-content-end">
            {/* Edit Button */}
            <OverlayTrigger placement="left" overlay={<Tooltip>Edit</Tooltip>}>
              <Link to={`/helper/edit/${info.getValue().helperID}`}>
                <Button
                  variant="secondary"
                  className="text-light ms-2"
                  size="sm"
                >
                  <FaEdit />
                </Button>
              </Link>
            </OverlayTrigger>

            <OverlayTrigger
              placement="left"
              overlay={<Tooltip>Delete</Tooltip>}
            >
              <Button
                variant="danger"
                className="text-light ms-2"
                size="sm"
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete this helper?"
                    )
                  ) {
                    const deleteH = async () => {
                      await deleteHelper(info.getValue().helperID);
                      fetchData();
                    };
                    deleteH();
                  }
                }}
              >
                <FaTrashAlt />
              </Button>
            </OverlayTrigger>
          </div>
        );
      },
      footer: (props: any) => props.column.id,
      enableColumnFilter: false,
    });
  }

  // Set data and filter states
  const [data, setData] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [showColumnFilter, setShowColumnFilter] = React.useState(false);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  // Get and update data on load and org change
  useEffect(() => {
    fetchData();
  }, [selectedOrg]);

  const fetchData = async () => {
    const response = await lookupList();
    if (response) setData(response);
  };

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    // manualPagination: true,
    // pageCount: 20,
    initialState: {
      pagination: {
        pageSize: 15,
      },
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  });

  return (
    <>
      {/* Table Header Controls */}
      <div className="w-100 table-header d-flex flex-wrap">
        <Link to={`/register/helper`}>
          <Button variant="primary" className="me-2 mb-3 text-capitalize">
            Register New Helper
          </Button>
        </Link>

        <Button
          variant="secondary"
          className="me-2 mb-3 d-inline"
          onClick={() => {
            setShowColumnFilter(!showColumnFilter);
          }}
        >
          {showColumnFilter ? "Hide" : "Show"} Filters
        </Button>

        {/* <SelectOrganization
          value={selectedOrg}
          onChange={(e: any) => setSelectedOrg(e.target.value)}
          enabled={getRoles().includes("CORA_Admin")}
        /> */}

        <DebouncedInput
          value={globalFilter ?? ""}
          onChange={(value) => setGlobalFilter(String(value))}
          className="form-control d-inline-block  w-auto mb-3"
          placeholder="Search everything..."
        />
      </div>

      {/* Table */}
      <Table striped hover responsive>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ verticalAlign: "top" }}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer d-flex align-items-center"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <FaSortUp className="text-primary ms-1" />,
                            desc: <FaSortDown className="text-primary ms-1" />,
                          }[header.column.getIsSorted() as string] ?? (
                            <FaSort className="text-gray ms-1" />
                          )}
                        </div>
                        {header.column.getCanFilter() && showColumnFilter ? (
                          <div>
                            <Filter column={header.column} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} className="align-middle">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      {/* Table footer */}
      <div className="d-flex flex-row justify-content-end align-items-center">
        <span className="text-nowrap me-3">
          {`${
            table.getState().pagination.pageIndex *
            table.getState().pagination.pageSize
          }-${
            table.getState().pagination.pageIndex *
              table.getState().pagination.pageSize +
            table.getState().pagination.pageSize
          } of ${table.getPrePaginationRowModel().rows.length}`}
        </span>

        <Form.Select
          aria-label="Table rows per page"
          size="sm"
          style={{ maxWidth: "10rem" }}
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[15, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Rows per page {pageSize}
            </option>
          ))}
        </Form.Select>

        <Button
          variant="light-gray"
          size="sm"
          className="ms-2 text-primary"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <FaLessThan />
        </Button>
        <Button
          variant="light-gray"
          size="sm"
          className="ms-2"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <FaGreaterThan className="text-primary" />
        </Button>
      </div>
    </>
  );
}
